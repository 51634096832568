import React, { useEffect, useState } from 'react';
import Layout from 'common/Layout/index';
import styles from './index.module.less';
import VerticalBarChart from './verticalBarChart';
import StackedBarChart from './stackedBarChart';
import Table_open_allocated from './table_open_allocated';
import Top_BU_Chart from './top_BU_chart';
import Top_Project_Chart from './top_project_chart';
import SpeedometerChart from './speedometerChart';
import { Spin, Space, Select, Divider } from 'antd';
import { dateRangeDashboard } from 'utilities/helpers';
import CardStatus from './cardStatus';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardCount } from '../../redux/slice';
import moment from 'moment';

const { Option } = Select;

export default function CharView() {
  const dispatch = useDispatch();

  const {
    open_vs_allocated_count_BU_wise,
    total,
    initiated,
    proposed,
    ratified,
    allocated,
    getDashboardCountLoading,
    ageing_category,
    ageing_data,
    top_five_project_by_invoices
  } = useSelector((state) => state.invoices);

  const [selectedDate, setSelectedDate] = useState(
    dateRangeDashboard[2]?.value
  );

  useEffect(() => {
    let payload = {
      from_date: selectedDate,
      to_date: moment().format('DD MMM YYYY')
    };
    dispatch(getDashboardCount(payload));
  }, [selectedDate]);

  //console.log('ageing_category', ageing_category);
  //console.log('ageing_data', ageing_data);

  const handleChangeDateRange = (value) => {
    setSelectedDate(value);
  };
  return (
    <Layout>
      <Space className={`${styles.title_container} mb-3`}>
        <div className={`${styles.text_Title} sfprotext-bold font-22`}>
          FundCentral Analytics
        </div>

        <div className={`${styles.dateRange_container}`}>
          <Select
            defaultValue={dateRangeDashboard[2]}
            style={{ width: 220 }}
            onChange={handleChangeDateRange}
            // options={dateRangeDashboard}
          >
            {dateRangeDashboard.map((item, index) => {
              const { label, value } = item;

              return (
                <Option key={index} value={value} label={label}>
                  <div style={{ fontSize: '12px' }}>{label}</div>
                </Option>
              );
            })}
          </Select>
        </div>
      </Space>
      <Divider />
      <Spin spinning={getDashboardCountLoading}>
        <CardStatus
          total={total}
          initiated={initiated}
          proposed={proposed}
          ratified={ratified}
          allocated={allocated}
        />
        <div className={`${styles.chartView_container} mb-3`}>
          <StackedBarChart count_BU_wise={open_vs_allocated_count_BU_wise} />
        </div>
        <Space className={`${styles.speedometerView_container} mb-3`}>
          <div className={`${styles.speedometer_container}`}>
            <SpeedometerChart ageing_data={ageing_data} />
          </div>
          <div className={`${styles.speedometer_VerticalBar_container}`}>
            <VerticalBarChart
              count_BU_wise={open_vs_allocated_count_BU_wise}
              ageing_category={ageing_category}
            />
          </div>
        </Space>
        <Space className={`${styles.tableView_container} mb-3`}>
          <div className={`${styles.table_container}`}>
            <Table_open_allocated
              count_BU_wise={open_vs_allocated_count_BU_wise}
            />
          </div>

          <div className={`${styles.horiz_container} `}>
            <Top_BU_Chart count_BU_wise={open_vs_allocated_count_BU_wise} />
          </div>
          <div className={`${styles.horiz_container} `}>
            <Top_Project_Chart
              top_five_project_by_invoices={top_five_project_by_invoices}
            />
          </div>
        </Space>
      </Spin>
    </Layout>
  );
}
