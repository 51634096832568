import { Tooltip } from 'antd';
import CustomTable from 'common/Content/CustomTable';
import styles from './index.module.less';

export default function Table_open_allocated({ count_BU_wise }) {
  console.log('count_BU_wise', count_BU_wise);
  const columns = [
    {
      title: <span>BU</span>,
      dataIndex: 'BU_name',
      key: 'BU_name',
      sorter: (a, b) => a.BU_name.localeCompare(b.BU_name),
      ellipsis: true,
      // width: '200px',
      render: (BU_name) => {
        return (
          <>
            <Tooltip
              title={BU_name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
              placement="top"
            >
              <span style={{ textTransform: 'uppercase' }}>{BU_name}</span>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Open', //style={{backgroundColor:"white !important", color:"black"}}
      dataIndex: 'total_open_invoices',
      key: 'total_open_invoices',
      sorter: (a, b) =>
        Number(a.total_open_invoices) - Number(b.total_open_invoices),
      ellipsis: {
        showTitle: false
      },
      //  width: '200px',
      render: (total_open_invoices) => {
        return (
          <>
            <Tooltip
              title={total_open_invoices}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
              placement="top"
            >
              {total_open_invoices}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'Allocated', //style={{backgroundColor:"white !important", color:"black"}}
      dataIndex: 'total_allocated_invoices',
      key: 'total_allocated_invoices',
      sorter: (a, b) =>
        Number(a.total_allocated_invoices) - Number(b.total_allocated_invoices),
      ellipsis: {
        showTitle: false
      },
      //  width: '200px',
      render: (total_allocated_invoices) => {
        return (
          <>
            <Tooltip
              title={total_allocated_invoices}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
              placement="top"
            >
              {total_allocated_invoices}
            </Tooltip>
          </>
        );
      }
    }
  ];

  return (
    <div>
      <div className={`${styles.table_title}`}>
        <span
          style={{
            fontSize: '12px',
            fontWeight: 600,
            color: 'rgba(0,0,0,0.85)'
          }}
        >
          {' '}
          Open Invoices Vs. Allocated Invoices (BU-wise)
        </span>
      </div>
      <CustomTable
        data={count_BU_wise}
        columns={columns}
        pagination={{
          size: 'small'
        }}
      />
    </div>
  );
}
