import React from 'react';
import { Col, Row, Typography } from 'antd';
// import CustomButton from '../CustomButton';

const { Text } = Typography;

const Header = ({ title, titleSuffix, rightComponent, ...rest }) => {
  return (
    <Row {...rest}>
      <Col>
        <Text className="sfprotext-bold font-22">{title}</Text>
        {titleSuffix ? (
          <Text className="font-15 ml-1" style={{ fontWeight: '600' }}>
            {titleSuffix}
          </Text>
        ) : null}
      </Col>
      {rightComponent ? <Col>{rightComponent}</Col> : null}
    </Row>
  );
};

export default Header;
