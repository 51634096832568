import apiClient from 'utilities/apiClient';

export const invoices = (role, type, payload) => {
  return apiClient.get(
    `${apiClient.Urls.apiUrl}/${role}/${type}`,
    payload,
    true
  );
};

export const vendorList = (payload) => {
  return apiClient.get(apiClient.Urls.vendorList, payload, true);
};

export const activeBankList = (payload) => {
  return apiClient.get(apiClient.Urls.activeBankList, payload, true);
};

export const allocationRequest = (role, payload) => {
  return apiClient.post(
    `${apiClient.Urls.apiUrl}/${role}/newAllocationRequest`,
    payload,
    true
  );
};

export const requestFund = (role, payload) => {
  return apiClient.post(
    `${apiClient.Urls.apiUrl}/${role}/requestFund`,
    payload,
    true
  );
};

export const buApproveAllocationRequest = (role, payload) => {
  return apiClient.post(
    `${apiClient.Urls.apiUrl}/${role}/approveAllocationRequest`,
    payload,
    true
  );
};

export const buForwardAllocationRequest = (role, payload) => {
  return apiClient.post(
    `${apiClient.Urls.apiUrl}/${role}/forwardAllocationRequest`,
    payload,
    true
  );
};

export const projectList = (role, payload) => {
  return apiClient.get(
    `${apiClient.Urls.apiUrl}/${role}/getProjectList`,
    payload,
    true
  );
};

export const buList = (role, payload) => {
  return apiClient.get(
    `${apiClient.Urls.apiUrl}/${role}/getBUList`,
    payload,
    true
  );
};
export const activeDebitBankList = (payload) => {
  return apiClient.get(apiClient.Urls.activeDebitBankList, payload, true);
};
export const vendorwise = (role, type1, type2, payload) => {
  return apiClient.get(
    `${apiClient.Urls.apiUrl}/${role}/${type1}/${type2}`,
    payload,
    true
  );
};

export const sendBackAllocationRequest = (role, payload) => {
  return apiClient.get(
    `${apiClient.Urls.apiUrl}/${role}/sendBackAllocationRequest`,
    payload,
    true
  );
};

export const exportPaidInvoices = (role, payload) => {
  return apiClient.get(
    `${apiClient.Urls.apiUrl}/${role}/exportPaidInvoices`,
    payload,
    true
  );
};

export const normalViewCount = (role, payload) => {
  return apiClient.get(
    `${apiClient.Urls.apiUrl}/${role}/normal_view_count`,
    payload,
    true
  );
};

export const dashboardCount = (payload) => {
  return apiClient.get(apiClient.Urls.dashboardCount, payload, true);
};

export const checkVendorStatus = (payload) => {
  return apiClient.get(apiClient.Urls.checkVendorStatus, payload, true);
};

export const sendBackAllocRequestInvoiceWise = (role, payload) => {
  return apiClient.post(
    `${apiClient.Urls.apiUrl}/${role}/sendBackAllocRequestInvoiceWise`,
    payload,
    true
  );
};
export const inboundFailed_List = (role, payload) => {
  return apiClient.get(
    `${apiClient.Urls.apiUrl}/${role}/getInboundFailedList`,
    payload,
    true
  );
};
export const retryInboundPost = (role, payload) => {
  return apiClient.post(
    `${apiClient.Urls.apiUrl}/${role}/retryInbound`,
    payload,
    true
  );
};
