//import styles from './index.module.less';
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export const options = {
  plugins: {
    legend: {
      position: 'hide'
    },
    title: {
      display: true,
      text: 'Ageing-wise Invoice Breakdown'
    }
  },
  responsive: true,
  scales: {
    x: {
      stacked: true
    },
    y: {
      stacked: true
    }
  }
};

/* export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' //as const,
    },
    title: {
      display: true,
      text: 'Chart.js Bar Chart'
    }
  },
  scales: {
    xAxes: [{ barPercentage: 0 }]
  },
 // maintainAspectRatio: false
}; */

export default function VerticalBarChart({ ageing_category }) {
  const ageing_category_Array = Object.values(ageing_category);

  const data = {
    labels: ['<30 days', '<60 days', '<90 days', '<180 days'],

    datasets: [
      {
        label: 'Ageing category',
        data: ageing_category_Array?.map((item) => item),
        backgroundColor: '#4059e5', //'#7673FF',
        barThickness: 30
      }
      /*  {
        label: 'allocated invoices',
        data: count_BU_wise?.map((item) => item?.total_allocated_invoices),
        backgroundColor: '#C2C0FF', //#4059E5',
        barThickness: 30
      } */
    ]
  };
  return (
    <div>
      <Bar options={options} data={data} width={750} height={220} />
    </div>
  );
}
