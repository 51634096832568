import React, { useEffect, useState } from 'react';
import Layout from '@/common/Layout/index';
import FinanceCoEPage from './component/FinanceCoEPage';
import { useDispatch, useSelector } from 'react-redux';
import {
  getActiveBankList,
  //getInvoices,
  getAllocatedInvoices,
  getPaidInvoices,
  getDisbursedInvoices,
  getVendorList,
  PostBuRequestFundAllocation,
  getProjectList,
  getBUList,
  getActiveDebitBankList
} from './redux/slice';
import { postSendBackAllocRequestInvoiceWise } from '../../redux/slice';
//import { getUserProfile } from '../../../user/redux/slice';
import { getNormalViewCount } from '../../redux/slice';
import { getSendBackAllocRequest } from '../../redux/slice';
import {
  filterToPayload,
  invoiceFilters,
  userRole,
  getLoggedInUser,
  invoiceType
} from 'utilities/helpers';
import { message } from 'antd';

const FinanceCoE = () => {
  const dispatch = useDispatch();
  //const { projectAssigned, BU_assigned } = useSelector((state) => state.user);
  const { SBUList } = useSelector((state) => state.treasury);
  const {
    allInvoicesPagination,
    vendorList,
    activeBankList,
    postAllocationRequestLoading,
    openInvoicesPagination,
    paidInvoicesLoading,
    paidInvoices,
    paidInvoicesPagination,
    pendingInvoicesPagination,
    rejectedInvoicesPagination,
    allocatedInvoicesLoading,
    allocatedInvoices,
    allocatedInvoicesPagination,
    approvedInvoicesPagination,
    disbursedInvoices,
    disbursedInvoicesPagination,
    disbursedInvoicesLoading,
    projectList,
    BUList,
    activeDebitBankList,
    disbursed,
    paid,
    allocated,
    postSendBackAllocRequestInvoiceWiseLoading
  } = useSelector((state) => state.invoices);
  // console.log('allInvoices', allInvoices);disbursed

  const [page, setPage] = useState(1);
  const [pageGl, setPageGl] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [glView, setGlView] = useState(true);
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [reqAllocInvoiceKeys, setReqAllocInvoiceKeys] = useState([]);
  const [reqAllocInvoices, setReqAllocInvoices] = useState([]);
  const [filterValues, setFilterValues] = useState(invoiceFilters);
  const [openReqAllocModal, setOpenReqAllocModal] = useState(false);
  const [tab, setTab] = useState(1);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [vendorSearch, setVendorSearch] = useState('');
  const [projectSearch, setProjectSearch] = useState('');
  const [openExportAlloModal, setOpenExportAlloModal] = useState(false);
  const [openReqRejectModal, setOpenReqRejectModal] = useState(false);
  const [lodingTLReport, setLodingTLReport] = useState(false);

  const user_role1 = getLoggedInUser();
  const user_role = user_role1?.user_details?.role;
  // console.log('loginUser', user_role1?.accessToken);
  const loading = () => {
    switch (tab) {
      case 1:
        return allocatedInvoicesLoading;
      case 2:
        return paidInvoicesLoading;
      case 3:
        return disbursedInvoicesLoading;
      default:
        return false;
    }
  };
  const invoices = () => {
    switch (tab) {
      case 1:
        return allocatedInvoices;
      case 2:
        return paidInvoices;
      case 3:
        return disbursedInvoices;
      default:
        return [];
    }
  };

  const pagination = () => {
    switch (tab) {
      case 1:
        return allocatedInvoicesPagination;
      case 2:
        return paidInvoicesPagination;
      case 3:
        return disbursedInvoicesPagination;
      default:
        return null;
    }
  };
  // console.log('.tds_project_code@@@@@@', filterValues?.tds_project_code);
  // console.log('vendorSearch@@@@@@', filterValues?.vendor);
  //console.log('filterValues######', filterValues);

  useEffect(() => {
    dispatch(getActiveDebitBankList());
  }, []);

  const sendBackAllocRequestHandler = () => {
    let payload = {};
    dispatch(
      getSendBackAllocRequest({ role: userRole(user_role), payload })
    ).then(() => {
      handleGetInvoices();
      handleNormalViewCount();
    });
  };

  const getExportPaidInvoicesHandler = () => {
    glView === true
      ? fetch(
          `${process.env.REACT_APP_API_URL}/Finance/exportPaidInvoices?is_H2H=false`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/csv',
              Authorization: `Bearer ${user_role1?.accessToken}`
            }
          }
        ).then((response) => {
          if (response?.status !== 500) {
            response.blob().then((blob) => {
              const url = window.URL.createObjectURL(new Blob([blob]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `a2p_exported_paid_invoices.csv`);

              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
            });
          } else {
            message.success('No any paid invoices found');
            console.log('responceNOT');
          }
        })
      : fetch(
          `${process.env.REACT_APP_API_URL}/Finance/exportPaidInvoices?is_H2H=true`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/csv',
              Authorization: `Bearer ${user_role1?.accessToken}`
            }
          }
        ).then((response) => {
          if (response?.status !== 500) {
            response.blob().then((blob) => {
              const url = window.URL.createObjectURL(new Blob([blob]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `a2p_exported_paid_invoices.csv`);

              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
            });
          } else {
            message.success('No any paid invoices found');
            console.log('responceNOT');
          }
        });
  };

  const getTLReportsHandler = () => {
    setLodingTLReport(true);
    fetch(
      `${process.env.REACT_APP_API_URL}/invoice/exportProjectVendorReport`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/csv',
          Authorization: `Bearer ${user_role1?.accessToken}`
        }
      }
    ).then((response) => {
      if (response?.status !== 500) {
        setLodingTLReport(false);
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `a2p_exported_TL_report.csv`);

          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        });
      } else {
        message.success('No any TL report found');
        console.log('responceNOT');
      }
    });
  };

  useEffect(() => {
    const invoice_type = invoiceType[0]?.value;
    setTriggerFilter(true);
    setFilterValues({ invoice_type: invoice_type });
  }, [invoiceType]);

  // useEffect(() => {
  //   handleNormalViewCount();
  // }, [filterValues, glView, tab]); //filterValues?.invoice_type, glView,

  const handleNormalViewCount = () => {
    if (glView === true) {
      if (filterValues?.invoice_type === 'non_H2H') {
        let payload = {
          is_H2H: false,
          invoice_type: 'non_H2H',
          BU_name: filterValues?.BU_name ? filterValues?.BU_name : 'All',
          tds_project_code: filterValues?.tds_project_code
            ? filterValues?.tds_project_code
            : 'All',
          vendor_code: filterValues?.vendor ? filterValues?.vendor : 'All',
          GL_date: filterValues?.gl_date ? filterValues?.gl_date : 'All',
          age: filterValues?.age ? filterValues?.age : 'All',
          search_invoice: filterValues?.search_invoice
            ? filterValues?.search_invoice
            : ''
        };
        dispatch(getNormalViewCount({ role: userRole(user_role), payload }));
      } else {
        let payload = {
          is_H2H: false,
          invoice_type: 'foreign',
          BU_name: filterValues?.BU_name ? filterValues?.BU_name : 'All',
          tds_project_code: filterValues?.tds_project_code
            ? filterValues?.tds_project_code
            : 'All',
          vendor_code: filterValues?.vendor ? filterValues?.vendor : 'All',
          GL_date: filterValues?.gl_date ? filterValues?.gl_date : 'All',
          age: filterValues?.age ? filterValues?.age : 'All',
          search_invoice: filterValues?.search_invoice
            ? filterValues?.search_invoice
            : ''
        };
        dispatch(getNormalViewCount({ role: userRole(user_role), payload }));
      }
    } else {
      let payload = {
        invoice_type: 'H2H',
        is_H2H: true,
        BU_name: filterValues?.BU_name ? filterValues?.BU_name : 'All',
        tds_project_code: filterValues?.tds_project_code
          ? filterValues?.tds_project_code
          : 'All',
        vendor_code: filterValues?.vendor ? filterValues?.vendor : 'All',
        invoice_date: filterValues?.invoice_date
          ? filterValues?.invoice_date
          : 'All',
        age: filterValues?.age ? filterValues?.age : 'All',
        search_invoice: filterValues?.search_invoice
          ? filterValues?.search_invoice
          : ''
      };
      dispatch(getNormalViewCount({ role: userRole(user_role), payload }));
    }
  };

  useEffect(() => {
    let payload = {};
    setTriggerFilter(true);
    dispatch(getBUList({ role: userRole(user_role), payload }));
  }, []);

  useEffect(() => {
    handleGetProjectList();
  }, [filterValues?.BU_name, projectSearch]);

  const handleGetProjectList = () => {
    let payload = {
      BU_name: filterValues?.BU_name
      //search_project: projectSearch
    };
    if (projectSearch) payload['search_project'] = projectSearch;
    filterValues?.BU_name === undefined || filterValues?.BU_name === 'All'
      ? ''
      : dispatch(getProjectList({ role: userRole(user_role), payload }));
  };

  useEffect(() => {
    handleGetVendorList();
  }, [filterValues?.tds_project_code, vendorSearch]);

  const handleGetVendorList = () => {
    if (user_role === 'Finance CoE') {
      let payload = {
        tds_project_code: filterValues?.tds_project_code || 'All'
        //search_vendor: vendorSearch
      };
      if (vendorSearch) payload['search_vendor'] = vendorSearch;

      if (filterValues?.tds_project_code) {
        dispatch(getVendorList(payload));
      }
    }
  };

  // useEffect(() => {
  //   //handleGetInvoices();
  // }, [page, pageSize]); //, filterValues

  useEffect(() => {
    if (glView === true) {
      if (triggerFilter) {
        /*  if (page === 1) { */
        handleGetInvoices();
        handleNormalViewCount();
        /* } else {
          setPage(1);
        } */
      }
    } else {
      if (triggerFilter) {
        handleGetInvoices();
        handleNormalViewCount();
      }
    }
  }, [filterValues, glView, tab, page, pageSize, pageGl]);

  const handleGetInvoices = () => {
    setReqAllocInvoiceKeys([]);
    setReqAllocInvoices([]);

    if (glView === true) {
      console.log('glView === true', glView);
      let payload = {
        page,
        per_page: pageSize,
        is_H2H: false,
        invoice_type: filterValues?.invoice_type //invoiceType[0]?.value
      };
      Object.keys(filterValues).forEach((item) => {
        if (filterValues[item] && filterValues[item] !== 'All') {
          payload[filterToPayload[item]] = filterValues[item];
        }
      });

      /*  if (glView) {
        if (payload['is_H2H']) delete payload['is_H2H'];
      } else {
        if (payload['is_H2H']) delete payload['is_H2H'];
        payload['is_H2H'] = true;
        payload['invoice_type'] = null;
      } */

      /* dispatch(
        getInvoices({ role: userRole(user_role), type: 'all', payload })
      ); */
      tab === 1
        ? dispatch(
            getAllocatedInvoices({
              role: userRole(user_role),
              type: 'all',
              payload: { ...payload, status: 'allocated' }
            })
          )
        : '';
      tab === 2
        ? dispatch(
            getPaidInvoices({
              role: userRole(user_role),
              type: 'all',
              payload: { ...payload, status: 'paid' }
            })
          )
        : '';
      tab === 3
        ? dispatch(
            getDisbursedInvoices({
              role: userRole(user_role),
              type: 'all',
              payload: { ...payload, status: 'disbursed' }
            })
          )
        : '';
    } else if (glView === false) {
      console.log('glView === false', glView);
      let payload = {
        page: pageGl,
        per_page: pageSize,
        is_H2H: true,
        invoice_type: null
      };
      Object.keys(filterValues).forEach((item) => {
        if (filterValues[item] && filterValues[item] !== 'All') {
          console.log('filterValues[item]:', filterValues[item]);
          payload[filterToPayload[item]] =
            filterValues[item] === 'foreign' || filterValues[item] === 'non_H2H'
              ? null
              : filterValues[item];
        }
      });
      tab === 1
        ? dispatch(
            getAllocatedInvoices({
              role: userRole(user_role),
              type: 'all',
              payload: { ...payload, status: 'allocated' }
            })
          )
        : '';
      tab === 2
        ? dispatch(
            getPaidInvoices({
              role: userRole(user_role),
              type: 'all',
              payload: { ...payload, status: 'paid' }
            })
          )
        : '';
      tab === 3
        ? dispatch(
            getDisbursedInvoices({
              role: userRole(user_role),
              type: 'all',
              payload: { ...payload, status: 'disbursed' }
            })
          )
        : '';
    }
  };

  const handleFiltersOnChange = (slug, value) => {
    setTriggerFilter(true);
    setFilterValues({
      ...filterValues,
      [slug]: value
    });
    setOpenDropdown(!openDropdown);
  };

  const toggleGlView = () => {
    setTriggerFilter(true);
    setGlView(!glView);
  };

  const handleGetActiveBankList = (payload) => {
    dispatch(getActiveBankList(payload));
  };

  const handlePostAllocationRequest = (role, payload) => {
    if (user_role === 'Finance CoE') {
      // dispatch(postAllocationRequest
      dispatch(PostBuRequestFundAllocation({ role, payload })).then(() => {
        setOpenReqAllocModal(false);
        handleGetInvoices();
        handleNormalViewCount();
      });
    }
  };
  const handlePostRejectRequest = (role, payload) => {
    if (user_role === 'Finance CoE') {
      // dispatch(postAllocationRequest
      dispatch(postSendBackAllocRequestInvoiceWise({ role, payload })).then(
        () => {
          setOpenReqRejectModal(false);
          handleGetInvoices();
          handleNormalViewCount();
        }
      );
    }
  };
  const handleTabOnChange = (tab) => {
    setTriggerFilter(true);
    setTab(tab);
  };
  return (
    <Layout>
      <FinanceCoEPage
        loading={loading()}
        invoices={invoices()}
        pagination={pagination()}
        page={page}
        setPage={setPage}
        pageGl={pageGl}
        setPageGl={setPageGl}
        pageSize={pageSize}
        setPageSize={setPageSize}
        filterValues={filterValues}
        glView={glView}
        toggleGlView={toggleGlView}
        reqAllocInvoiceKeys={reqAllocInvoiceKeys}
        setReqAllocInvoiceKeys={setReqAllocInvoiceKeys}
        reqAllocInvoices={reqAllocInvoices}
        setReqAllocInvoices={setReqAllocInvoices}
        vendorList={vendorList}
        openReqAllocModal={openReqAllocModal}
        setOpenReqAllocModal={setOpenReqAllocModal}
        activeBankList={activeBankList}
        handleGetActiveBankList={handleGetActiveBankList}
        postAllocationRequestLoading={postAllocationRequestLoading}
        handlePostAllocationRequest={handlePostAllocationRequest}
        handleFiltersOnChange={handleFiltersOnChange}
        tab={tab}
        handleTabOnChange={handleTabOnChange}
        allInvoicesPagination={allInvoicesPagination}
        openInvoicesPagination={openInvoicesPagination}
        paidInvoicesPagination={paidInvoicesPagination}
        pendingInvoicesPagination={pendingInvoicesPagination}
        rejectedInvoicesPagination={rejectedInvoicesPagination}
        allocatedInvoicesPagination={allocatedInvoicesPagination}
        approvedInvoicesPagination={approvedInvoicesPagination}
        disbursedInvoicesPagination={disbursedInvoicesPagination}
        user_role={user_role}
        projectList={projectList}
        // projectAssigned={projectAssigned}
        // BU_assigned={BU_assigned}
        BUList={BUList}
        SBUList={SBUList}
        sendBackAllocRequestHandler={sendBackAllocRequestHandler}
        getExportPaidInvoicesHandler={getExportPaidInvoicesHandler}
        activeDebitBankList={activeDebitBankList}
        vendorSearch={vendorSearch}
        setVendorSearch={setVendorSearch}
        setProjectSearch={setProjectSearch}
        disbursed={disbursed}
        paid={paid}
        allocated={allocated}
        openExportAlloModal={openExportAlloModal}
        setOpenExportAlloModal={setOpenExportAlloModal}
        openReqRejectModal={openReqRejectModal}
        setOpenReqRejectModal={setOpenReqRejectModal}
        handlePostRejectRequest={handlePostRejectRequest}
        postSendBackAllocRequestInvoiceWiseLoading={
          postSendBackAllocRequestInvoiceWiseLoading
        }
        getTLReportsHandler={getTLReportsHandler}
        lodingTLReport={lodingTLReport}
      />
    </Layout>
  );
};

export default FinanceCoE;

// const handleGetInvoices = () => {
//   setReqAllocInvoiceKeys([]);
//   setReqAllocInvoices([]);
//   if (user_role === 'Finance CoE') {
//     //console.log('test-2');
//   /*   let payload = {
//       page,
//       per_page: pageSize,
//       // status: 'All',
//       is_H2H: false,
//       invoice_type: invoiceType[0]?.value
//     }; */
//   /*   Object.keys(filterValues).forEach((item) => {
//       if (filterValues[item] && filterValues[item] !== 'All') {
//         payload[filterToPayload[item]] = filterValues[item];
//       }
//     }); */

//     if (glView) {
//       let payload = {
//         page,
//         per_page: pageSize,
//         // status: 'All',
//         is_H2H: false,
//         invoice_type: 'non-H2H'
//       };
//       Object.keys(filterValues).forEach((item) => {
//         if (filterValues[item] && filterValues[item] !== 'All') {
//           payload[filterToPayload[item]] = filterValues[item];
//         }
//       });
//      // payload['is_H2H']=false
//      // payload['invoice_type'] = 'non-H2H';
//      tab === 2
//      ? dispatch(
//          getPaidInvoices({
//            role: userRole(user_role),
//            type: 'all',
//            payload: { ...payload, status: 'paid' }
//          })
//        )
//      : '';

//    tab === 1
//      ? dispatch(
//          getAllocatedInvoices({
//            role: userRole(user_role),
//            type: 'all',
//            payload: { ...payload, status: 'allocated' }
//          })
//        )
//      : '';
//    tab === 3
//      ? dispatch(
//          getDisbursedInvoices({
//            role: userRole(user_role),
//            type: 'all',
//            payload: { ...payload, status: 'disbursed' }
//          })
//        )
//      : '';
//     } else {
//       let payload = {
//         page,
//         per_page: pageSize,
//         // status: 'All',
//         is_H2H: true,
//         invoice_type: null
//       };
//       Object.keys(filterValues).forEach((item) => {
//         if (filterValues[item] && filterValues[item] !== 'All') {
//           payload[filterToPayload[item]] = filterValues[item];
//         }
//       });
//       // payload['is_H2H'] = true;
//       // payload['invoice_type'] = null;
//       tab === 2
//       ? dispatch(
//           getPaidInvoices({
//             role: userRole(user_role),
//             type: 'all',
//             payload: { ...payload, status: 'paid' }
//           })
//         )
//       : '';

//     tab === 1
//       ? dispatch(
//           getAllocatedInvoices({
//             role: userRole(user_role),
//             type: 'all',
//             payload: { ...payload, status: 'allocated' }
//           })
//         )
//       : '';
//     tab === 3
//       ? dispatch(
//           getDisbursedInvoices({
//             role: userRole(user_role),
//             type: 'all',
//             payload: { ...payload, status: 'disbursed' }
//           })
//         )
//       : '';
//     }

//     /* if (glView) {
//       if (payload['is_H2H']) delete payload['is_H2H'];
//      // payload['invoice_type'] = 'non-H2H';
//     } else {
//       if (payload['is_H2H']) delete payload['is_H2H'];
//       payload['is_H2H'] = true;
//       payload['invoice_type'] = null;
//     } */

//     /* dispatch(
//       getInvoices({ role: userRole(user_role), type: 'all', payload })
//     ); */

//    /*  tab === 2
//       ? dispatch(
//           getPaidInvoices({
//             role: userRole(user_role),
//             type: 'all',
//             payload: { ...payload, status: 'paid' }
//           })
//         )
//       : '';

//     tab === 1
//       ? dispatch(
//           getAllocatedInvoices({
//             role: userRole(user_role),
//             type: 'all',
//             payload: { ...payload, status: 'allocated' }
//           })
//         )
//       : '';
//     tab === 3
//       ? dispatch(
//           getDisbursedInvoices({
//             role: userRole(user_role),
//             type: 'all',
//             payload: { ...payload, status: 'disbursed' }
//           })
//         )
//       : ''; */
//   }
// };
