import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { sbuList } from './api'; //, inboundFailed_List

const initialState = {
  // getInboundFailedListLoading: false,
  SBUList: []
  // inboundFailedList: []
};

const actions = {
  GET_SBU_LIST: 'treasury/GET_SBU_LIST'
  // GET_INBOUND_FAILED_LIST: 'getInboundFailedList/GET_INBOUND_FAILED_LIST'
};

export const getSBUList = createAsyncThunk(
  actions.GET_SBU_LIST,
  async ({ role, payload }) => {
    const response = await sbuList(role, payload);

    return response;
  }
);

/* export const getInboundFailedList = createAsyncThunk(
  actions.GET_INBOUND_FAILED_LIST,
  async ({ role, payload }) => {
    const response = await inboundFailed_List(role, payload);
    return response;
  }
); */

export const treasurySlice = createSlice({
  name: 'treasury',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSBUList.pending, (state) => {
        state.getSBUListLoading = true;
      })
      .addCase(getSBUList.fulfilled, (state, action) => {
        const { success, SBU_list, message: msg } = action.payload;

        state.getSBUListLoading = false;

        if (success) {
          const SBUList = SBU_list.map((item) => {
            return {
              label: item,
              value: item
            };
          });
          state.SBUList = SBUList.splice(1);
        } else {
          message.error(msg);
        }
      })
      .addCase(getSBUList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getSBUListLoading = false;

        message.error(msg);
      });
    /*  builder
      .addCase(getInboundFailedList.pending, (state) => {
        state.getInboundFailedListLoading = true;
      })
      .addCase(getInboundFailedList.fulfilled, (state, action) => {
        const { success, getInboundFailedList, message: msg } = action.payload;

        state.getInboundFailedListLoading = false;

        if (success) {
          const getInboundFailedList = getInboundFailedList.map((item) => {
            return {
              label: item,
              value: item
            };
          });
          state.inboundFailedList = getInboundFailedList.splice(1);
        } else {
          message.error(msg);
        }
      })
      .addCase(getInboundFailedList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getInboundFailedListLoading = false;

        message.error(msg);
      }); */
  }
});
export default treasurySlice.reducer;
