import React, { useState, useEffect, useMemo } from 'react';
import { Modal, Form, Col, Row, Select, Button, Input, Spin } from 'antd';
import styles from './index.module.less';
//import moment from 'moment';
import { getLoggedInUser, userRole } from 'utilities/helpers';
//import { message } from 'antd';
import FormItem from 'common/FormItem';
import { debounce } from 'lodash';
import {
  //getVendorList,
  getProjectList,
  getCheckVendorStatus
} from '../../../redux/slice';
import { useDispatch, useSelector } from 'react-redux';

const { Option } = Select;

function LiabilityNotAvailableModal({
  setOpenLiabilityNotAvailableModal,
  openLiabilityNotAvailableModal
}) {
  const [formData1, setFormData1] = useState({});
  // const [vendorSearch, setVendorSearch] = useState('');
  const [projectSearch, setProjectSearch] = useState('');
  const [isVisiable, setIsVisiable] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const user_role1 = getLoggedInUser();
  const user_role = user_role1?.user_details?.role;

  const {
    //vendorList,
    projectList,
    checkVendorStatusData_Liability_status_details,
    checkVendorStatusData_Vendor_status_details,
    getCheckVendorStatusLoading
  } = useSelector((state) => state.invoices);

  const [form] = Form.useForm();

  // console.log('vendorList', vendorList);
  console.log('projectList', projectList);
  console.log('projectSearch', projectSearch);
  console.log(
    'liability_status_details',
    checkVendorStatusData_Liability_status_details
  );
  console.log(
    'vendor_status_details',
    checkVendorStatusData_Vendor_status_details
  );

  const dispatch = useDispatch();

  /* useEffect(() => {
    handleGetVendorList();
  }, [vendorSearch]);

  const handleGetVendorList = () => {
    const payload = {};
    if (vendorSearch) payload['search_vendor'] = vendorSearch;
    dispatch(getVendorList(payload));
  }; */

  useEffect(() => {
    handleGetProject();
  }, [projectSearch]);

  const handleGetProject = () => {
    const payload = {};
    if (projectSearch) payload['search_project'] = projectSearch;
    dispatch(getProjectList({ role: userRole(user_role), payload }));
  };
  const onFinish = (value) => {
    //console.log('VVVVVV', value);
    setIsVisiable(true);
    const payload = {
      vendor_code: value?.vendor_code,
      tds_project_code: value?.project_code
    };
    // console.log('payload', payload);
    dispatch(getCheckVendorStatus(payload)).then(function (response) {
      if (response?.payload?.success) {
        console.log(response);
        // setOpenLiabilityNotAvailableModal(false);
        //history.push('/view-gift-received');
      } else {
        setIsSuccess(true);
      }
    });
  };

  const handleClickBack = () => {
    setOpenLiabilityNotAvailableModal(false);
  };

  const handleFormDataOnChange = (slug, value) => {
    console.log('slug, value', slug, value);
    if (slug === 'vendor_code') {
      // setVendorAddressValue('');
      form.setFieldsValue({
        project_code: undefined
      });
    }

    // console.log('slug,value', slug, value);
    setFormData1({
      ...formData1,
      [slug]: value
    });
  };
  /* const debounceFetcher = useMemo(() => {
    const loadOptions = (vendorSearch) => {
      setVendorSearch(vendorSearch);
    };
    return debounce(loadOptions, 400);
  }, []); */
  const debounceFetcherProject = useMemo(() => {
    const loadOptions = (projectSearch) => {
      setProjectSearch(projectSearch);
    };
    return debounce(loadOptions, 400);
  }, []);

  return (
    <>
      <Modal
        title={
          <span style={{ fontWeight: 600, fontSize: '16px' }}>
            If Liability Not Available
          </span>
        }
        visible={openLiabilityNotAvailableModal}
        // className={`${styles.check_circle_icon}`}
        width={'720px'}
        footer={false}
        onCancel={() => setOpenLiabilityNotAvailableModal(false)}
        // onOk={() => setOpenLiabilityNotAvailableModal(false)}
        // // cancelButtonProps={{ style: { display: 'none' } }}
        // okText={'Submit'}
        // okButtonProps={
        //   {
        //     //loading: postAllocationRequestLoading,
        //     // disabled: loading,
        //     //  onClick: onOk
        //   }
        // }
      >
        <Form
          name="vendor"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Row>
            <Col span={24}>
              <FormItem
                label="Vendor code"
                name="vendor_code"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Input
                  //  value={vendorAddressValue}
                  allowClear
                  placeholder="Enter Vendor Code only"
                  onChange={(e) =>
                    handleFormDataOnChange('vendor_code', e.target.value)
                  }
                />
                {/* <Select
                  showSearch
                  allowClear={true}
                  optionFilterProp="children"
                  placeholder="Select Vendor Name"
                  onSearch={debounceFetcher}
                  onChange={(value) =>
                    handleFormDataOnChange('vendor_code', value)
                  }
                  filterOption={(input, option) => {
                    return (
                      option?.key
                        ?.toString()
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0 ||
                      option?.title
                        ?.toString()
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0
                    );
                  }}
                >
                  {vendorList?.map((item, index) => (
                    <Option title={item.label} key={index} value={item?.value}>
                      <span
                        style={{
                          fontSize: '12px',
                          textTransform: 'uppercase'
                        }}
                      >
                        <span style={{ marginRight: '4px' }}>
                          {item?.label}
                        </span>
                        <span> ({item?.value})</span>
                      </span>
                    </Option>
                  ))}
                </Select> */}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <FormItem
                label="Project Name"
                name="project_code"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.select_styles}`}
              >
                <Select
                  showSearch
                  allowClear={true}
                  optionFilterProp="children"
                  placeholder="Select Project Name"
                  onSearch={debounceFetcherProject}
                  onChange={(value) =>
                    handleFormDataOnChange('project_code', value)
                  }
                  filterOption={(input, option) => {
                    return (
                      option?.key
                        ?.toString()
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0 ||
                      option?.title
                        ?.toString()
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0
                    );
                  }}
                >
                  {projectList?.map((item, index) => (
                    <Option title={item.label} key={index} value={item?.value}>
                      <span
                        style={{
                          fontSize: '12px',
                          textTransform: 'uppercase'
                        }}
                      >
                        <span style={{ marginRight: '4px' }}>
                          {item?.label}
                        </span>
                        <span> ({item?.value})</span>
                      </span>
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </Col>
          </Row>

          {!isVisiable && (
            <Row className={`${styles.row_button}`}>
              <Button
                className={`${styles.cancel_button}`}
                // htmlType="submit"
                size="middle"
                onClick={handleClickBack}
              >
                Cancel
              </Button>
              <FormItem>
                <div className={`${styles.button_margin}`}>
                  <Button
                    loading={getCheckVendorStatusLoading}
                    name="submit"
                    className={`${styles.submit_button_style}`}
                    htmlType="submit"
                    size="middle"
                  >
                    Fetch
                  </Button>
                </div>
              </FormItem>
            </Row>
          )}
        </Form>

        <Spin spinning={getCheckVendorStatusLoading}>
          {isVisiable && (
            <div>
              <div className={`${styles.content_vendor_status}`}>
                <Row>
                  <Col span={14}>
                    <span>Vendor Name:</span>
                    <span className={`${styles.vendor_ststus_SubText}`}>
                      {checkVendorStatusData_Vendor_status_details?.vendor_name ? (
                        checkVendorStatusData_Vendor_status_details?.vendor_name
                      ) : (
                        <span style={{ color: 'red' }}>Not available</span>
                      )}
                    </span>
                  </Col>
                  <Col span={10}>
                    <span>Vendor Code:</span>
                    <span className={`${styles.vendor_ststus_SubText}`}>
                      {checkVendorStatusData_Vendor_status_details?.vendor_code ? (
                        checkVendorStatusData_Vendor_status_details?.vendor_code
                      ) : (
                        <span style={{ color: 'red' }}>Not available</span>
                      )}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col span={14}>
                    <span>Project Code:</span>
                    <span className={`${styles.vendor_ststus_SubText}`}>
                      {checkVendorStatusData_Liability_status_details?.tds_project_code ? (
                        checkVendorStatusData_Liability_status_details?.tds_project_code
                      ) : (
                        <span style={{ color: 'red' }}>Not available</span>
                      )}
                    </span>
                  </Col>
                  <Col span={10}>
                    <span>Vendor Status:</span>
                    <span className={`${styles.vendor_ststus_SubText}`}>
                      {checkVendorStatusData_Vendor_status_details?.is_active ? (
                        checkVendorStatusData_Vendor_status_details?.is_active ===
                        true ? (
                          <span style={{ color: 'green' }}>Available</span>
                        ) : (
                          <span style={{ color: 'red' }}>Not available</span>
                        )
                      ) : (
                        <span style={{ color: 'red' }}>Not available</span>
                      )}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col span={14}>
                    <span>Bank Account:</span>
                    <span className={`${styles.vendor_ststus_SubText}`}>
                      {checkVendorStatusData_Vendor_status_details?.active_bank_status ? (
                        checkVendorStatusData_Vendor_status_details?.active_bank_status ===
                        true ? (
                          <span style={{ color: 'green' }}>Active</span>
                        ) : (
                          <span style={{ color: 'red' }}>Inactive</span>
                        )
                      ) : (
                        <span style={{ color: 'red' }}>Not available</span>
                      )}
                    </span>
                  </Col>
                  <Col span={10}>
                    <span>Payment Block:</span>
                    <span className={`${styles.vendor_ststus_SubText}`}>
                      {checkVendorStatusData_Vendor_status_details?.is_blocked ===
                      false ? (
                        <span style={{ color: 'green' }}>No</span>
                      ) : checkVendorStatusData_Vendor_status_details?.is_blocked ===
                        true ? (
                        <span style={{ color: 'red' }}>Yes</span>
                      ) : (
                        <span style={{ color: 'red' }}>Not available</span>
                      )}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span>Net Liability:</span>
                    <span className={`${styles.vendor_ststus_SubText}`}>
                      {checkVendorStatusData_Liability_status_details?.total_liability <
                      0 ? (
                        <span style={{ color: 'green' }}>Negative </span>
                      ) : checkVendorStatusData_Liability_status_details?.total_liability >=
                        0 ? (
                        <span style={{ color: 'red' }}>Positive</span>
                      ) : (
                        <span style={{ color: 'red' }}>Not available</span>
                      )}
                    </span>
                  </Col>
                </Row>
                <Row className={`${styles.message_text}`}>
                  <Col>
                    {
                      <>
                        {isSuccess ? (
                          <ul>
                            <li>
                              <div>
                                If vendor status is not available, then please
                                write to support@lexyslabs.freshdesk.com,
                                siraj.shekhar@lexyslabs.com and
                                muskanl@tataprojects.com to add the vendor
                              </div>
                            </li>
                          </ul>
                        ) : checkVendorStatusData_Vendor_status_details?.is_active ===
                          false ? (
                          <ul>
                            <li>
                              {' '}
                              <div>
                                If vendor status is inactive, then please write
                                to support@lexyslabs.freshdesk.com,
                                siraj.shekhar@lexyslabs.com and
                                muskanl@tataprojects.com to add the vendor
                              </div>
                            </li>
                          </ul>
                        ) : checkVendorStatusData_Vendor_status_details?.is_blocked ===
                            false &&
                          checkVendorStatusData_Liability_status_details?.total_liability <
                            0 &&
                          checkVendorStatusData_Vendor_status_details?.active_bank_status ===
                            true &&
                          checkVendorStatusData_Vendor_status_details?.is_active ===
                            true ? (
                          <ul>
                            <li>
                              {' '}
                              <div>
                                The vendor is active and should be visible on
                                the application. In case it is still not
                                visible, then please write to
                                support@lexyslabs.freshdesk.com,
                                siraj.shekhar@lexyslabs.com and
                                muskanl@tataprojects.com
                              </div>
                            </li>
                          </ul>
                        ) : (
                          <div>
                            <ul>
                              <li>
                                <div>
                                  If total liability is positive or not
                                  available, the vendor need to pay Tata
                                  Projects. Hence the vendor will not be allowed
                                  for allocation
                                </div>
                              </li>
                              <li>
                                <div>
                                  For Bank Account Inactive, please ask the
                                  vendor to update the bank account on Vendor
                                  Portal
                                </div>
                              </li>
                              <li>
                                <div>
                                  To unblock a vendor from Payment block or
                                  neutral status, please send a proof approved
                                  by Finance CoE to
                                  support@lexyslabs.freshdesk.com,
                                  siraj.shekhar@lexyslabs.com and
                                  muskanl@tataprojects.com
                                </div>
                              </li>
                            </ul>
                          </div>
                        )}
                      </>
                    }
                  </Col>
                </Row>
                <br />
                <br />
              </div>
              <Row className={`${styles.row_cancel_button}`}>
                <Button
                  className={`${styles.ok_button}`}
                  // htmlType="submit"
                  size="middle"
                  onClick={handleClickBack}
                >
                  OK
                </Button>
              </Row>
            </div>
          )}
        </Spin>
      </Modal>
    </>
  );
}
export default LiabilityNotAvailableModal;
