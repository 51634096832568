import React, { useState, useEffect } from 'react';
import {
  InputNumber,
  Space,
  Typography,
  Button,
  Tooltip
  // Dropdown,
  // Menu,
  // Divider
} from 'antd';
//import { CaretDownOutlined } from '@ant-design/icons';
import styles from './index.module.less';
import CustomButton from 'common/CustomButton';
//import { CaretDownOutlined } from '@ant-design/icons';
import {
  liabilityToLabelTreasury,
  numFormating,
  numIntStyleFormat
} from 'utilities/helpers';
import CustomModal from 'common/CustomModal';
import FinanceCoESummaryAllocModal from '../FinanceCoESummaryAllocModal';
// import { DoubleRightOutlined } from '@ant-design/icons';
// import { history } from 'app/history';

const { Title, Text } = Typography;

const FinanceCoESummaryInvoiceItem = ({
  data,
  reqFund,
  setReqFund,
  openReqFundAllocModal,
  setOpenReqFundAllocModal,
  handleRequestFund,
  //selectedBankBranchName,
  // setSelectedBankBranchName,
  openReqAllocModal,
  setOpenReqAllocModal,
  postRequestFundLoading,
  requestFundInvoices,
  postAllocationRequestLoading,
  handlePostAllocationRequest,
  summaryInvoicesActiveBankList,
  clickSubmitInvoices,
  setClickSubmitInvoices,
  allInvoicesByAge,
  handleSubmitInvoices,
  user_role,
  handlePostBuRequestFundAllocation,
  liabilityAmount,
  handleFiltersOnChange,
  activeDebitBankList,
  filterValues
  //setSelectedBankDebitBranchName,
  // selectedBankDebitBranchName
}) => {
  // console.log('TreasuryItem_data', data?.SBU_name);
  //console.log('activeDebitBankList', activeDebitBankList);
  if (!data) return null;
  const {
    // SBU_name,
    proposed_liability, //3rd line
    initiated_liability, //2nd line
    total_liability, //1st line
    allocated_liability, // 4th line
    disbursed_liability,
    ratified_liability,
    vendor_code,
    vendor_name,
    tds_project_code,
    total_advances_up
  } = data;
  if (!data) return null;
  // console.log('FinanceCoEdata', data);

  //console.log('total_liability', total_liability);
  // console.log('requested_liability', requested_liability);
  // console.log('approved_liability', approved_liability);
  // console.log('allocated_liability', allocated_liability);

  const [liabilities, setLiabilities] = useState([]);

  useEffect(() => {
    let allLiabilities = Object.keys(total_liability);
    // allLiabilities.splice(6, 0, 'allocated_amount');
    allLiabilities.splice(6, 1);
    setLiabilities(allLiabilities);
  }, [total_liability]);
  /* 
  const selectedBank = activeDebitBankList.find(
    (item) => item.bank_branch_name ===  selectedBankDebitBranchName[SBU_name]
  ); */

  return (
    <div className={`${styles.summary_invoice_item_container} mb-3`}>
      <div style={{ position: 'relative' }}>
        <Space>
          <Text
            className={`sfprotext-medium ${styles.summary_invoice_item_vendor_details_container}`}
          >
            {`${vendor_name}(${vendor_code})`}
          </Text>
          <Text className="font-12">
            <Text className="mr-1" style={{ color: '#666687' }}>
              Advances(UP):
              <Tooltip
                title={numIntStyleFormat(total_advances_up)}
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                ₹{numFormating(total_advances_up)}
              </Tooltip>
            </Text>
            <Text style={{ color: '#212134' }}>{}</Text>
          </Text>
        </Space>
        <CustomButton
          className={`${styles.summary_invoice_item_req_btn}`}
          onClick={() => {
            setOpenReqFundAllocModal({ [vendor_code]: true });
          }}
          // disabled={approved_liability['approved_amount'] <= 0}
        >
          Disburse Fund
        </CustomButton>
      </div>
      <div className={`${styles.summary_invoice_item_liabilities_container}`}>
        <Space
          /* size={18} align="start" */
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginRight: '143px',
            marginLeft: '10px'
          }}
        >
          {/* size={20} is space between two coloumn */}
          {liabilities.map((item, index) => {
            let isIndexOne = index === 0;
            // let isIndexTow = index === 1;
            // let isIndexThree = index === 3;
            let isTotalLiability = item === 'requested_amount'; // change---'requested_amount';
            //console.log('filterValues ', filterValues?.invoice_type);
            const submitPayload = {
              page: 1,
              per_page: 100000000000,
              vendor_code,
              tds_project_code,
              is_H2H: false,
              invoice_type: filterValues?.invoice_type,
              status: 'allocated'
            };

            if (!isTotalLiability) {
              submitPayload['age'] =
                item === 'liability_non_H2H' || item === 'total_liability'
                  ? ' '
                  : item.replace('_days', '').replace('liability', 'days');
            }

            return (
              <Space
                key={index}
                direction="vertical"
                /*  style={{ marginRight: '60px' }} */
              >
                <Text
                  className="font-12"
                  style={{
                    color: '#666687',
                    padding: '2px',
                    margin: '0px'
                  }}
                >
                  {liabilityToLabelTreasury[item]}
                </Text>
                <div
                  className="sfprotext-bold "
                  style={{
                    color: '#212134',
                    paddingLeft: '2px',
                    marginTop: '4px',
                    lineHeight: '0px',
                    fontSize: '11.5px' // Amount font size
                  }}
                >
                  <Tooltip
                    title={'Total'}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    <span>T: </span>
                  </Tooltip>
                  <Tooltip
                    title={numIntStyleFormat(total_liability[item])}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    {total_liability[item] >= 0 ? (
                      total_liability[item] === undefined ? (
                        ''
                      ) : (
                        <span>₹{numFormating(total_liability[item])}</span>
                      )
                    ) : total_liability[item] === undefined ? (
                      ''
                    ) : (
                      <span>-₹{numFormating(total_liability[item])}</span>
                    )}
                  </Tooltip>
                </div>

                <div
                  className="sfprotext-bold "
                  style={{
                    color: '#212134',
                    paddingLeft: '3px',
                    marginTop: '13px',
                    lineHeight: '0px',
                    fontSize: '11.5px' // Amount font size
                  }}
                >
                  <Tooltip
                    title={'Initiated'}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    <span>I: </span>
                  </Tooltip>
                  <Tooltip
                    title={numIntStyleFormat(initiated_liability[item])}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    {initiated_liability[item] >= 0 ? (
                      initiated_liability[item] === undefined ? (
                        ''
                      ) : (
                        <span>₹{numFormating(initiated_liability[item])}</span>
                      )
                    ) : initiated_liability[item] === undefined ? (
                      ''
                    ) : (
                      <span>-₹{numFormating(initiated_liability[item])}</span>
                    )}
                  </Tooltip>
                </div>
                {/*  // Liability 3rd line start------------------------------------------ */}
                <div
                  className="sfprotext-bold "
                  style={{
                    color: '#212134',
                    paddingLeft: '2px',
                    marginTop: '13px',
                    lineHeight: '0px',
                    fontSize: '11.5px' // Amount font size
                  }}
                >
                  <Tooltip
                    title={'Proposed'}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    <span>P: </span>
                  </Tooltip>

                  <Tooltip
                    title={numIntStyleFormat(proposed_liability[item])}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    {proposed_liability[item] >= 0 ? (
                      proposed_liability[item] === undefined ? (
                        ''
                      ) : (
                        <span>₹{numFormating(proposed_liability[item])}</span>
                      )
                    ) : proposed_liability[item] === undefined ? (
                      ''
                    ) : (
                      <span>-₹{numFormating(proposed_liability[item])}</span>
                    )}
                  </Tooltip>
                </div>
                <div
                  className="sfprotext-bold "
                  style={{
                    color: '#212134',
                    paddingLeft: '2px',
                    marginTop: '13px',
                    lineHeight: '0px',
                    fontSize: '11.5px' // Amount font size
                  }}
                >
                  <Tooltip
                    title={'Ratified'}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    <span>R: </span>
                  </Tooltip>
                  <Tooltip
                    title={numIntStyleFormat(ratified_liability[item])}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    {ratified_liability[item] >= 0 ? (
                      ratified_liability[item] === undefined ? (
                        ''
                      ) : (
                        <span>₹{numFormating(ratified_liability[item])}</span>
                      )
                    ) : ratified_liability[item] === undefined ? (
                      ''
                    ) : (
                      <span>-₹{numFormating(ratified_liability[item])}</span>
                    )}
                  </Tooltip>
                </div>
                {/* 4th line is Allocated_liability */}
                <div
                  className="sfprotext-bold "
                  style={{
                    color: '#212134',
                    paddingLeft: '2px',
                    marginTop: '13px',
                    lineHeight: '0px',
                    fontSize: '11.5px' // Amount font size
                  }}
                >
                  <Tooltip
                    title={'Allocated'}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    <span>A: </span>
                  </Tooltip>

                  <Tooltip
                    title={numIntStyleFormat(allocated_liability[item])}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    {allocated_liability[item] >= 0 ? (
                      allocated_liability[item] === undefined ? (
                        ''
                      ) : (
                        <span>₹{numFormating(allocated_liability[item])}</span>
                      )
                    ) : allocated_liability[item] === undefined ? (
                      ''
                    ) : (
                      <span>-₹{numFormating(allocated_liability[item])}</span>
                    )}
                  </Tooltip>
                </div>
                <div
                  className="sfprotext-bold "
                  style={{
                    color: '#212134',
                    paddingLeft: '2px',
                    marginTop: '13px',
                    lineHeight: '0px',
                    fontSize: '11.5px' // Amount font size
                  }}
                >
                  <Tooltip
                    title={'Disbursed'}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    <span>D: </span>
                  </Tooltip>

                  <Tooltip
                    title={numIntStyleFormat(disbursed_liability[item])}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    {disbursed_liability[item] >= 0 ? (
                      disbursed_liability[item] === undefined ? (
                        ''
                      ) : (
                        <span>₹{numFormating(disbursed_liability[item])}</span>
                      )
                    ) : disbursed_liability[item] === undefined ? (
                      ''
                    ) : (
                      <span>-₹{numFormating(disbursed_liability[item])}</span>
                    )}
                  </Tooltip>
                </div>
                {!isIndexOne ? (
                  <Button
                    size="small"
                    style={{
                      paddingLeft: '0px',
                      paddingRight: '0px',
                      margin: '0px',
                      lineHeight: '0px'
                    }}
                    type="link"
                    onClick={() => {
                      setClickSubmitInvoices({ [vendor_code]: true });
                      handleSubmitInvoices(submitPayload);
                    }}
                    disabled={
                      !allocated_liability[item] ||
                      allocated_liability[item] === disbursed_liability[item]
                    }
                    className={`${styles.summary_invoice_item_submit_btn}`}
                  >
                    Submit invoices
                  </Button>
                ) : (
                  <span style={{ color: 'transparent' }}>.</span>
                )}
              </Space>
            );
          })}
        </Space>
      </div>
      {/*start--> For RequestFund Button for inter amount  in BU Summary screen*/}

      {openReqFundAllocModal[vendor_code] ? (
        <CustomModal
          visible={openReqFundAllocModal[vendor_code]}
          onCancel={() => setOpenReqFundAllocModal({})}
          cancelButtonProps={{
            onClick: () => setOpenReqFundAllocModal({})
          }}
          okButtonProps={{
            loading: postRequestFundLoading,
            /*  disabled:
              !reqFund[vendor_code] ||
              Number(reqFund[vendor_code] || 0) >
                Number(approved_liability['approved_amount']), //--change---Number(requested_liability['approved_amount']), */
            onClick: () =>
              handleRequestFund({
                requested_fund: reqFund[vendor_code],
                //SBU_name,
                tds_project_code,
                vendor_code,
                invoice_type: 'non_H2H'
                // status: 'approved'
              })
          }}
        >
          <Title level={5} className="font-12" style={{ paddingBottom: '5px' }}>
            Enter the amount you want to disburse
          </Title>
          <InputNumber
            min={1}
            max={
              allocated_liability['total_liability'] <= 0
                ? Math.abs(allocated_liability['total_liability'])
                : allocated_liability['total_liability']
            }
            /*   max={requested_liability['requested_amount']} */
            placeholder="Enter amount"
            style={{ width: '70%' }}
            value={reqFund[vendor_code] || null}
            onChange={(value) =>
              setReqFund({ ...reqFund, [vendor_code]: value })
            }
          />
        </CustomModal>
      ) : null}
      {/* END--> For RequestFund Button for inter amount */}
      {console.log('openReqAllocModal[vendor_code]', openReqAllocModal)}
      {openReqAllocModal[vendor_code] ? (
        <FinanceCoESummaryAllocModal
          requestedAmount={liabilityAmount}
          fromSummary={true}
          visible={openReqAllocModal[vendor_code]}
          toggleModal={() => {
            setOpenReqAllocModal({});
            setClickSubmitInvoices({});
          }}
          data={
            clickSubmitInvoices[vendor_code]
              ? allInvoicesByAge
              : requestFundInvoices
          }
          grandTotal={allocated_liability['liability']}
          currentTotal={disbursed_liability['liability']}
          summaryInvoicesActiveBankList={summaryInvoicesActiveBankList}
          postAllocationRequestLoading={postAllocationRequestLoading}
          handlePostAllocationRequest={handlePostAllocationRequest}
          // selectedBankBranchName={ selectedBankBranchName}
          user_role={user_role}
          handlePostBuRequestFundAllocation={handlePostBuRequestFundAllocation}
          handleFiltersOnChange={handleFiltersOnChange}
          activeDebitBankList={activeDebitBankList}
        />
      ) : null}
    </div>
  );
};

export default FinanceCoESummaryInvoiceItem;
