import { useState } from 'react';
import { Image, Layout, Menu, Tooltip } from 'antd';
import {
  HomeOutlined,
  DashboardOutlined,
  FileTextOutlined
  // DollarOutlined,
  // ExportOutlined,
  // SettingOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { history } from 'app/history';
import styles from './index.module.less';
import UserMenu from './components/UserMenu';
import sidebarUser from './images/sidebar_user.svg';
import tataProjectsLogo from './images/tata-projects-logo.svg';
import { getLoggedInUser } from '../../utilities/helpers';
// import LocalStorage from 'utilities/localStorage';

const { Header, Sider, Content } = Layout;
// const { SubMenu } = Menu;

const menuData = [
  {
    key: 'invoices',
    path: '/invoices',
    icon: <HomeOutlined />,
    label: 'Invoices'
  },
  {
    key: 'dashboard',
    path: '/dashboard',
    icon: <DashboardOutlined />,
    label: 'Dashboard'
  }
];
const menuData1 = [
  {
    key: 'financeCoE',
    path: '/financeCoE',
    icon: <HomeOutlined />,
    label: 'Invoices'
  },
  {
    key: 'dashboard',
    path: '/dashboard',
    icon: <DashboardOutlined />,
    label: 'Dashboard'
  }
];
const menuDataTreasury = [
  {
    key: 'invoices',
    path: '/invoices',
    icon: <HomeOutlined />,
    label: 'Invoices'
  },
  {
    key: 'dashboard',
    path: '/dashboard',
    icon: <DashboardOutlined />,
    label: 'Dashboard'
  },
  {
    key: 'inboundFailedList',
    path: '/inboundFailedList',
    icon: <FileTextOutlined />,
    label: 'Inbound Failed List'
  }
];

const CustomLayout = ({ children, sider = true, header = true }) => {
  const [selectedMenuKeys] = useState(
    history.location.pathname.replace('/', '')
  );
  const user_role1 = getLoggedInUser();
  const user_role = user_role1?.user_details?.role;
  return (
    <Layout className={styles.custom_layout_container} hasSider={sider}>
      {sider ? (
        <Sider
          trigger={null}
          collapsible
          collapsed={true}
          collapsedWidth={64}
          className={styles.sider_container}
        >
          <div
            className={styles.sider_logo}
            onClick={() =>
              user_role === 'Finance CoE'
                ? history.push('/financeCoE')
                : history.push('/invoices')
            }
          >
            <Tooltip
              title={user_role}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              placement="rightTop"
            >
              <Image alt="siderbar_user" src={sidebarUser} preview={false} />
            </Tooltip>
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={['invoices']}
            selectedKeys={[selectedMenuKeys]}
          >
            {user_role === 'Finance CoE'
              ? menuData1.map((item) => {
                  // console.log('item', item);
                  // console.log('selectedMenuKeys', selectedMenuKeys);
                  // console.log('item?.key', item?.key);
                  let isSelected = item?.key === selectedMenuKeys;
                  //console.log('isSelected', isSelected);
                  return (
                    <Menu.Item key={item.key} icon={item.icon}>
                      <Link
                        to={item.path}
                        className={
                          isSelected ? `${styles.active}` : `${styles.inactive}`
                        }
                      >
                        {item.label}
                      </Link>
                    </Menu.Item>
                  );
                })
              : user_role === 'Treasury'
              ? menuDataTreasury.map((item) => {
                  let isSelected = item?.key === selectedMenuKeys;
                  return (
                    <Menu.Item key={item.key} icon={item.icon}>
                      <Link
                        to={item.path}
                        className={
                          isSelected ? `${styles.active}` : `${styles.inactive}`
                        }
                      >
                        {item.label}
                      </Link>
                    </Menu.Item>
                  );
                })
              : menuData.map((item) => {
                  let isSelected = item?.key === selectedMenuKeys;
                  return (
                    <Menu.Item key={item.key} icon={item.icon}>
                      <Link
                        to={item.path}
                        className={
                          isSelected ? `${styles.active}` : `${styles.inactive}`
                        }
                      >
                        {item.label}
                      </Link>
                    </Menu.Item>
                  );
                })}
          </Menu>
        </Sider>
      ) : null}
      <Layout className={styles.site_layout}>
        {header ? (
          <Header className={`df-jb-ac ${styles.site_layout_header}`}>
            <Image
              alt="tata-projects-logo"
              src={tataProjectsLogo}
              preview={false}
            />
            <UserMenu />
          </Header>
        ) : null}
        <Content className={styles.site_layout_content}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default CustomLayout;
{
  /* <Menu
            //className={` df-jc-ac ${styles.menu}`}
            theme="dark"
            //mode="inline"
            mode="vertical"
            defaultSelectedKeys={['invoices']} //'users'
            selectedKeys={[selectedMenuKeys]}
            
          >
            {user_role === 'Finance CoE'
              ? menuData1.map((item) => {
                  return (
                    <Menu.Item
                      key={item.key}
                      icon={item.icon}
                      className={`${styles.sidebar_icon}`}
                    >
                      <Link to={item.path}>{item.label}</Link>
                    </Menu.Item>
                  );
                })
              : menuData.map((item) => {
                let isSelected = item?.key === selectedMenuKeys;
                  return (
                    <Menu.Item
                      key={item.key}
                      icon={item.icon}
                      className={`${styles.sidebar_icon}`}
                    >
                      <NavLink
                        to={item.path}
                        className={({ isSelected }) =>
                        isSelected ? `${styles.active}` : `${styles.inactive}`
                        }
                      >
                        {item.label}
                      </NavLink>
                    </Menu.Item>
                  );
                })}
          </Menu> */
}
