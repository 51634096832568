import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  activeBankList,
  allocationRequest,
  invoices,
  requestFund,
  vendorList,
  buApproveAllocationRequest,
  buForwardAllocationRequest,
  projectList,
  buList,
  activeDebitBankList,
  vendorwise,
  sendBackAllocationRequest,
  exportPaidInvoices,
  normalViewCount,
  dashboardCount,
  checkVendorStatus,
  sendBackAllocRequestInvoiceWise,
  inboundFailed_List,
  retryInboundPost
} from './api';

const initialState = {
  requestFundCheckboxLoading: false,
  invoicesByAgeLoading: false,
  getInvoicesByAgeLoading: false,
  getVendorWiseLoading: false,
  sendBackAllocRequestLoading: false,
  exportPaidInvoicesLoading: false,
  getDashboardCountLoading: false,
  getCheckVendorStatusLoading: false,
  postSendBackAllocRequestInvoiceWiseLoading: false,
  getInboundFailedListLoading: false,
  postRetryInboundLoading: false,
  vendorList: [],
  activeBankList: [],
  allInvoicesByAge: [],
  projectList: [],
  BUList: [],
  activeDebitBankList: [],
  vendorWiseList: [],
  all: '',
  initiated: '',
  open: '',
  paid: '',
  rejected: '',
  proposed: '',
  ratified: '',
  allocated: '',
  disbursed: '',
  month_to_date_my_initiated: '',
  month_to_date_my_proposed: '',
  month_to_date_my_ratified: '',
  month_to_date_my_allocated: '',
  month_to_date_my_disbursed: '',
  open_vs_allocated_count_BU_wise: [],
  ageing_category: {},
  ageing_data: {},
  top_five_project_by_invoices: [],
  checkVendorStatusData_Liability_status_details: {},
  checkVendorStatusData_Vendor_status_details: {},
  inboundFailedList: [],
  paginationInboundFailedList: {}
};

const actions = {
  GET_INVOICES: 'invoices/GET_INVOICES',
  GET_OPEN_INVOICES: 'invoices/GET_OPEN_INVOICES',
  GET_PAID_INVOICES: 'invoices/GET_PAID_INVOICES',
  GET_PENDING_INVOICES: 'invoices/GET_PENDING_INVOICES',
  GET_REJECTED_INVOICES: 'invoices/GET_REJECTED_INVOICES',
  GET_APPROVED_INVOICES: 'invoices/GET_APPROVED_INVOICES',
  GET_RATIFIED_INVOICES: 'invoices/GET_RATIFIED_INVOICES',
  GET_ALLOCATED_INVOICES: 'invoices/GET_ALLOCATED_INVOICES',
  GET_VENDOR_LIST: 'invoices/GET_VENDOR_LIST',
  GET_ACTIVE_BANK_LIST: 'invoices/GET_ACTIVE_BANK_LIST',
  POST_ALLOCATION_REQUEST: 'invoices/POST_ALLOCATION_REQUEST',
  POST_REQUEST_FUND: 'invoices/POST_REQUEST_FUND',
  GET_INVOICES_BY_AGE: 'invoices/GET_INVOICES_BY_AGE',
  POST_BU_APPROVE_ALLOCATION_REQUEST:
    'invoices/POST_BU_APPROVE_ALLOCATION_REQUEST',
  POST_BU_REQUEST_FUND_ALLOCATION: 'invoices/POST_BU_REQUEST_FUND_ALLOCATION',
  GET_PROJECT_LIST: 'invoices/GET_PROJECT_LIST',
  GET_BU_LIST: 'invoices/GET_BU_LIST',
  GET_ACTIVE_DEBIT_BANK_LIST: 'invoices/GET_ACTIVE_DEBIT_BANK_LIST',
  GET_VENDOR_WISE_LIST: 'invoices/GET_VENDOR_WISE_LIST',
  GET_DISBURSED_INVOICES: 'invoices/GET_DISBURSED_INVOICES',
  GET_SEND_BACK_ALLOC_REQ: 'invoices/GET_SEND_BACK_ALLOC_REQ',
  GET_EXPORT_PAID_INVOICES: 'invoices/GET_EXPORT_PAID_INVOICES',
  GET_NORMAL_VIEW_COUNT: 'getNormalViewCount/GET_NORMAL_VIEW_COUNT',
  GET_DASHBOARD_COUNT: 'getDashboardCount/GET_DASHBOARD_COUNT',
  GET_CHECK_VENDOR_STATUS: 'getCheckVendorStatus/GET_CHECK_VENDOR_STATUS',
  POST_SEND_BACK_ALLOC_REQUEST_INVOICE_WISE:
    'postSendBackAllocRequestInvoiceWise/POST_SEND_BACK_ALLOC_REQUEST_INVOICE_WISE',
  GET_INBOUND_FAILED_LIST: 'getInboundFailedList/GET_INBOUND_FAILED_LIST',
  POST_RETRY_INBOUND: 'postRetryInbound/POST_RETRY_INBOUND'
};
/* GET  https://a2p-api.lexyslabs.com/api/v1/Treasury/getInboundFailedList?page=1&per_page=10
POST  https://a2p-api.lexyslabs.com/api/v1/Treasury/retryInbound
Payload

{

    "invoices": [26866707, 26866749]

} */

export const postRetryInbound = createAsyncThunk(
  actions.POST_RETRY_INBOUND,
  async ({ role, payload }) => {
    const response = await retryInboundPost(role, payload);
    return response;
  }
);
export const getInboundFailedList = createAsyncThunk(
  actions.GET_INBOUND_FAILED_LIST,
  async ({ role, payload }) => {
    const response = await inboundFailed_List(role, payload);
    return response;
  }
);

export const getCheckVendorStatus = createAsyncThunk(
  actions.GET_CHECK_VENDOR_STATUS,
  async (payload) => {
    const response = await checkVendorStatus(payload);
    return response;
  }
);
export const getInvoices = createAsyncThunk(
  actions.GET_INVOICES,
  async ({ role, type, payload }) => {
    const response = await invoices(role, type, payload);

    return response;
  }
);

export const getOpenInvoices = createAsyncThunk(
  actions.GET_OPEN_INVOICES,
  async ({ role, type, payload }) => {
    const response = await invoices(role, type, payload);

    return response;
  }
);

export const getPaidInvoices = createAsyncThunk(
  actions.GET_PAID_INVOICES,
  async ({ role, type, payload }) => {
    const response = await invoices(role, type, payload);

    return response;
  }
);

export const getPendingInvoices = createAsyncThunk(
  actions.GET_PENDING_INVOICES,
  async ({ role, type, payload }) => {
    const response = await invoices(role, type, payload);

    return response;
  }
);

export const getRejectedInvoices = createAsyncThunk(
  actions.GET_REJECTED_INVOICES,
  async ({ role, type, payload }) => {
    const response = await invoices(role, type, payload);

    return response;
  }
);

export const getAllocatedInvoices = createAsyncThunk(
  actions.GET_ALLOCATED_INVOICES,
  async ({ role, type, payload }) => {
    const response = await invoices(role, type, payload);

    return response;
  }
);

export const getApprovedInvoices = createAsyncThunk(
  actions.GET_APPROVED_INVOICES,
  async ({ role, type, payload }) => {
    const response = await invoices(role, type, payload);

    return response;
  }
);
export const getRatifiedInvoices = createAsyncThunk(
  actions.GET_RATIFIED_INVOICES,
  async ({ role, type, payload }) => {
    const response = await invoices(role, type, payload);

    return response;
  }
);

export const getDisbursedInvoices = createAsyncThunk(
  actions.GET_DISBURSED_INVOICES,
  async ({ role, type, payload }) => {
    const response = await invoices(role, type, payload);

    return response;
  }
);

export const getVendorList = createAsyncThunk(
  actions.GET_VENDOR_LIST,
  async (payload) => {
    const response = await vendorList(payload);

    return response;
  }
);
//?search_vendor=${payload?.search_vendor}
export const getActiveBankList = createAsyncThunk(
  actions.GET_ACTIVE_BANK_LIST,
  async (payload) => {
    const response = await activeBankList(payload);
    return response;
  }
);

export const postAllocationRequest = createAsyncThunk(
  actions.POST_ALLOCATION_REQUEST,
  async ({ role, payload }) => {
    const response = await allocationRequest(role, payload);

    return response;
  }
);

export const postRequestFund = createAsyncThunk(
  actions.POST_REQUEST_FUND,
  async ({ role, payload }) => {
    const response = await requestFund(role, payload);

    return response;
  }
);

export const getInvoicesByAge = createAsyncThunk(
  actions.GET_INVOICES_BY_AGE,
  async ({ role, type, payload }) => {
    const response = await invoices(role, type, payload);

    return response;
  }
);

export const postBuApproveAllocationRequest = createAsyncThunk(
  actions.POST_BU_APPROVE_ALLOCATION_REQUEST,
  async ({ role, payload }) => {
    const response = await buApproveAllocationRequest(role, payload);

    return response;
  }
);

export const PostBuRequestFundAllocation = createAsyncThunk(
  actions.POST_BU_REQUEST_FUND_ALLOCATION,
  async ({ role, payload }) => {
    const response = await buForwardAllocationRequest(role, payload);

    return response;
  }
);

export const getProjectList = createAsyncThunk(
  actions.GET_PROJECT_LIST,
  async ({ role, payload }) => {
    const response = await projectList(role, payload);

    return response;
  }
);

export const getBUList = createAsyncThunk(
  actions.GET_BU_LIST,
  async ({ role, payload }) => {
    const response = await buList(role, payload);

    return response;
  }
);

export const getActiveDebitBankList = createAsyncThunk(
  actions.GET_ACTIVE_DEBIT_BANK_LIST,
  async (payload) => {
    const response = await activeDebitBankList(payload);
    return response;
  }
);

export const getVendorWise = createAsyncThunk(
  actions.GET_VENDOR_WISE_LIST,
  async ({ role, type1, type2, payload }) => {
    const response = await vendorwise(role, type1, type2, payload);
    return response;
  }
);

export const getSendBackAllocRequest = createAsyncThunk(
  actions.GET_SEND_BACK_ALLOC_REQ,
  async ({ role, payload }) => {
    const response = await sendBackAllocationRequest(role, payload);

    return response;
  }
);

export const getExportPaidInvoices = createAsyncThunk(
  actions.GET_EXPORT_PAID_INVOICES,
  async ({ role, payload }) => {
    const response = await exportPaidInvoices(role, payload);

    return response;
  }
);

export const getNormalViewCount = createAsyncThunk(
  actions.GET_NORMAL_VIEW_COUNT,
  async ({ role, payload }) => {
    const response = await normalViewCount(role, payload);

    return response;
  }
);

export const getDashboardCount = createAsyncThunk(
  actions.GET_DASHBOARD_COUNT,
  async (payload) => {
    const response = await dashboardCount(payload);
    return response;
  }
);

export const postSendBackAllocRequestInvoiceWise = createAsyncThunk(
  actions.POST_SEND_BACK_ALLOC_REQUEST_INVOICE_WISE,
  async ({ role, payload }) => {
    const response = await sendBackAllocRequestInvoiceWise(role, payload);

    return response;
  }
);

export const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInvoices.pending, (state, action) => {
        const { arg } = action.meta;
        const { type } = arg;

        state[`${type}InvoicesLoading`] = true;
      })
      .addCase(getInvoices.fulfilled, (state, action) => {
        //console.log('getInvoices_action.payload', action.payload);
        const {
          success,
          data,
          pagination,
          month_to_date_my_initiated,
          month_to_date_my_proposed,
          month_to_date_my_ratified,
          month_to_date_my_allocated,
          month_to_date_my_disbursed
        } = action.payload;
        const { arg } = action.meta;
        const { type } = arg;
        state.month_to_date_my_initiated = month_to_date_my_initiated;
        state.month_to_date_my_proposed = month_to_date_my_proposed;
        state.month_to_date_my_ratified = month_to_date_my_ratified;
        state.month_to_date_my_allocated = month_to_date_my_allocated;
        state.month_to_date_my_disbursed = month_to_date_my_disbursed;

        state[`${type}InvoicesLoading`] = false;

        if (success) {
          state[`${type}Invoices`] = data
            .filter((o) => o)
            .map((item, index) => ({
              ...item,
              key: index
            }));
          if (type === 'summary') {
            let summaryInvoicesActiveBankList = {};
            data
              .filter((o) => o)
              .forEach((item) => {
                summaryInvoicesActiveBankList[item.vendor_code] =
                  item.active_bank_list;
              });
            state[`${type}InvoicesActiveBankList`] =
              summaryInvoicesActiveBankList;
          }
          state[`${type}InvoicesPagination`] = pagination || null;
        } else {
          state[`${type}Invoices`] = [];

          // message.error(msg);
        }
      })
      .addCase(getInvoices.rejected, (state, action) => {
        const { message: msg } = action.error;
        const { arg } = action.meta;
        const { type } = arg;

        state[`${type}InvoicesLoading`] = false;

        message.error(msg);
      });

    builder
      .addCase(getOpenInvoices.pending, (state) => {
        state.openInvoicesLoading = true;
      })
      .addCase(getOpenInvoices.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;

        state.openInvoicesLoading = false;

        if (success) {
          state.openInvoices = data.map((item, index) => ({
            ...item,
            key: index
          }));
          state.openInvoicesPagination = pagination || null;
        } else {
          state.openInvoices = [];
          state.openInvoicesPagination = 0;
          // message.error(msg);
        }
      })
      .addCase(getOpenInvoices.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.openInvoicesLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getPaidInvoices.pending, (state) => {
        state.paidInvoicesLoading = true;
      })
      .addCase(getPaidInvoices.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;

        state.paidInvoicesLoading = false;

        if (success) {
          state.paidInvoices = data.map((item, index) => ({
            ...item,
            key: index
          }));
          state.paidInvoicesPagination = pagination || null;
        } else {
          state.paidInvoices = [];
          state.paidInvoicesPagination = 0;
          // message.error(msg);
        }
      })
      .addCase(getPaidInvoices.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.paidInvoicesLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getPendingInvoices.pending, (state) => {
        state.pendingInvoicesLoading = true;
      })
      .addCase(getPendingInvoices.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;

        state.pendingInvoicesLoading = false;

        if (success) {
          state.pendingInvoices = data.map((item, index) => ({
            ...item,
            key: index
          }));
          state.pendingInvoicesPagination = pagination || null;
        } else {
          state.pendingInvoices = [];
          state.pendingInvoicesPagination = 0;
          // message.error(msg);
        }
      })
      .addCase(getPendingInvoices.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.pendingInvoicesLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getRejectedInvoices.pending, (state) => {
        state.rejectedInvoicesLoading = true;
      })
      .addCase(getRejectedInvoices.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;

        state.rejectedInvoicesLoading = false;

        if (success) {
          state.rejectedInvoices = data.map((item, index) => ({
            ...item,
            key: index
          }));
          state.rejectedInvoicesPagination = pagination || null;
        } else {
          state.rejectedInvoices = [];
          state.rejectedInvoicesPagination = 0;
          // message.error(msg);
        }
      })
      .addCase(getRejectedInvoices.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.rejectedInvoicesLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllocatedInvoices.pending, (state) => {
        state.allocatedInvoicesLoading = true;
      })
      .addCase(getAllocatedInvoices.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;

        state.allocatedInvoicesLoading = false;

        if (success) {
          state.allocatedInvoices = data.map((item, index) => ({
            ...item,
            key: index
          }));
          state.allocatedInvoicesPagination = pagination || null;
        } else {
          state.allocatedInvoices = [];
          state.allocatedInvoicesPagination = 0;
          // message.error(msg);
        }
      })
      .addCase(getAllocatedInvoices.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.allocatedInvoicesLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getApprovedInvoices.pending, (state) => {
        state.approvedInvoicesLoading = true;
      })
      .addCase(getApprovedInvoices.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;

        state.approvedInvoicesLoading = false;

        if (success) {
          state.approvedInvoices = data.map((item, index) => ({
            ...item,
            key: index
          }));
          state.approvedInvoicesPagination = pagination || null;
        } else {
          state.approvedInvoices = [];
          state.approvedInvoicesPagination = 0;
          // message.error(msg);
        }
      })
      .addCase(getApprovedInvoices.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.approvedInvoicesLoading = false;

        message.error(msg);
      });
    //----------------
    builder
      .addCase(getRatifiedInvoices.pending, (state) => {
        state.ratifiedInvoicesLoading = true;
      })
      .addCase(getRatifiedInvoices.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;

        state.ratifiedInvoicesLoading = false;

        if (success) {
          state.ratifiedInvoices = data.map((item, index) => ({
            ...item,
            key: index
          }));
          state.ratifiedInvoicesPagination = pagination || null;
        } else {
          state.ratifiedInvoices = [];
          state.ratifiedInvoicesPagination = 0;
          // message.error(msg);
        }
      })
      .addCase(getRatifiedInvoices.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.ratifiedInvoicesLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getDisbursedInvoices.pending, (state) => {
        state.disbursedInvoicesLoading = true;
      })
      .addCase(getDisbursedInvoices.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;

        state.disbursedInvoicesLoading = false;

        if (success) {
          state.disbursedInvoices = data.map((item, index) => ({
            ...item,
            key: index
          }));
          state.disbursedInvoicesPagination = pagination || null;
        } else {
          state.disbursedInvoices = [];
          state.disbursedInvoicesPagination = 0;
          // message.error(msg);
        }
      })
      .addCase(getDisbursedInvoices.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.disbursedInvoicesLoading = false;

        message.error(msg);
      });
    //------------------

    builder
      .addCase(getVendorList.pending, (state) => {
        state.getVendorListLoading = true;
      })
      .addCase(getVendorList.fulfilled, (state, action) => {
        const { success, vendor_list } = action.payload;

        state.getVendorListLoading = false;

        if (success) {
          const vendorList = vendor_list.map((item) => {
            if (item['All']) {
              return { label: 'All', value: 'All' };
            } else {
              return { label: item.vendor_name, value: item.vendor_code };
            }
          });
          state.vendorList = vendorList;
        } else {
          // message.error(msg);
        }
      })
      .addCase(getVendorList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getVendorListLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getActiveBankList.pending, (state) => {
        state.getActiveBankListLoading = true;
      })
      .addCase(getActiveBankList.fulfilled, (state, action) => {
        const { success, active_bank_list, message: msg } = action.payload;

        state.getActiveBankListLoading = false;

        if (success) {
          state.activeBankList = active_bank_list;
        } else {
          message.error(msg);
        }
      })
      .addCase(getActiveBankList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getActiveBankListLoading = false;

        message.error(msg);
      });

    builder
      .addCase(postAllocationRequest.pending, (state) => {
        state.postAllocationRequestLoading = true;
      })
      .addCase(postAllocationRequest.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;

        state.postAllocationRequestLoading = false;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(postAllocationRequest.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postAllocationRequestLoading = false;

        message.error(msg);
      });

    builder
      .addCase(postRequestFund.pending, (state) => {
        state.postRequestFundLoading = true;
        state.requestFundCheckboxLoading = false;
      })
      .addCase(postRequestFund.fulfilled, (state, action) => {
        const { success, data, message: msg } = action.payload;

        state.postRequestFundLoading = false;

        if (success) {
          state.requestFundCheckboxLoading = true;
          message.success(msg);
          state.requestFundInvoices = data
            .filter((o) => o)
            .map((item, index) => ({
              ...item,
              key: index
            }));
          console.log('state.requestFundInvoices', state.requestFundInvoices);
        } else {
          state.requestFundCheckboxLoading = false;
          message.info(msg);
        }
      })
      .addCase(postRequestFund.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postRequestFundLoading = false;
        state.requestFundCheckboxLoading = false;
        message.error(msg);
      });

    builder
      .addCase(getInvoicesByAge.pending, (state) => {
        state.getInvoicesByAgeLoading = true;
      })
      .addCase(getInvoicesByAge.fulfilled, (state, action) => {
        const { success, data, message: msg } = action.payload;

        state.getInvoicesByAgeLoading = false;

        if (success) {
          state.invoicesByAgeLoading = true;
          state.allInvoicesByAge = data
            .filter((o) => o)
            .map((item, index) => ({
              ...item,
              key: index
            }));
        } else {
          state.invoicesByAgeLoading = false;
          message.error(msg);
        }
      })
      .addCase(getInvoicesByAge.rejected, (state) => {
        // const { message: msg } = action.error;

        state.getInvoicesByAgeLoading = false;
      });

    builder
      .addCase(postBuApproveAllocationRequest.pending, (state) => {
        state.postBuApproveAllocationRequestLoading = true;
      })
      .addCase(postBuApproveAllocationRequest.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;

        state.postBuApproveAllocationRequestLoading = false;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(postBuApproveAllocationRequest.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postBuApproveAllocationRequestLoading = false;

        message.error(msg);
      });

    builder
      .addCase(PostBuRequestFundAllocation.pending, (state) => {
        state.PostBuRequestFundAllocationLoading = true;
      })
      .addCase(PostBuRequestFundAllocation.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;

        state.PostBuRequestFundAllocationLoading = false;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(PostBuRequestFundAllocation.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.PostBuRequestFundAllocationLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getProjectList.pending, (state) => {
        state.getProjectListLoading = true;
      })
      .addCase(getProjectList.fulfilled, (state, action) => {
        const { success, project_list, message: msg } = action.payload;

        state.getProjectListLoading = false;

        if (success) {
          const projectList = project_list.map((item) => {
            if (item['All']) {
              return { label: 'All', value: 'All' };
            } else {
              return {
                label: item.tds_project_name,
                value: item.tds_project_code
              };
            }
          });
          state.projectList = projectList;
        } else {
          message.error(msg);
        }
      })
      .addCase(getProjectList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getProjectListLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getBUList.pending, (state) => {
        state.getBUListLoading = true;
      })
      .addCase(getBUList.fulfilled, (state, action) => {
        const { success, BU_list, message: msg } = action.payload;

        state.getBUListLoading = false;

        if (success) {
          const BUList = BU_list.map((item) => {
            if (item['All']) {
              return { label: 'All', value: 'All' };
            } else {
              return {
                label: item,
                value: item
              };
            }
          });
          state.BUList = BUList;
        } else {
          message.error(msg);
        }
      })
      .addCase(getBUList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getBUListLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getActiveDebitBankList.pending, (state) => {
        state.getActiveDebitBankListLoading = true;
      })
      .addCase(getActiveDebitBankList.fulfilled, (state, action) => {
        const {
          success,
          active_debit_bank_list,
          message: msg
        } = action.payload;

        state.getActiveDebitBankListLoading = false;

        if (success) {
          state.activeDebitBankList = active_debit_bank_list;
        } else {
          message.error(msg);
        }
      })
      .addCase(getActiveDebitBankList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getActiveDebitBankListLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getVendorWise.pending, (state) => {
        state.getVendorWiseLoading = true;
      })
      .addCase(getVendorWise.fulfilled, (state, action) => {
        const { success, pagination, data, message: msg } = action.payload;
        // console.log('action.payload', action.payload);
        state.getVendorWiseLoading = false;
        // console.log('data:::::', data);
        if (success) {
          state.vendorWiseList = data;
          // console.log('vendorWiseList::::::', state.vendorWiseList);
          state.vendorWisePagination = pagination || null;
        } else {
          message.error(msg);
        }
      })
      .addCase(getVendorWise.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getVendorWiseLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getSendBackAllocRequest.pending, (state) => {
        state.sendBackAllocRequestLoading = true;
      })
      .addCase(getSendBackAllocRequest.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;

        state.sendBackAllocRequestLoading = false;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(getSendBackAllocRequest.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.sendBackAllocRequestLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getExportPaidInvoices.pending, (state) => {
        state.exportPaidInvoicesLoading = true;
      })
      .addCase(getExportPaidInvoices.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;

        state.exportPaidInvoicesLoading = false;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(getExportPaidInvoices.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.exportPaidInvoicesLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getNormalViewCount.pending, (state) => {
        state.getBUListLoading = true;
      })
      .addCase(getNormalViewCount.fulfilled, (state, action) => {
        //console.log('action.payload_NVC', action.payload);
        const {
          all: all,
          initiated: initiated,
          open: open,
          paid: paid,
          rejected: rejected,
          proposed: proposed,
          ratified: ratified,
          allocated: allocated,
          disbursed: disbursed
        } = action.payload;
        state.all = all;
        state.initiated = initiated;
        state.open = open;
        state.paid = paid;
        state.rejected = rejected;
        state.proposed = proposed;
        state.ratified = ratified;
        state.allocated = allocated;
        state.disbursed = disbursed;
        state.getBUListLoading = false;
      })
      .addCase(getNormalViewCount.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getBUListLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getDashboardCount.pending, (state) => {
        state.getDashboardCountLoading = true;
      })
      .addCase(getDashboardCount.fulfilled, (state, action) => {
        const {
          success,
          open_vs_allocated_count_BU_wise: open_vs_allocated_count_BU_wise,
          ageing_category: ageing_category,
          ageing_data: ageing_data,
          top_five_project_by_invoices: top_five_project_by_invoices,
          total: total,
          initiated: initiated,
          proposed: proposed,
          ratified: ratified,
          allocated: allocated,
          message: msg
        } = action.payload;
        state.getDashboardCountLoading = false;
        if (success) {
          state.open_vs_allocated_count_BU_wise =
            open_vs_allocated_count_BU_wise;
          state.top_five_project_by_invoices = top_five_project_by_invoices;
          state.ageing_category = ageing_category;
          state.ageing_data = ageing_data;
          state.total = total;
          state.initiated = initiated;
          state.proposed = proposed;
          state.ratified = ratified;
          state.allocated = allocated;
        } else {
          message.error(msg);
        }
      })
      .addCase(getDashboardCount.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.getDashboardCountLoading = false;
        message.error(msg);
      });
    builder
      .addCase(getCheckVendorStatus.pending, (state) => {
        state.getCheckVendorStatusLoading = true;
      })
      .addCase(getCheckVendorStatus.fulfilled, (state, action) => {
        const {
          success,
          message: msg,
          liability_status_details,
          vendor_status_details
        } = action.payload;
        state.getCheckVendorStatusLoading = false;
        state.checkVendorStatusData_Liability_status_details =
          liability_status_details ? liability_status_details : {};
        state.checkVendorStatusData_Vendor_status_details =
          vendor_status_details ? vendor_status_details : {};
        // state.pagination = pagination || null;
        if (success) {
          message.success('Vendor details fetched successfully');
        } else {
          message.error(msg);
        }
      })
      .addCase(getCheckVendorStatus.rejected, (state) => {
        state.getCheckVendorStatusLoading = false;
      });

    builder
      .addCase(postSendBackAllocRequestInvoiceWise.pending, (state) => {
        state.postSendBackAllocRequestInvoiceWiseLoading = true;
      })
      .addCase(
        postSendBackAllocRequestInvoiceWise.fulfilled,
        (state, action) => {
          const { success, message: msg } = action.payload;

          state.postSendBackAllocRequestInvoiceWiseLoading = false;

          if (success) {
            message.success(msg);
          } else {
            message.error(msg);
          }
        }
      )
      .addCase(
        postSendBackAllocRequestInvoiceWise.rejected,
        (state, action) => {
          const { message: msg } = action.error;

          state.postSendBackAllocRequestInvoiceWiseLoading = false;

          message.error(msg);
        }
      );
    builder
      .addCase(getInboundFailedList.pending, (state) => {
        state.getInboundFailedListLoading = true;
      })
      .addCase(getInboundFailedList.fulfilled, (state, action) => {
        const { success, data, pagination, message: msg } = action.payload;

        state.getInboundFailedListLoading = false;

        if (success) {
          state.inboundFailedList = data ? data : '';
          state.paginationInboundFailedList = pagination ? pagination : '';
          // const getInboundFailedList = getInboundFailedList.map((item) => {
          //   return {
          //     label: item,
          //     value: item
          //   };
          // });
          // state.inboundFailedList = getInboundFailedList.splice(1);
        } else {
          message.error(msg);
        }
      })
      .addCase(getInboundFailedList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getInboundFailedListLoading = false;

        message.error(msg);
      });
    builder
      .addCase(postRetryInbound.pending, (state) => {
        state.postRetryInboundLoading = true;
      })
      .addCase(postRetryInbound.fulfilled, (state, action) => {
        const { success } = action.payload;
        //, message: msg
        state.postRetryInboundLoading = false;

        if (success) {
          // message.success(msg);
        } else {
          // message.error(msg);
        }
      })
      .addCase(postRetryInbound.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postRetryInboundLoading = false;

        message.error(msg);
      });
  }
});

export default invoicesSlice.reducer;
