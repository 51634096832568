import React from 'react'; //, { useState }
import { Modal, Form } from 'antd';
// import styles from './index.module.less';
//import moment from 'moment';
//import { getLoggedInUser } from 'utilities/helpers';
//import { message } from 'antd';

function ViewInitiatedAmount({ viewInitAmountModal, setViewInitAmountModal }) {
  // const [dateRange, setDateRange] = useState({});
  // const [startDate, setStartDate] = useState('');

  //const user_role1 = getLoggedInUser();
  // const user_role = user_role1?.user_details?.role;

  // useState(() => {
  //   if (startDate !== null) {
  //     setStartDate(startDate);
  //   }
  // }, [startDate]);

  // const handleDateOnChange = (slug, value) => {
  //   if (slug === 'fromDate') {
  //     setStartDate(value);
  //   }
  //   setDateRange({
  //     ...dateRange,
  //     [slug]: value
  //   });
  // };

  // console.log('startDate', startDate);
  // console.log('dateRange', dateRange);

  const onOk = () => {
    setViewInitAmountModal(false);
    // const fromDate = dateRange?.fromDate || '';
    /*  ? `${moment(dateRange.fromDate).format('YYYY-MM-DD')}`
      : ''; */
    // const toDate = dateRange?.toDate || '';
    /*  ? `${moment(dateRange.toDate).format('YYYY-MM-DD')}`
      : ''; */

    // console.log('fromDate', fromDate);
    // console.log('toDate', toDate);

    /* 
    glView === true
      ? fetch(
          `${process.env.REACT_APP_API_URL}/Finance/exportAllocatedInvoices?from_date=${fromDate}&to_date=${toDate}&is_H2H=false`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/csv',
              Authorization: `Bearer ${user_role1?.accessToken}`
            }
          }
        ).then((response) => {
          if (response?.status !== 500) {
            response.blob().then((blob) => {
              const url = window.URL.createObjectURL(new Blob([blob]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute(
                'download',
                `a2p_exported_allocated_invoices.csv`
              );

              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
            });
            setViewInitAmountModal(false); //close the Modal...........
          } else {
            message.success('No any allocated invoices found');
            console.log('responceNOT');
            setViewInitAmountModal(false);
          }
        })
      : fetch(
          `${process.env.REACT_APP_API_URL}/Finance/exportAllocatedInvoices?from_date=${fromDate}&to_date=${toDate}&is_H2H=true`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/csv',
              Authorization: `Bearer ${user_role1?.accessToken}`
            }
          }
        ).then((response) => {
          if (response?.status !== 500) {
            response.blob().then((blob) => {
              const url = window.URL.createObjectURL(new Blob([blob]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute(
                'download',
                `a2p_exported_allocated_invoices.csv`
              );

              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
            });
            setViewInitAmountModal(false); //close the Modal...........
          } else {
            message.success('No any allocated invoices found');
            console.log('responceNOT');
           setViewInitAmountModal(false);
          }
        }); */
  };

  return (
    <>
      <Modal
        title={
          <span style={{ fontWeight: 600, fontSize: '16px' }}>
            View Initiated Amount :{' '}
          </span>
        }
        visible={viewInitAmountModal}
        // className={`${styles.check_circle_icon}`}
        width={'720px'}
        onCancel={() => setViewInitAmountModal(false)}
        onOk={() => setViewInitAmountModal(false)}
        // cancelButtonProps={{ style: { display: 'none' } }}
        okText={'OK'}
        okButtonProps={{
          //loading: postAllocationRequestLoading,
          // disabled: !selectedBank?.length > 0,
          onClick: onOk
        }}
      >
        <Form
          name="date"
          initialValues={{ remember: true }}
          //onFinish={onOk}
          autoComplete="off"
        >
          {/*  <Row>
            <Col span={4}>
              <Form.Item style={{ textAlign: 'center', fontWeight: 600 }}>
                Select Date:
              </Form.Item>
            </Col>

            <Col span={8}>
              {' '}
              <Form.Item
                label="From"
                name="fromDate"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.date_styles}`}
              >
                <DatePicker
                  className={`${styles.datePicker_styles}`}
                  size="small"
                  format={'DD-MMM-YYYY'}
                  onChange={(_, dateValue) =>
                    handleDateOnChange('fromDate', dateValue)
                  }
                  disabledDate={(current) => {
                    return (
                      // moment().add(-1, 'days') >= current ||
                      moment().add(0, 'month') <= current
                    );
                  }}
                ></DatePicker>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="To"
                name="toDate"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
                className={`${styles.date_styles}`}
              >
                <DatePicker
                  className={`${styles.datePicker_styles}`}
                  style={{ fontSize: '11px' }}
                  size="small"
                  format={'DD-MMM-YYYY'}
                  onChange={(_, dateValue) =>
                    handleDateOnChange('toDate', dateValue)
                  }
                  disabledDate={(current) => {
                    return (
                      //current && current.valueOf() > Date.now()
                      // moment().add(-1, 'days') >= current ||
                      moment().add(0, 'month') <= current
                    );
                  }}
                ></DatePicker>
              </Form.Item>
            </Col>
          </Row> */}
          {/* <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item> */}
        </Form>
      </Modal>
    </>
  );
}
export default ViewInitiatedAmount;
