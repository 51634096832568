import React, { useEffect, useState } from 'react';
import {
  InputNumber,
  Space,
  Typography,
  Tooltip,
  Select,
  Input,
  message
} from 'antd';
import styles from './index.module.less';
import CustomModal from 'common/CustomModal';
import CustomTableModal from '../../../../../common/Content/CustomTableModal';
import moment from 'moment';
import { SearchOutlined } from '@ant-design/icons';
import { numFormating, numIntStyleFormat } from 'utilities/helpers';

const { Text } = Typography;
const { Option } = Select;

const TreasurySummaryAllocModal = ({
  visible,
  toggleModal,
  data,
  glView,
  postAllocationRequestLoading,
  fromSummary,
  user_role,
  handlePostBuRequestFundAllocation,
  requestedAmount,
  activeDebitBankList,
  grandTotal,
  currentTotal
}) => {
  console.log('DATATreasuryModal', data);
  const [reqAmount, setReqAmount] = useState(0);
  const [totalInvoicesAmount, setTotalInvoicesAmount] = useState(0);
  const [editReqAmount] = useState(false);
  const [selectedBank, setSelectedBank] = useState('');
  const [reqAllocInvoiceKeys, setReqAllocInvoiceKeys] = useState([]);
  const [reqAllocInvoices, setReqAllocInvoices] = useState([]);
  const [unselectedInvoices, setUnselectedInvoices] = useState([]);
  const [search, setSearch] = useState();

  const searchHandler = (e) => {
    setSearch(e.target.value);
  };
  const filtreddata = !search
    ? data
    : data?.filter((item) => {
        return Object.keys(item)?.some((key) => {
          return item[key]
            ?.toString()
            ?.toLowerCase()
            ?.includes(search.toLowerCase());
        });
      });

  useEffect(() => {
    const totalAmount = data?.reduce(
      (a, b) => a + parseFloat(b?.net_payable),
      0
    );
    setReqAmount(totalAmount);
    setTotalInvoicesAmount(totalAmount);

    if (fromSummary) {
      let invoiceKeys = data?.map((o) => o.key);
      setReqAllocInvoiceKeys(invoiceKeys);
      setReqAllocInvoices(data);
    }
    setSelectedBank(activeDebitBankList[0]?.bank_account_number);
  }, []);

  const invoiceData = data[0];
  const { SBU_name } = invoiceData;
  const columns = [
    {
      title: 'PROJECT CODE',
      dataIndex: 'tds_project_code',
      key: 'tds_project_code',
      sorter: (a, b) => a.tds_project_code - b.tds_project_code,
      ellipsis: {
        showTitle: false
      },
      width: '110px',
      render: (tds_project_code) => {
        return (
          <>
            <Tooltip
              title={tds_project_code}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {tds_project_code}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'VENDOR',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
      sorter: (a, b) => a.vendor_name.localeCompare(b.vendor_name),
      ellipsis: {
        showTitle: false
      },
      width: '260px',
      render: (vendor_name) => {
        return (
          <>
            <Tooltip
              title={vendor_name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {vendor_name}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'INVOICE NUMBER',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      sorter: (a, b) => a.invoice_number.localeCompare(b.invoice_number),
      ellipsis: {
        showTitle: false
      },
      width: '240px',
      render: (invoice_number) => {
        return (
          <>
            <Tooltip
              title={invoice_number}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <a>{invoice_number}</a>
            </Tooltip>
          </>
        );
      }
    },
    glView
      ? {
          title: 'GL DATE',
          dataIndex: 'GL_date',
          key: 'GL_date',
          sorter: (a, b) =>
            moment(a['GL_date']).unix() - moment(b['GL_date']).unix()
        }
      : fromSummary
      ? ({
          title: 'GL DATE',
          dataIndex: 'GL_date',
          key: 'GL_date',
          sorter: (a, b) =>
            moment(a['GL_date']).unix() - moment(b['GL_date']).unix()
        },
        {
          title: 'INVOICE DATE',
          dataIndex: 'invoice_date',
          key: 'invoice_date',
          sorter: (a, b) =>
            moment(a['invoice_date']).unix() - moment(b['invoice_date']).unix()
        })
      : {
          title: 'INVOICE DATE',
          dataIndex: 'invoice_date',
          key: 'invoice_date',
          sorter: (a, b) =>
            moment(a['invoice_date']).unix() - moment(b['invoice_date']).unix()
        },
    {
      title: 'NET PAYABLE',
      dataIndex: 'net_payable',
      key: 'net_payable',
      sorter: (a, b) => Number(a.net_payable) - Number(b.net_payable),
      ellipsis: {
        showTitle: false
      },
      render: (net_payable) => {
        return (
          <>
            <Tooltip
              title={numIntStyleFormat(net_payable)}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span style={{ fontWeight: 'bold' }}>
                {net_payable >= 0 ? (
                  <span>₹{numFormating(net_payable)}</span>
                ) : (
                  <span>-₹{numFormating(net_payable)}</span>
                )}
              </span>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'AGEING',
      dataIndex: 'ageing',
      key: 'ageing',
      sorter: (a, b) =>
        Number(a.ageing.replace(` days`, '')) -
        Number(b.ageing.replace(` days`, ''))
    }
  ];

  const rowSelection = {
    selectedRowKeys: reqAllocInvoiceKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      const unselectedRows = data?.filter((object1) => {
        return !selectedRows.some((object2) => {
          return object1.id === object2.id;
        });
      });
      setUnselectedInvoices(unselectedRows);

      setReqAllocInvoiceKeys(selectedRowKeys);
      setReqAllocInvoices(selectedRows);

      const reCalculatedTotalAmount = selectedRows?.reduce(
        (a, b) => a + parseFloat(b?.net_payable),
        0
      );
      //setReqAmount(reCalculatedTotalAmount);
      setTotalInvoicesAmount(reCalculatedTotalAmount);
    }
  };

  // console.log('reqAllocInvoices', reqAllocInvoices);
  console.log('setSelectedBank', selectedBank);
  console.log('activeDebitBankList', activeDebitBankList);
  // console.log('data', data);

  const tolerance = 0.5;
  function compareFloatsGreaterWithTolerance(a, b, tolerance) {
    const diff = a - b;
    return diff > tolerance ? a : null;
  } // : diff < -tolerance ? b

  useEffect(() => {
    const totalInvoicesAmountWithCurrentTotal =
      Math.abs(totalInvoicesAmount) + Math.abs(currentTotal);

    const greaterFloat = compareFloatsGreaterWithTolerance(
      totalInvoicesAmountWithCurrentTotal,
      Math.abs(grandTotal),
      tolerance
    );
    // const total = Math.abs(totalInvoicesAmount) + Math.abs(currentTotal);
    //if (total > Math.abs(grandTotal))
    if (greaterFloat !== null) {
      message.error('You are exceeding the ratified liability');
    }
  }, [totalInvoicesAmount]);

  const onOk = () => {
    if (user_role === 'Treasury') {
      // let debit_bank_detail = null;
      const allRows = data?.map((item) => item?.id);
      const approvedRows = reqAllocInvoices?.map((item) => item?.id);
      const rejectedRows = unselectedInvoices?.map((item) => item?.id);
      let payload = {
        approved_allocation_invoice_id:
          approvedRows?.length === 0 && rejectedRows?.length === 0
            ? allRows
            : approvedRows,
        rejected_allocation_invoice_id: rejectedRows,
        debit_bank_detail: activeDebitBankList.find(
          (item) => item.bank_account_number === selectedBank
        ),
        requested_fund: totalInvoicesAmount,
        selected_worth: totalInvoicesAmount
        /*  debit_bank_detail: data[0]?.non_H2H
        ? debit_bank_detail
        : activeDebitBankList.find(
            (item) => item.bank_branch_name === selectedBank
          ), */
      };
      console.log('Treasury_payload', payload);
      handlePostBuRequestFundAllocation('Treasury', payload);
    }
  };

  return (
    <CustomModal
      title="Request Fund Allocation"
      visible={visible}
      width={'90%'}
      bodyStyle={{ height: '80vh', overflow: 'scroll', overflowX: 'hidden' }}
      style={{ top: 50 }}
      onCancel={toggleModal}
      cancelButtonProps={{ onClick: toggleModal }}
      okText={
        totalInvoicesAmount >= 0
          ? `Submit Fund allocation of ₹${numFormating(
              totalInvoicesAmount
            )} for vendor`
          : `Submit Fund allocation of -₹${numFormating(
              totalInvoicesAmount
            )} for vendor`
      }
      okButtonProps={{
        disabled:
          !selectedBank?.length > 0 ||
          totalInvoicesAmount >= 0 ||
          compareFloatsGreaterWithTolerance(
            Math.abs(totalInvoicesAmount),
            Math.abs(reqAmount),
            tolerance
          ) !== null ||
          compareFloatsGreaterWithTolerance(
            Math.abs(totalInvoicesAmount) + Math.abs(currentTotal),
            Math.abs(grandTotal),
            tolerance
          ) !== null,
        // Math.abs(totalInvoicesAmount) > Math.abs(reqAmount) ||
        // Math.abs(totalInvoicesAmount) + Math.abs(currentTotal) >
        //   Math.abs(grandTotal),
        // user_role === 'Treasury'
        //   ? !selectedBank?.length > 0
        //   : null,
        loading: postAllocationRequestLoading,
        onClick: onOk
      }}
    >
      <div>
        <Space size={'middle'} align="center">
          <Text
            className={`${styles.req_alloc_modal_vendor_details} sfprotext-medium font-13`}
          >{`${SBU_name}`}</Text>
          <Space
            size={'large'}
            className={`${styles.req_alloc_modal_requested_amount_details}`}
          >
            <Text className="font-14">{requestedAmount}:</Text>
            {!editReqAmount ? (
              <Text className="sfprotext-bold font-14">
                {/* numIntStyleFormat(reqAmount) */}
                <Tooltip
                  title={reqAmount}
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {reqAmount >= 0 ? (
                    <span>₹{numFormating(reqAmount)}</span>
                  ) : (
                    <span>-₹{numFormating(reqAmount)}</span>
                  )}
                </Tooltip>
              </Text>
            ) : (
              <InputNumber
                min={1}
                max={totalInvoicesAmount}
                value={reqAmount}
                onChange={setReqAmount}
              />
            )}
          </Space>
        </Space>
      </div>
      <div>
        <Space size={'middle'} align="center" className="mt-3">
          <Text className="sfprotext-bold font-14">
            {'Selected invoices for the requested amount'}
          </Text>
          <Space
            size={'middle'}
            className={`${styles.req_alloc_modal_invoices_total_amount_details}`}
          >
            <Text className="font-14">{`${
              (fromSummary ? reqAllocInvoices : data).length
            } invoices of worth`}</Text>
            <Text className="sfprotext-bold font-14">
              <Tooltip
                title={totalInvoicesAmount}
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {totalInvoicesAmount >= 0 ? (
                  <span>₹{numFormating(totalInvoicesAmount)}</span>
                ) : (
                  <span>-₹{numFormating(totalInvoicesAmount)}</span>
                )}
              </Tooltip>
            </Text>
          </Space>
        </Space>
      </div>

      <div className="mt-3">
        <Text className="sfprotext-medium font-14 mr-3">
          Select Debit Bank:
        </Text>
        <Select
          size="middle"
          showSearch
          style={{ minWidth: '50%', fontSize: '11px', height: 30 }}
          placeholder="Search to Select"
          value={selectedBank}
          optionFilterProp="children"
          filterOption={(input, option) => option.children.includes(input)}
          onChange={setSelectedBank}
          defaultValue={`Name: ${activeDebitBankList[0]?.bank_name}, IFSC: ${activeDebitBankList[0]?.bank_branch_name}, A/c no: ${activeDebitBankList[0]?.bank_account_number}`}
        >
          {activeDebitBankList.map((bank, index) => {
            const { bank_account_number } = bank;
            /*  const { bank_name, bank_branch_name, bank_account_number } = bank; */
            return (
              <Option key={index} value={bank.bank_account_number}>
                {' '}
                <div style={{ fontSize: '11px' }}>
                  {` A/c no: ${bank_account_number}`}
                  {/*  {`Name: ${bank_name}, IFSC: ${bank_branch_name}, A/c no: ${bank_account_number}`} */}
                </div>
              </Option>
            );
          })}
        </Select>
      </div>

      <div className="mt-3" style={{ width: '300px' }}>
        <Input
          placeholder="Search using invoice number/project"
          allowClear
          onChange={searchHandler}
          justify="start"
          align="middle"
          // className="mb-4"
          value={search}
          prefix={<SearchOutlined />}
        />
      </div>
      <div className="mt-3">
        <CustomTableModal
          style={{
            fontFamily: 'SFProText Regular',
            padding: '40px 4px !important',
            lineHeight: '18px',
            color: '#32324D'
          }}
          data={filtreddata}
          columns={columns}
          pagination={false}
          rowSelection={fromSummary ? rowSelection : null}
        />
      </div>
    </CustomModal>
  );
};

export default TreasurySummaryAllocModal;
