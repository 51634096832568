import React, { useEffect, useState } from 'react';
import {
  InputNumber,
  Select,
  Space,
  Tooltip,
  Typography
  // Input,
  // Col,
  // Row

  // Input,
  // InputNumber,
} from 'antd';
import styles from './index.module.less';
import CustomModal from 'common/CustomModal';
import CustomTableModal from 'common/Content/CustomTableModal';
//import CustomButton from 'common/CustomButton';
import moment from 'moment';
import SearchBarModal from '@/common/Content/searchBarModal';
import { numFormating, numIntStyleFormat } from 'utilities/helpers';
//import emptyState from 'assets/images/empty_state.svg';

const { Text } = Typography;
const { Option } = Select;

const FinanceCoEReqRejectModal = ({
  visible,
  toggleModal,
  data,
  glView,
  handleGetActiveBankList, ////dashbord
  //postAllocationRequestLoading,
  handlePostAllocationRequest,
  fromSummary, //summary
  selectedBankBranchName, //summary
  user_role,
  requestedAmount,
  openCheckbox, //summary
  handleFiltersOnChangeSerchInModal, //summary
  activeDebitBankList,
  postSendBackAllocRequestInvoiceWiseLoading
}) => {
  if (!data) return null;
  // console.log('DATAAAAAAAAAA', data[0]);
  // console.log('DATAAAAAAAAAA', data[0]?.non_H2H);
  // console.log('handleGetActiveBankList', handleGetActiveBankList);
  // console.log(' toggleModal', toggleModal);
  // console.log(' activeBankList..........', `Name: ${activeBankList[0]?.bank_name}, IFSC: ${activeBankList[0]?.bank_branch_name}, A/c no: ${activeBankList[0]?.bank_account_number}`);
  // console.log(' activeBankList..........', activeBankList[0]);

  const [reqAmount, setReqAmount] = useState(0);
  const [totalInvoicesAmount, setTotalInvoicesAmount] = useState(0);
  const [editReqAmount] = useState(false);
  const [selectedBank, setSelectedBank] = useState('');
  // const [selectedBankLength] = useState();
  const [reqAllocInvoiceKeys, setReqAllocInvoiceKeys] = useState([]);
  const [reqAllocInvoices, setReqAllocInvoices] = useState([]);
  // const [bankName, setBankName] = useState('');
  // const [bankIFSC, setBankIFSC] = useState('');
  // const [bankAccount, setBankAccount] = useState('');
  // const [accountHolderName, setAccountHolderName] = useState('');

  //console.log(' selectedBank..........', selectedBank);
  /*  if (user_role === 'Finance CoE') {
    //set default bank a/c if it is single//
    useEffect(() => {
      if (activeBankList?.length === 1) {
        setSelectedBank(activeBankList[0]?.bank_branch_name);
      } else setSelectedBank('');
    }, [activeBankList[0]]);
  } */

  useEffect(() => {
    let invoiceKeys = data.map((o) => o.key);
    if (openCheckbox) {
      setReqAllocInvoiceKeys(invoiceKeys);
    }
  }, [openCheckbox]);

  useEffect(() => {
    const totalAmount = data.reduce(
      (a, b) => a + parseFloat(b?.net_payable),
      0
    );
    setReqAmount(totalAmount);
    setTotalInvoicesAmount(totalAmount);

    if (handleGetActiveBankList) {
      const invoiceData = data[0];
      const { vendor_code, vendor_site_code } = invoiceData;
      handleGetActiveBankList({ vendor_code, vendor_site_code });
    }

    if (fromSummary) {
      setReqAllocInvoices(data);
    }
    setSelectedBank(activeDebitBankList[0]?.bank_branch_name);
    /*  if (selectedBankBranchName) {
      setSelectedBank(selectedBankBranchName);
      setSelectedBankLength(activeBankList?.length);
      console.log('NOT Required.....');
    } */
  }, []);

  const invoiceData = data[0];
  const { vendor_name, vendor_code } = invoiceData;
  const columns = [
    {
      title: 'PROJECT CODE',
      dataIndex: 'tds_project_code',
      key: 'tds_project_code',
      sorter: (a, b) => a.tds_project_code - b.tds_project_code,
      ellipsis: {
        showTitle: false
      },
      width: '110px',
      render: (tds_project_code) => {
        return (
          <>
            <Tooltip
              title={tds_project_code}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {tds_project_code}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'VENDOR',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
      sorter: (a, b) => a.vendor_name.localeCompare(b.vendor_name),
      ellipsis: {
        showTitle: false
      },
      width: '260px',
      render: (vendor_name) => {
        return (
          <>
            <Tooltip
              title={vendor_name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {vendor_name}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'INVOICE NUMBER',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      sorter: (a, b) => a.invoice_number.localeCompare(b.invoice_number),
      ellipsis: {
        showTitle: false
      },
      width: '240px',
      render: (invoice_number) => {
        return (
          <>
            <Tooltip
              title={invoice_number}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <a>{invoice_number}</a>
            </Tooltip>
          </>
        );
      }
    },
    glView
      ? {
          title: 'GL DATE',
          dataIndex: 'GL_date',
          key: 'GL_date',
          sorter: (a, b) =>
            moment(a['GL_date']).unix() - moment(b['GL_date']).unix()
        }
      : fromSummary
      ? ({
          title: 'GL DATE',
          dataIndex: 'GL_date',
          key: 'GL_date',
          sorter: (a, b) =>
            moment(a['GL_date']).unix() - moment(b['GL_date']).unix()
        },
        {
          title: 'INVOICE DATE',
          dataIndex: 'invoice_date',
          key: 'invoice_date',
          sorter: (a, b) =>
            moment(a['invoice_date']).unix() - moment(b['invoice_date']).unix()
        })
      : {
          title: 'INVOICE DATE',
          dataIndex: 'invoice_date',
          key: 'invoice_date',
          sorter: (a, b) =>
            moment(a['invoice_date']).unix() - moment(b['invoice_date']).unix()
        },
    {
      title: 'NET PAYABLE',
      dataIndex: 'net_payable',
      key: 'net_payable',
      sorter: (a, b) => Number(a.net_payable) - Number(b.net_payable),
      ellipsis: {
        showTitle: false
      },
      render: (net_payable) => {
        return (
          <>
            <Tooltip
              title={numIntStyleFormat(net_payable)}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span style={{ fontWeight: 'bold' }}>
                {net_payable >= 0 ? (
                  <span>
                    ₹{numFormating(net_payable)}
                    {/*  {`₹${net_payable?.toLocaleString(undefined, {
                      maximumFractionDigits: 2
                    })}`} */}{' '}
                  </span>
                ) : (
                  <span>
                    -₹{numFormating(net_payable)}
                    {/* ₹
                    {Math.abs(net_payable)?.toLocaleString(undefined, {
                      maximumFractionDigits: 2
                    })} */}
                  </span>
                )}
              </span>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'AGEING',
      dataIndex: 'ageing',
      key: 'ageing',
      width: '150',
      sorter: (a, b) =>
        Number(a.ageing.replace(` days`, '')) -
        Number(b.ageing.replace(` days`, ''))
    }
  ];

  const rowSelection = {
    selectedRowKeys: reqAllocInvoiceKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setReqAllocInvoiceKeys(selectedRowKeys);
      setReqAllocInvoices(selectedRows);

      const reCalculatedTotalAmount = selectedRows.reduce(
        (a, b) => a + parseFloat(b.net_payable),
        0
      );
      setReqAmount(reCalculatedTotalAmount);
      setTotalInvoicesAmount(reCalculatedTotalAmount);
    }
  };

  const onOk = () => {
    // let bank_detail = null;
    /*  if (selectedBankBranchName) {
      const invoiceData = data[0];
      const { vendor_code } = invoiceData;
      bank_detail = summaryInvoicesActiveBankList[vendor_code].find(
        (item) => item.bank_branch_name === selectedBank
      );
      console.log("bank_detail_1",bank_detail);
    } */

    if (user_role === 'Finance CoE') {
      let payload = {
        // requested_fund: reqAmount,
        // selected_worth: totalInvoicesAmount,
        /* bank_detail: data[0]?.non_H2H
          ? bank_detail
          : activeBankList.find(
              (item) => item.bank_branch_name === selectedBank
            ), */
        /*  invoices: data[0]?.non_H2H
          ? null
          : (fromSummary ? reqAllocInvoices : data).map((item) => item.id),
        non_H2H_invoices: data[0]?.non_H2H
          ? (fromSummary ? reqAllocInvoices : data).map((item) => item.id)
          : null, */
        approved_allocation_invoice_id: data?.map((item) => item?.id),
        rejected_allocation_invoice_id: [],
        bank_detail: activeDebitBankList.find(
          (item) => item.bank_account_number === selectedBank
        ),
        requested_fund: reqAmount,
        selected_worth: totalInvoicesAmount
        /*  bank_detail: {
          bank_name: bankName,
          bank_branch_name: bankIFSC,
          bank_account_number: bankAccount,
          bank_account_name: accountHolderName
        } */
      };
      console.log('payloadFinance', payload);
      handlePostAllocationRequest('Finance', payload);
    } else if (user_role === 'BU Commercial') {
      let payload = {
        approved_allocation_invoice_id: data?.map((item) => item?.id)
      };
      handlePostAllocationRequest('BUC', payload);
    } else if (user_role === 'Treasury') {
      let payload = {
        approved_allocation_invoice_id: data?.map((item) => item?.id)
      };
      handlePostAllocationRequest('Treasury', payload);
    }
    // setOpenCheckbox(false);
  };
  console.log('selectedBank?.length', reqAmount);
  return (
    <CustomModal
      title="Request Fund Allocation"
      visible={visible}
      width={'90%'}
      bodyStyle={{ height: '80vh', overflow: 'scroll', overflowX: 'hidden' }}
      style={{ top: 50 }}
      onCancel={toggleModal}
      cancelButtonProps={{ onClick: toggleModal }}
      okText={
        totalInvoicesAmount >= 0
          ? `Submit Fund allocation of ₹${numFormating(
              totalInvoicesAmount
            )} for vendor`
          : `Submit Fund allocation of -₹${numFormating(
              totalInvoicesAmount
            )} for vendor`
      }
      okButtonProps={{
        disabled:
          !selectedBank?.length > 0 ||
          totalInvoicesAmount >= 0 ||
          Math.abs(totalInvoicesAmount) > Math.abs(reqAmount),
        //disabled: !selectedBank?.length || !reqAmount > 0,
        /*  disabled:
          user_role === 'Finance CoE'
            ? data[0]?.non_H2H
              ? null
              : (!selectedBankLength > 0 && !selectedBank) || !reqAmount > 0
            : null, */
        loading: postSendBackAllocRequestInvoiceWiseLoading,
        onClick: onOk
      }}
    >
      <div>
        <Space size={'middle'} align="center">
          <Text
            className={`${styles.req_alloc_modal_vendor_details} sfprotext-medium font-13`}
          >{`${vendor_name} (${vendor_code})`}</Text>
          <Space
            size={'large'}
            className={`${styles.req_alloc_modal_requested_amount_details}`}
          >
            {/*   <Text className="font-14">{'Requested amount:'}</Text> */}
            <Text className="font-14">{requestedAmount}:</Text>
            {!editReqAmount ? (
              <Text className="sfprotext-bold font-14">
                <Tooltip
                  title={reqAmount}
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {reqAmount >= 0 ? (
                    <span>₹{numFormating(reqAmount)}</span>
                  ) : (
                    <span>- ₹{numFormating(reqAmount)}</span>
                  )}
                </Tooltip>
              </Text>
            ) : (
              <InputNumber
                min={1}
                max={totalInvoicesAmount}
                value={reqAmount}
                onChange={setReqAmount}
              />
            )}
          </Space>
        </Space>
      </div>
      <div>
        <Space size={'middle'} align="center" className="mt-3">
          <Text className="sfprotext-bold font-14">
            {'Selected invoices for the requested amount'}
          </Text>
          <Space
            size={'middle'}
            className={`${styles.req_alloc_modal_invoices_total_amount_details}`}
          >
            <Text className="font-14">{`${
              (fromSummary ? reqAllocInvoices : data).length
            } invoices of worth`}</Text>
            <Text className="sfprotext-bold font-14">
              <Tooltip
                title={totalInvoicesAmount}
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {totalInvoicesAmount >= 0 ? (
                  <span>₹{numFormating(totalInvoicesAmount)}</span>
                ) : (
                  <span>-₹{numFormating(totalInvoicesAmount)}</span>
                )}
              </Tooltip>
            </Text>
          </Space>
        </Space>
      </div>
      <div className="mt-3">
        <Text className="sfprotext-medium font-14 mr-3">Select Bank:</Text>
        <Select
          size="middle"
          showSearch
          style={{ minWidth: '50%', fontSize: '11px', height: 30 }}
          placeholder="Search to Select"
          value={selectedBank}
          optionFilterProp="children"
          filterOption={(input, option) => option.children.includes(input)}
          onChange={setSelectedBank}
          defaultValue={`Name: ${activeDebitBankList[0]?.bank_name}, IFSC: ${activeDebitBankList[0]?.bank_branch_name}, A/c no: ${activeDebitBankList[0]?.bank_account_number}`}
        >
          {activeDebitBankList.map((bank, index) => {
            const { bank_account_number } = bank;
            /*  const { bank_name, bank_branch_name, bank_account_number } = bank; */
            return (
              <Option key={index} value={bank.bank_account_number}>
                {' '}
                <div style={{ fontSize: '11px' }}>
                  {` A/c no: ${bank_account_number}`}
                  {/* {`Name: ${bank_name}, IFSC: ${bank_branch_name}, A/c no: ${bank_account_number}`} */}
                </div>
              </Option>
            );
          })}
        </Select>
      </div>
      {/* <div className="mt-3">
        <Row gutter={8}>
          <Col span={8}>
            <Text style={{ fontSize: '12px' }}>Bank Name:</Text>
            <Input
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
              placeholder="Enter Bank Name:"
            />
          </Col>
          <Col span={8}>
            <Text style={{ fontSize: '12px' }}>IFSC Code:</Text>
            <Input
              value={bankIFSC}
              onChange={(e) => setBankIFSC(e.target.value)}
              placeholder="Enter IFSC Code:"
            />
          </Col>
        </Row>
      </div>
      <div>
        <Row gutter={8}>
          <Col span={8}>
            <Text style={{ fontSize: '12px' }}> A/C Number:</Text>
            <Input
              value={bankAccount}
              onChange={(e) => setBankAccount(e.target.value)}
              placeholder="Enter A/C Number"
            />
          </Col>
          <Col span={8}>
            <Text style={{ fontSize: '12px' }}> A/C Holder Name:</Text>
            <Input
              value={accountHolderName}
              onChange={(e) => setAccountHolderName(e.target.value)}
              placeholder="Enter A/C Holder Name"
            />
          </Col>
        </Row>
      </div> */}
      {/* <div style={{ marginBottom: '4px' }}>
          <Row>
            <Col span={4}>
              <span className="sfprotext-medium font-12">
                {' '}
                Enter A/C Holder Name:{' '}
              </span>
            </Col>
            <Col span={8}>
              <span>
                <Input
                  value={bankAccount}
                  onChange={(event)=>setBankAccount(event.target.value)}
                  placeholder="Enter A/C Number"
                  size="middle"
                  style={{ width: '400px' }}
                />
              </span>
            </Col>
          </Row> 
        </div>*/}

      {/* {user_role === 'Finance CoE' ? (
        data[0]?.non_H2H ? null : (
          <div className="mt-3">
            <Text className="sfprotext-medium font-14 mr-3">Select Bank:</Text>
            <Select
              size="middle"
              showSearch
              style={{ minWidth: '50%', fontSize: '12px', height: 30 }}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) => option.children.includes(input)}
              onChange={setSelectedBank}
              defaultValue={`Name: ${activeBankList[0]?.bank_name}, IFSC: ${activeBankList[0]?.bank_branch_name}, A/c no: ${activeBankList[0]?.bank_account_number}`}
            >
              {activeBankList.map((bank, index) => {
                const { bank_name, bank_branch_name, bank_account_number } =
                  bank;

                return (
                  <Option key={index} value={bank.bank_branch_name}>
                    {' '}
                    <div
                      style={{ fontSize: '12px' }}
                    >{`Name: ${bank_name}, IFSC: ${bank_branch_name}, A/c no: ${bank_account_number}`}</div>
                  </Option>
                );
              })}
            </Select>
          </div>
        )
      ) : null} */}
      <div className="mt-3">
        {!openCheckbox && selectedBankBranchName ? (
          <SearchBarModal
            justify="start"
            align="middle"
            className="mb-4"
            gutter={32}
            inputProps={{
              placeholder: 'Search using invoice number/project'
            }}
            //handleFiltersOnChange={handleFiltersOnChange}
            handleFiltersOnChangeSerchInModal={
              handleFiltersOnChangeSerchInModal
            }
            user_role={user_role}
          />
        ) : null}
      </div>
      <div className="mt-3">
        <CustomTableModal
          style={{
            fontFamily: 'SFProText Regular',
            padding: '40px 4px !important',
            lineHeight: '18px',
            color: '#32324D'
          }}
          data={data}
          columns={columns}
          pagination={false}
          rowSelection={fromSummary ? rowSelection : null}
        />
      </div>
    </CustomModal>
  );
};

export default FinanceCoEReqRejectModal;
