import React, { useState, useEffect } from 'react';
import { InputNumber, Space, Typography, Button, Tooltip } from 'antd';
import styles from './index.module.less';
import CustomButton from 'common/CustomButton';
import {
  liabilityToLabelTreasury,
  numFormating,
  numIntStyleFormat
} from 'utilities/helpers';
import CustomModal from 'common/CustomModal';
import TreasurySummaryAllocModal from '../TreasurySummaryAllocModal';
import { DoubleRightOutlined } from '@ant-design/icons';
import { history } from 'app/history';

const { Title, Text } = Typography;

const TreasurySummaryInvoiceItem = ({
  data,
  reqFund,
  setReqFund,
  openReqFundAllocModal,
  setOpenReqFundAllocModal,
  handleRequestFund,

  openReqAllocModal,
  setOpenReqAllocModal,
  postRequestFundLoading,
  requestFundInvoices,
  postAllocationRequestLoading,
  handlePostAllocationRequest,
  summaryInvoicesActiveBankList,
  clickSubmitInvoices,
  setClickSubmitInvoices,
  allInvoicesByAge,
  handleSubmitInvoices,
  user_role,
  handlePostBuRequestFundAllocation,
  liabilityAmount,
  handleFiltersOnChange,
  activeDebitBankList
}) => {
  if (!data) return null;
  const {
    SBU_name,
    proposed_liability, //3rd line
    initiated_liability, //2nd line
    total_liability, //1st line
    allocated_liability,
    ratified_liability,
    total_advances_up
  } = data;
  console.log('Treasurydata', data);

  const [liabilities, setLiabilities] = useState([]);

  useEffect(() => {
    let allLiabilities = Object.keys(total_liability);
    allLiabilities.splice(6, 1);
    setLiabilities(allLiabilities);
  }, [total_liability]);
  return (
    <div className={`${styles.summary_invoice_item_container} mb-3`}>
      <div style={{ position: 'relative' }}>
        <Space>
          <Text
            className={`sfprotext-medium ${styles.summary_invoice_item_vendor_details_container}`}
          >
            {`${SBU_name} `}
            <Tooltip
              title="View project list"
              color={'#1E206A'}
              overlayInnerStyle={{ color: ' #FFFFFF', fontSize: '11px' }}
              arrowPointAtCenter="false"
            >
              <DoubleRightOutlined
                style={{ marginLeft: '8px' }}
                onClick={() => {
                  history.push(`/project/${data?.SBU_name}`);
                }}
              />
            </Tooltip>
          </Text>
          <Text className="font-12">
            <Text className="mr-1" style={{ color: '#666687' }}>
              Advances(UP):
              <Tooltip
                title={numIntStyleFormat(total_advances_up)}
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                ₹{numFormating(total_advances_up)}
              </Tooltip>
            </Text>
            <Text style={{ color: '#212134' }}>{}</Text>
          </Text>
        </Space>
        <CustomButton
          className={`${styles.summary_invoice_item_req_btn}`}
          onClick={() => {
            setOpenReqFundAllocModal({ [SBU_name]: true });
          }}
        >
          Allocate Fund
        </CustomButton>
      </div>
      <div className={`${styles.summary_invoice_item_liabilities_container}`}>
        <Space
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginRight: '140px',
            marginLeft: '10px'
          }}
        >
          {liabilities.map((item, index) => {
            let isIndexOne = index === 0;

            let isTotalLiability = item === 'requested_amount'; // change---'requested_amount';

            const submitPayload = {
              page: 1,
              per_page: 100000000000,
              SBU_name,
              status: 'ratified',
              non_H2H: item === 'liability_non_H2H' ? true : false
            };

            if (!isTotalLiability) {
              submitPayload['age'] =
                item === 'liability_non_H2H' || item === 'total_liability'
                  ? ' '
                  : item.replace('_days', '').replace('liability', 'days');
            }

            return (
              <Space key={index} direction="vertical">
                <div
                  className="font-12"
                  style={{
                    color: '#666687',
                    padding: '2px',
                    margin: '0px'
                  }}
                >
                  {liabilityToLabelTreasury[item]}
                </div>
                <div
                  className="sfprotext-bold "
                  style={{
                    color: '#212134',
                    paddingLeft: '1px',
                    marginTop: '4px',
                    lineHeight: '0px',
                    fontSize: '11.5px' // Amount font size
                  }}
                >
                  <Tooltip
                    title={'Total'}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    <span>T: </span>
                  </Tooltip>
                  <Tooltip
                    title={numIntStyleFormat(total_liability[item])}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    {total_liability[item] >= 0 ? (
                      total_liability[item] === undefined ? (
                        ''
                      ) : (
                        <span>₹{numFormating(total_liability[item])}</span>
                      )
                    ) : total_liability[item] === undefined ? (
                      ''
                    ) : (
                      <span>-₹{numFormating(total_liability[item])}</span>
                    )}
                  </Tooltip>
                </div>

                <div
                  className="sfprotext-bold "
                  style={{
                    color: '#212134',
                    paddingLeft: '3px',
                    marginTop: '13px',
                    lineHeight: '0px',
                    fontSize: '11.5px' // Amount font size
                  }}
                >
                  <Tooltip
                    title={'Initiated'}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    <span>I: </span>
                  </Tooltip>
                  <Tooltip
                    title={numIntStyleFormat(initiated_liability[item])}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    {initiated_liability[item] >= 0 ? (
                      initiated_liability[item] === undefined ? (
                        ''
                      ) : (
                        <span>₹{numFormating(initiated_liability[item])}</span>
                      )
                    ) : initiated_liability[item] === undefined ? (
                      ''
                    ) : (
                      <span>-₹{numFormating(initiated_liability[item])}</span>
                    )}
                  </Tooltip>
                </div>

                <div
                  className="sfprotext-bold "
                  style={{
                    color: '#212134',
                    paddingLeft: '2px',
                    marginTop: '13px',
                    lineHeight: '0px',
                    fontSize: '11.5px' // Amount font size
                  }}
                >
                  <Tooltip
                    title={'Proposed'}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    <span>P: </span>
                  </Tooltip>
                  <Tooltip
                    title={numIntStyleFormat(proposed_liability[item])}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    {proposed_liability[item] >= 0 ? (
                      proposed_liability[item] === undefined ? (
                        ''
                      ) : (
                        <span>₹{numFormating(proposed_liability[item])}</span>
                      )
                    ) : proposed_liability[item] === undefined ? (
                      ''
                    ) : (
                      <span>-₹{numFormating(proposed_liability[item])}</span>
                    )}
                  </Tooltip>
                </div>
                <div
                  className="sfprotext-bold "
                  style={{
                    color: '#212134',
                    paddingLeft: '2px',
                    marginTop: '13px',
                    lineHeight: '0px',
                    fontSize: '11.5px' // Amount font size
                  }}
                >
                  <Tooltip
                    title={'Ratified'}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    <span>R: </span>
                  </Tooltip>
                  <Tooltip
                    title={numIntStyleFormat(ratified_liability[item])}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    {ratified_liability[item] >= 0 ? (
                      ratified_liability[item] === undefined ? (
                        ''
                      ) : (
                        <span>₹{numFormating(ratified_liability[item])}</span>
                      )
                    ) : ratified_liability[item] === undefined ? (
                      ''
                    ) : (
                      <span>-₹{numFormating(ratified_liability[item])}</span>
                    )}
                  </Tooltip>
                </div>
                {/* 4th line is Allocated_liability */}
                <div
                  className="sfprotext-bold "
                  style={{
                    color: '#212134',
                    paddingLeft: '2px',
                    marginTop: '13px',
                    lineHeight: '0px',
                    fontSize: '11.5px' // Amount font size
                  }}
                >
                  <Tooltip
                    title={'Allocated'}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    <span>A: </span>
                  </Tooltip>

                  <Tooltip
                    title={numIntStyleFormat(allocated_liability[item])}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    {allocated_liability[item] >= 0 ? (
                      allocated_liability[item] === undefined ? (
                        ''
                      ) : (
                        <span>₹{numFormating(allocated_liability[item])}</span>
                      )
                    ) : allocated_liability[item] === undefined ? (
                      ''
                    ) : (
                      <span>-₹{numFormating(allocated_liability[item])}</span>
                    )}
                  </Tooltip>
                </div>
                {!isIndexOne ? (
                  <Button
                    size="small"
                    style={{
                      paddingLeft: '0px',
                      paddingRight: '0px',
                      margin: '0px',
                      lineHeight: '0px'
                    }}
                    type="link"
                    onClick={() => {
                      setClickSubmitInvoices({ [SBU_name]: true });
                      handleSubmitInvoices(submitPayload);
                    }}
                    disabled={
                      !ratified_liability[item] ||
                      ratified_liability[item] === allocated_liability[item]
                    }
                    className={`${styles.summary_invoice_item_submit_btn}`}
                  >
                    Submit invoices
                  </Button>
                ) : (
                  <span style={{ color: 'transparent' }}>.</span>
                )}
              </Space>
            );
          })}
        </Space>
      </div>
      {openReqFundAllocModal[SBU_name] ? (
        <CustomModal
          visible={openReqFundAllocModal[SBU_name]}
          onCancel={() => setOpenReqFundAllocModal({})}
          cancelButtonProps={{
            onClick: () => setOpenReqFundAllocModal({})
          }}
          okButtonProps={{
            loading: postRequestFundLoading,
            onClick: () =>
              handleRequestFund({
                requested_fund: reqFund[SBU_name],
                SBU_name
              })
          }}
        >
          <Title level={5} className="font-12">
            Enter the amount you want to allocate
          </Title>
          <InputNumber
            min={1}
            max={
              ratified_liability['total_liability'] <= 0
                ? Math.abs(ratified_liability['total_liability'])
                : ratified_liability['total_liability']
            }
            placeholder="Enter amount"
            style={{ width: '70%' }}
            value={reqFund[SBU_name] || null}
            onChange={(value) => setReqFund({ ...reqFund, [SBU_name]: value })}
          />
        </CustomModal>
      ) : null}
      {openReqAllocModal[SBU_name] ? (
        <TreasurySummaryAllocModal
          requestedAmount={liabilityAmount}
          fromSummary={true}
          visible={openReqAllocModal[SBU_name]}
          toggleModal={() => {
            setOpenReqAllocModal({});
            setClickSubmitInvoices({});
          }}
          data={
            clickSubmitInvoices[SBU_name]
              ? allInvoicesByAge
              : requestFundInvoices
          }
          grandTotal={ratified_liability['liability']}
          currentTotal={allocated_liability['liability']}
          summaryInvoicesActiveBankList={summaryInvoicesActiveBankList}
          postAllocationRequestLoading={postAllocationRequestLoading}
          handlePostAllocationRequest={handlePostAllocationRequest}
          user_role={user_role}
          handlePostBuRequestFundAllocation={handlePostBuRequestFundAllocation}
          handleFiltersOnChange={handleFiltersOnChange}
          activeDebitBankList={activeDebitBankList}
        />
      ) : null}
    </div>
  );
};

export default TreasurySummaryInvoiceItem;
