import { Avatar, Dropdown, Menu, Typography } from 'antd';
import {
  CaretDownOutlined,
  LogoutOutlined
  //ToolOutlined
} from '@ant-design/icons';
import styles from './index.module.less';
import { logout } from 'features/login/redux/slice';
import { useDispatch } from 'react-redux';
import { useMsal } from '@azure/msal-react';
//import { Link } from 'react-router-dom';
import LocalStorage from 'utilities/localStorage';

const { Text } = Typography;

const UserMenu = () => {
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const userDetails = LocalStorage.getItem('A2P_user');
  const userFullName = userDetails?.user_details?.full_name
    ? userDetails.user_details?.full_name.slice(0, 1)
    : 'U';

  const signOutClickHandler = () => {
    instance.logoutRedirect({
      account: LocalStorage.homeAccountId,
      postLogoutRedirectUri: '/',
      mainWindowRedirectUri: '/',
      onRedirectNavigate: (url) => {
        console.log('url', url);
        // Return false if you would like to stop navigation after local logout
        dispatch(logout());
        return true;
      }
    });
  };
  const menu = (
    <Menu>
      <Menu.Item
        onClick={() => signOutClickHandler()}
        key="1"
        icon={<LogoutOutlined />}
      >
        <Text>Logout</Text>
      </Menu.Item>

      {/* <Menu>
      <Menu.Item key="1" icon={<LogoutOutlined />}>
        <Text onClick={() => dispatch(logout())}>Logout</Text>
      </Menu.Item>
      <Menu.Item key="2" icon={<ToolOutlined />}>
        <Link to="/changepassword">Change Password</Link>
      </Menu.Item>
    </Menu> */}
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <div className={`df-jb-ac ${styles.user_menu_container}`}>
        <Avatar className={styles.user_menu_avatar}>{userFullName}</Avatar>
        <CaretDownOutlined />
      </div>
    </Dropdown>
  );
};

export default UserMenu;
