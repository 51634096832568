import React, { useMemo } from 'react';
import {
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Input,
  Menu,
  Row,
  Select,
  Space,
  Typography
} from 'antd';
import { SearchOutlined, CaretDownOutlined } from '@ant-design/icons';
import moment from 'moment';
import { debounce } from 'lodash';

const { Text } = Typography;
const { Option } = Select;
const DisplayFilter = ({ type, options, value, onChange, setSearchValue }) => {
  const debounceFetcher = useMemo(() => {
    const loadOptions = (searchValue) => {
      setSearchValue(searchValue);
    };
    return debounce(loadOptions, 400);
  }, []);

  switch (type) {
    case 'select':
      return (
        <Select
          size="small"
          showSearch
          style={{
            width: 360,
            fontSize: '11px',
            padding: '0px'
          }}
          placeholder="Search to Select"
          onChange={onChange}
          defaultValue={options[0]?.value}
          onSearch={debounceFetcher}
          //options={options}
          // filterOption={false}
          optionFilterProp="children"
          filterOption={(input, option) => {
            return (
              option?.props?.children
                ?.toString()
                ?.toLowerCase()
                ?.indexOf(input?.toLowerCase()) >= 0 ||
              option?.props?.label
                ?.toString()
                ?.toLowerCase()
                ?.indexOf(input?.toLowerCase()) >= 0
            );
          }}
        >
          {options.map((item, index) => {
            const { label, value } = item;
            return (
              <Option key={index} value={value} label={label}>
                <div style={{ fontSize: '11px' }}>
                  <span>{`${label} (${value})`}</span>
                </div>
              </Option>
            );
          })}
        </Select>
      );

    case 'date':
      return (
        <DatePicker
          disabledDate={(current) => {
            return (
              //moment().add(-1, 'days') >= current ||
              moment().add(0, 'month') <= current
            );
          }}
          size="small"
          style={{ fontSize: '11px' }}
          value={value !== 'All' ? moment(value) : null}
          format={'DD-MMM-YYYY'}
          onChange={(_, dateValue) => {
            onChange(dateValue || 'All');
          }}
        />
      );

    default:
      return (
        <Menu>
          {options.map((item, index) => {
            const { label, value } = item;

            return (
              <Menu.Item key={index} onClick={() => onChange(value)}>
                <span style={{ fontSize: '11px' }}>{label}</span>
              </Menu.Item>
            );
          })}
        </Menu>
      );
  }
};
const DisplayFilter1 = ({ type, options, value, onChange, setSearchValue }) => {
  const debounceFetcher = useMemo(() => {
    const loadOptions = (searchValue) => {
      setSearchValue(searchValue);
    };
    return debounce(loadOptions, 400);
  }, []);

  switch (type) {
    case 'select':
      return (
        <Select
          size="small"
          showSearch
          style={{
            width: 360,
            fontSize: '11px',
            padding: '0px'
          }}
          placeholder="Search to Select"
          onChange={onChange}
          defaultValue={options[0]?.value}
          onSearch={debounceFetcher}
          //options={options}
          // filterOption={false}
          optionFilterProp="children"
          filterOption={(input, option) => {
            return (
              option?.props?.children
                ?.toString()
                ?.toLowerCase()
                ?.indexOf(input?.toLowerCase()) >= 0 ||
              option?.props?.label
                ?.toString()
                ?.toLowerCase()
                ?.indexOf(input?.toLowerCase()) >= 0
            );
          }}
        >
          {options.map((item, index) => {
            const { label, value } = item;
            return (
              <Option key={index} value={value} label={label}>
                <div style={{ fontSize: '11px' }}>{label}</div>
              </Option>
            );
          })}
        </Select>
      );

    case 'date':
      return (
        <DatePicker
          disabledDate={(current) => {
            return (
              //moment().add(-1, 'days') >= current ||
              moment().add(0, 'month') <= current
            );
          }}
          size="small"
          style={{ fontSize: '11px' }}
          value={value !== 'All' ? moment(value) : null}
          format={'DD-MMM-YYYY'}
          onChange={(_, dateValue) => {
            onChange(dateValue || 'All');
          }}
        />
      );

    default:
      return (
        <Menu>
          {options.map((item, index) => {
            const { label, value } = item;

            return (
              <Menu.Item key={index} onClick={() => onChange(value)}>
                <span style={{ fontSize: '11px' }}>{label}</span>
              </Menu.Item>
            );
          })}
        </Menu>
      );
  }
};

const SearchBarDashboard = ({
  inputProps,
  filters,
  filterValues,
  handleFiltersOnChange,
  setVendorSearch,
  setProjectSearch,
  ...rest
}) => {
  return (
    <Row {...rest}>
      {inputProps ? (
        <Col span={5}>
          <Input
            size="small"
            style={{ padding: '4px 10px', fontSize: '11px', width: 200 }}
            {...inputProps}
            prefix={<SearchOutlined />}
            onChange={debounce((e) => {
              if (handleFiltersOnChange) {
                handleFiltersOnChange('search_invoice', e.target.value);
              }
            }, 400)}
            allowClear
          />
        </Col>
      ) : null}
      {filters?.length ? (
        <Col span={19}>
          <Space
            style={{
              fontFamily: 'SFProText Regular',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '12px',
              lineHeight: '18px',
              color: '#32324D'
            }}
          >
            <Text
              className="SFProText Regular"
              style={{
                fontStyle: 'normal',
                fontSize: '12px',
                lineHeight: '18px',
                fontWeight: 500,
                color: '#000000',
                marginLeft: '-10px'
              }}
            >
              Filters:
            </Text>
            {filters.map((item, index) => {
              if (!item) return null;
              const { label, slug, options, type } = item;
              const selectedFilterValue = filterValues[slug] || 'All';
              const selectedFilterValueLabel = options?.length
                ? options?.find((o) => o.value === selectedFilterValue)
                    ?.label || 'All'
                : selectedFilterValue;
              const showDivider = filters.length - 1 !== index;
              let setSearchValue =
                slug === 'tds_project_code'
                  ? setProjectSearch
                  : setVendorSearch;

              return (
                <Space key={index}>
                  <Text>{`${label}: `}</Text>
                  <Dropdown
                    menu={{
                      selectable: true
                    }}
                    overlay={
                      slug === 'tds_project_code' || slug === 'vendor' ? (
                        <DisplayFilter
                          type={type}
                          options={options}
                          value={selectedFilterValue}
                          onChange={(value) => {
                            //setSearchValue('');
                            handleFiltersOnChange(slug, value);
                          }}
                          setSearchValue={setSearchValue}
                          setVendorSearch={setVendorSearch}
                          setProjectSearch={setProjectSearch}
                        />
                      ) : (
                        <DisplayFilter1
                          type={type}
                          options={options}
                          value={selectedFilterValue}
                          onChange={(value) => {
                            //setSearchValue('');
                            handleFiltersOnChange(slug, value);
                          }}
                          setSearchValue={setSearchValue}
                          setVendorSearch={setVendorSearch}
                          setProjectSearch={setProjectSearch}
                        />
                      )
                    }
                    trigger={['hover']}
                    placement="bottomLeft"
                  >
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <span style={{ fontSize: '11px', padding: 0 }}>
                        {selectedFilterValueLabel} <CaretDownOutlined />
                      </span>
                    </a>
                  </Dropdown>
                  {showDivider ? (
                    <Divider
                      type="vertical"
                      style={{ marginLeft: '4px', marginRight: '4px' }}
                    />
                  ) : null}
                </Space>
              );
            })}
          </Space>
        </Col>
      ) : null}
    </Row>
  );
};

export default SearchBarDashboard;
