import React, { useEffect, useState } from 'react';
import { Space, Tooltip, Typography } from 'antd'; //InputNumber, Select,
import styles from './index.module.less';
import CustomModal from 'common/CustomModal';
import CustomTableModal from 'common/Content/CustomTableModal';
import moment from 'moment';
import SearchBarModal from '@/common/Content/searchBarModal';
import {
  userRole,
  numFormating,
  numIntStyleFormat
} from '../../../../utilities/helpers';

const { Text } = Typography;
//const { Option } = Select;

const ReqRejectModal = ({
  visible,
  toggleModal,
  data,
  glView,
  //activeBankList, //dashbord
  //handleGetActiveBankList, ////dashbord
  //postSendBackAllocRequestInvoiceWiseLoading,
  handlePostRejectRequest,
  fromSummary, //summary
  selectedBankBranchName, //summary
  user_role,
  //requestedAmount,
  openCheckbox, //summary
  handleFiltersOnChangeSerchInModal //summary
  // activeDebitBankList
}) => {
  if (!data) return null;
  console.log('data', data);
  //const [reqAmount, setReqAmount] = useState(0);
  const [totalInvoicesAmount, setTotalInvoicesAmount] = useState(0);
  //const [editReqAmount] = useState(false);
  //const [selectedBank, setSelectedBank] = useState('');
  //const [selectedBankLength] = useState();
  const [reqAllocInvoiceKeys, setReqAllocInvoiceKeys] = useState([]);
  const [reqAllocInvoices, setReqAllocInvoices] = useState([]);
  //const [selectedDebitBank, setSelectedDebitBank] = useState('');

  useEffect(() => {
    let invoiceKeys = data.map((o) => o.key);
    if (openCheckbox) {
      setReqAllocInvoiceKeys(invoiceKeys);
    }
  }, [openCheckbox]);

  useEffect(() => {
    const totalAmount = data.reduce(
      (a, b) => a + parseFloat(b?.net_payable),
      0
    );
    // setReqAmount(totalAmount);
    setTotalInvoicesAmount(totalAmount);

    /* if (handleGetActiveBankList) {
      const invoiceData = data[0];
      const { vendor_code, vendor_site_code } = invoiceData;
      handleGetActiveBankList({ vendor_code, vendor_site_code });
    } */

    if (fromSummary) {
      setReqAllocInvoices(data);
    }
    //setSelectedDebitBank(activeDebitBankList[0]?.bank_branch_name);
    // setSelectedDebitBank(activeDebitBankList[0]?.bank_account_number);
  }, []);

  // console.log('setSelectedDebitBank', setSelectedDebitBank);
  // console.log('activeDebitBankList', activeDebitBankList);
  // console.log('selectedDebitBank', selectedDebitBank);

  //const invoiceData = data[0];
  //const { vendor_name, vendor_code } = invoiceData;
  //console.log(vendor_name, vendor_code);
  const columns = [
    {
      title: 'PROJECT CODE',
      dataIndex: 'tds_project_code',
      key: 'tds_project_code',
      sorter: (a, b) => a.tds_project_code - b.tds_project_code,
      ellipsis: {
        showTitle: false
      },
      width: '110px',
      render: (tds_project_code) => {
        return (
          <>
            <Tooltip
              title={tds_project_code}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {tds_project_code}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'VENDOR',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
      sorter: (a, b) => a.vendor_name.localeCompare(b.vendor_name),
      ellipsis: {
        showTitle: false
      },
      width: '260px',
      render: (vendor_name) => {
        return (
          <>
            <Tooltip
              title={vendor_name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {vendor_name}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'INVOICE NUMBER',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      sorter: (a, b) => a.invoice_number.localeCompare(b.invoice_number),
      ellipsis: {
        showTitle: false
      },
      width: '240px',
      render: (invoice_number) => {
        return (
          <>
            <Tooltip
              title={invoice_number}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <a>{invoice_number}</a>
            </Tooltip>
          </>
        );
      }
    },
    glView
      ? {
          title: 'GL DATE',
          dataIndex: 'GL_date',
          key: 'GL_date',
          sorter: (a, b) =>
            moment(a['GL_date']).unix() - moment(b['GL_date']).unix()
        }
      : fromSummary
      ? ({
          title: 'GL DATE',
          dataIndex: 'GL_date',
          key: 'GL_date',
          sorter: (a, b) =>
            moment(a['GL_date']).unix() - moment(b['GL_date']).unix()
        },
        {
          title: 'INVOICE DATE',
          dataIndex: 'invoice_date',
          key: 'invoice_date',
          sorter: (a, b) =>
            moment(a['invoice_date']).unix() - moment(b['invoice_date']).unix()
        })
      : {
          title: 'INVOICE DATE',
          dataIndex: 'invoice_date',
          key: 'invoice_date',
          sorter: (a, b) =>
            moment(a['invoice_date']).unix() - moment(b['invoice_date']).unix()
        },
    {
      title: 'NET PAYABLE',
      dataIndex: 'net_payable',
      key: 'net_payable',
      sorter: (a, b) => Number(a.net_payable) - Number(b.net_payable),
      ellipsis: {
        showTitle: false
      },
      render: (net_payable) => {
        return (
          <>
            <Tooltip
              title={numIntStyleFormat(net_payable)}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span style={{ fontWeight: 'bold' }}>
                {net_payable >= 0 ? (
                  <span>
                    ₹{numFormating(net_payable)}
                    {/* {`₹${net_payable?.toLocaleString(undefined, {
                      maximumFractionDigits: 2
                    })}`} */}{' '}
                  </span>
                ) : (
                  <span>
                    -₹{numFormating(net_payable)}
                    {/* {Math.abs(net_payable)?.toLocaleString(undefined, {
                      maximumFractionDigits: 2
                    })} */}
                  </span>
                )}
              </span>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'AGEING',
      dataIndex: 'ageing',
      key: 'ageing',
      width: '150',
      sorter: (a, b) =>
        Number(a.ageing.replace(` days`, '')) -
        Number(b.ageing.replace(` days`, ''))
    }
  ];

  const rowSelection = {
    selectedRowKeys: reqAllocInvoiceKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setReqAllocInvoiceKeys(selectedRowKeys);
      setReqAllocInvoices(selectedRows);

      const reCalculatedTotalAmount = selectedRows.reduce(
        (a, b) => a + parseFloat(b?.net_payable),
        0
      );
      //(reCalculatedTotalAmount);
      setTotalInvoicesAmount(reCalculatedTotalAmount);
    }
  };

  const onOk = () => {
    if (user_role === 'SBU Commercial') {
      let payload = {
        rejected_invoices: data?.map((item) => item?.id)
      };
      handlePostRejectRequest(userRole(user_role), payload);
    } else if (user_role === 'SBU Head') {
      let payload = {
        rejected_invoices: data?.map((item) => item?.id)
      };
      handlePostRejectRequest(userRole(user_role), payload);
    } else if (user_role === 'Treasury') {
      let payload = {
        rejected_invoices: data?.map((item) => item?.id)
      };
      handlePostRejectRequest(userRole(user_role), payload);
    }
    // setOpenCheckbox(false);
  };

  return (
    <CustomModal
      title="Request Reject"
      visible={visible}
      width={'90%'}
      bodyStyle={{ height: '80vh', overflow: 'scroll', overflowX: 'hidden' }}
      style={{ top: 50 }}
      onCancel={toggleModal}
      cancelButtonProps={{ onClick: toggleModal }}
      okText={`Submit for rejection of invoices of worth ₹${numFormating(
        totalInvoicesAmount
      )}`}
      okButtonProps={{
        /* disabled:
          user_role === 'Project Engineer'
            ? data[0]?.non_H2H
              ? null || totalInvoicesAmount >= 0
              : (!selectedBankLength > 0 && !selectedBank) ||
                !reqAmount > 0 ||
                totalInvoicesAmount >= 0
            : user_role === 'Treasury'
            ? !selectedDebitBank?.length > 0 || totalInvoicesAmount >= 0
            : null || totalInvoicesAmount >= 0,
        loading: postSendBackAllocRequestInvoiceWiseLoading, */
        onClick: onOk
      }}
    >
      {/* <div>
        <Space size={'middle'} align="center">
          <Text
            className={`${styles.req_alloc_modal_vendor_details} sfprotext-medium font-13`}
          >{`${vendor_name} (${vendor_code})`}</Text>
          <Space
            size={'large'}
            className={`${styles.req_alloc_modal_requested_amount_details}`}
          >
            <Text className="font-14">{requestedAmount}:</Text>
            {!editReqAmount ? (
              <Text className="sfprotext-bold font-14">
                <Tooltip
                  title={numIntStyleFormat(reqAmount)}
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {reqAmount >= 0 ? (
                    <span>₹{numFormating(reqAmount)} </span>
                  ) : (
                    <span>-₹{numFormating(reqAmount)}</span>
                  )}
                </Tooltip>
              </Text>
            ) : (
              <InputNumber
                min={1}
                max={totalInvoicesAmount}
                value={reqAmount}
                onChange={setReqAmount}
              />
            )}
          </Space>
        </Space>
      </div> */}
      <div>
        <Space size={'middle'} style={{ textAlign: 'center' }}>
          <Text className="sfprotext-bold font-16">
            {'Do you want to reject these incoices?'}
          </Text>
        </Space>
      </div>
      <div>
        <Space size={'middle'} align="center" className="mt-3">
          <Text className="sfprotext-bold font-14">
            {'Selected invoices for the rejected'}
          </Text>
          <Space
            size={'middle'}
            className={`${styles.req_alloc_modal_invoices_total_amount_details}`}
          >
            <Text className="font-14">{`${
              (fromSummary ? reqAllocInvoices : data).length
            } invoices of worth`}</Text>
            <Text className="sfprotext-bold font-14">
              <Tooltip
                title={numIntStyleFormat(totalInvoicesAmount)}
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {totalInvoicesAmount >= 0 ? (
                  <span>₹{numFormating(totalInvoicesAmount)} </span>
                ) : (
                  <span>-₹{numFormating(totalInvoicesAmount)}</span>
                )}
              </Tooltip>
            </Text>
          </Space>
        </Space>
      </div>
      {/*  {user_role === 'Project Engineer' ? (
        data[0]?.non_H2H ? null : (
          <div className="mt-3">
            <Text className="sfprotext-medium font-13 mr-3">Select Bank:</Text>
            <Select
              // size="middle"
              className={`${styles.ant_select_size}`}
              showSearch
              value={selectedBank}
              style={{ minWidth: '50%', fontSize: '11px', height: '35px' }}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) => option.children.includes(input)}
              onChange={setSelectedBank}
              defaultValue={`Name: ${activeBankList[0]?.bank_name}, IFSC: ${activeBankList[0]?.bank_branch_name}, A/c no: ${activeBankList[0]?.bank_account_number}`}
            >
              {activeBankList.map((bank, index) => {
                const { bank_name, bank_branch_name, bank_account_number } =
                  bank;

                return (
                  <Option key={index} value={bank.bank_account_number}>
                  
                    <div
                      style={{ fontSize: '11px' }}
                    >{`Name: ${bank_name}, IFSC: ${bank_branch_name}, A/c no: ${bank_account_number}`}</div>
                  </Option>
                );
              })}
            </Select>
          </div>
        )
      ) : null} */}
      {/*  <div className="mt-3">
        {user_role === 'Treasury' && (
          <div>
            <Text className="sfprotext-medium font-14 mr-3">
              Select Debit Bank:
            </Text>
            <Select
              size="middle"
              showSearch
              style={{ minWidth: '50%', fontSize: '11px', height: 30 }}
              placeholder="Search to Select"
              value={selectedDebitBank}
              optionFilterProp="children"
              filterOption={(input, option) => option.children.includes(input)}
              onChange={setSelectedDebitBank}
              defaultValue={`Name: ${activeDebitBankList[0]?.bank_name}, IFSC: ${activeDebitBankList[0]?.bank_branch_name}, A/c no: ${activeDebitBankList[0]?.bank_account_number}`}
            >
              {activeDebitBankList.map((bank, index) => {
                const { bank_account_number } = bank;
                
                return (
                  <Option key={index} value={bank.bank_account_number}>
                    {' '}
                    <div style={{ fontSize: '11px' }}>
                      {` A/c no: ${bank_account_number}`}
                    </div>
                  </Option>
                );
              })}
            </Select>
          </div>
        )}
      </div> */}
      <div className="mt-3">
        {!openCheckbox && selectedBankBranchName ? (
          <SearchBarModal
            justify="start"
            align="middle"
            className="mb-4"
            gutter={32}
            inputProps={{
              placeholder: 'Search using invoice number/project'
            }}
            //handleFiltersOnChange={handleFiltersOnChange}
            handleFiltersOnChangeSerchInModal={
              handleFiltersOnChangeSerchInModal
            }
            user_role={user_role}
          />
        ) : null}
      </div>
      <div className="mt-3">
        <CustomTableModal
          style={{
            fontFamily: 'SFProText Regular',
            padding: '40px 4px !important',
            lineHeight: '18px',
            color: '#32324D'
          }}
          data={data}
          columns={columns}
          pagination={false}
          rowSelection={fromSummary ? rowSelection : null}
        />
      </div>
    </CustomModal>
  );
};

export default ReqRejectModal;
