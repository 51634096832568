import React from 'react';
import { Table } from 'antd';
import styles from './index.module.less';

const TableFinanceCoE = ({ data, ...rest }) => {
  return (
    <div
      className={`${styles.custom_table_container}${
        !data?.length ? ` ${styles.no_data}` : ''
      }`}
    >
      <Table dataSource={data} {...rest} />
    </div>
  );
};

export default TableFinanceCoE;
