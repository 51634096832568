import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { userProfile } from './api';

const initialState = {
  getUserProfileLoading: false,
  userPro: [],
  projectAssigned: [],
  BU_assigned: []
};

const actions = {
  GET_USER_PROFILE: 'user/GET_USER_PROFILE'
};

export const getUserProfile = createAsyncThunk(
  actions.GET_USER_PROFILE,
  async (payload) => {
    const response = await userProfile(payload);

    return response;
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserProfile.pending, (state) => {
        state.getUserProfileLoading = true;
      })

      .addCase(getUserProfile.fulfilled, (state, action) => {
        const { success, profile_data, message: msg } = action.payload;

        state.getUserProfileLoading = false;
        if (success) {
          state.userPro = profile_data;
          const projectAssigned = profile_data.project_assigned?.map((item) => {
            return {
              label: item,
              value: item
            };
          });
          //projectAssigned.unshift({label:'All',value:'All'});
          state.projectAssigned = projectAssigned;
          const BU_assigned = profile_data.BU_assigned?.map((item) => {
            return {
              label: item,
              value: item
            };
          });
          state.BU_assigned = BU_assigned;
        } else {
          message.error(msg);
        }
      })
      .addCase(getUserProfile.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getUserProfileLoading = false;

        message.error(msg);
      });
  }
});
export default userSlice.reducer;
