import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Layout from 'common/Layout/index';
import InvoicesSummaryPage from '../InvoicesSummaryPage';
import {
  getInvoices,
  getInvoicesByAge,
  postAllocationRequest,
  postRequestFund,
  PostBuRequestFundAllocation,
  getProjectList,
  getBUList,
  getVendorList,
  getActiveDebitBankList
} from '../../redux/slice';
import { getSBUList } from '../treasury/redux/slice';
import {
  userRole,
  getLoggedInUser,
  invoiceFilters,
  filterToPayload,
  invoiceType
} from '../../../../utilities/helpers';

const InvoicesSummary = () => {
  const user_role1 = getLoggedInUser();
  const user_role = user_role1?.user_details?.role;

  const dispatch = useDispatch();
  const {
    summaryInvoicesLoading,
    summaryInvoices,
    summaryInvoicesPagination,
    postRequestFundLoading,
    requestFundInvoices,
    postAllocationRequestLoading,
    summaryInvoicesActiveBankList,
    allInvoicesByAge,
    invoicesByAgeLoading,
    requestFundCheckboxLoading,
    projectList,
    BUList,
    vendorList,
    activeDebitBankList,
    month_to_date_my_initiated,
    month_to_date_my_proposed,
    month_to_date_my_ratified,
    month_to_date_my_allocated,
    month_to_date_my_disbursed
  } = useSelector((state) => state.invoices);

  const { SBUList } = useSelector((state) => state.treasury);

  const [page, setPage] = useState(1);
  const [reqFund, setReqFund] = useState({});
  const [openReqFundAllocModal, setOpenReqFundAllocModal] = useState({});
  const [openReqAllocModal, setOpenReqAllocModal] = useState({});
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [searchVendor, setSearchVendor] = useState('');
  const [selectedBankBranchName, setSelectedBankBranchName] = useState({});
  const [clickSubmitInvoices, setClickSubmitInvoices] = useState({});
  const [openCheckbox, setOpenCheckbox] = useState(requestFundCheckboxLoading);
  const liabilityAmount = 'Liability Amount';
  const [filterValues, setFilterValues] = useState(invoiceFilters);
  const [pageSize, setPageSize] = useState(10);
  const [modalSearchField, setModalSearchField] = useState({});
  const [triggerFilterModal, setTriggerFilterModal] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [vendorSearch, setVendorSearch] = useState('');
  const [projectSearch, setProjectSearch] = useState('');
  const [viewInitAmountModal, setViewInitAmountModal] = useState(false);

  if (user_role === 'Treasury' || user_role === 'Finance CoE') {
    useEffect(() => {
      dispatch(getActiveDebitBankList());
    }, []);
  }

  /* ----------------for'Project Engineer')-------------------------------*/
  useEffect(() => {
    if (user_role === 'Project Engineer') {
      let payload = {};
      dispatch(getProjectList({ role: userRole(user_role), payload }));
    }
  }, []);

  useEffect(() => {
    if (user_role === 'Project Engineer') {
      const tds_project_code = projectList[0]?.value;
      setTriggerFilter(true);
      setFilterValues({ tds_project_code: tds_project_code });
    }
  }, [projectList?.length]);

  useEffect(() => {
    if (user_role === 'Project Engineer') {
      handleGetVendorListPE();
    }
  }, [filterValues?.tds_project_code, vendorSearch]); //, vendorSearch

  const handleGetVendorListPE = () => {
    if (user_role === 'Project Engineer') {
      let payload = {
        tds_project_code: filterValues?.tds_project_code
      };
      if (vendorSearch) payload['search_vendor'] = vendorSearch;
      filterValues?.tds_project_code ? dispatch(getVendorList(payload)) : '';
    }
  };

  useEffect(() => {
    if (user_role === 'Project Engineer') {
      console.log('call_1');
      handleGetInvoices();
    }
  }, [page, pageSize]);

  /*--------- for 'Finance CoE' ---------- */
  useEffect(() => {
    if (user_role === 'Finance CoE') {
      const invoice_type = invoiceType[0]?.value;
      setTriggerFilter(true);
      setFilterValues({ invoice_type: invoice_type });
    }
  }, [invoiceType?.length]);

  useEffect(() => {
    if (user_role === 'Finance CoE') {
      let payload = {};
      dispatch(getBUList({ role: userRole(user_role), payload }));
    }
  }, [filterValues?.invoice_type]);

  useEffect(() => {
    if (user_role === 'Finance CoE') {
      handleGetVendorListFC();
    }
  }, [filterValues?.tds_project_code, vendorSearch]);

  const handleGetVendorListFC = () => {
    if (filterValues?.tds_project_code !== undefined) {
      let payload = {
        tds_project_code: filterValues?.tds_project_code,
        search_vendor: vendorSearch
      };
      dispatch(getVendorList(payload));
    }
  };

  useEffect(() => {
    if (user_role === 'Finance CoE') {
      handleGetProjectListFC();
    }
  }, [filterValues?.BU_name, projectSearch]);

  const handleGetProjectListFC = () => {
    let payload = {
      BU_name: filterValues?.BU_name
    };
    if (projectSearch) payload['search_project'] = projectSearch;
    filterValues?.BU_name === undefined || filterValues?.BU_name === 'All'
      ? ''
      : dispatch(getProjectList({ role: userRole(user_role), payload }));
  };

  //-----------Start SBU Commercial and  SBU Head----------
  useEffect(() => {
    if (user_role === 'SBU Commercial' || user_role === 'SBU Head') {
      handleGetBUList();
    }
  }, []);

  const handleGetBUList = () => {
    let payload = {};
    dispatch(getBUList({ role: userRole(user_role), payload }));
  };

  useEffect(() => {
    if (user_role === 'SBU Commercial' || user_role === 'SBU Head') {
      console.log('BUList---->', BUList);
      const BU_name = BUList[0]?.value;
      setTriggerFilter(true);
      setFilterValues({ BU_name: BU_name });
    }
  }, [BUList.length]);

  useEffect(() => {
    if (user_role === 'SBU Commercial' || user_role === 'SBU Head') {
      handleGetProjectList();
    }
  }, [filterValues?.BU_name, projectSearch]);

  const handleGetProjectList = () => {
    if (
      filterValues?.BU_name !== 'All' &&
      filterValues?.BU_name !== undefined
    ) {
      let payload = {
        BU_name: filterValues?.BU_name
      };
      // if (projectSearch) payload['search_project'] = projectSearch;
      if (projectSearch) payload['search_project'] = projectSearch;
      dispatch(getProjectList({ role: userRole(user_role), payload }));
    }
  };

  useEffect(() => {
    if (user_role === 'SBU Commercial' || user_role === 'SBU Head') {
      console.log('call_5');
      handleGetInvoices();
    }
  }, [page, pageSize]);

  //-----------Start 'Treasury'-------------------------------
  useEffect(() => {
    if (user_role === 'Treasury') {
      console.log('call_6');
      handleGetInvoices();
    }
  }, [page, pageSize]);
  useEffect(() => {
    if (user_role === 'Treasury') {
      handleGetSBUList();
    }
  }, []);

  const handleGetSBUList = () => {
    let payload = {};
    dispatch(getSBUList({ role: userRole(user_role), payload }));
  };

  useEffect(() => {
    if (
      user_role === 'Project Engineer' ||
      user_role === 'SBU Commercial' ||
      user_role === 'SBU Head' ||
      user_role === 'Treasury'
    ) {
      if (triggerFilter) {
        if (page === 1) {
          handleGetInvoices();
        } else {
          setPage(1);
        }
      }
    }
  }, [searchVendor, filterValues]); // projectList, BUList,, invoiceType

  useEffect(() => {
    if (user_role === 'Finance CoE') {
      if (triggerFilter) {
        if (page === 1) {
          handleGetInvoices();
        } else {
          setPage(1);
        }
      }
    }
  }, [searchVendor, filterValues, page, pageSize]); // projectList, BUList,, invoiceType

  useEffect(() => {
    if (triggerFilterModal) {
      handleInvoiceSearchInModal();

      setPage(1);
    }
  }, [filterValues, triggerFilterModal]);

  const handleFiltersOnChangeSerchInModal = (slug, value) => {
    setTriggerFilterModal(true);
    setFilterValues({
      ...filterValues,
      [slug]: value
    });
  };

  const handleFiltersOnChange = (slug, value) => {
    setTriggerFilter(true);
    //setSearchVendor(value); //-------
    setFilterValues({
      ...filterValues,
      [slug]: value
    });
    setOpenDropdown(!openDropdown);
  };

  const handleGetInvoices = () => {
    if (user_role === 'Project Engineer') {
      let payload = {
        page,
        per_page: pageSize,
        tds_project_code: filterValues?.tds_project_code
      };
      Object.keys(filterValues).forEach((item) => {
        if (filterValues[item] && filterValues[item] !== 'All') {
          payload[filterToPayload[item]] = filterValues[item];
        }
      });
      if (
        filterValues?.tds_project_code === 'All' ||
        filterValues?.tds_project_code === undefined
      ) {
        console.log('api pending state handle');
      } else {
        filterValues?.tds_project_code
          ? dispatch(
              getInvoices({
                role: userRole(user_role),
                type: 'summary',
                payload
              })
            )
          : '';
      }
    }
    if (user_role === 'SBU Commercial' || user_role === 'SBU Head') {
      if (
        filterValues?.BU_name === 'All' ||
        filterValues?.BU_name === undefined
      ) {
        console.log('');
      } else {
        let payload = {
          page,
          per_page: pageSize,
          BU_name: filterValues?.BU_name
        };
        Object.keys(filterValues).forEach((item) => {
          if (filterValues[item] && filterValues[item] !== 'All') {
            payload[filterToPayload[item]] = filterValues[item];
          }
        });
        dispatch(
          getInvoices({ role: userRole(user_role), type: 'summary', payload })
        );
      }
    }
    if (user_role === 'Treasury') {
      let payload = {
        page,
        per_page: pageSize,
        SBU_name: filterValues?.SBU_name
      };
      Object.keys(filterValues).forEach((item) => {
        if (filterValues[item] && filterValues[item] !== 'All') {
          payload[filterToPayload[item]] = filterValues[item];
        }
      });
      console.log('Payload_Treasury', payload);
      dispatch(
        getInvoices({ role: userRole(user_role), type: 'summary', payload })
      );
    }
    if (user_role === 'Finance CoE') {
      if (
        filterValues?.invoice_type === 'All' ||
        filterValues?.invoice_type === undefined
      ) {
        console.log('');
      } else {
        let payload = {
          page,
          per_page: pageSize,
          invoice_type: filterValues?.invoice_type
        };
        Object.keys(filterValues).forEach((item) => {
          if (filterValues[item] && filterValues[item] !== 'All') {
            payload[filterToPayload[item]] = filterValues[item];
          }
        });
        dispatch(
          getInvoices({ role: userRole(user_role), type: 'summary', payload })
        );
      }
    }
  };

  const handleInvoiceSearchInModal = () => {
    let payload = {
      page: modalSearchField.page,
      per_page: 100000000000,
      tds_project_code: modalSearchField.tds_project_code,
      status: modalSearchField.status,
      age: modalSearchField.age
    };
    Object.keys(filterValues).forEach((item) => {
      if (filterValues[item] && filterValues[item] !== 'All') {
        payload[filterToPayload[item]] = filterValues[item];
      }
    });
    dispatch(
      getInvoicesByAge({ role: userRole(user_role), type: 'All', payload })
    );
  };
  const handleSearchVendor = (value) => {
    setTriggerFilter(true);
    setSearchVendor(value);
  };

  const handleRequestFund = (payload) => {
    dispatch(postRequestFund({ role: userRole(user_role), payload })).then(
      ({ meta, payload }) => {
        if (payload?.success) {
          setOpenReqFundAllocModal({});
          if (user_role === 'Project Engineer') {
            const {
              arg: {
                payload: { vendor_code }
              }
            } = meta;
            setOpenReqAllocModal({ [vendor_code]: true });
            setOpenCheckbox(false); //true //change for unchecked checkbox when we click 'propose fund' button
          }
          // For BU Propose Fund
          else if (user_role === 'SBU Commercial') {
            const {
              arg: {
                payload: { tds_project_code }
              }
            } = meta;
            setOpenReqAllocModal({ [tds_project_code]: true });
            setOpenCheckbox(false); //true //change for unchecked checkbox when we click 'propose fund' button
          }
          // For BU Propose Fund
          else if (user_role === 'SBU Head') {
            const {
              arg: {
                payload: { tds_project_code }
              }
            } = meta;
            setOpenReqAllocModal({ [tds_project_code]: true });
            setOpenCheckbox(true);
          }
          // For Treasury Request Fund
          else if (user_role === 'Treasury') {
            const {
              arg: {
                payload: { SBU_name }
              }
            } = meta;
            setOpenReqAllocModal({ [SBU_name]: true });
            setOpenCheckbox(true);
          }
          if (user_role === 'Finance CoE') {
            const {
              arg: {
                payload: { vendor_code }
              }
            } = meta;
            setOpenReqAllocModal({ [vendor_code]: true });
            setOpenCheckbox(true);
          }
        }
      }
    );
    setReqFund('');
  };

  const handleSubmitInvoices = (payload) => {
    // console.log('payload_handleSubmitInvoices>>>>>>:', payload);
    setModalSearchField(payload);
    dispatch(
      getInvoicesByAge({ role: userRole(user_role), type: 'all', payload })
    ).then(({ meta, payload }) => {
      if (payload?.success) {
        if (user_role === 'Project Engineer') {
          const {
            arg: {
              payload: { vendor_code }
            }
          } = meta;

          setOpenReqAllocModal({ [vendor_code]: true });
        }
        if (user_role === 'SBU Commercial' || user_role === 'SBU Head') {
          const {
            arg: {
              payload: { tds_project_code }
            }
          } = meta;

          setOpenReqAllocModal({ [tds_project_code]: true });
        }
        if (user_role === 'Treasury') {
          const {
            arg: {
              payload: { SBU_name }
            }
          } = meta;

          setOpenReqAllocModal({ [SBU_name]: true });
        }
        if (user_role === 'Finance CoE') {
          const {
            arg: {
              payload: { vendor_code }
            }
          } = meta;

          setOpenReqAllocModal({ [vendor_code]: true });
        }
      }
    });
  };

  const handlePostAllocationRequest = (role, payload) => {
    dispatch(postAllocationRequest({ role, payload })).then(() => {
      setOpenReqAllocModal({});
      setClickSubmitInvoices({});
      handleGetInvoices();
    });
  };

  const handlePostBuRequestFundAllocation = (role, payload) => {
    dispatch(PostBuRequestFundAllocation({ role, payload })).then(() => {
      setOpenReqAllocModal({});
      setClickSubmitInvoices({});
      handleGetInvoices();
    });
  };

  return (
    <Layout>
      <InvoicesSummaryPage
        loading={summaryInvoicesLoading}
        invoices={summaryInvoices}
        pagination={summaryInvoicesPagination}
        page={page}
        setPage={setPage}
        setPageSize={setPageSize}
        pageSize={pageSize}
        reqFund={reqFund}
        setReqFund={setReqFund}
        openReqFundAllocModal={openReqFundAllocModal}
        setOpenReqFundAllocModal={setOpenReqFundAllocModal}
        openReqAllocModal={openReqAllocModal}
        setOpenReqAllocModal={setOpenReqAllocModal}
        handleSearchVendor={handleSearchVendor}
        handleRequestFund={handleRequestFund}
        selectedBankBranchName={selectedBankBranchName}
        setSelectedBankBranchName={setSelectedBankBranchName}
        postRequestFundLoading={postRequestFundLoading}
        requestFundInvoices={requestFundInvoices}
        postAllocationRequestLoading={postAllocationRequestLoading}
        handlePostAllocationRequest={handlePostAllocationRequest}
        summaryInvoicesActiveBankList={summaryInvoicesActiveBankList}
        clickSubmitInvoices={clickSubmitInvoices}
        setClickSubmitInvoices={setClickSubmitInvoices}
        allInvoicesByAge={allInvoicesByAge}
        handleSubmitInvoices={handleSubmitInvoices}
        user_role={user_role}
        handlePostBuRequestFundAllocation={handlePostBuRequestFundAllocation}
        liabilityAmount={liabilityAmount}
        openCheckbox={openCheckbox}
        setOpenCheckbox={setOpenCheckbox}
        projectList={projectList}
        filterValues={filterValues}
        handleFiltersOnChange={handleFiltersOnChange}
        BUList={BUList}
        SBUList={SBUList}
        vendorList={vendorList}
        handleFiltersOnChangeSerchInModal={handleFiltersOnChangeSerchInModal}
        invoicesByAgeLoading={invoicesByAgeLoading}
        activeDebitBankList={activeDebitBankList}
        invoiceType={invoiceType}
        setVendorSearch={setVendorSearch}
        setProjectSearch={setProjectSearch}
        setViewInitAmountModal={setViewInitAmountModal}
        viewInitAmountModal={viewInitAmountModal}
        month_to_date_my_initiated={month_to_date_my_initiated}
        month_to_date_my_proposed={month_to_date_my_proposed}
        month_to_date_my_ratified={month_to_date_my_ratified}
        month_to_date_my_allocated={month_to_date_my_allocated}
        month_to_date_my_disbursed={month_to_date_my_disbursed}
      />
    </Layout>
  );
};

export default InvoicesSummary;
