import React, { useEffect, useState } from 'react';
import { InputNumber, Space, Tooltip, Typography, Input, message } from 'antd'; //
import styles from './index.module.less';
import CustomModal from 'common/CustomModal';
import CustomTableModal from 'common/Content/CustomTableModal';
import { SearchOutlined } from '@ant-design/icons';
import { numFormating, numIntStyleFormat } from 'utilities/helpers';

const { Text } = Typography;

const PeSummaryAllocModal = ({
  visible,
  toggleModal,
  data,
  postAllocationRequestLoading,
  handlePostAllocationRequest,
  fromSummary,
  summaryInvoicesActiveBankList, //summary
  selectedBankBranchName, //summary
  user_role,
  requestedAmount,
  openCheckbox,
  setOpenCheckbox,
  //________________
  grandTotal,
  currentTotal
  //____________________
}) => {
  if (!data) return null;
  // console.log('grandTotal%%%%%%%%', grandTotal); //Invoice details
  // console.log('requestedAmount%%%%%%%%', requestedAmount);//Liability Amount
  const [reqAmount, setReqAmount] = useState(0);
  const [totalInvoicesAmount, setTotalInvoicesAmount] = useState(0);
  const [editReqAmount] = useState(false);
  const [selectedBank, setSelectedBank] = useState('');
  const [reqAllocInvoiceKeys, setReqAllocInvoiceKeys] = useState([]);
  const [reqAllocInvoices, setReqAllocInvoices] = useState([]);
  const [search, setSearch] = useState();
  const [selectedRowLength, setSelectedRowLength] = useState([]);
  // const [greaterFloatValue, setGreaterFloatValue] = useState();

  console.log(selectedRowLength);

  const searchHandler = (e) => {
    setSearch(e.target.value);
  };
  const filtreddata = !search
    ? data
    : data?.filter((item) => {
        return Object.keys(item)?.some((key) => {
          return item[key]
            ?.toString()
            ?.toLowerCase()
            ?.includes(search.toLowerCase());
        });
      });

  useEffect(() => {
    let invoiceKeys = data.map((o) => o.key);
    if (openCheckbox) {
      setReqAllocInvoiceKeys(invoiceKeys);
    }
  }, [openCheckbox]);

  useEffect(() => {
    const totalAmount = data.reduce(
      (a, b) => a + parseFloat(b?.net_payable),
      0
    );

    console.log('totalAmount%%%%%%%%', totalAmount);
    console.log('data%%%%%%%%', data);

    setReqAmount(totalAmount);
    setTotalInvoicesAmount(totalAmount);

    if (fromSummary) {
      setReqAllocInvoices(data);
    }

    if (selectedBankBranchName) {
      setSelectedBank(selectedBankBranchName);
    }
  }, []);

  const invoiceData = data[0];
  const { vendor_name, vendor_code } = invoiceData;
  const columns = [
    {
      title: 'PROJECT CODE',
      dataIndex: 'tds_project_code',
      key: 'tds_project_code',
      sorter: (a, b) => a.tds_project_code - b.tds_project_code,
      ellipsis: {
        showTitle: false
      },
      width: '110px',
      render: (tds_project_code) => {
        return (
          <>
            <Tooltip
              title={tds_project_code}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {tds_project_code}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'VENDOR',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
      sorter: (a, b) => a.vendor_name.localeCompare(b.vendor_name),
      ellipsis: {
        showTitle: false
      },
      width: '260px',
      render: (vendor_name) => {
        return (
          <>
            <Tooltip
              title={vendor_name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {vendor_name}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'INVOICE NUMBER',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      sorter: (a, b) => a.invoice_number.localeCompare(b.invoice_number),
      ellipsis: {
        showTitle: false
      },
      width: '240px',
      render: (invoice_number) => {
        return (
          <>
            <Tooltip
              title={invoice_number}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <a>{invoice_number}</a>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'NET PAYABLE',
      dataIndex: 'net_payable',
      key: 'net_payable',
      sorter: (a, b) => Number(a.net_payable) - Number(b.net_payable),
      ellipsis: {
        showTitle: false
      },
      render: (net_payable) => {
        return (
          <>
            <Tooltip
              title={numIntStyleFormat(net_payable)}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span style={{ fontWeight: 'bold' }}>
                {net_payable >= 0 ? (
                  <span>₹{numFormating(net_payable)} </span>
                ) : (
                  <span>-₹{numFormating(net_payable)}</span>
                )}
              </span>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'AGEING',
      dataIndex: 'ageing',
      key: 'ageing',
      width: '150',
      sorter: (a, b) =>
        Number(a.ageing.replace(` days`, '')) -
        Number(b.ageing.replace(` days`, ''))
    }
  ];

  const rowSelection = {
    selectedRowKeys: reqAllocInvoiceKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowLength(selectedRows);
      setReqAllocInvoiceKeys(selectedRowKeys);
      setReqAllocInvoices(selectedRows);

      const reCalculatedTotalAmount = selectedRows.reduce(
        (a, b) => a + parseFloat(b.net_payable),
        0
      );
      console.log(' reCalculatedTotalAmount%%%%%%%', reCalculatedTotalAmount);
      //setReqAmount(reCalculatedTotalAmount); //fixed the Liability Amount
      setTotalInvoicesAmount(reCalculatedTotalAmount);
    }
  };
  //________________________________________________________________
  const tolerance = 0.5;
  function compareFloatsGreaterWithTolerance(a, b, tolerance) {
    const diff = a - b;
    return diff > tolerance ? a : null;
  } // : diff < -tolerance ? b

  //_______________________________________________________________
  useEffect(() => {
    const totalInvoicesAmountWithCurrentTotal =
      Math.abs(totalInvoicesAmount) + Math.abs(currentTotal);
    //_____________________________________________________
    const greaterFloat = compareFloatsGreaterWithTolerance(
      totalInvoicesAmountWithCurrentTotal,
      Math.abs(grandTotal),
      tolerance
    );
    //____________________________________________________________
    // setGreaterFloatValue(greaterFloat);
    // console.log('greaterFloat', greaterFloat);
    // if (greaterFloat !== null) {
    //   console.log(`${greaterFloat} is greater with tolerance.`);
    // } else {
    //   console.log('The float numbers are within the tolerance or equal.');
    // }

    // const total = Math.abs(totalInvoicesAmount) + Math.abs(currentTotal);
    //if (total > Math.abs(grandTotal))
    //__________________________________________________________
    if (greaterFloat !== null) {
      message.error('You are exceeding the total liability');
    }
    //______________________________________________________________
  }, [totalInvoicesAmount]);
  //__________________________________________________________________________________
  const onOk = () => {
    let bank_detail = null;
    if (selectedBankBranchName !== null) {
      const invoiceData = data[0];
      const { vendor_code } = invoiceData;
      bank_detail = summaryInvoicesActiveBankList[vendor_code].find(
        (item) => item.bank_account_number === selectedBank
      );
    }
    if (user_role === 'Project Engineer') {
      let payload = {
        requested_fund: totalInvoicesAmount, //reqAmount, --------new change
        selected_worth: totalInvoicesAmount,
        bank_detail: selectedBankBranchName !== null ? bank_detail : null,
        invoices:
          data[0]?.non_H2H === false
            ? selectedBankBranchName !== null
              ? (fromSummary ? reqAllocInvoices : data).map((item) => item.id)
              : null
            : null,
        non_H2H_invoices:
          data[0]?.non_H2H === true
            ? (fromSummary ? reqAllocInvoices : data).map((item) => item.id)
            : null

        /*   non_H2H_invoices:
          selectedBankBranchName === null
            ? (fromSummary ? reqAllocInvoices : data).map((item) => item.id)
            : null */
      };
      handlePostAllocationRequest('PE', payload);
    } else if (user_role === 'BU Commercial') {
      let payload = {
        approved_allocation_invoice_id: data?.map((item) => item?.id),
        requested_fund: totalInvoicesAmount,
        selected_worth: totalInvoicesAmount
      };
      handlePostAllocationRequest('BUC', payload);
    } else if (user_role === 'Treasury') {
      let payload = {
        approved_allocation_invoice_id: data?.map((item) => item?.id),
        requested_fund: totalInvoicesAmount,
        selected_worth: totalInvoicesAmount
      };
      handlePostAllocationRequest('Treasury', payload);
    }
    setOpenCheckbox(false);
  };
  //console.log('totalInvoicesAmount%%%%%%%%%%%%%%%', totalInvoicesAmount); //total bucket amount

  return (
    <CustomModal
      title="Request Fund Allocation"
      visible={visible}
      width={'90%'}
      bodyStyle={{ height: '80vh', overflow: 'scroll', overflowX: 'hidden' }}
      style={{ top: 50 }}
      onCancel={toggleModal}
      cancelButtonProps={{ onClick: toggleModal }}
      okText={
        totalInvoicesAmount >= 0
          ? `Submit Fund allocation of ₹${numFormating(
              totalInvoicesAmount
            )} for vendor`
          : `Submit Fund allocation of -₹${numFormating(
              totalInvoicesAmount
            )} for vendor`
      }
      okButtonProps={{
        //_______________________________________________________________________
        disabled:
          selectedRowLength?.length <= 0 ||
          totalInvoicesAmount >= 0 ||
          compareFloatsGreaterWithTolerance(
            Math.abs(totalInvoicesAmount),
            Math.abs(reqAmount),
            tolerance
          ) !== null ||
          compareFloatsGreaterWithTolerance(
            Math.abs(totalInvoicesAmount) + Math.abs(currentTotal),
            Math.abs(grandTotal),
            tolerance
          ) !== null,

        //_______________________________________________________
        //greaterFloatValue !== null,

        // Math.abs(totalInvoicesAmount) > Math.abs(reqAmount) ||
        // Math.abs(totalInvoicesAmount) + Math.abs(currentTotal) >
        //   Math.abs(grandTotal),
        loading: postAllocationRequestLoading,
        onClick: onOk
      }}
    >
      <div>
        <Space size={'middle'} align="center">
          <Text
            className={`${styles.req_alloc_modal_vendor_details} sfprotext-medium font-13`}
          >{`${vendor_name} (${vendor_code})`}</Text>
          <Space
            size={'large'}
            className={`${styles.req_alloc_modal_requested_amount_details}`}
          >
            <Text className="font-14">{requestedAmount}:</Text>
            {!editReqAmount ? (
              <Text className="sfprotext-bold font-14">
                <Tooltip
                  title={reqAmount}
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {reqAmount >= 0 ? (
                    <span>₹{numFormating(reqAmount)} </span>
                  ) : (
                    <span>-₹{numFormating(reqAmount)}</span>
                  )}
                </Tooltip>
              </Text>
            ) : (
              <InputNumber
                min={1}
                max={totalInvoicesAmount}
                value={reqAmount}
                onChange={setReqAmount}
              />
            )}
          </Space>
        </Space>
      </div>
      <div>
        <Space size={'middle'} align="center" className="mt-3">
          <Text className="sfprotext-bold font-14">
            {'Selected invoices for the requested amount'}
          </Text>
          <Space
            size={'middle'}
            className={`${styles.req_alloc_modal_invoices_total_amount_details}`}
          >
            <Text className="font-14">{`${
              (fromSummary ? reqAllocInvoices : data).length
            } invoices of worth`}</Text>
            <Text className="sfprotext-bold font-14">
              {/* numIntStyleFormat(totalInvoicesAmount) */}
              <Tooltip
                title={totalInvoicesAmount}
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {totalInvoicesAmount >= 0 ? (
                  <span>₹{numFormating(totalInvoicesAmount)} </span>
                ) : (
                  <span>-₹{numFormating(totalInvoicesAmount)}</span>
                )}
              </Tooltip>
            </Text>
          </Space>
        </Space>
      </div>
      {user_role === 'Project Engineer' ? (
        !selectedBankBranchName ? (
          <div className="mt-3">
            <Text className="sfprotext-medium font-13 mr-3">Select Bank:</Text>
            {/* <Select
              size="middle"
              showSearch
              style={{ minWidth: '50%', fontSize: '12px', height: 30 }}
              placeholder="Search to Select"
              optionFilterProp="children"
              filterOption={(input, option) => option.children.includes(input)}
              onChange={setSelectedBank}
            >
              {activeBankList.map((bank, index) => {
                const { bank_name, bank_branch_name, bank_account_number } =
                  bank;

                return (
                  <Option key={index} value={bank.bank_branch_name}>
                    {' '}
                    <div
                      style={{ fontSize: '12px' }}
                    >{`Name: ${bank_name}, IFSC: ${bank_branch_name}, A/c no: ${bank_account_number}`}</div>
                  </Option>
                );
              })}
            </Select> */}
          </div>
        ) : null
      ) : null}
      <div className="mt-3" style={{ width: '300px' }}>
        {!openCheckbox && selectedBankBranchName ? (
          <Input
            placeholder="Search using invoice number/project"
            allowClear
            onChange={searchHandler}
            justify="start"
            align="middle"
            // className="mb-4"
            value={search}
            prefix={<SearchOutlined />}
          />
        ) : null}
      </div>
      <div className="mt-3">
        <CustomTableModal
          style={{
            fontFamily: 'SFProText Regular',
            padding: '40px 4px !important',
            lineHeight: '18px',
            color: '#32324D'
          }}
          data={filtreddata}
          columns={columns}
          pagination={false}
          rowSelection={fromSummary ? rowSelection : null}
        />
      </div>
    </CustomModal>
  );
};

export default PeSummaryAllocModal;
