import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { signin } from './api';
import LocalStorage from 'utilities/localStorage';

const initialState = {
  loading: false,
  accessToken: '',
  user: null
};

const actions = {
  LOGIN: 'login/LOGIN'
};

export const login = createAsyncThunk(actions.LOGIN, async (payload) => {
  const response = await signin(payload);
  if (response?.accessToken) {
    const { accessToken, user_details } = response;
    let newUser = {
      accessToken,
      user_details
    };

    LocalStorage.setItem('A2P_user', JSON.stringify(newUser));
  }

  return response;
});

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    resetLoginDetails: (state) => {
      state.accessToken = '';
      state.user = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        const { accessToken, message: msg, user } = action.payload;
        state.loading = false;
        state.accessToken = accessToken;
        state.user = user;

        if (accessToken) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(login.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});

export const { resetLoginDetails } = loginSlice.actions;

export const logout = () => (dispatch) => {
  localStorage.clear();
  dispatch(resetLoginDetails());
};

export default loginSlice.reducer;
