import React, { useEffect, useState } from 'react';
import { Col, Row, Space, Typography } from 'antd';
import CustomButton from '../../CustomButton';
import CustomButtonPadding from '../../CustomButtonPadding';
import styles from './index.module.less';
import { getLoggedInUser } from 'utilities/helpers';

const { Text } = Typography;

const SubHeader = ({ tabs, onTabChange, buttons, ...rest }) => {
  const user_role1 = getLoggedInUser();
  const user_role = user_role1?.user_details?.role;

  const [selectedTabId, setSelectedTabId] = useState(null);

  useEffect(() => {
    setSelectedTabId(tabs?.[0]?.id || null);
  }, []);

  return (
    <Row {...rest} style={{ marginBottom: '20px' }}>
      <Col className={styles.sub_header_tabs_container}>
        <Space
          size="large"
          //style={{ color: '#8E8EA9 !important', fontSize: '13px' }}
          className={`${styles.sub_header_tabs_space} ${styles.tab_style_fontSize}`}
        >
          {tabs.map((tab) => {
            const { id, label, count } = tab;
            return (
              <div
                key={id}
                className={`${styles.sub_header_tab}
                  ${selectedTabId === id ? ` ${styles.selected}` : ''}
                `}
                onClick={() => {
                  setSelectedTabId(id);
                  onTabChange(id);
                }}
              >
                <Text
                  type="secondary"
                  className={`sfprotext-bold ${styles.sub_header_tab_text}`}
                >{`${label} (${count})`}</Text>
                <div className={styles.horizontal_line} />
              </div>
            );
          })}
        </Space>
      </Col>
      <Col>
        <Space>
          {buttons.map((button, index) => {
            const { type, text, onClick } = button;
            return user_role === 'Project Engineer' ? (
              <CustomButton
                key={index}
                type={type || 'default'}
                onClick={onClick}
              >
                {text}
              </CustomButton>
            ) : user_role === 'SBU Commercial' ||
              user_role === 'SBU Head' ||
              user_role === 'Treasury' ? (
              <CustomButtonPadding
                key={index}
                type={type || 'default'}
                onClick={onClick}
              >
                {text}
              </CustomButtonPadding>
            ) : (
              <CustomButton
                key={index}
                type={type || 'default'}
                onClick={onClick}
              >
                {text}
              </CustomButton>
            );
          })}
        </Space>
      </Col>
    </Row>
  );
};

export default SubHeader;
