//import styles from './index.module.less';
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

/* export const options = {
  plugins: {
    title: {
      display: true,
      text: 'Open vs Allocated (BU wise)'
    }
  },
  responsive: true,
  scales: {
    x: {
      stacked: true
    },
    y: {
      stacked: true
    }
  }
}; */
export const options = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 2
    }
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'hide'
    },
    title: {
      display: true,
      text: 'Top 5 Business Units by Invoices'
    }
  },
  scales: {
    x: {
      stacked: true
    },
    y: {
      stacked: true,
      ticks: {
        font: {
          size: 11,
          // fontWaight:'SFProText Regular',
          // style: 'italic',
          weight: '550'
        }
      }
    }
  }
};

export default function Top_BU_Chart({ count_BU_wise }) {
  // console.log('open_vs_allocated_count_BU_wise', count_BU_wise);

  const arrayForSort = [...count_BU_wise];
  const sorted_open_invoices = arrayForSort?.sort(function (a, b) {
    return b?.total_open_invoices - a?.total_open_invoices;
  });
  // console.log('sorted_open_invoices', sorted_open_invoices);
  const top_five = sorted_open_invoices?.slice(0, 5);
  console.log('top_five', top_five);
  const data = {
    labels: top_five?.map((item) => item?.BU_name),

    datasets: [
      {
        label: '',
        data: top_five?.map((item) => item?.total_open_invoices),
        backgroundColor: '#4059e5', //'#7673FF',
        borderColor: '#4059e5',
        barThickness: 25
      }
      /* {
        label: 'allocated invoices',
        data: count_BU_wise?.map((item) => item?.total_allocated_invoices),
        backgroundColor: '#C2C0FF', //#4059E5',
        barThickness: 30
      } */
    ]
  };
  return (
    <div>
      <Bar options={options} data={data} width={350} height={370} />
    </div>
  );
}
