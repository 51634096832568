//import styles from './index.module.less';
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
export const options = {
  plugins: {
    title: {
      display: true,
      text: 'Open Invoices Vs. Allocated Invoices (BU-wise)'
    }
  },
  responsive: true,
  scales: {
    x: {
      stacked: true
    },
    y: {
      stacked: true
    }
  }
};

/* export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' //as const,
    },
    title: {
      display: true,
      text: 'Chart.js Bar Chart'
    }
  },
  scales: {
    xAxes: [{ barPercentage: 0 }]
  },
 // maintainAspectRatio: false
}; */

/* const labels = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];
const data1 = [65, 59, 80, 81, 56, 65, 59, 80, 81, 56, 10, 46];
const data2 = [15, 29, 80, 10, 46, 15, 29, 80, 10, 46, 81, 56]; */
/* export const data = {
  labels,

  datasets: [
    {
      label: 'Dataset 1',
      data: data1?.map((item) => item),
      backgroundColor: '#4059E5', //'#7673FF',
      barThickness: 30
    },
    {
      label: 'Dataset 2',
      data: data2?.map((item) => item),
      backgroundColor: '#C2C0FF', //#4059E5',
      barThickness: 30
    }
  ]
}; */

export default function StackedBarChart({ count_BU_wise }) {
  const data = {
    labels: count_BU_wise?.map((item) => item?.BU_name),

    datasets: [
      {
        label: 'Open invoices',
        data: count_BU_wise?.map((item) => item?.total_open_invoices),
        backgroundColor: '#4059e5', //'#7673FF',
        barThickness: 30
      },
      {
        label: 'Allocated invoices',
        data: count_BU_wise?.map((item) => item?.total_allocated_invoices),
        backgroundColor: '#C2C0FF', //#4059E5',
        barThickness: 30
      }
    ]
  };
  return (
    <div>
      <Bar options={options} data={data} width={630} height={200} />
    </div>
  );
}
