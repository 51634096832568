import apiClient from 'utilities/apiClient';

export const invoices = (role, type, payload) => {
  return apiClient.get(
    `${apiClient.Urls.apiUrl}/${role}/${type}`,
    payload,
    true
  );
};

export const vendorList = (payload) => {
  return apiClient.get(apiClient.Urls.vendorList, payload, true);
};

export const activeBankList = (payload) => {
  return apiClient.get(apiClient.Urls.activeBankList, payload, true);
};

export const allocationRequest = (role, payload) => {
  return apiClient.post(
    `${apiClient.Urls.apiUrl}/${role}/newAllocationRequest`,
    payload,
    true
  );
};

export const requestFund = (role, payload) => {
  return apiClient.post(
    `${apiClient.Urls.apiUrl}/${role}/requestFund`,
    payload,
    true
  );
};

// BU Commercial & Treasury Request Allocation
export const buApproveAllocationRequest = (role, payload) => {
  return apiClient.post(
    `${apiClient.Urls.apiUrl}/${role}/approveAllocationRequest`,
    payload,
    true
  );
};

//BU Commercial Request Fund Allocation
export const buForwardAllocationRequest = (role, payload) => {
  return apiClient.post(
    `${apiClient.Urls.apiUrl}/${role}/forwardAllocationRequest`,
    payload,
    true
  );
};

//BU Commercial & Treasury's ProjectList
export const projectList = (role, payload) => {
  return apiClient.get(
    `${apiClient.Urls.apiUrl}/${role}/getProjectList`,
    payload,
    true
  );
};

//BU Commercial & Treasury-> BUList
export const buList = (role, payload) => {
  return apiClient.get(
    `${apiClient.Urls.apiUrl}/${role}/getBUList`,
    payload,
    true
  );
};
//For Treasury getActiveDebitBankList
export const activeDebitBankList = (payload) => {
  return apiClient.get(apiClient.Urls.activeDebitBankList, payload, true);
};
//BU Commercial & Treasury->  vendorwise
export const vendorwise = (role, type1, type2, payload) => {
  return apiClient.get(
    `${apiClient.Urls.apiUrl}/${role}/${type1}/${type2}`,
    payload,
    true
  );
  // return apiClient.get(apiClient.Urls.vendorwise, payload, true);
};

// export const exportAllocatedInvoices = (role, payload) => {
//   return apiClient.get(
//     `${apiClient.Urls.apiUrl}/${role}/exportAllocatedInvoices`,
//     payload,
//     'application/csv',
//     true
//   );
// };
//${url}
