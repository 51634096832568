import styles from './index.module.less';
import { Space } from 'antd'; //, Card
//import { DollarOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { numFormating } from 'utilities/helpers';
import rupee from '../images/rupee.svg';
import initiated_icon from '../images/initiated_icon.svg';
import proposed_icon from '../images/proposed_icon.svg';
import ratified_icon from '../images/ratified_icon.svg';
import allocated_icon from '../images/allocated_icon.svg';

export default function CardStatus({
  total,
  initiated,
  proposed,
  ratified,
  allocated
}) {
  const cardContent = [
    {
      id: 1,
      title: 'TOTAL',
      value: total,
      icon: rupee //<DollarOutlined />
    },
    {
      id: 2,
      title: 'INITIATED',
      value: initiated,
      icon: initiated_icon //<QuestionCircleOutlined />
    },

    {
      id: 3,
      title: 'PROPOSED',
      value: proposed,
      icon: proposed_icon //<QuestionCircleOutlined />
    },

    {
      id: 4,
      title: 'RATIFIED',
      value: ratified,
      icon: ratified_icon // <QuestionCircleOutlined />
    },
    {
      id: 5,
      title: 'ALLOCATED',
      value: allocated,
      icon: allocated_icon // <QuestionCircleOutlined />
    }
  ];
  return (
    <div>
      <div className={`${styles.card_container} mb-3 `}>
        <Space
          direction="horizontal"
          size="middle"
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          {cardContent?.map((item, index) => (
            <Space
              //   title={item?.title}
              //   size="small"
              key={index}
              className={`${styles.card_content_style}`}
            >
              <div className={`${styles.card_icon_div}`}>
                <span className={`${styles.card_icon_style}`}>
                  <img
                    className={`${styles.card_icon_style_img}`}
                    src={item?.icon}
                  />
                </span>
              </div>
              <div>
                <span className={`${styles.card_title_style}`}>
                  {item?.title}
                </span>
                <div className={`${styles.card_amount_style}`}>
                  ₹{numFormating(item?.value)}
                </div>
              </div>
            </Space>
          ))}
        </Space>
      </div>
    </div>
  );
}
