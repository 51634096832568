import React, { useEffect, useState } from 'react'; //, useCallback
import Layout from '@/common/Layout/index';
import InboundFailedListPage from './components/inboundFailedListPage';
import { useDispatch, useSelector } from 'react-redux';
import {
  //   getActiveBankList,
  //   getInvoices,
  //   getOpenInvoices,
  //   getPaidInvoices,
  //   getPendingInvoices,
  //   getRejectedInvoices,
  //   getAllocatedInvoices,
  //   getApprovedInvoices,
  //   getRatifiedInvoices,
  //   getVendorList,
  //   postAllocationRequest,
  //   PostBuRequestFundAllocation,
  //   getProjectList,
  //   getBUList,
  //   getActiveDebitBankList,
  //   getSendBackAllocRequest,
  //   getNormalViewCount,
  //   postSendBackAllocRequestInvoiceWise,
  getInboundFailedList,
  postRetryInbound
} from '../../redux/slice';
//import { getSBUList } from './components/treasury/redux/slice';
import {
  // filterToPayload,
  //invoiceFilters,
  userRole,
  getLoggedInUser
} from 'utilities/helpers';
import { message } from 'antd';
function InboundFailedList() {
  const dispatch = useDispatch();
  // const { SBUList } = useSelector((state) => state.treasury);
  const {
    // allInvoicesLoading,
    // allInvoices,
    // allInvoicesPagination,
    // vendorList,
    // activeBankList,
    // postAllocationRequestLoading,
    // openInvoicesLoading,
    // openInvoices,
    // openInvoicesPagination,
    // paidInvoicesLoading,
    // paidInvoices,
    // paidInvoicesPagination,
    // pendingInvoicesLoading,
    // pendingInvoices,
    // pendingInvoicesPagination,
    // rejectedInvoicesLoading,
    // rejectedInvoices,
    // rejectedInvoicesPagination,
    // allocatedInvoicesLoading,
    // allocatedInvoices,
    // allocatedInvoicesPagination,
    // approvedInvoicesLoading,
    // approvedInvoices,
    // approvedInvoicesPagination,
    // ratifiedInvoicesPagination,
    // ratifiedInvoicesLoading,
    // ratifiedInvoices,
    // projectList,
    // BUList,
    // activeDebitBankList,
    // all,
    // initiated,
    // open,
    // paid,
    // rejected,
    // proposed,
    // ratified,
    // allocated,
    // postSendBackAllocRequestInvoiceWiseLoading,
    //pagination,
    inboundFailedList,
    getInboundFailedListLoading,
    paginationInboundFailedList,
    postRetryInboundLoading
  } = useSelector((state) => state.invoices);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(100000);
  //const [glView, setGlView] = useState(true);
  //   const [triggerFilter, setTriggerFilter] = useState(false);
  //   const [reqAllocInvoiceKeys, setReqAllocInvoiceKeys] = useState([]);
  //   const [reqAllocInvoices, setReqAllocInvoices] = useState([]);
  //   const [filterValues, setFilterValues] = useState(invoiceFilters);
  //   const [openReqAllocModal, setOpenReqAllocModal] = useState(false);
  //   const [tab, setTab] = useState(1);
  //   const [openDropdown, setOpenDropdown] = useState(false);
  //   const [vendorSearch, setVendorSearch] = useState('');
  //   const [projectSearch, setProjectSearch] = useState('');
  //   const [openExportPropModal, setOpenExportPropModal] = useState(false);
  //   const [openExportInitiatedModal, setOpenExportInitiatedModal] =
  //     useState(false);
  //   const [openExportRatifiedModal, setOpenExportRatifiedModal] = useState(false);
  //   const [openExportAllocatedModal, setOpenExportAllocatedModal] =
  //     useState(false);
  //   const [openExportJourneyModal, setOpenExportJourneyModal] = useState(false);
  //   const [openLiabilityNotAvailableModal, setOpenLiabilityNotAvailableModal] =
  //     useState(false);
  //   const [openReqRejectModal, setOpenReqRejectModal] = useState(false);
  //   const [lodingTLReport, setLodingTLReport] = useState(false);

  const user_role1 = getLoggedInUser();
  const user_role = user_role1?.user_details?.role;
  console.log('user_role......', user_role);
  console.log('inboundFailedList.........', inboundFailedList);
  paginationInboundFailedList;
  console.log(
    'paginationInboundFailedList..............',
    paginationInboundFailedList
  );
  /*   const loading = () => {
    if (user_role === 'Treasury') {
      switch (tab) {
        case 1:
          return ratifiedInvoicesLoading;
        case 2:
          return allocatedInvoicesLoading;
        case 3:
          return paidInvoicesLoading;
        case 4:
          return allInvoicesLoading;

        default:
          return false;
      }
    } else if (user_role === 'SBU Commercial') {
      switch (tab) {
        case 1:
          return pendingInvoicesLoading;
        case 2:
          return approvedInvoicesLoading;
        case 3:
          return rejectedInvoicesLoading;
        case 4:
          return paidInvoicesLoading;
        case 5:
          return allInvoicesLoading;
        default:
          return false;
      }
    } else if (user_role === 'SBU Head') {
      switch (tab) {
        case 1:
          return approvedInvoicesLoading;
        case 2:
          return ratifiedInvoicesLoading;
       
        case 3:
          return paidInvoicesLoading;
        case 4:
          return allInvoicesLoading;
        default:
          return false;
      }
    } else {
      switch (tab) {
        case 1:
          return openInvoicesLoading;
        case 2:
          return pendingInvoicesLoading;
        case 3:
          return rejectedInvoicesLoading;
        case 4:
          return paidInvoicesLoading;
        case 5:
          return allInvoicesLoading;
        // case 6:
        //   return allocatedInvoicesLoading;
        // case 7:
        //   return approvedInvoicesLoading;

        default:
          return false;
      }
    }
  }; */

  /*  const invoices = () => {
    if (user_role === 'Treasury') {
      switch (tab) {
        case 1:
          return ratifiedInvoices;
        case 2:
          return allocatedInvoices;
        case 3:
          return paidInvoices;
        case 4:
          return allInvoices;
        default:
          return [];
      }
    } else if (user_role === 'SBU Commercial') {
      switch (tab) {
        case 1:
          return pendingInvoices;
        case 2:
          return approvedInvoices;
        case 3:
          return rejectedInvoices;
        case 4:
          return paidInvoices;
        case 5:
          return allInvoices;
        default:
          return [];
      }
    } else if (user_role === 'SBU Head') {
      switch (tab) {
        case 1:
          return approvedInvoices;
        case 2:
          return ratifiedInvoices;

        case 3:
          return paidInvoices;
        case 4:
          return allInvoices;
        default:
          return [];
      }
    } else {
      switch (tab) {
        case 1:
          return openInvoices;
        case 2:
          return pendingInvoices;
        case 3:
          return rejectedInvoices;
        case 4:
          return paidInvoices;
        case 5:
          return allInvoices;
        // case 6:
        //   return allocatedInvoices;
        // case 7:
        //   return approvedInvoices;
        default:
          return [];
      }
    }
  }; */

  /*  const pagination = () => {
    if (user_role === 'Treasury') {
      switch (tab) {
        case 1:
          return ratifiedInvoicesPagination;
        case 2:
          return allocatedInvoicesPagination;
        case 3:
          return paidInvoicesPagination;
        case 4:
          return allInvoicesPagination;
        default:
          return null;
      }
    } else if (user_role === 'SBU Commercial') {
      switch (tab) {
        case 1:
          return pendingInvoicesPagination;
        case 2:
          return approvedInvoicesPagination;
        case 3:
          return rejectedInvoicesPagination;
        case 4:
          return paidInvoicesPagination;
        case 5:
          return allInvoicesPagination;
        default:
          return null;
      }
    } else if (user_role === 'SBU Head') {
      switch (tab) {
        case 1:
          return approvedInvoicesPagination;
        case 2:
          return ratifiedInvoicesPagination;

        case 3:
          return paidInvoicesPagination;
        case 4:
          return allInvoicesPagination;
        default:
          return null;
      }
    } else {
      switch (tab) {
        case 1:
          return openInvoicesPagination;
        case 2:
          return pendingInvoicesPagination;
        case 3:
          return rejectedInvoicesPagination;
        case 4:
          return paidInvoicesPagination;
        case 5:
          return allInvoicesPagination; //paidInvoicesPagination;
        // case 6:
        //   return allocatedInvoicesPagination;
        // case 7:
        //   return approvedInvoicesPagination; //paidInvoicesPagination;
        default:
          return null;
      }
    }
  }; */

  /* if (user_role === 'Treasury') {
    useEffect(() => {
      dispatch(getActiveDebitBankList());
    }, []);
  }
  const sendBackAllocRequestHandler = () => {
    let payload = {};
    dispatch(
      getSendBackAllocRequest({ role: userRole(user_role), payload })
    ).then(() => {
      handleGetInvoices();
      handleNormalViewCount();
    });
  };

  const getExportPaidInvoicesHandler = () => {
    fetch(
      `${process.env.REACT_APP_API_URL}/${userRole(
        user_role
      )}/exportPaidInvoices`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/csv',
          Authorization: `Bearer ${user_role1?.accessToken}`
        }
      }
    ).then((response) => {
      if (response?.status !== 500) {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `a2p_exported_paid_invoices.csv`);

          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        });
      } else {
        message.success('No any paid invoices found');
        console.log('responceNOT');
      }
    });
  };

  const getTLReportsHandler = () => {
    setLodingTLReport(true);
    fetch(
      `${process.env.REACT_APP_API_URL}/invoice/exportProjectVendorReport`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/csv',
          Authorization: `Bearer ${user_role1?.accessToken}`
        }
      }
    ).then((response) => {
      if (response?.status !== 500) {
        setLodingTLReport(false);
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `a2p_exported_TL_report.csv`);

          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        });
      } else {
        message.success('No any TL report found');
        console.log('responceNOT');
      }
    });
  };

  const handleNormalViewCount = () => {
    if (user_role === 'Project Engineer') {
      let payload = {
        tds_project_code: filterValues?.tds_project_code,
        vendor_code: filterValues?.vendor ? filterValues?.vendor : 'All',
        GL_date: filterValues?.gl_date ? filterValues?.gl_date : 'All',
        age: filterValues?.age ? filterValues?.age : 'All',
        search_invoice: filterValues?.search_invoice
          ? filterValues?.search_invoice
          : ''
      };
      filterValues?.tds_project_code
        ? dispatch(getNormalViewCount({ role: userRole(user_role), payload }))
        : '';
    }
    if (user_role === 'SBU Commercial' || user_role === 'SBU Head') {
      let payload = {
        BU_name: filterValues?.BU_name,
        tds_project_code: filterValues?.tds_project_code
          ? filterValues?.tds_project_code
          : 'All',
        vendor_code: filterValues?.vendor ? filterValues?.vendor : 'All',
        GL_date: filterValues?.gl_date ? filterValues?.gl_date : 'All',
        age: filterValues?.age ? filterValues?.age : 'All',
        search_invoice: filterValues?.search_invoice
          ? filterValues?.search_invoice
          : ''
      };
      if (
        filterValues?.BU_name === 'All' ||
        filterValues?.BU_name === undefined
      ) {
        console.log('');
      } else {
        dispatch(getNormalViewCount({ role: userRole(user_role), payload }));
      }
    }
    if (user_role === 'Treasury') {
      let payload = {
        SBU_name: filterValues?.SBU_name,
        BU_name: filterValues?.BU_name ? filterValues?.BU_name : 'All',
        tds_project_code: filterValues?.tds_project_code
          ? filterValues?.tds_project_code
          : 'All',
        vendor_code: filterValues?.vendor ? filterValues?.vendor : 'All',
        GL_date: filterValues?.gl_date ? filterValues?.gl_date : 'All',
        age: filterValues?.age ? filterValues?.age : 'All',
        search_invoice: filterValues?.search_invoice
          ? filterValues?.search_invoice
          : ''
      };
      if (
        filterValues?.SBU_name === 'All' ||
        filterValues?.SBU_name === undefined
      ) {
        console.log('');
      } else {
        dispatch(getNormalViewCount({ role: userRole(user_role), payload }));
      }
    }
  }; */

  //--------- Start 'Project Engineer' -------------------------
  /*  useEffect(() => {
    if (user_role === 'Project Engineer') {
      const tds_project_code = projectList[0]?.value;
      setTriggerFilter(true);
      setFilterValues({ tds_project_code: tds_project_code });
    }
  }, [projectList]);
  useEffect(() => {
    if (user_role === 'Project Engineer') {
      let payload = {};
      //if (projectSearch) payload['search_project'] = projectSearch;
      dispatch(getProjectList({ role: userRole(user_role), payload }));
    }
  }, []); //projectSearch */

  //------ Start 'SBU Commercial' && 'SBU Head' -------------------------

  /*   useEffect(() => {
    if (user_role === 'SBU Commercial' || user_role === 'SBU Head') {
      handleGetBUList();
    }
  }, []);
  const handleGetBUList = () => {
    let payload = {};
    dispatch(getBUList({ role: userRole(user_role), payload }));
  };

  useEffect(() => {
    if (user_role === 'SBU Commercial' || user_role === 'SBU Head') {
      const BU_name = BUList[0]?.value;
      setTriggerFilter(true);
      setFilterValues({ BU_name: BU_name });
    }
  }, [BUList?.length]); */

  //------ Start 'Treasury' --------------------------------
  useEffect(() => {
    if (user_role === 'Treasury') {
      let payload = {
        page: 1,
        per_page: 100000
      };
      dispatch(
        getInboundFailedList({
          role: userRole(user_role),
          payload
        })
      );
    }
  }, []);

  const retryHandler = () => {
    console.log('RETRY..........');
    let payload = {
      invoices: inboundFailedList?.map((item) => item?.id)
    };
    dispatch(
      postRetryInbound({
        role: userRole(user_role),
        payload
      })
    ).then(function (response) {
      if (response?.payload?.success) {
        const Total_inbound_failed_invoices =
          response?.payload?.inbound_failed_invoices?.length;
        const Total_inbound_success_invoices =
          response?.payload?.inbound_success_invoices?.length;

        message.success(
          <>
            <div>{response.payload.message}</div>
            <div style={{ color: 'red' }}>
              Total Inbound Failed Invoices= {Total_inbound_failed_invoices}
            </div>
            <div style={{ color: 'green' }}>
              Total Inbound Success Invoices= {Total_inbound_success_invoices}
            </div>
          </>
        );
      } else {
        // setInitiate(false);
        message.error(response.payload.error);
      }
    });
    /* dispatch(createDraftUpload(formdata)).then(function (response) {
      if (response?.payload?.success) {
        message.success(response.payload.msg);
        // setGpId(response?.payload?.data?.id);
        setDraftLoading(false);
        user?.user_details?.roles[0] === 'issuer'
          ? history.push('/issuer-requester')
          : history.push('/dashboard');
      } else {
        // setInitiate(false);
        message.error(response.payload.err);
        setDraftLoading(false);
      }
    }); */
  };
  /* 
  useEffect(() => {
    if (user_role === 'Treasury') {
      const SBU_name = SBUList[0]?.value;
      setTriggerFilter(true);
      setFilterValues({ SBU_name: SBU_name });
    }
  }, [SBUList.length]);

  useEffect(() => {
    if (user_role === 'Treasury') {
      handleGetSBUList();
    }
  }, []);

  const handleGetSBUList = () => {
    let payload = {};
    dispatch(getSBUList({ role: userRole(user_role), payload }));
  };

  useEffect(() => {
    if (user_role === 'Treasury') {
      handleGetBUListTreasury();
    }
  }, [filterValues?.SBU_name]);

  const handleGetBUListTreasury = () => {
    let payload = { SBU_name: filterValues?.SBU_name }; //|| 'All'
    filterValues?.SBU_name === 'All' || filterValues?.SBU_name === undefined
      ? ''
      : dispatch(getBUList({ role: userRole(user_role), payload }));
  };

  useEffect(() => {
    if (
      user_role === 'SBU Commercial' ||
      user_role === 'SBU Head' ||
      user_role === 'Treasury'
    ) {
      handleGetProjectList();
    }
  }, [filterValues?.BU_name, projectSearch]);

  const handleGetProjectList = () => {
    if (user_role === 'SBU Commercial') {
      if (
        filterValues?.BU_name !== 'All' &&
        filterValues?.BU_name !== undefined
      ) {
        let payload = {
          BU_name: filterValues?.BU_name
        };

        if (projectSearch) payload['search_project'] = projectSearch;

        dispatch(getProjectList({ role: userRole(user_role), payload }));
      }
    }
    if (user_role === 'SBU Head') {
      if (
        filterValues?.BU_name !== 'All' &&
        filterValues?.BU_name !== undefined
      ) {
        let payload = {
          BU_name: filterValues?.BU_name
        };
        if (projectSearch) payload['search_project'] = projectSearch;
        dispatch(getProjectList({ role: userRole(user_role), payload }));
      }
    }
    if (user_role === 'Treasury') {
      if (
        filterValues?.SBU_name === 'All' ||
        filterValues?.SBU_name === undefined ||
        filterValues?.BU_name === 'All' ||
        filterValues?.BU_name === undefined
      ) {
        console.log('');
      } else {
        let payload = {
          SBU_name: filterValues?.SBU_name, // || 'All',
          BU_name: filterValues?.BU_name // || 'All',
          //search_project: projectSearch
        };
        if (projectSearch) payload['search_project'] = projectSearch;
        dispatch(getProjectList({ role: userRole(user_role), payload }));
      }
    }
  };

  useEffect(() => {
    handleGetVendorList();
  }, [
    filterValues?.SBU_name,
    filterValues?.BU_name,
    filterValues?.tds_project_code,
    vendorSearch
  ]);
  const handleGetVendorList = () => {
    if (user_role === 'Project Engineer') {
      if (filterValues?.tds_project_code !== undefined) {
        console.log('vendorSearch', vendorSearch);
        let payload = {
          tds_project_code: filterValues?.tds_project_code
        };
        if (vendorSearch) payload['search_vendor'] = vendorSearch;
        filterValues?.tds_project_code ? dispatch(getVendorList(payload)) : '';
      }
    }
    if (user_role === 'SBU Commercial') {
      let payload = {
        BU_name: filterValues?.BU_name || 'All',
        tds_project_code: filterValues?.tds_project_code || 'All',
        search_vendor: vendorSearch
      };
      if (vendorSearch) payload['search_vendor'] = vendorSearch;
      filterValues?.tds_project_code ? dispatch(getVendorList(payload)) : '';
    }
    if (user_role === 'SBU Head') {
      let payload = {
        BU_name: filterValues?.BU_name || 'All',
        tds_project_code: filterValues?.tds_project_code || 'All',
        search_vendor: vendorSearch
      };
      filterValues?.tds_project_code ? dispatch(getVendorList(payload)) : '';
    }
    if (user_role === 'Treasury') {
      let payload = {
        SBU_name: filterValues?.SBU_name || 'All',
        BU_name: filterValues?.BU_name || 'All',
        tds_project_code: filterValues?.tds_project_code || 'All',
        search_vendor: vendorSearch
      };
      filterValues?.tds_project_code ? dispatch(getVendorList(payload)) : '';
    }
  };

  useEffect(() => {
    if (user_role === 'Treasury') {
      if (
        filterValues?.SBU_name !== 'All' &&
        filterValues?.SBU_name !== undefined
      ) {
        handleGetInvoices();
      }
    } else {
      // console.log('call_1');
      handleGetInvoices();
    }
  }, [page, pageSize]); // filterValues, tab

  useEffect(() => {
    if (user_role === 'Treasury') {
      if (
        filterValues?.SBU_name !== 'All' &&
        filterValues?.SBU_name !== undefined
      ) {
        if (triggerFilter) {
          if (page === 1) {
            handleGetInvoices();
            handleNormalViewCount();
          } else {
            setPage(1);
          }
        }
      }
    } else {
      if (triggerFilter) {
        if (page === 1) {
          handleGetInvoices();
          handleNormalViewCount();
        } else {
          setPage(1);
        }
      }
    }
  }, [filterValues, glView, tab]);

  const handleGetInvoices = () => {
    setReqAllocInvoiceKeys([]);
    setReqAllocInvoices([]);
    if (user_role === 'Project Engineer') {
      if (
        filterValues?.tds_project_code === 'All' ||
        filterValues?.tds_project_code === undefined
      ) {
        console.log('api pending state handle');
      } else {
        // console.log('call_3');
        let payload = {
          page,
          per_page: pageSize,
          tds_project_code: filterValues?.tds_project_code,
          status: 'All'
        };
        Object.keys(filterValues).forEach((item) => {
          if (filterValues[item] && filterValues[item] !== 'All') {
            payload[filterToPayload[item]] = filterValues[item];
          }
        });

        if (glView) {
          if (payload['invoice_date']) delete payload['invoice_date'];
        } else {
          if (payload['GL_date']) delete payload['GL_date'];
          payload['orderby_invoice_date'] = true;
        }

        tab === 5
          ? dispatch(
              getInvoices({ role: userRole(user_role), type: 'all', payload })
            )
          : '';
        tab === 1
          ? dispatch(
              getOpenInvoices({
                role: userRole(user_role),
                type: 'all',
                payload: { ...payload, status: 'open' }
              })
            )
          : '';

        tab === 4
          ? dispatch(
              getPaidInvoices({
                role: userRole(user_role),
                type: 'all',
                payload: { ...payload, status: 'paid' }
              })
            )
          : '';
        tab === 2
          ? dispatch(
              getPendingInvoices({
                role: userRole(user_role),
                type: 'all',
                payload: { ...payload, status: 'initiated' }
              })
            )
          : '';
        tab === 3
          ? dispatch(
              getRejectedInvoices({
                role: userRole(user_role),
                type: 'all',
                payload: { ...payload, status: 'rejected' }
              })
            )
          : '';
      }
    }
    if (user_role === 'SBU Commercial') {
      if (
        filterValues?.BU_name !== 'All' &&
        filterValues?.BU_name !== undefined
      ) {
        let payload = {
          page,
          per_page: pageSize,
          BU_name: filterValues?.BU_name,
          status: 'All'
        };
        Object.keys(filterValues).forEach((item) => {
          if (filterValues[item] && filterValues[item] !== 'All') {
            payload[filterToPayload[item]] = filterValues[item];
          }
        });

        if (glView) {
          if (payload['invoice_date']) delete payload['invoice_date'];
        } else {
          if (payload['GL_date']) delete payload['GL_date'];
          payload['orderby_invoice_date'] = true;
        }
        tab === 5
          ? dispatch(
              getInvoices({ role: userRole(user_role), type: 'all', payload })
            )
          : '';
       
        tab === 4
          ? dispatch(
              getPaidInvoices({
                role: userRole(user_role),
                type: 'all',
                payload: { ...payload, status: 'paid' }
              })
            )
          : '';
        tab === 1
          ? dispatch(
              getPendingInvoices({
                role: userRole(user_role),
                type: 'all',
                payload: { ...payload, status: 'initiated' }
              })
            )
          : '';
        tab === 3
          ? dispatch(
              getRejectedInvoices({
                role: userRole(user_role),
                type: 'all',
                payload: { ...payload, status: 'rejected' }
              })
            )
          : '';
        tab === 2
          ? dispatch(
              getApprovedInvoices({
                role: userRole(user_role),
                type: 'all',
                payload: { ...payload, status: 'proposed' }
              })
            )
          : '';
      }
    }
    if (user_role === 'SBU Head') {
      if (
        filterValues?.BU_name !== 'All' &&
        filterValues?.BU_name !== undefined
      ) {
        let payload = {
          page,
          per_page: pageSize,
          BU_name: filterValues?.BU_name,
          status: 'All'
        };
        Object.keys(filterValues).forEach((item) => {
          if (filterValues[item] && filterValues[item] !== 'All') {
            payload[filterToPayload[item]] = filterValues[item];
          }
        });

        if (glView) {
          if (payload['invoice_date']) delete payload['invoice_date'];
        } else {
          if (payload['GL_date']) delete payload['GL_date'];
          payload['orderby_invoice_date'] = true;
        }
        tab === 4
          ? dispatch(
              getInvoices({ role: userRole(user_role), type: 'all', payload })
            )
          : '';
        tab === 1
          ? dispatch(
              getApprovedInvoices({
                role: userRole(user_role),
                type: 'all',
                payload: { ...payload, status: 'proposed' }
              })
            )
          : '';
        tab === 2
          ? dispatch(
              getRatifiedInvoices({
                role: userRole(user_role),
                type: 'all',
                payload: { ...payload, status: 'ratified' }
              })
            )
          : '';

        tab === 3
          ? dispatch(
              getPaidInvoices({
                role: userRole(user_role),
                type: 'all',
                payload: { ...payload, status: 'paid' }
              })
            )
          : '';
      }
    
    }
    if (user_role === 'Treasury') {
      let payload = {
        page,
        per_page: pageSize,
        SBU_name: filterValues?.SBU_name,
        status: 'All'
      };
      Object.keys(filterValues).forEach((item) => {
        if (filterValues[item] && filterValues[item] !== 'All') {
          payload[filterToPayload[item]] = filterValues[item];
        }
      });

      if (glView) {
        if (payload['invoice_date']) delete payload['invoice_date'];
      } else {
        if (payload['GL_date']) delete payload['GL_date'];
        payload['orderby_invoice_date'] = true;
      }
      tab === 4
        ? dispatch(
            getInvoices({ role: userRole(user_role), type: 'all', payload })
          )
        : '';
      tab === 1
        ? dispatch(
            getRatifiedInvoices({
              role: userRole(user_role),
              type: 'all',
              payload: { ...payload, status: 'ratified' }
            })
          )
        : '';
      tab === 2
        ? dispatch(
            getAllocatedInvoices({
              role: userRole(user_role),
              type: 'all',
              payload: { ...payload, status: 'allocated' }
            })
          )
        : '';
      tab === 3
        ? dispatch(
            getPaidInvoices({
              role: userRole(user_role),
              type: 'all',
              payload: { ...payload, status: 'paid' }
            })
          )
        : '';
    }
  };

  const handleFiltersOnChange = (slug, value) => {
    console.log('slug', slug);
    console.log('value', value);
    setTriggerFilter(true);
    setFilterValues({
      ...filterValues,
      [slug]: value
    });
    setOpenDropdown(!openDropdown);
  };

  const toggleGlView = () => {
    setTriggerFilter(true);
    setGlView(!glView);
  };

  const handleGetActiveBankList = (payload) => {
    dispatch(getActiveBankList(payload));
  };

  const handlePostAllocationRequest = (role, payload) => {
    if (user_role === 'Project Engineer') {
      dispatch(postAllocationRequest({ role, payload })).then(() => {
        setOpenReqAllocModal(false);
        handleGetInvoices();
        handleNormalViewCount();
      });
    } else if (user_role === 'SBU Commercial') {
      dispatch(PostBuRequestFundAllocation({ role, payload })).then(() => {
        setOpenReqAllocModal(false);
        handleGetInvoices();
        handleNormalViewCount();
      });
    } else if (user_role === 'SBU Head') {
      dispatch(PostBuRequestFundAllocation({ role, payload })).then(() => {
        setOpenReqAllocModal(false);
        handleGetInvoices();
        handleNormalViewCount();
      });
    } else if (user_role === 'Treasury') {
      dispatch(PostBuRequestFundAllocation({ role, payload })).then(() => {
        setOpenReqAllocModal(false);
        handleGetInvoices();
        handleNormalViewCount();
      });
    }
  };
  const handlePostRejectRequest = (role, payload) => {
    if (user_role === 'SBU Commercial') {
      dispatch(postSendBackAllocRequestInvoiceWise({ role, payload })).then(
        () => {
          setOpenReqRejectModal(false);
          handleGetInvoices();
          handleNormalViewCount();
        }
      );
    } else if (user_role === 'SBU Head') {
      dispatch(postSendBackAllocRequestInvoiceWise({ role, payload })).then(
        () => {
          setOpenReqRejectModal(false);
          handleGetInvoices();
          handleNormalViewCount();
        }
      );
    } else if (user_role === 'Treasury') {
      dispatch(postSendBackAllocRequestInvoiceWise({ role, payload })).then(
        () => {
          setOpenReqRejectModal(false);
          handleGetInvoices();
          handleNormalViewCount();
        }
      );
    }
  };

  const handleTabOnChange = (tab) => {
    setTab(tab);
  };
 */

  return (
    <Layout>
      <InboundFailedListPage
        // loading={loading()}
        // invoices={invoices()}
        //pagination={pagination()}
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        //filterValues={filterValues}
        //glView={glView}
        // toggleGlView={toggleGlView}
        // reqAllocInvoiceKeys={reqAllocInvoiceKeys}
        // setReqAllocInvoiceKeys={setReqAllocInvoiceKeys}
        // reqAllocInvoices={reqAllocInvoices}
        // setReqAllocInvoices={setReqAllocInvoices}
        // vendorList={vendorList}
        // openReqAllocModal={openReqAllocModal}
        // setOpenReqAllocModal={setOpenReqAllocModal}
        // activeBankList={activeBankList}
        // handleGetActiveBankList={handleGetActiveBankList}
        // postAllocationRequestLoading={postAllocationRequestLoading}
        // handlePostAllocationRequest={handlePostAllocationRequest}
        // handleFiltersOnChange={handleFiltersOnChange}
        // tab={tab}
        // handleTabOnChange={handleTabOnChange}
        // allInvoicesPagination={allInvoicesPagination}
        // openInvoicesPagination={openInvoicesPagination}
        // paidInvoicesPagination={paidInvoicesPagination}
        // pendingInvoicesPagination={pendingInvoicesPagination}
        // rejectedInvoicesPagination={rejectedInvoicesPagination}
        // allocatedInvoicesPagination={allocatedInvoicesPagination}
        // approvedInvoicesPagination={approvedInvoicesPagination}
        // ratifiedInvoicesPagination={ratifiedInvoicesPagination}
        // user_role={user_role}
        // projectList={projectList}
        // BUList={BUList}
        // SBUList={SBUList}
        // activeDebitBankList={activeDebitBankList}
        // sendBackAllocRequestHandler={sendBackAllocRequestHandler}
        // getExportPaidInvoicesHandler={getExportPaidInvoicesHandler}
        // vendorSearch={vendorSearch}
        // setVendorSearch={setVendorSearch}
        // setProjectSearch={setProjectSearch}
        // projectSearch={projectSearch}
        // all={all}
        // initiated={initiated}
        // open={open}
        // paid={paid}
        // rejected={rejected}
        // proposed={proposed}
        // ratified={ratified}
        // allocated={allocated}
        // openExportPropModal={openExportPropModal}
        // setOpenExportPropModal={setOpenExportPropModal}
        // openExportInitiatedModal={openExportInitiatedModal}
        // setOpenExportInitiatedModal={setOpenExportInitiatedModal}
        // setOpenExportRatifiedModal={setOpenExportRatifiedModal}
        // openExportRatifiedModal={openExportRatifiedModal}
        // setOpenExportAllocatedModal={setOpenExportAllocatedModal}
        // openExportAllocatedModal={openExportAllocatedModal}
        // setOpenExportJourneyModal={setOpenExportJourneyModal}
        // openExportJourneyModal={openExportJourneyModal}
        // setOpenLiabilityNotAvailableModal={setOpenLiabilityNotAvailableModal}
        // openLiabilityNotAvailableModal={openLiabilityNotAvailableModal}
        // handlePostRejectRequest={handlePostRejectRequest}
        // openReqRejectModal={openReqRejectModal}
        // setOpenReqRejectModal={setOpenReqRejectModal}
        // postSendBackAllocRequestInvoiceWiseLoading={
        //   postSendBackAllocRequestInvoiceWiseLoading
        // }
        // getTLReportsHandler={getTLReportsHandler}
        // lodingTLReport={lodingTLReport}
        inboundFailedList={inboundFailedList}
        getInboundFailedListLoading={getInboundFailedListLoading}
        paginationInboundFailedList={paginationInboundFailedList}
        user_role={user_role}
        retryHandler={retryHandler}
        postRetryInboundLoading={postRetryInboundLoading}
      />
    </Layout>
  );
}
export default InboundFailedList;
