import React, { useState, useEffect } from 'react';
import { Pagination, Spin } from 'antd';
import styles from './index.module.less';
import CustomButton from 'common/CustomButton';
import Header from 'common/Content/header';
import { history } from 'app/history';
import Layout from 'common/Layout/index';
import SearchBarProjectWise from 'common/Content/searchBarProjectWise';
import { getVendorWise } from '../../../redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import TreasuryProjectListPage from './TreasuryProjectListPage';
import emptyState from 'assets/images/empty_state.svg';
import { useParams } from 'react-router-dom';
import { getLoggedInUser } from '../../../../../utilities/helpers';

const TreasuryProjectList = () => {
  const user_role1 = getLoggedInUser();
  const user_role = user_role1?.user_details?.role;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [searchProject, setSearchProject] = useState('');

  const dispatch = useDispatch();
  const params = useParams();
  const projectPayload = params?.id;
  const { vendorWiseList, getVendorWiseLoading, vendorWisePagination } =
    useSelector((state) => state.invoices);

  const getTreasuryProjectWiseHandler = () => {
    let payload = {
      SBU_name: projectPayload,
      page: page,
      per_page: pageSize
    };
    if (searchProject) payload['search_tds_project'] = searchProject;
    dispatch(
      getVendorWise({
        role: 'Treasury',
        type1: 'summary',
        type2: 'projectwise',
        payload
      })
    );
  };

  useEffect(() => {
    getTreasuryProjectWiseHandler();
  }, [projectPayload, page, pageSize]);

  useEffect(() => {
    if (triggerFilter) {
      if (page === 1) {
        getTreasuryProjectWiseHandler();
      } else {
        setPage(1);
      }
    }
  }, [searchProject, triggerFilter]);

  const handleFiltersOnChange = (slug, value) => {
    console.log('slug', slug); //don't delete ''slug''
    //  console.log('value', value);
    setTriggerFilter(true);
    setSearchProject(value);
  };
  return (
    <Layout>
      <div className={styles.invoices_summary_container}>
        <Header
          justify="space-between"
          align="middle"
          title="Treasury Summary View"
          titleSuffix={`${vendorWiseList[0]?.SBU_name}`}
          rightComponent={
            <CustomButton
              type="default"
              onClick={() => history.go(-1)} //history.push('/invoices')
            >
              Back to Summary View
            </CustomButton>
          }
          className="mb-3"
        />

        <SearchBarProjectWise
          justify="start"
          align="middle"
          className="mb-4"
          gutter={32}
          inputProps={{
            placeholder: 'Search by project name'
          }}
          handleFiltersOnChange={handleFiltersOnChange}
          user_role={user_role}
        />

        <Spin spinning={getVendorWiseLoading}>
          <div className="mb-3" style={{ marginTop: '25px' }}>
            {vendorWiseList?.length > 0 ? (
              vendorWiseList?.map((item, index) => {
                return (
                  <div key={index}>
                    {console.log('item:', item)}
                    <TreasuryProjectListPage
                      data={item}
                      loading={getVendorWiseLoading}
                    />
                  </div>
                );
              })
            ) : (
              <span style={{ display: 'flex', justifyContent: 'center' }}>
                <img alt="users_empty_state" src={emptyState} />
                <div className={styles.empty_state_text}>No Invoices</div>
              </span>
            )}
          </div>
          <Pagination
            size="small"
            current={page}
            pageSize={pageSize}
            total={vendorWisePagination?.total_records || 0}
            onChange={(page, pageSize) => {
              setPage(page);
              setPageSize(pageSize);
            }}
            className="text-right"
          />
        </Spin>
      </div>
    </Layout>
  );
};

export default TreasuryProjectList;
