import React from 'react';
import { Space, Switch, Typography } from 'antd';
import styles from './index.module.less';

const { Text } = Typography;

const Switcher = ({ left, right, onChange }) => {
  return (
    <div className={styles.switcher_container}>
      <Space size="middle">
        <Text className="sfprotext-medium font-15">{left}</Text>
        <Switch onChange={onChange} />
        <Text className="sfprotext-medium font-15">{right}</Text>
      </Space>
    </div>
  );
};

export default Switcher;
