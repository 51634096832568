import React from 'react';
import { Image, Tag, Tooltip, Spin } from 'antd';
import styles from './index.module.less';
import emptyState from 'assets/images/empty_state.svg';
import Header from 'common/Content/header';
//import SearchBarDashboard from 'common/Content/searchBarDashboard';
import CustomTable from 'common/Content/CustomTable';
import SubHeader from 'common/Content/SubHeaderInboundFailedList';
//import CustomButton from 'common/CustomButton';
//import Switcher from '../Switcher';InfoCircleOutlined
import { InfoCircleOutlined } from '@ant-design/icons';
import {
  //   ageFilters,
  //   statusFilters,
  numFormating,
  numIntStyleFormat
} from 'utilities/helpers';
// import ReqAllocModal from '../ReqAllocModal';
import moment from 'moment';
// import { history } from 'app/history';
// import ExportProposedInvoices from './exportProposedInvoices/index';
// import ExportInitiatedInvoices from './exportInitiatedInvoices';
// import ExportRatifiedInvoices from './exportRatifiedInvoices';
// import ExportAllocatedInvoices from './exportAllocatedInvoices';
// import ExportInvoicesJourney from './exportInvoicesJourney';
// import LiabilityNotAvailableModal from './if_liability_not_available';
// import ReqRejectModal from '../ReqRejectModal';

//const { Link } = Typography;

const InboundFailedListPage = ({
  //   loading,
  //   invoices = [],
  //pagination,
  //filterValues,
  page,
  setPage,
  // pageSize,
  setPageSize,
  //glView,
  //toggleGlView,
  //   reqAllocInvoiceKeys,
  //   setReqAllocInvoiceKeys,
  //   // reqAllocInvoices,
  //   setReqAllocInvoices,
  // vendorList,
  //   openReqAllocModal,
  //   setOpenReqAllocModal,
  //   activeBankList,
  //   handleGetActiveBankList,
  //   postAllocationRequestLoading,
  //   handlePostAllocationRequest,
  //   handleFiltersOnChange,
  //   tab,
  //handleTabOnChange,
  //allInvoicesPagination,
  //openInvoicesPagination,
  //paidInvoicesPagination,
  //pendingInvoicesPagination,
  // rejectedInvoicesPagination,
  // allocatedInvoicesPagination,
  // approvedInvoicesPagination,
  // ratifiedInvoicesPagination,
  user_role,
  // projectList,
  //projectAssigned,
  // BU_assigned,
  //   BUList,
  //   SBUList,
  //   activeDebitBankList,
  //   sendBackAllocRequestHandler,
  //   getExportPaidInvoicesHandler,
  //vendorSearch,
  //   setVendorSearch,
  //   setProjectSearch,
  //   all,
  //   initiated,
  //   open,
  //   paid,
  //   rejected,
  //   proposed,
  //ratified,
  //   allocated,
  //   openExportPropModal,
  //   setOpenExportPropModal,
  //   openExportInitiatedModal,
  //   setOpenExportInitiatedModal,
  //   setOpenExportRatifiedModal,
  //   openExportRatifiedModal,
  //   setOpenExportAllocatedModal,
  //   openExportAllocatedModal,
  //   setOpenExportJourneyModal,
  //   openExportJourneyModal,
  //   setOpenLiabilityNotAvailableModal,
  //   openLiabilityNotAvailableModal,
  //   handlePostRejectRequest,
  //   setOpenReqRejectModal,
  //   openReqRejectModal,
  //   postSendBackAllocRequestInvoiceWiseLoading,
  //   getTLReportsHandler,
  //lodingTLReport,
  inboundFailedList,
  getInboundFailedListLoading,
  paginationInboundFailedList,
  retryHandler,
  postRetryInboundLoading
}) => {
  // console.log('invoices_Next>>>>:', invoices);

  const columns = [
    {
      //title: 'PROJECT CODE',
      title: (
        <Tooltip
          title="PROJECT CODE"
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          PROJECT CODE
        </Tooltip>
      ),
      dataIndex: 'tds_project_code',
      key: 'tds_project_code',
      width: '100px',
      sorter: (a, b) => a.tds_project_code - b.tds_project_code,
      ellipsis: {
        showTitle: false
      },
      render: (tds_project_code) => {
        return (
          <>
            <Tooltip
              title={tds_project_code}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {tds_project_code}
            </Tooltip>
          </>
        );
      }
    },
    {
      // title: 'VENDOR',
      title: <span>VENDOR</span>,
      dataIndex: 'vendor_name',
      key: 'vendor_name',
      sorter: (a, b) => a.vendor_name.localeCompare(b.vendor_name),
      ellipsis: true,
      // width: '200px',
      render: (vendor_name) => {
        return (
          <>
            <Tooltip
              title={vendor_name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
              placement="top"
            >
              <span style={{ textTransform: 'uppercase' }}>{vendor_name}</span>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'INVOICE NUMBER', //style={{backgroundColor:"white !important", color:"black"}}
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      sorter: (a, b) => a.invoice_number.localeCompare(b.invoice_number),
      ellipsis: {
        showTitle: false
      },
      //  width: '200px',
      render: (invoice_number) => {
        return (
          <>
            <Tooltip
              title={<a>{invoice_number}</a>}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
              placement="top"
            >
              <a>{invoice_number}</a>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'GL DATE',
      dataIndex: 'GL_date',
      key: 'GL_date',
      ellipsis: true,
      width: '125px',
      sorter: (a, b) => moment(a?.GL_date).unix() - moment(b?.GL_date).unix()
    },

    {
      title: 'CLEAR PAYABLE',
      dataIndex: 'amount',
      key: 'amount',
      sorter: (a, b) => Number(a.amount) - Number(b.amount),
      ellipsis: {
        showTitle: false
      },
      width: '125px',
      render: (amount) => {
        return (
          <>
            <Tooltip
              title={
                <span style={{ fontWeight: 'bold' }}>
                  {numIntStyleFormat(amount)}
                </span>
              }
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span style={{ fontWeight: 'bold' }}>
                {amount >= 0 ? (
                  <span>₹{numFormating(amount)}</span>
                ) : (
                  <span>-₹{numFormating(amount)}</span>
                )}
              </span>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'AGEING',
      dataIndex: 'ageing',
      key: 'ageing',
      width: '115px',
      sorter: (a, b) =>
        Number(a.ageing.replace(` days`, '')) -
        Number(b.ageing.replace(` days`, ''))
    },
    {
      //title: 'ALLOCATION STATUS',
      title: (
        <Tooltip
          title="ALLOCATION STATUS"
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          ALLOCATION STATUS
        </Tooltip>
      ),
      dataIndex: 'status',
      key: 'status',
      width: '130px',
      ellipsis: {
        showTitle: false
      },
      render: (status) => {
        return (
          <Tag
            className={`${styles.status_tag}`}
            color={'#EAF5FF'}
            style={{
              color: '#006096',
              textTransform: 'capitalize',
              fontSize: '10.5px',
              fontWeight: 400
              // lineHeight: '16px'
            }}
          >
            <span style={{ textAlign: 'center' }}>{status}</span>
          </Tag>
        );
      }
    },
    {
      title: (
        <Tooltip
          title="REASON FOR FAILURE"
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          REASON FOR FAILURE
        </Tooltip>
      ),
      dataIndex: 'inbound_remarks',
      key: 'inbound_remarks',
      width: '130px',
      ellipsis: {
        showTitle: false
      },
      render: (inbound_remarks) => {
        return (
          <Tooltip
            title={inbound_remarks}
            color={'white'}
            overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
            arrowPointAtCenter="false"
            width={'300px'}
          >
            <InfoCircleOutlined
              style={{ fontSize: '16px', color: '#4059e5' }}
            />
          </Tooltip>
        );
      }
    }
    /*  {
      title: 'REASON FOR FAILURE',
      key: 'inbound_remarks'
      //ellipsis: true,
      //width: '130px'
       render: (record) => (
        <Button
          className={`${styles.allocation_btn}`}
          style={{
            fontSize: '11px'
          }}
          type="link"
          disabled={
            user_role === 'SBU Commercial'
              ? record.status === 'proposed' ||
                record.status === 'ratified' ||
                record.status === 'allocated' ||
                record.status === 'Disbursed' ||
                record.status === 'Ratified' ||
                record.status === 'paid'
              : user_role === 'SBU Head'
              ? record.status === 'initiated' ||
                record.status === 'ratified' ||
                record.status === 'allocated' ||
                record.status === 'Disbursed' ||
                record.status === 'Ratified' ||
                record.status === 'paid'
              : user_role === 'Treasury'
              ? record.status === 'proposed' ||
                record.status === 'allocated' ||
                record.status === 'Disbursed' ||
                record.status === 'initiated' ||
                record.status === 'paid'
              : ''
          }
          onClick={() => {
            setOpenReqRejectModal(!openReqRejectModal);
          }}
        >
          Request reject
        </Button>
      )
    } */
  ];
  let locale = {
    emptyText: (
      <span>
        <Image alt="users_empty_state" src={emptyState} preview={false} />
        <div className={styles.empty_state_text}>No Invoices</div>
      </span>
    )
  };

  /*  const rowSelection = {
    selectedRowKeys: reqAllocInvoiceKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setReqAllocInvoiceKeys(selectedRowKeys);
      setReqAllocInvoices(selectedRows);
    }
    
  }; */

  return (
    <Spin spinning={getInboundFailedListLoading}>
      <div className={styles.invoices_container}>
        <Header
          justify="space-between"
          align="middle"
          title="Inbound Failed List"
          titleSuffix=""
          /*  rightComponent={
          <Row>
            
          </Row>
        } */
          className="mb-4"
        />

        {user_role === 'Treasury' && (
          <Spin spinning={postRetryInboundLoading}>
            <SubHeader
              justify="space-between"
              align="middle"
              tabs={[
                {
                  id: 1,
                  label: 'Total Failed Records',
                  count: paginationInboundFailedList?.total_records || 0 //ratifiedInvoicesPagination?.total_records || 0
                }
              ]}
              onTabChange={''}
              buttons={[
                {
                  type: 'primary',
                  text: 'Retry',
                  onClick: () => {
                    retryHandler();
                  } // history.push('/summary')
                }
              ]}
            />
          </Spin>
        )}
        <div className={`${styles.invoices_table_container}`}>
          {/*  {user_role === 'Treasury' ? (
          <SearchBarDashboard
            justify="start"
            align="middle"
            className="mb-4"
            gutter={32}
            inputProps={{
              placeholder: 'Search using invoice number/vendor name'
            }}
            filters={[
              {
                label: 'SBU',
                slug: 'SBU_name',
                options: SBUList,
                type: 'select'
              },
              {
                label: 'BU',
                slug: 'BU_name',
                options: BUList,
                type: 'select'
              },
              {
                label: 'Project',
                slug: 'tds_project_code',
                options: projectList,
                type: 'select'
              },
              {
                label: 'Vendor',
                slug: 'vendor',
                options: vendorList,
                type: 'select'
              },
              {
                label: 'Age',
                slug: 'age',
                options: ageFilters
              },
              {
                label: `${glView ? 'GL' : 'Invoice'} date`,
                slug: `${glView ? 'gl' : 'invoice'}_date`,
                type: 'date'
              },

              tab === 5
                ? {
                    label: 'Status',
                    slug: 'status',
                    options: statusFilters
                  }
                : null
            ]}
            filterValues={filterValues}
            handleFiltersOnChange={handleFiltersOnChange}
            user_role={user_role}
            setVendorSearch={setVendorSearch}
            setProjectSearch={setProjectSearch}
          />
        ) : null} */}

          <CustomTable
            data={inboundFailedList}
            columns={columns}
            locale={locale}
            //loading={getInboundFailedListLoading}
            //rowSelection={rowSelection}
            pagination={{
              current: page,
              size: 'small',
              showSizeChanger: true,
              total: paginationInboundFailedList?.total_records || 0,
              pageSize: paginationInboundFailedList?.total_records || 0, // pageSize,
              onChange: (page, pageSize) => {
                setPage(page);
                setPageSize(pageSize);
              }
            }}
          />
        </div>
      </div>
    </Spin>
  );
};

export default InboundFailedListPage;
