import LocalStorage from './localStorage';
import moment from 'moment';

export const getLoggedInUser = () => {
  const loginUser = LocalStorage.getItem('A2P_user') || '';
  const user = loginUser || { user: null };
  return {
    ...user,
    loggedIn: loginUser !== ''
  };
};

export const invoiceFilters = {
  vendor: 'All',
  // tds_project_code: 'All',
  BU_name: 'All',
  SBU_name: 'All',
  age: 'All',
  gl_date: 'All',
  invoice_date: 'All',
  status: 'All',
  search_invoice: '',
  search_vendor: '',
  search_tds_project: '',
  is_H2H: '',
  invoice_type: 'non_H2H',
  search_SBU: ''
};

export const filterToPayload = {
  vendor: 'vendor_code',
  tds_project_code: 'tds_project_code',
  BU_name: 'BU_name',
  SBU_name: 'SBU_name',
  age: 'age',
  gl_date: 'GL_date',
  invoice_date: 'invoice_date',
  status: 'status',
  search_invoice: 'search_invoice',
  search_vendor: 'search_vendor',
  search_tds_project: 'search_tds_project',
  is_H2H: 'is_H2H',
  invoice_type: 'invoice_type',
  search_SBU: 'search_SBU'
};

export const ageFilters = [
  { label: 'All', value: 'All' },
  { label: 'Days 0-30', value: 'days_0_30' },
  { label: 'Days 31-60', value: 'days_31_60' },
  { label: 'Days 61-90', value: 'days_61_90' },
  { label: 'Days above 90', value: 'days_above_90' }
];

export const statusFilters = [
  { label: 'All', value: 'All' },
  { label: 'Open', value: 'open' },
  { label: 'Initiated', value: 'initiated' },
  { label: 'Proposed', value: 'proposed' },
  { label: 'Allocated', value: 'allocated' },
  { label: 'Rejected', value: 'rejected' },
  { label: 'Paid', value: 'paid' },
  { label: 'Disbursed', value: 'disbursed' },
  { label: 'Ratified', value: 'ratified' }
];

export const invoiceType = [
  { label: 'Non-H2H', value: 'non_H2H' },
  { label: 'Foreign', value: 'foreign' }
];

export const liabilityToLabel = {
  total_liability: 'Liability',
  liability_non_H2H: 'Non-H2H',
  liability_0_30_days: '0 - 30 days',
  liability_31_60_days: '30 - 60 days',
  liability_61_90_days: '60 - 90 days',
  liability_above_90_days: 'Age > 90 days'
};

export const liabilityToLabelBU = {
  liability: 'Liability',
  liability_non_H2H: 'Non-H2H',
  liability_0_30_days: '0 - 30 days',
  liability_31_60_days: '30 - 60 days',
  liability_61_90_days: '60 - 90 days',
  liability_above_90_days: 'Age > 90 days'
};

export const liabilityToLabelTreasury = {
  total_liability: 'Liability',
  liability_non_H2H: 'Non-H2H',
  liability_0_30_days: '0 - 30 days',
  liability_31_60_days: '30 - 60 days',
  liability_61_90_days: '60 - 90 days',
  liability_above_90_days: 'Age > 90 days'
};

export const liabilityToLabelVendorList = {
  total_liability: 'Liability',
  liability_non_H2H: 'Non-H2H',
  liability_0_30_days: '0 - 30 days',
  liability_31_60_days: '30 - 60 days',
  liability_61_90_days: '60 - 90 days',
  liability_above_90_days: 'Age > 90 days'
};

export const userRole = (role) => {
  switch (role) {
    case 'Project Engineer':
      return 'PE';
    case 'SBU Commercial':
      return 'SBUC';
    case 'SBU Head':
      return 'SBUHead';
    case 'Treasury':
      return 'Treasury';
    case 'Finance CoE':
      return 'Finance';
    default:
      return '';
  }
};

export const title = (role) => {
  switch (role) {
    case 'Project Engineer':
      return 'Vendor Summary View';
    case 'SBU Commercial':
      return 'SBU Summary View';
    case 'SBU Head':
      return 'SBU Head Summary View';
    case 'Treasury':
      return 'Treasury Summary View';
    case 'Finance CoE':
      return 'Finance CoE Summary View';
    default:
      return '';
  }
};

export function convertDecimal2Place(n) {
  var isDecimal = n - Math.floor(n) !== 0;
  if (isDecimal) {
    n = n?.toString();
    n = n?.slice(0, n?.indexOf('.') + 3);
    n = parseFloat(n);
    return n;
  } else {
    return n;
  }
}
//parseInt() and parseFloat()
export function numFormating(value) {
  var val = Math.abs(value);
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(2) + ' Cr';
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(2) + ' Lac';
  } else if (val >= 1000) {
    val = Math.abs(val)?.toLocaleString(undefined, {
      maximumFractionDigits: 2
    });
  }
  return val;
}

export function numIntStyleFormat(value) {
  value =
    value === undefined
      ? ''
      : value >= 0
      ? value === undefined
        ? ''
        : ` ₹${value?.toLocaleString(undefined, { maximumFractionDigits: 2 })}`
      : value === undefined
      ? ''
      : `-₹${Math.abs(value)?.toLocaleString(undefined, {
          maximumFractionDigits: 2
        })}`;
  return value;
}
//Debounce...................
export function Debounce(func, delay) {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, delay);
  };
}

const Financial_Year_Date = (current_monthYear) => {
  switch (current_monthYear) {
    case '01':
      return moment().subtract(11, 'months').format('MMM YYYY');
    case '02':
      return moment().subtract(10, 'months').format('MMM YYYY');
    case '03':
      return moment().subtract(9, 'months').format('MMM YYYY');
    case '04':
      return moment().subtract(8, 'months').format('MMM YYYY');
    case '05':
      return moment().subtract(7, 'months').format('MMM YYYY');
    case '06':
      return moment().subtract(6, 'months').format('MMM YYYY');
    case '07':
      return moment().subtract(5, 'months').format('MMM YYYY');
    case '08':
      return moment().subtract(4, 'months').format('MMM YYYY');
    case '09':
      return moment().subtract(2, 'months').format('MMM YYYY');
    case '10':
      return moment().subtract(1, 'months').format('MMM YYYY');
    case '11':
      return moment().subtract(12, 'months').format('MMM YYYY');
    default:
      return moment().subtract(0, 'months').format('MMM YYYY');
  }
};

export const dateRangeDashboard = [
  {
    label: 'Financial Year To Date',
    value: `01 ${Financial_Year_Date(moment().format('MM'))}`
  },
  {
    label: 'Quarter To Date',
    value: `01 ${moment().subtract(3, 'months').format('MMM YYYY')}`
  },
  { label: 'Month To Date', value: `01 ${moment().format('MMM YYYY')}` }
];
//console.log('mmmmmmm', moment().subtract(11, 'months').format('MMM YYYY'));

export const TabTitle = (newTitle) => {
  return (document.title = newTitle);
};

// function areEqual(a, b, tolerance = 0.0001) {
//   return Math.abs(a - b) <= tolerance;
// }

// const number1 = 0.1 + 0.2;
// const number2 = 0.3;

// const isEqual = areEqual(number1, number2);

// console.log(
//   '############',
//   isEqual ? 'Numbers are equal' : 'Numbers are not equal',
//   isEqual
// );
//---------------------------------------------------------------
//   function compareFloatsGreaterWithTolerance(a, b, tolerance) {
//     const diff = a - b;
//     return diff > tolerance ? a : diff < -tolerance ? b : null;
//   }

//   // Usage
//   const float1 = 2.2345;
//   const float2 = 2.2346;
//   const tolerance = 0.0001;

//   const greaterFloat = compareFloatsGreaterWithTolerance(
//     float1,
//     float2,
//     tolerance
//   );
// console.log("greaterFloat",greaterFloat)
//   if (greaterFloat !== null) {
//     console.log(`${greaterFloat} is greater with tolerance.`);
//   } else {
//     console.log('The float numbers are within the tolerance or equal.');
//   }
