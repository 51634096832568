import React from 'react';
import Header from 'common/Content/header';
import CustomButton from 'common/CustomButton';
import styles from './index.module.less';
import { history } from 'app/history';
import { Pagination, Spin, Alert } from 'antd';
import SummaryInvoiceItem from '../SummaryInvoiceItem';
import BuSummaryInvoiceItem from '../BuSummaryInvoiceItem';
import TreasurySummaryInvoiceItem from '../treasury/TreasurySummaryInvoiceItem';
import FinanceCoESummaryInvoiceItem from '../FinanceCoE/component/FinanceCoESummaryInvoiceItem/index';
import SearchBar from 'common/Content/searchBar';
import { title, numFormating } from '../../../../utilities/helpers';
//import SearchBarDashboard from '@/common/Content/searchBarDashboard';
import emptyState from 'assets/images/empty_state.svg';
import BuHeadSummaryInvoiceItem from '../BuHeadSummaryInvoiceItem';
import ViewInitiatedAmount from './viewInitiatedAmount/index';
import moment from 'moment';

const InvoicesSummaryPage = ({
  loading,
  invoices = [],
  pagination,
  page,
  setPage,
  pageSize,
  setPageSize,
  reqFund,
  setReqFund,
  openReqFundAllocModal,
  setOpenReqFundAllocModal,
  openReqAllocModal,
  setOpenReqAllocModal,
  //handleSearchVendor,
  handleRequestFund,
  selectedBankBranchName,
  setSelectedBankBranchName,
  postRequestFundLoading,
  requestFundInvoices,
  postAllocationRequestLoading,
  handlePostAllocationRequest,
  summaryInvoicesActiveBankList,
  clickSubmitInvoices,
  setClickSubmitInvoices,
  allInvoicesByAge,
  handleSubmitInvoices,
  user_role,
  handlePostBuRequestFundAllocation,
  liabilityAmount,
  setOpenCheckbox,
  openCheckbox,
  projectList,
  filterValues,
  handleFiltersOnChange,
  BUList,
  vendorList,
  handleFiltersOnChangeSerchInModal,
  invoicesByAgeLoading,
  activeDebitBankList,
  invoiceType,
  setVendorSearch,
  setProjectSearch,
  setViewInitAmountModal,
  viewInitAmountModal,
  month_to_date_my_initiated,
  month_to_date_my_proposed,
  month_to_date_my_ratified,
  month_to_date_my_allocated,
  month_to_date_my_disbursed
}) => {
  //console.log('month_to_date_my_initiated', month_to_date_my_proposed);
  return (
    <div className={styles.invoices_summary_container}>
      <Header
        justify="space-between"
        align="middle"
        title={title(user_role)}
        titleSuffix=""
        rightComponent={
          <CustomButton
            type="default"
            onClick={() =>
              user_role === 'Finance CoE'
                ? history.push('/financeCoE')
                : history.push('/invoices')
            }
          >
            Switch to Normal View
          </CustomButton>
        }
        className="mb-3"
      />

      {user_role === 'Project Engineer' ? (
        <SearchBar
          justify="start"
          align="middle"
          className="mb-4"
          gutter={32}
          inputProps={{
            placeholder: 'Search by vendor name'
          }}
          filters={[
            {
              label: 'Project',
              slug: 'tds_project_code',
              options: projectList,
              type: 'select'
            },
            {
              label: 'Vendor',
              slug: 'vendor',
              options: vendorList,
              type: 'select'
            }
          ]}
          filterValues={filterValues}
          //handleSearchVendor={handleSearchVendor}
          handleFiltersOnChange={handleFiltersOnChange}
          user_role={user_role}
          setVendorSearch={setVendorSearch}
          setProjectSearch={setProjectSearch}
          rightComponent={
            <Alert
              type="warning"
              message={`₹${
                month_to_date_my_initiated
                  ? numFormating(month_to_date_my_initiated)
                  : 0
              } initiated since 1 ${moment().format(
                'MMM'
              )}${', '}${moment().format('YYYY')}`}
              showIcon
              style={{ lineHeight: '1', fontSize: '13px' }}
              // style={{backgroundColor:'#fffbe6',border: '1px solid #ffe58f'}}
            />

            /* <CustomButton
              type="default"
              onClick={() =>setViewInitAmountModal(true)
              }
            >
              View Initiated Amount
            </CustomButton> */
          }
        />
      ) : user_role === 'SBU Commercial' ? (
        <SearchBar
          justify="start"
          align="middle"
          className="mb-4"
          gutter={32}
          inputProps={{
            placeholder: 'Search by project name'
          }}
          filters={[
            {
              label: 'BU',
              slug: 'BU_name',
              options: BUList,
              type: 'select'
            },
            {
              label: 'Project',
              slug: 'tds_project_code',
              options: projectList,
              type: 'select'
            }
          ]}
          filterValues={filterValues}
          //handleSearchVendor={handleSearchVendor}
          handleFiltersOnChange={handleFiltersOnChange}
          user_role={user_role}
          setVendorSearch={setVendorSearch}
          setProjectSearch={setProjectSearch}
          rightComponent={
            <Alert
              type="warning"
              message={`₹${
                month_to_date_my_proposed
                  ? numFormating(month_to_date_my_proposed)
                  : 0
              } proposed since 1 ${moment().format(
                'MMM'
              )}${', '}${moment().format('YYYY')}`}
              showIcon
              style={{ lineHeight: '1', fontSize: '13px' }}
            />
          }
        />
      ) : user_role === 'SBU Head' ? (
        <SearchBar
          justify="start"
          align="middle"
          className="mb-4"
          gutter={32}
          inputProps={{
            placeholder: 'Search by project name'
          }}
          filters={[
            {
              label: 'BU',
              slug: 'BU_name',
              options: BUList,
              type: 'select'
            },
            {
              label: 'Project',
              slug: 'tds_project_code',
              options: projectList,
              type: 'select'
            }
          ]}
          filterValues={filterValues}
          //handleSearchVendor={handleSearchVendor}
          handleFiltersOnChange={handleFiltersOnChange}
          user_role={user_role}
          setVendorSearch={setVendorSearch}
          setProjectSearch={setProjectSearch}
          rightComponent={
            <Alert
              type="warning"
              message={`₹${
                month_to_date_my_ratified
                  ? numFormating(month_to_date_my_ratified)
                  : 0
              } ratified since 1 ${moment().format(
                'MMM'
              )}${', '}${moment().format('YYYY')}`}
              showIcon
              style={{ lineHeight: '1', fontSize: '13px' }}
            />
          }
        />
      ) : user_role === 'Treasury' ? (
        <SearchBar
          justify="start"
          align="middle"
          className="mb-4"
          gutter={32}
          inputProps={{
            placeholder: 'Search by SBU name'
          }}
          filters={
            [
              /*  {
              label: 'Project',
              slug: 'tds_project_code',
              options: projectList,
              type: 'select'
            }, */
            ]
          }
          filterValues={filterValues}
          // handleSearchVendor={handleSearchVendor}
          handleFiltersOnChange={handleFiltersOnChange}
          user_role={user_role}
          setVendorSearch={setVendorSearch}
          //setProjectSearch={setProjectSearch}
          rightComponent={
            <Alert
              type="warning"
              message={`₹${
                month_to_date_my_allocated
                  ? numFormating(month_to_date_my_allocated)
                  : 0
              } allocated since 1 ${moment().format(
                'MMM'
              )}${', '}${moment().format('YYYY')}`}
              showIcon
              style={{ lineHeight: '1', fontSize: '13px' }}
            />
          }
        />
      ) : user_role === 'Finance CoE' ? (
        <SearchBar
          justify="start"
          align="middle"
          className="mb-4"
          gutter={32}
          inputProps={{
            placeholder: 'Search by vendor name'
          }}
          filters={[
            {
              label: 'Invoice Type',
              slug: 'invoice_type',
              options: invoiceType,
              type: 'select'
            },
            {
              label: 'BU',
              slug: 'BU_name',
              options: BUList,
              type: 'select'
            },
            {
              label: 'Project',
              slug: 'tds_project_code',
              options: projectList,
              type: 'select'
            },
            {
              label: 'Vendor',
              slug: 'vendor',
              options: vendorList,
              type: 'select'
            }
          ]}
          filterValues={filterValues}
          // handleSearchVendor={handleSearchVendor}
          handleFiltersOnChange={handleFiltersOnChange}
          user_role={user_role}
          setVendorSearch={setVendorSearch}
          setProjectSearch={setProjectSearch}
          rightComponent={
            <Alert
              type="warning"
              message={`₹${
                month_to_date_my_disbursed
                  ? numFormating(month_to_date_my_disbursed)
                  : 0
              } disbursed since 1 ${moment().format(
                'MMM'
              )}${', '}${moment().format('YYYY')}`}
              showIcon
              style={{ lineHeight: '1', fontSize: '13px' }}
            />
          }
        />
      ) : null}

      <Spin spinning={loading}>
        <div className="mb-3">
          {invoices?.length > 0 ? (
            invoices?.map((invoice, index) => {
              return (
                <div key={index}>
                  {user_role === 'Project Engineer' && (
                    <SummaryInvoiceItem
                      data={invoice}
                      reqFund={reqFund}
                      setReqFund={setReqFund}
                      openReqFundAllocModal={openReqFundAllocModal}
                      setOpenReqFundAllocModal={setOpenReqFundAllocModal}
                      handleRequestFund={handleRequestFund}
                      selectedBankBranchName={selectedBankBranchName}
                      setSelectedBankBranchName={setSelectedBankBranchName}
                      openReqAllocModal={openReqAllocModal}
                      setOpenReqAllocModal={setOpenReqAllocModal}
                      postRequestFundLoading={postRequestFundLoading}
                      requestFundInvoices={requestFundInvoices}
                      postAllocationRequestLoading={
                        postAllocationRequestLoading
                      }
                      handlePostAllocationRequest={handlePostAllocationRequest}
                      summaryInvoicesActiveBankList={
                        summaryInvoicesActiveBankList
                      }
                      clickSubmitInvoices={clickSubmitInvoices}
                      setClickSubmitInvoices={setClickSubmitInvoices}
                      allInvoicesByAge={allInvoicesByAge}
                      handleSubmitInvoices={handleSubmitInvoices}
                      user_role={user_role}
                      liabilityAmount={liabilityAmount}
                      setOpenCheckbox={setOpenCheckbox}
                      openCheckbox={openCheckbox}
                      handleFiltersOnChange={handleFiltersOnChange}
                      handleFiltersOnChangeSerchInModal={
                        handleFiltersOnChangeSerchInModal
                      }
                      invoicesByAgeLoading={invoicesByAgeLoading}
                    />
                  )}
                  {user_role === 'SBU Commercial' && (
                    <BuSummaryInvoiceItem
                      key={index}
                      data={invoice}
                      reqFund={reqFund}
                      setReqFund={setReqFund}
                      openReqFundAllocModal={openReqFundAllocModal}
                      setOpenReqFundAllocModal={setOpenReqFundAllocModal}
                      handleRequestFund={handleRequestFund}
                      selectedBankBranchName={selectedBankBranchName}
                      setSelectedBankBranchName={setSelectedBankBranchName}
                      openReqAllocModal={openReqAllocModal}
                      setOpenReqAllocModal={setOpenReqAllocModal}
                      postRequestFundLoading={postRequestFundLoading}
                      requestFundInvoices={requestFundInvoices}
                      postAllocationRequestLoading={
                        postAllocationRequestLoading
                      }
                      handlePostAllocationRequest={handlePostAllocationRequest}
                      summaryInvoicesActiveBankList={
                        summaryInvoicesActiveBankList
                      }
                      clickSubmitInvoices={clickSubmitInvoices}
                      setClickSubmitInvoices={setClickSubmitInvoices}
                      allInvoicesByAge={allInvoicesByAge}
                      handleSubmitInvoices={handleSubmitInvoices}
                      user_role={user_role}
                      handlePostBuRequestFundAllocation={
                        handlePostBuRequestFundAllocation
                      }
                      liabilityAmount={liabilityAmount}
                      setOpenCheckbox={setOpenCheckbox}
                      openCheckbox={openCheckbox}
                      handleFiltersOnChangeSerchInModal={
                        handleFiltersOnChangeSerchInModal
                      }
                    />
                  )}
                  {user_role === 'SBU Head' && (
                    <BuHeadSummaryInvoiceItem
                      key={index}
                      data={invoice}
                      reqFund={reqFund}
                      setReqFund={setReqFund}
                      openReqFundAllocModal={openReqFundAllocModal}
                      setOpenReqFundAllocModal={setOpenReqFundAllocModal}
                      handleRequestFund={handleRequestFund}
                      selectedBankBranchName={selectedBankBranchName}
                      setSelectedBankBranchName={setSelectedBankBranchName}
                      openReqAllocModal={openReqAllocModal}
                      setOpenReqAllocModal={setOpenReqAllocModal}
                      postRequestFundLoading={postRequestFundLoading}
                      requestFundInvoices={requestFundInvoices}
                      postAllocationRequestLoading={
                        postAllocationRequestLoading
                      }
                      handlePostAllocationRequest={handlePostAllocationRequest}
                      summaryInvoicesActiveBankList={
                        summaryInvoicesActiveBankList
                      }
                      clickSubmitInvoices={clickSubmitInvoices}
                      setClickSubmitInvoices={setClickSubmitInvoices}
                      allInvoicesByAge={allInvoicesByAge}
                      handleSubmitInvoices={handleSubmitInvoices}
                      user_role={user_role}
                      handlePostBuRequestFundAllocation={
                        handlePostBuRequestFundAllocation
                      }
                      liabilityAmount={liabilityAmount}
                      setOpenCheckbox={setOpenCheckbox}
                      openCheckbox={openCheckbox}
                      handleFiltersOnChangeSerchInModal={
                        handleFiltersOnChangeSerchInModal
                      }
                    />
                  )}
                  {user_role === 'Treasury' && (
                    <TreasurySummaryInvoiceItem
                      key={index}
                      data={invoice}
                      reqFund={reqFund}
                      setReqFund={setReqFund}
                      openReqFundAllocModal={openReqFundAllocModal}
                      setOpenReqFundAllocModal={setOpenReqFundAllocModal}
                      handleRequestFund={handleRequestFund}
                      selectedBankBranchName={selectedBankBranchName}
                      setSelectedBankBranchName={setSelectedBankBranchName}
                      openReqAllocModal={openReqAllocModal}
                      setOpenReqAllocModal={setOpenReqAllocModal}
                      postRequestFundLoading={postRequestFundLoading}
                      requestFundInvoices={requestFundInvoices}
                      postAllocationRequestLoading={
                        postAllocationRequestLoading
                      }
                      handlePostAllocationRequest={handlePostAllocationRequest}
                      summaryInvoicesActiveBankList={
                        summaryInvoicesActiveBankList
                      }
                      clickSubmitInvoices={clickSubmitInvoices}
                      setClickSubmitInvoices={setClickSubmitInvoices}
                      allInvoicesByAge={allInvoicesByAge}
                      handleSubmitInvoices={handleSubmitInvoices}
                      user_role={user_role}
                      handlePostBuRequestFundAllocation={
                        handlePostBuRequestFundAllocation
                      }
                      liabilityAmount={liabilityAmount}
                      handleFiltersOnChange={handleFiltersOnChange}
                      setOpenCheckbox={setOpenCheckbox}
                      openCheckbox={openCheckbox}
                      activeDebitBankList={activeDebitBankList}
                    />
                  )}
                  {user_role === 'Finance CoE' && (
                    <FinanceCoESummaryInvoiceItem
                      key={index}
                      data={invoice}
                      reqFund={reqFund}
                      setReqFund={setReqFund}
                      openReqFundAllocModal={openReqFundAllocModal}
                      setOpenReqFundAllocModal={setOpenReqFundAllocModal}
                      handleRequestFund={handleRequestFund}
                      selectedBankBranchName={selectedBankBranchName}
                      setSelectedBankBranchName={setSelectedBankBranchName}
                      openReqAllocModal={openReqAllocModal}
                      setOpenReqAllocModal={setOpenReqAllocModal}
                      postRequestFundLoading={postRequestFundLoading}
                      requestFundInvoices={requestFundInvoices}
                      postAllocationRequestLoading={
                        postAllocationRequestLoading
                      }
                      handlePostAllocationRequest={handlePostAllocationRequest}
                      summaryInvoicesActiveBankList={
                        summaryInvoicesActiveBankList
                      }
                      clickSubmitInvoices={clickSubmitInvoices}
                      setClickSubmitInvoices={setClickSubmitInvoices}
                      allInvoicesByAge={allInvoicesByAge}
                      handleSubmitInvoices={handleSubmitInvoices}
                      user_role={user_role}
                      handlePostBuRequestFundAllocation={
                        handlePostBuRequestFundAllocation
                      }
                      liabilityAmount={liabilityAmount}
                      handleFiltersOnChange={handleFiltersOnChange}
                      setOpenCheckbox={setOpenCheckbox}
                      openCheckbox={openCheckbox}
                      activeDebitBankList={activeDebitBankList}
                      filterValues={filterValues}
                    />
                  )}
                </div>
              );
            })
          ) : (
            <span style={{ display: 'flex', justifyContent: 'center' }}>
              <img alt="users_empty_state" src={emptyState} />
              <div className={styles.empty_state_text}>No Invoices</div>
            </span>
          )}
        </div>
        <Pagination
          size="small"
          current={page}
          pageSize={pageSize}
          total={pagination?.total_records || 0}
          onChange={(page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
          }}
          className="text-right"
        />
      </Spin>
      {viewInitAmountModal ? (
        <ViewInitiatedAmount
          viewInitAmountModal={viewInitAmountModal}
          setViewInitAmountModal={setViewInitAmountModal}
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default InvoicesSummaryPage;
