import React, { useState, useEffect } from 'react';
import { Pagination, Spin } from 'antd';
import styles from './index.module.less';
import CustomButton from 'common/CustomButton';
import Header from 'common/Content/header';
import { history } from 'app/history';
import Layout from 'common/Layout/index';
import SearchBarVendorWise from 'common/Content/searchBarVendorWise';
//import { CaretDownOutlined } from '@ant-design/icons';
import { getVendorWise } from '../../redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import BuVendorListPage from './BuVendorListPage';
import emptyState from 'assets/images/empty_state.svg';
import { useParams } from 'react-router-dom';
import { getLoggedInUser } from '../../../../utilities/helpers';

//const { Title, Text } = Typography;

const BuVendorList = () => {
  const user_role1 = getLoggedInUser();
  const user_role = user_role1?.user_details?.role;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [triggerFilter, setTriggerFilter] = useState(false);
  const [searchVendor, setSearchVendor] = useState('');

  const dispatch = useDispatch();
  const params = useParams();
  const vendorPayload = params?.id;

  const { vendorWiseList, getVendorWiseLoading, vendorWisePagination } =
    useSelector((state) => state.invoices);

  // console.log('vendorWisePagination', vendorWisePagination);

  function getVendorWiseHandler() {
    let pay = vendorPayload
      .replace('BU_name:', '')
      .replace('tds_project_code:', '');
    let pay1 = pay.split(',');
    let payload = {
      BU_name: pay1[0],
      tds_project_code: parseInt(pay1[1]),
      page: page,
      per_page: pageSize
    };
    if (searchVendor) payload['search_vendor'] = searchVendor;
    dispatch(
      getVendorWise({
        role: 'SBUC',
        type1: 'summary',
        type2: 'vendorwise',
        payload
      })
    );
  }
  useEffect(() => {
    getVendorWiseHandler();
  }, [vendorPayload, page, pageSize]);

  // console.log('vendorWiseList:', vendorWiseList);

  useEffect(() => {
    if (triggerFilter) {
      if (page === 1) {
        getVendorWiseHandler();
      } else {
        setPage(1);
      }
    }
  }, [searchVendor, triggerFilter]);

  const handleFiltersOnChange = (slug, value) => {
    console.log('slug', slug); //don't delete ''slug''
    //  console.log('value', value);
    setTriggerFilter(true);
    setSearchVendor(value);
  };
  return (
    <Layout>
      <div className={styles.invoices_summary_container}>
        <Header
          justify="space-between"
          align="middle"
          title="SBU Summary View "
          titleSuffix={`${vendorWiseList[0]?.tds_project_name}(${vendorWiseList[0]?.tds_project_code})`}
          rightComponent={
            <CustomButton
              type="default"
              onClick={() => history.go(-1)} //history.push('/invoices')
            >
              Back to Summary View
            </CustomButton>
          }
          className="mb-3"
        />
        <SearchBarVendorWise
          justify="start"
          align="middle"
          className="mb-4"
          gutter={32}
          inputProps={{
            placeholder: 'Search by vendor name'
          }}
          handleFiltersOnChange={handleFiltersOnChange}
          user_role={user_role}
        />

        <Spin spinning={getVendorWiseLoading}>
          <div className="mb-3" style={{ marginTop: '25px' }}>
            {vendorWiseList?.length > 0 ? (
              vendorWiseList?.map((item, index) => {
                return (
                  <div key={index}>
                    <BuVendorListPage
                      data={item}
                      loading={getVendorWiseLoading}
                    />
                  </div>
                );
              })
            ) : (
              <span style={{ display: 'flex', justifyContent: 'center' }}>
                <img alt="users_empty_state" src={emptyState} />
                <div className={styles.empty_state_text}>No Invoices</div>
              </span>
            )}
          </div>
          <Pagination
            size="small"
            current={page}
            pageSize={pageSize}
            total={vendorWisePagination?.total_records || 0}
            onChange={(page, pageSize) => {
              setPage(page);
              setPageSize(pageSize);
            }}
            className="text-right"
          />
        </Spin>
      </div>
    </Layout>
  );
};

export default BuVendorList;
