import LocalStorage from './localStorage';

export const apiUrl = process.env.REACT_APP_API_URL;

const encodeQueryData = (data = null) => {
  if (data) {
    const ret = [];
    for (let d in data)
      ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
    return '?' + ret.join('&');
  }
  return '';
};

const getLocalToken = () => {
  const user = LocalStorage.getItem('A2P_user');
  const authToken = user?.accessToken;
  if (authToken !== null && authToken !== '') {
    return `Bearer ${authToken}`;
  } else {
    return '';
  }
};

const apiClient = {
  Urls: {
    apiUrl,
    signin: `${apiUrl}/auth/signin`,
    vendorList: `${apiUrl}/invoice/getVendorList`,
    activeBankList: `${apiUrl}/invoice/getActiveBankList`,
    profile: `${apiUrl}/user/profile`,
    activeDebitBankList: `${apiUrl}/invoice/getActiveDebitBankList`,
    vendorwise: `${apiUrl}/BUC/summary/vendorwise`,
    // projectList: `${apiUrl}/SBUC/getProjectList`,
    dashboardCount: `${apiUrl}/dashboard/getDashboard`,
    checkVendorStatus: `${apiUrl}/invoice/checkVendorStatus`
  },

  make: function (url, method, params, auth, type) {
    let headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };

    let authHeaders = {
      Authorization: getLocalToken()
    };

    if (auth) {
      headers = {
        ...headers,
        ...authHeaders
      };
    }

    if (method == 'GET') {
      let queryParams = '';
      if (Object.keys(params || {}).length) {
        queryParams = encodeQueryData(params);
      }
      return fetch(url + queryParams, {
        method,
        headers
      })
        .then((response) => response.json())
        .catch((e) => e);
    } else {
      return fetch(url, {
        method,
        headers: type === 'file' ? authHeaders : headers,
        body: type === 'file' ? params : JSON.stringify(params)
      })
        .then((response) => response.json())
        .catch((e) => e);
    }
  },

  get: function (url, params, auth) {
    return apiClient.make(url, 'GET', params, auth);
  },

  post: function (url, params, auth, type) {
    return apiClient.make(url, 'POST', params, auth, type);
  },

  put: function (url, params, auth) {
    return apiClient.make(url, 'PUT', params, auth);
  },

  patch: function (url, params, auth) {
    return apiClient.make(url, 'PATCH', params, auth);
  },

  delete: function (url, params, auth) {
    return apiClient.make(url, 'DELETE', params, auth);
  }
};

export default apiClient;
