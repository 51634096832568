import React, { useState } from 'react';
import { Modal, Form, DatePicker, Col, Row, Spin } from 'antd';
import styles from './index.module.less';
import moment from 'moment';
import { getLoggedInUser } from 'utilities/helpers'; //, userRole
import { message } from 'antd';

const { RangePicker } = DatePicker;

function ExportAllocatedInvoices({
  openExportAlloModal,
  setOpenExportAlloModal,
  glView
}) {
  const [dateRange, setDateRange] = useState([]);
  const [dates, setDates] = useState(null);
  const [loading, setLoading] = useState(false);

  const user_role1 = getLoggedInUser();

  const disabledDate = (current) => {
    if (!dates) {
      return false;
    }
    const tooLate =
      //(dates[0] && current.diff(dates[0], 'days') > 90) ||
      current.valueOf() > Date.now();
    const tooEarly =
      // (dates[1] && dates[1].diff(current, 'days') > 90) ||
      current.valueOf() > Date.now();
    return !!tooEarly || !!tooLate;
  };

  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  const onOk = () => {
    const fromDate = moment(dateRange[0]?._d)?.format('DD-MMM-YYYY') || '';
    const toDate = moment(dateRange[1]?._d)?.format('DD-MMM-YYYY') || '';

    setLoading(true);
    glView === true
      ? fetch(
          `${process.env.REACT_APP_API_URL}/Finance/exportAllocatedInvoices?from_date=${fromDate}&to_date=${toDate}&is_H2H=false`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/csv',
              Authorization: `Bearer ${user_role1?.accessToken}`
            }
          }
        ).then((response) => {
          console.log('response@@@@@@@@', response);
          if (response?.status !== 500) {
            response.blob().then((blob) => {
              const url = window.URL.createObjectURL(new Blob([blob]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute(
                'download',
                `a2p_exported_allocated_invoices.csv`
              );

              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
            });
            setOpenExportAlloModal(false); //close the Modal...........
            setLoading(false);
          } else {
            message.success('No any allocated invoices found');
            console.log('responceNOT');
            setOpenExportAlloModal(false);
            setLoading(false);
          }
        })
      : fetch(
          `${process.env.REACT_APP_API_URL}/Finance/exportAllocatedInvoices?from_date=${fromDate}&to_date=${toDate}&is_H2H=true`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/csv',
              Authorization: `Bearer ${user_role1?.accessToken}`
            }
          }
        ).then((response) => {
          if (response?.status !== 500) {
            response.blob().then((blob) => {
              const url = window.URL.createObjectURL(new Blob([blob]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute(
                'download',
                `a2p_exported_allocated_invoices.csv`
              );

              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);
            });
            setOpenExportAlloModal(false); //close the Modal...........
            setLoading(false);
          } else {
            message.success('No any allocated invoices found');
            console.log('responceNOT');
            setOpenExportAlloModal(false);
            setLoading(false);
          }
        });
  };

  return (
    <>
      <Modal
        title={
          <span style={{ fontWeight: 600, fontSize: '16px' }}>
            Export Allocated Invoices :{' '}
          </span>
        }
        visible={openExportAlloModal}
        width={'720px'}
        onCancel={() => setOpenExportAlloModal(false)}
        onOk={() => setOpenExportAlloModal(false)}
        // cancelButtonProps={{ style: { display: 'none' } }}
        okText={'Export'}
        okButtonProps={{
          //loading: exportAllocatedInvoicesLoading,
          disabled: loading,
          onClick: onOk
        }}
      >
        <Form
          name="date"
          initialValues={{ remember: true }}
          //onFinish={onOk}
          autoComplete="off"
        >
          <Row>
            <Col className="gutter-row" span={24}>
              <Form.Item
                style={{ fontWeight: '600' }}
                label="Select start and end date"
                name="fromDate"
                rules={[
                  {
                    required: false,
                    message: 'Please enter date'
                  }
                ]}
              >
                <RangePicker
                  format={'DD-MMM-YYYY'}
                  value={dateRange}
                  disabledDate={disabledDate}
                  onCalendarChange={(val) => setDates(val)}
                  onChange={(x) => {
                    setDateRange(x);
                  }}
                  onOpenChange={onOpenChange}
                />

                {/* <div
                  style={{
                    fontSize: '10px',
                    color: 'GrayText',
                    fontWeight: '300',
                    marginLeft: '20%'
                  }}
                >
                  Limit date rage up to 90 days
                </div> */}
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {loading && (
          <Spin tip="Loading" size="small">
            <div className={`${styles.content}`} />
          </Spin>
        )}
      </Modal>
    </>
  );
}
export default ExportAllocatedInvoices;
