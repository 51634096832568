import React, { useEffect, useState } from 'react';
import { InputNumber, Space, Typography, Tooltip, message } from 'antd';
import styles from './index.module.less';
import CustomModal from 'common/CustomModal';
import CustomTableModal from 'common/Content/CustomTableModal';
import moment from 'moment';
import SearchBarModal from '@/common/Content/searchBarModal';
import { userRole, numFormating, numIntStyleFormat } from 'utilities/helpers';

const { Text } = Typography;

const BuHeadSummaryAllocModal = ({
  visible,
  toggleModal,
  data,
  glView,
  postAllocationRequestLoading,
  fromSummary,
  user_role,
  handlePostBuRequestFundAllocation,
  requestedAmount,
  openCheckbox,
  handleFiltersOnChangeSerchInModal,
  grandTotal,
  currentTotal
}) => {
  const [reqAmount, setReqAmount] = useState(0);
  const [totalInvoicesAmount, setTotalInvoicesAmount] = useState(0);
  const [editReqAmount] = useState(false);
  const [reqAllocInvoiceKeys, setReqAllocInvoiceKeys] = useState([]);
  const [reqAllocInvoices, setReqAllocInvoices] = useState([]);
  const [unselectedInvoices, setUnselectedInvoices] = useState([]);

  console.log('unselectedInvoices', unselectedInvoices);

  useEffect(() => {
    const totalAmount = data?.reduce(
      (a, b) => a + parseFloat(b?.net_payable),
      0
    );
    setReqAmount(totalAmount);
    setTotalInvoicesAmount(totalAmount);

    if (fromSummary) {
      let invoiceKeys = data?.map((o) => o.key);
      setReqAllocInvoiceKeys(invoiceKeys);
      setReqAllocInvoices(data);
    }
  }, [data]);

  const invoiceData = data[0];
  const { tds_project_name, tds_project_code } = invoiceData;
  const columns = [
    {
      title: 'PROJECT CODE',
      dataIndex: 'tds_project_code',
      key: 'tds_project_code',
      sorter: (a, b) => a.tds_project_code - b.tds_project_code,
      ellipsis: {
        showTitle: false
      },
      width: '110px',
      render: (tds_project_code) => {
        return (
          <>
            <Tooltip
              title={tds_project_code}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {tds_project_code}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'VENDOR',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
      sorter: (a, b) => a.vendor_name.localeCompare(b.vendor_name),
      ellipsis: {
        showTitle: false
      },
      width: '260px',
      render: (vendor_name) => {
        return (
          <>
            <Tooltip
              title={vendor_name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {vendor_name}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'INVOICE NUMBER',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      sorter: (a, b) => a.invoice_number.localeCompare(b.invoice_number),
      ellipsis: {
        showTitle: false
      },
      width: '240px',
      render: (invoice_number) => {
        return (
          <>
            <Tooltip
              title={invoice_number}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <a>{invoice_number}</a>
            </Tooltip>
          </>
        );
      }
    },
    glView
      ? {
          title: 'GL DATE',
          dataIndex: 'GL_date',
          key: 'GL_date',
          sorter: (a, b) =>
            moment(a['GL_date']).unix() - moment(b['GL_date']).unix()
        }
      : fromSummary
      ? ({
          title: 'GL DATE',
          dataIndex: 'GL_date',
          key: 'GL_date',
          sorter: (a, b) =>
            moment(a['GL_date']).unix() - moment(b['GL_date']).unix()
        },
        {
          title: 'INVOICE DATE',
          dataIndex: 'invoice_date',
          key: 'invoice_date',
          sorter: (a, b) =>
            moment(a['invoice_date']).unix() - moment(b['invoice_date']).unix()
        })
      : {
          title: 'INVOICE DATE',
          dataIndex: 'invoice_date',
          key: 'invoice_date',
          sorter: (a, b) =>
            moment(a['invoice_date']).unix() - moment(b['invoice_date']).unix()
        },
    {
      title: 'NET PAYABLE',
      dataIndex: 'net_payable',
      key: 'net_payable',
      sorter: (a, b) => Number(a.net_payable) - Number(b.net_payable),
      ellipsis: {
        showTitle: false
      },
      render: (net_payable) => {
        return (
          <>
            <Tooltip
              title={numIntStyleFormat(net_payable)}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span style={{ fontWeight: 'bold' }}>
                {net_payable >= 0 ? (
                  <span>₹{numFormating(net_payable)}</span>
                ) : (
                  <span>-₹{numFormating(net_payable)}</span>
                )}
              </span>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'AGEING',
      dataIndex: 'ageing',
      key: 'ageing',
      sorter: (a, b) =>
        Number(a.ageing.replace(` days`, '')) -
        Number(b.ageing.replace(` days`, ''))
    }
  ];

  const rowSelection = {
    selectedRowKeys: reqAllocInvoiceKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      const unselectedRows = data?.filter((object1) => {
        return !selectedRows.some((object2) => {
          return object1.id === object2.id;
        });
      });
      setUnselectedInvoices(unselectedRows);
      setReqAllocInvoiceKeys(selectedRowKeys);
      setReqAllocInvoices(selectedRows);
      // console.log('selectedRows', selectedRows);
      // console.log('selectedRowKeys', selectedRowKeys);
      const reCalculatedTotalAmount = selectedRows?.reduce(
        (a, b) => a + parseFloat(b?.net_payable),
        0
      );
      //setReqAmount(reCalculatedTotalAmount); //fixed the Liability Amount
      setTotalInvoicesAmount(reCalculatedTotalAmount);
    }
  };

  console.log('reqAllocInvoices', reqAllocInvoices);

  const tolerance = 0.5;
  function compareFloatsGreaterWithTolerance(a, b, tolerance) {
    const diff = a - b;
    return diff > tolerance ? a : null;
  } // : diff < -tolerance ? b

  useEffect(() => {
    const totalInvoicesAmountWithCurrentTotal =
      Math.abs(totalInvoicesAmount) + Math.abs(currentTotal);

    const greaterFloat = compareFloatsGreaterWithTolerance(
      totalInvoicesAmountWithCurrentTotal,
      Math.abs(grandTotal),
      tolerance
    );
    // const total = Math.abs(totalInvoicesAmount) + Math.abs(currentTotal);
    //if (total > Math.abs(grandTotal))
    if (greaterFloat !== null) {
      message.error('You are exceeding the proposed liability');
    }
  }, [totalInvoicesAmount]);

  const onOk = () => {
    if (user_role === 'SBU Head') {
      const allRows = data?.map((item) => item?.id);
      const approvedRows = reqAllocInvoices?.map((item) => item?.id);
      const rejectedRows = unselectedInvoices?.map((item) => item?.id);
      let payload = {
        approved_allocation_invoice_id:
          approvedRows?.length === 0 && rejectedRows?.length === 0
            ? allRows
            : approvedRows,
        rejected_allocation_invoice_id: rejectedRows,
        requested_fund: totalInvoicesAmount,
        selected_worth: totalInvoicesAmount
      };
      handlePostBuRequestFundAllocation(userRole(user_role), payload);
    }
  };
  return (
    <CustomModal
      title="Request Fund Allocation"
      visible={visible}
      width={'90%'}
      bodyStyle={{ height: '80vh', overflow: 'scroll', overflowX: 'hidden' }}
      style={{ top: 50 }}
      onCancel={toggleModal}
      cancelButtonProps={{ onClick: toggleModal }}
      okText={
        totalInvoicesAmount >= 0
          ? `Submit Fund allocation of ₹${numFormating(
              totalInvoicesAmount
            )} for vendor`
          : `Submit Fund allocation of -₹${numFormating(
              totalInvoicesAmount
            )} for vendor`
      }
      okButtonProps={{
        disabled:
          reqAllocInvoices?.length <= 0 ||
          totalInvoicesAmount >= 0 ||
          compareFloatsGreaterWithTolerance(
            Math.abs(totalInvoicesAmount),
            Math.abs(reqAmount),
            tolerance
          ) !== null ||
          compareFloatsGreaterWithTolerance(
            Math.abs(totalInvoicesAmount) + Math.abs(currentTotal),
            Math.abs(grandTotal),
            tolerance
          ) !== null,
        // Math.abs(totalInvoicesAmount) > Math.abs(reqAmount) ||
        // Math.abs(totalInvoicesAmount) + Math.abs(currentTotal) >
        //   Math.abs(grandTotal),
        loading: postAllocationRequestLoading,
        onClick: onOk
      }}
    >
      <div>
        <Space size={'middle'} align="center">
          <Text
            className={`${styles.req_alloc_modal_vendor_details} sfprotext-medium font-13`}
          >{`${tds_project_name} (${tds_project_code})`}</Text>
          <Space
            size={'large'}
            className={`${styles.req_alloc_modal_requested_amount_details}`}
          >
            <Text className="font-14">{requestedAmount}:</Text>
            {!editReqAmount ? (
              <Text className="sfprotext-bold font-14">
                <Tooltip
                  title={reqAmount}
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {reqAmount >= 0 ? (
                    <span>₹{numFormating(reqAmount)}</span>
                  ) : (
                    <span>-₹{numFormating(reqAmount)}</span>
                  )}
                </Tooltip>
              </Text>
            ) : (
              <InputNumber
                min={1}
                max={totalInvoicesAmount}
                value={reqAmount}
                onChange={setReqAmount}
              />
            )}
          </Space>
        </Space>
      </div>
      <div>
        <Space size={'middle'} align="center" className="mt-3">
          <Text className="sfprotext-bold font-14">
            {'Selected invoices for the requested amount'}
          </Text>
          <Space
            size={'middle'}
            className={`${styles.req_alloc_modal_invoices_total_amount_details}`}
          >
            <Text className="font-14">{`${
              (fromSummary ? reqAllocInvoices : data).length
            } invoices of worth`}</Text>
            <Text className="sfprotext-bold font-14">
              <Tooltip
                title={totalInvoicesAmount}
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {totalInvoicesAmount >= 0 ? (
                  <span>₹{numFormating(totalInvoicesAmount)}</span>
                ) : (
                  <span>-₹{numFormating(totalInvoicesAmount)}</span>
                )}
              </Tooltip>
            </Text>
          </Space>
        </Space>
      </div>
      <div className="mt-3">
        {!openCheckbox ? (
          <SearchBarModal
            justify="start"
            align="middle"
            className="mb-4"
            gutter={32}
            inputProps={{
              placeholder: 'Search using invoice number/project'
            }}
            //handleFiltersOnChange={handleFiltersOnChange}
            handleFiltersOnChangeSerchInModal={
              handleFiltersOnChangeSerchInModal
            }
            user_role={user_role}
          />
        ) : null}
      </div>
      <div className="mt-3">
        <CustomTableModal
          style={{
            fontFamily: 'SFProText Regular',
            padding: '40px 4px !important',
            lineHeight: '18px',
            color: '#32324D'
          }}
          data={data}
          columns={columns}
          pagination={false}
          rowSelection={fromSummary ? rowSelection : null}
        />
      </div>
    </CustomModal>
  );
};

export default BuHeadSummaryAllocModal;
