import React from 'react';
import { Image, Tag, Tooltip, Spin } from 'antd'; //, Button
import styles from './index.module.less';
import emptyState from 'assets/images/empty_state.svg';
import Header from 'common/Content/header';
import SearchBarDashboard from 'common/Content/searchBarDashboard';
import TableFinanceCoE from 'common/Content/TableFinanceCoE';
import SubHeader from 'common/Content/SubHeader';
//import CustomButton from 'common/CustomButton';
import Switcher from '../../../Switcher';
import {
  ageFilters,
  invoiceType,
  numFormating,
  numIntStyleFormat
} from 'utilities/helpers';
import FinanceCoEReqAllocModal from '../FinanceCoEReqAllocModal/index';
import moment from 'moment';
import { history } from 'app/history';
import ExportAllocatedInvoices from '../exportAllocatedInvoices/index';
import FinanceCoEReqRejectModal from '../FinanceCoEReqRejectModal';

const FinanceCoEPage = ({
  loading,
  invoices = [],
  pagination,
  filterValues,
  page,
  setPage,
  pageGl,
  setPageGl,
  pageSize,
  setPageSize,
  glView,
  toggleGlView,
  reqAllocInvoiceKeys,
  setReqAllocInvoiceKeys,
  reqAllocInvoices,
  setReqAllocInvoices,
  vendorList,
  openReqAllocModal,
  setOpenReqAllocModal,
  activeBankList,
  handleGetActiveBankList,
  postAllocationRequestLoading,
  handlePostAllocationRequest,
  handleFiltersOnChange,
  /// tab,
  handleTabOnChange,
  // allInvoicesPagination,
  // openInvoicesPagination,
  // paidInvoicesPagination,
  // pendingInvoicesPagination,
  // rejectedInvoicesPagination,
  // allocatedInvoicesPagination,
  //approvedInvoicesPagination,
  // disbursedInvoicesPagination,
  user_role,
  projectList,
  // projectAssigned,
  // BU_assigned,
  BUList,
  sendBackAllocRequestHandler,
  getExportPaidInvoicesHandler,
  activeDebitBankList,
  setVendorSearch,
  setProjectSearch,
  disbursed,
  paid,
  allocated,
  openExportAlloModal,
  setOpenExportAlloModal,
  openReqRejectModal,
  setOpenReqRejectModal,
  handlePostRejectRequest,
  postSendBackAllocRequestInvoiceWiseLoading,
  //getTLReportsHandler,
  lodingTLReport
}) => {
  console.log('@@@@@@@@@pageGl>>>>:', pageGl, glView);
  let requestedAmount = 'RequestedAmount';
  const columns = [
    {
      //title: 'PROJECT CODE',
      title: (
        <Tooltip
          title="PROJECT CODE"
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          PROJECT CODE
        </Tooltip>
      ),
      dataIndex: 'tds_project_code',
      key: 'tds_project_code',
      width: '85px',
      sorter: (a, b) => a.tds_project_code - b.tds_project_code,
      ellipsis: {
        showTitle: false
      },
      render: (tds_project_code) => {
        return (
          <>
            <Tooltip
              title={tds_project_code}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {tds_project_code}
            </Tooltip>
          </>
        );
      }
    },
    {
      // title: 'VENDOR',
      title: (
        <Tooltip
          title="VENDOR"
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          <span>VENDOR</span>
        </Tooltip>
      ),
      dataIndex: 'vendor_name',
      key: 'vendor_name',
      sorter: (a, b) => a.vendor_name.localeCompare(b.vendor_name),
      ellipsis: {
        showTitle: false
      },
      //width: '230px',
      render: (vendor_name) => {
        return (
          <>
            <Tooltip
              title={vendor_name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {vendor_name}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: (
        <Tooltip
          title="INVOICE NUMBER"
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          INVOICE NUMBER
        </Tooltip>
      ),
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      sorter: (a, b) => a.invoice_number.localeCompare(b.invoice_number),
      ellipsis: {
        showTitle: false
      },
      // width: '190px',
      render: (invoice_number) => {
        return (
          <>
            <Tooltip
              title={<a>{invoice_number}</a>}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <a>{invoice_number}</a>
            </Tooltip>
          </>
        );
      }
    },
    /* {
      title: `${glView ? 'Non H2H-Foreign' : 'H2H'}`,
      dataIndex: `${glView ? 'GL' : 'invoice'}_date`,
      key: `${glView ? 'GL' : 'invoice'}_date`,
      width: '120px',
      sorter: (a, b) =>
        moment(a[`${glView ? 'GL' : 'invoice'}_date`]).unix() -
        moment(b[`${glView ? 'GL' : 'invoice'}_date`]).unix()
    }, */
    {
      title: 'GL DATE',
      dataIndex: 'GL_date',
      key: 'GL_date',
      width: '125px',
      ellipsis: true,
      sorter: (a, b) =>
        moment(a['GL_date']).unix() - moment(b['GL_date']).unix()
    },
    /* {
      title: 'ADVANCES(UP)',
      dataIndex: 'net_advances_up',
      key: 'net_advances_up',
      sorter: (a, b) => Number(a.net_advances_up) - Number(b.net_advances_up),
      ellipsis: {
        showTitle: false
      },
      render: (net_advances_up) => {
        return (
          <>
            <Tooltip
              title={numIntStyleFormat(net_advances_up)}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span style={{ fontWeight: 'bold' }}>
                {net_advances_up >= 0 ? (
                  <span>
                    ₹{numFormating(net_advances_up)}
                  </span>
                ) : (
                  <span>
                    -₹{numFormating(net_advances_up)}
                  </span>
                )}
              </span>
            </Tooltip>
          </>
        );
      }
    }, */
    {
      title: (
        <Tooltip
          title="NET PAYABLE"
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          NET PAYABLE
        </Tooltip>
      ),
      dataIndex: 'net_payable',
      key: 'net_payable',
      sorter: (a, b) => Number(a.net_payable) - Number(b.net_payable),
      ellipsis: {
        showTitle: false
      },
      width: '125px',
      render: (net_payable) => {
        return (
          <>
            <Tooltip
              title={
                <span style={{ fontWeight: 'bold' }}>
                  {numIntStyleFormat(net_payable)}
                </span>
              }
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span style={{ fontWeight: 'bold' }}>
                {net_payable >= 0 ? (
                  <span>₹{numFormating(net_payable)}</span>
                ) : (
                  <span>-₹{numFormating(net_payable)}</span>
                )}
              </span>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'AGEING',
      dataIndex: 'ageing',
      key: 'ageing',
      width: '115px',
      ellipsis: true,
      sorter: (a, b) =>
        Number(a.ageing.replace(` days`, '')) -
        Number(b.ageing.replace(` days`, ''))
    },
    {
      title: 'UTR NUMBER',
      dataIndex: 'utr_number',
      key: 'utr_number',
      ellipsis: {
        showTitle: false
      },
      width: '100px',
      render: (utr_number) => (utr_number !== null ? utr_number : 'NYP')
    },
    {
      //title: 'ALLOCATION STATUS',
      title: (
        <Tooltip
          title="ALLOCATION STATUS"
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          ALLOCATION STATUS
        </Tooltip>
      ),
      dataIndex: 'status',
      key: 'status',
      width: '130px',
      ellipsis: true,
      render: (status) => {
        return (
          <Tag
            className={`${styles.status_tag}`}
            color={'#EAF5FF'}
            style={{
              color: '#006096',
              textTransform: 'capitalize',
              fontSize: '10.5px',
              fontWeight: 400
              //lineHeight: '18px'
            }}
          >
            {status}
          </Tag>
        );
      }
    }
    /*  {
      title: 'ACTION',
      key: 'action',
      // ellipsis: {
      //   showTitle: false
      // },
      width: '120px',
      render: (record) => (
        <Button
          className={`${styles.allocation_btn}`}
          style={{
            fontSize: '11px'
          }}
          type="link"
          disabled={
            user_role === 'Finance CoE'
              ? !reqAllocInvoices?.length ||
                record.status === 'paid' ||
                reqAllocInvoices.find(
                  (item) => item.vendor_code !== record.vendor_code
                )
              : user_role === 'BU Commercial'
              ? !reqAllocInvoices.length ||
                record.my_approval === 'approved' ||
                reqAllocInvoices.find(
                  (item) => item.tds_project_code !== record.tds_project_code
                )
              : user_role === 'Treasury'
              ? !reqAllocInvoices.length ||
                record.my_approval === 'approved' ||
                reqAllocInvoices.find(
                  (item) => item.tds_project_code !== record.tds_project_code
                )
              : !reqAllocInvoices.length ||
                reqAllocInvoices.find(
                  (item) => item.vendor_code !== record.vendor_code
                )
          }
          onClick={() => {
            setOpenReqAllocModal(!openReqAllocModal);
          }}
        >
          Fund allocation
        </Button>
      )
    } */
    /* {
      title: 'ACTION',
      key: 'action',
      // ellipsis: {
      //   showTitle: false
      // },
      width: '120px',
      render: (record) => (
        <Button
          className={`${styles.allocation_btn}`}
          style={{
            fontSize: '11px'
          }}
          type="link"
          disabled={
            user_role === 'Finance CoE'
              ? !reqAllocInvoices?.length ||
                record.status === 'paid' ||
                reqAllocInvoices.find(
                  (item) => item.vendor_code !== record.vendor_code
                )
              : !reqAllocInvoices.length ||
                reqAllocInvoices.find(
                  (item) => item.vendor_code !== record.vendor_code
                )
          }
          onClick={() => {
            setOpenReqRejectModal(!openReqRejectModal);
          }}
        >
          Request Reject
        </Button>
      )
    } */
  ];

  let locale = {
    emptyText: (
      <span>
        <Image alt="users_empty_state" src={emptyState} preview={false} />
        <div className={styles.empty_state_text}>No Invoices</div>
      </span>
    )
  };

  const rowSelection = {
    selectedRowKeys: reqAllocInvoiceKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setReqAllocInvoiceKeys(selectedRowKeys);
      setReqAllocInvoices(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled:
        user_role === 'Finance CoE'
          ? invoices[0]?.non_H2H === true
            ? record.status === 'pending' ||
              record.status === 'approved' ||
              record.status === 'disbursed' ||
              record.status === 'paid' ||
              reqAllocInvoices.find(
                (item) => item.vendor_code !== record.vendor_code
              )
            : record.status === 'allocated' ||
              reqAllocInvoices.find(
                (item) => item.vendor_code !== record.vendor_code
              )
          : user_role === 'BU Commercial'
          ? record.status === 'approved' ||
            record.status === 'allocated' ||
            record.status === 'paid' ||
            reqAllocInvoices.find(
              (item) => item.tds_project_code !== record.tds_project_code
            )
          : user_role === 'Treasury'
          ? record.status === 'paid' ||
            record.status === 'allocated' ||
            reqAllocInvoices.find(
              (item) => item.tds_project_code !== record.tds_project_code
            )
          : reqAllocInvoices.find(
              (item) => item.vendor_code !== record.vendor_code
            ),

      name: record.vendor_code
    })
  };

  return (
    <div className={styles.invoices_container}>
      <Header
        justify="space-between"
        align="middle"
        title="Finance CoE Invoices"
        titleSuffix=""
        rightComponent={
          <Switcher
            left={'Non-H2H & Foreign'}
            right={'H2H'}
            onChange={toggleGlView}
          />
        }
        className="mb-4"
      />

      {user_role === 'Finance CoE' &&
        (glView !== false ? (
          <Spin spinning={lodingTLReport}>
            <SubHeader
              justify="space-between"
              align="middle"
              tabs={[
                {
                  id: 1,
                  label: 'Allocated',
                  count: allocated // allocatedInvoicesPagination?.total_records || 0
                },
                {
                  id: 2,
                  label: 'Paid',
                  count: paid //paidInvoicesPagination?.total_records || 0
                },
                {
                  id: 3,
                  label: 'Disbursed',
                  count: disbursed // disbursedInvoicesPagination?.total_records || 0
                }
              ]}
              onTabChange={handleTabOnChange}
              buttons={[
                {
                  text: 'Transaction complete',
                  onClick: () => sendBackAllocRequestHandler()
                },
                {
                  text: 'Export paid invoices',
                  onClick: () => getExportPaidInvoicesHandler()
                },
                {
                  text: 'Export Allocated invoices',
                  onClick: () => {
                    setOpenExportAlloModal(true);
                  }
                },
                /* {
                  text: 'TL Report',
                  onClick: () => getTLReportsHandler()
                }, */
                {
                  type: 'primary',
                  text: 'Summarize',
                  onClick: () => history.push('/summary')
                }
              ]}
            />
          </Spin>
        ) : (
          <Spin spinning={lodingTLReport}>
            <SubHeader
              justify="space-between"
              align="middle"
              tabs={[
                {
                  id: 1,
                  label: 'Allocated',
                  count: allocated // allocatedInvoicesPagination?.total_records || 0
                },
                {
                  id: 2,
                  label: 'Paid',
                  count: paid // paidInvoicesPagination?.total_records || 0
                },
                {
                  id: 3,
                  label: 'Disbursed',
                  count: disbursed //disbursedInvoicesPagination?.total_records || 0
                }
              ]}
              onTabChange={handleTabOnChange}
              buttons={[
                {
                  text: 'Export paid invoices',
                  onClick: () => getExportPaidInvoicesHandler()
                },
                {
                  text: 'Export Allocated invoices',
                  onClick: () => {
                    setOpenExportAlloModal(true);
                  }
                }
                /*  {
                  text: 'TL Report',
                  onClick: () => getTLReportsHandler()
                } */
              ]}
            />
          </Spin>
        ))}

      <div className={`${styles.invoices_table_container}`}>
        {user_role === 'Finance CoE' && (
          <SearchBarDashboard
            justify="start"
            align="middle"
            className="mb-4"
            gutter={32}
            inputProps={{
              placeholder: 'Search using invoice number/vendor name'
            }}
            filters={[
              glView !== false
                ? {
                    label: 'Invoice Type',
                    slug: 'invoice_type',
                    options: invoiceType,
                    type: 'select'
                  }
                : null,
              {
                label: 'BU',
                slug: 'BU_name',
                options: BUList,
                type: 'select'
              },
              {
                label: 'Project',
                slug: 'tds_project_code',
                options: projectList,
                type: 'select'
              },
              {
                label: 'Vendor',
                slug: 'vendor',
                options: vendorList,
                type: 'select'
              },
              {
                label: 'Age',
                slug: 'age',
                options: ageFilters
              },
              {
                label: `${glView ? 'GL' : 'GL'} date`, //'Invoice'
                slug: `${glView ? 'gl' : 'gl'}_date`, //'invoice'
                type: 'date'
              }
              /*  invoices[0]?.non_H2H===false
                ? {
                  label: 'Invoice Type',
                  slug: 'invoice_type',
                  options: invoiceType,
                  type: 'select'
                }
                : null */
            ]}
            filterValues={filterValues}
            handleFiltersOnChange={handleFiltersOnChange}
            user_role={user_role}
            setVendorSearch={setVendorSearch}
            setProjectSearch={setProjectSearch}
          />
        )}
        <div
          className={`${styles.custom_table_container}${
            !invoices.length ? ` ${styles.no_data}` : ''
          }`}
        >
          {glView !== false ? (
            <TableFinanceCoE
              data={invoices}
              columns={columns}
              locale={locale}
              loading={loading}
              rowSelection={rowSelection}
              pagination={{
                current: page,
                size: 'small',
                showSizeChanger: true,
                total: pagination?.total_records || 0,
                pageSize: pageSize,
                onChange: (page, pageSize) => {
                  setPage(page);
                  setPageSize(pageSize);
                }
              }}
            />
          ) : (
            <TableFinanceCoE
              data={invoices}
              columns={columns}
              locale={locale}
              loading={loading}
              rowSelection={rowSelection}
              pagination={{
                current: pageGl,
                size: 'small',
                showSizeChanger: true,
                total: pagination?.total_records || 0,
                pageSize: pageSize,
                onChange: (pageGl, pageSize) => {
                  setPageGl(pageGl);
                  setPageSize(pageSize);
                }
              }}
            />
          )}
        </div>
        {openReqAllocModal ? (
          <FinanceCoEReqAllocModal
            requestedAmount={requestedAmount}
            visible={openReqAllocModal}
            toggleModal={() => {
              setOpenReqAllocModal(!openReqAllocModal);
            }}
            data={reqAllocInvoices}
            glView={glView}
            activeBankList={activeBankList}
            handleGetActiveBankList={handleGetActiveBankList}
            postAllocationRequestLoading={postAllocationRequestLoading}
            handlePostAllocationRequest={handlePostAllocationRequest}
            user_role={user_role}
            handleFiltersOnChange={handleFiltersOnChange}
            activeDebitBankList={activeDebitBankList}
            /*  bankName={bankName}
            setBankName={setBankName}
            bankIFSC={bankIFSC}
            setBankIFSC={setBankIFSC}
            bankAccount={bankAccount}
            setBankAccount={setBankAccount} */
          />
        ) : null}
      </div>
      {openExportAlloModal ? (
        <ExportAllocatedInvoices
          openExportAlloModal={openExportAlloModal}
          setOpenExportAlloModal={setOpenExportAlloModal}
          glView={glView}
        />
      ) : (
        ''
      )}

      {openReqRejectModal ? (
        <FinanceCoEReqRejectModal
          requestedAmount={requestedAmount}
          visible={openReqRejectModal}
          toggleModal={() => {
            setOpenReqRejectModal(!openReqRejectModal);
          }}
          data={reqAllocInvoices}
          glView={glView}
          activeBankList={activeBankList}
          handleGetActiveBankList={handleGetActiveBankList}
          postSendBackAllocRequestInvoiceWiseLoading={
            postSendBackAllocRequestInvoiceWiseLoading
          }
          handlePostRejectRequest={handlePostRejectRequest}
          user_role={user_role}
          handleFiltersOnChange={handleFiltersOnChange}
          activeDebitBankList={activeDebitBankList}
        />
      ) : null}
    </div>
  );
};

export default FinanceCoEPage;
