import React, { useState } from 'react';
import { Modal, Form, DatePicker, Col, Row, Spin } from 'antd';
import styles from './index.module.less';
import moment from 'moment';
import { getLoggedInUser, userRole } from 'utilities/helpers';
import { message } from 'antd';

function ExportInvoicesJourney({
  setOpenExportJourneyModal,
  openExportJourneyModal
}) {
  const [dateRange, setDateRange] = useState([moment(), moment()]);
  const [loading, setLoading] = useState(false);

  const user_role1 = getLoggedInUser();
  const user_role = user_role1?.user_details?.role;

  const [dates, setDates] = useState(null);
  //const [value, setValue] = useState(null);

  const disabledDate = (current) => {
    // console.log('current@@@@@', current);
    // console.log('dates@@@@@', dates);

    if (!dates) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], 'days') >= 40;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') >= 40;
    return (
      !!tooEarly || !!tooLate || (current && current.valueOf() > Date.now())
    );
  };

  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  console.log('dateRange', dateRange);

  const onOk = () => {
    const fromDate = moment(dateRange[0]?._d)?.format('DD-MMM-YYYY') || '';
    const toDate = moment(dateRange[1]?._d)?.format('DD-MMM-YYYY') || '';
    setLoading(true);
    /* PE:   /api/v1/PE/exportMyInvoicesJourney?from_date=03-May-2023&to_date=19-May-2023*/
    fetch(
      `${process.env.REACT_APP_API_URL}/${userRole(
        user_role
      )}/exportMyInvoicesJourney?from_date=${fromDate}&to_date=${toDate}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/csv',
          Authorization: `Bearer ${user_role1?.accessToken}`
        }
      }
    ).then((response) => {
      if (response?.status !== 500) {
        response.blob().then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `a2p_exported_InvoicesJourney.csv`);

          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        });
        setOpenExportJourneyModal(false); //close the Modal...........
        setLoading(false);
      } else {
        message.success('No any invoices found');
        setOpenExportJourneyModal(false);
        setLoading(false);
        console.log('responceNOT');
      }
    });
  };

  return (
    <>
      <Modal
        title={
          <span style={{ fontWeight: 600, fontSize: '16px' }}>
            Invoices Journey :{' '}
          </span>
        }
        visible={openExportJourneyModal}
        width={'720px'}
        onCancel={() => setOpenExportJourneyModal(false)}
        onOk={() => setOpenExportJourneyModal(false)}
        okText={'Export'}
        okButtonProps={{
          //loading: postAllocationRequestLoading,
          disabled: loading,
          onClick: onOk
        }}
      >
        <Form
          name="date"
          initialValues={{ remember: true }}
          //onFinish={onOk}
          autoComplete="off"
        >
          <Row>
            <Col className="gutter-row" span={24}>
              <Form.Item
                style={{ fontWeight: '600' }}
                label="Select start and end date"
                name="fromDate"
                rules={[
                  {
                    required: false,
                    message: 'Please enter date'
                  }
                ]}
              >
                <DatePicker.RangePicker
                  format={'DD-MMM-YYYY'}
                  onChange={(x) => {
                    setDateRange(x);
                  }}
                  onCalendarChange={(val) => {
                    setDates(val);
                  }}
                  onOpenChange={onOpenChange}
                  changeOnBlur
                  disabledDate={disabledDate}
                  // {(current) => {
                  //   return current && current.valueOf() > Date.now();
                  // }}
                  value={dateRange || dates}
                  // separator={'-'}
                  // style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {loading && (
          <Spin tip="Loading" size="small">
            <div className={`${styles.content}`} />
          </Spin>
        )}
      </Modal>
    </>
  );
}
export default ExportInvoicesJourney;
