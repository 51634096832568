//import styles from './index.module.less';
import ReactSpeedometer from 'react-d3-speedometer';
export default function SpeedometerChart({ ageing_data }) {
  const ageing_data_Array = Object.values(ageing_data);
  console.log('ageing_data_Array', ageing_data_Array);

  const textColor = '#4059e5';

  return (
    <div>
      <span
        style={{
          textAlign: 'center',
          display: 'flex',
          justifyContent: 'center',
          color: 'rgba(0,0,0,0.85)',
          fontSize: '12px',
          fontWeight: 600
        }}
      >
        Average Ageing Monitor
      </span>
      <div
        style={{
          width: '320px',
          height: '180px',
          marginTop: '15px',
          display: 'flex',
          justifyContent: 'center'
          // flexDirection:'column'
        }}
      >
        <ReactSpeedometer
          forceRender={true}
          needleHeightRatio={0.7}
          maxSegmentLabels={2}
          segments={5555}
          minValue={ageing_data_Array[0]}
          maxValue={ageing_data_Array[2]}
          value={ageing_data_Array[1]}
          textColor={textColor}
          // customSegmentStops={[ageing_data_Array[0], ageing_data_Array[1], ageing_data_Array[2]]}
          segmentColors={['#C2C0FF', '#4059e5']}
          needleColor={'#4059e5'}
          //currentValueText={'Current Value: ${value}'}
        />
      </div>
    </div>
  );
}
