import React, { useEffect, useState } from 'react';
import { InputNumber, Space, Typography, Tooltip, Select, message } from 'antd';
import styles from './index.module.less';
import CustomModal from 'common/CustomModal';
//import CustomTable from 'common/Content/CustomTable';
//import CustomButton from 'common/CustomButton';
import CustomTableModal from 'common/Content/CustomTableModal';
import moment from 'moment';
import { numFormating, numIntStyleFormat } from 'utilities/helpers';
//import SearchBar from '@/common/Content/searchBar';
//import SearchBarDashboard from '@/common/Content/searchBarDashboard';

const { Text } = Typography;
const { Option } = Select;

const FinanceCoESummaryAllocModal = ({
  visible,
  toggleModal,
  data,
  glView,
  //activeBankList,
  //handleGetActiveBankList,
  postAllocationRequestLoading,
  //handlePostAllocationRequest,
  fromSummary,
  //summaryInvoicesActiveBankList,
  // selectedBankBranchName,
  //handleFiltersOnChange,
  user_role,
  handlePostBuRequestFundAllocation,
  requestedAmount,
  activeDebitBankList,
  grandTotal,
  currentTotal
  // setSelectedBankDebitBranchName,
  // selectedBankDebitBranchName,
}) => {
  //console.log('DATATreasuryModal', data);
  const [reqAmount, setReqAmount] = useState(0);
  const [totalInvoicesAmount, setTotalInvoicesAmount] = useState(0);
  const [editReqAmount] = useState(false);
  const [selectedBank, setSelectedBank] = useState('');
  const [reqAllocInvoiceKeys, setReqAllocInvoiceKeys] = useState([]);
  const [reqAllocInvoices, setReqAllocInvoices] = useState([]);
  const [unselectedInvoices, setUnselectedInvoices] = useState([]);
  // const [bankName, setBankName] = useState('');
  //const [bankIFSC, setBankIFSC] = useState('');
  //const [bankAccount, setBankAccount] = useState('');
  //const [accountHolderName, setAccountHolderName] = useState('');
  console.log('unselectedInvoices', unselectedInvoices);
  // console.log('bankName', bankName);
  // console.log('bankNameLen', bankName?.length);

  useEffect(() => {
    const totalAmount = data?.reduce(
      (a, b) => a + parseFloat(b?.net_payable),
      0
    );
    setReqAmount(totalAmount);
    setTotalInvoicesAmount(totalAmount);

    /*  if (handleGetActiveBankList) {
      const invoiceData = data[0];
      const { vendor_code, vendor_site_code } = invoiceData;
      handleGetActiveBankList({ vendor_code, vendor_site_code });
    } */

    if (fromSummary) {
      let invoiceKeys = data?.map((o) => o.key);
      setReqAllocInvoiceKeys(invoiceKeys);
      setReqAllocInvoices(data);
    }
    setSelectedBank(activeDebitBankList[0]?.bank_branch_name);
  }, []);

  const invoiceData = data[0];
  const { SBU_name } = invoiceData;
  const columns = [
    {
      title: 'PROJECT CODE',
      dataIndex: 'tds_project_code',
      key: 'tds_project_code',
      sorter: (a, b) => a.tds_project_code - b.tds_project_code,
      ellipsis: {
        showTitle: false
      },
      width: '110px',
      render: (tds_project_code) => {
        return (
          <>
            <Tooltip
              title={tds_project_code}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {tds_project_code}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'VENDOR',
      dataIndex: 'vendor_name',
      key: 'vendor_name',
      sorter: (a, b) => a.vendor_name.localeCompare(b.vendor_name),
      ellipsis: {
        showTitle: false
      },
      width: '260px',
      render: (vendor_name) => {
        return (
          <>
            <Tooltip
              title={vendor_name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {vendor_name}
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'INVOICE NUMBER',
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      sorter: (a, b) => a.invoice_number.localeCompare(b.invoice_number),
      ellipsis: {
        showTitle: false
      },
      width: '240px',
      render: (invoice_number) => {
        return (
          <>
            <Tooltip
              title={invoice_number}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <a>{invoice_number}</a>
            </Tooltip>
          </>
        );
      }
    },
    glView
      ? {
          title: 'GL DATE',
          dataIndex: 'GL_date',
          key: 'GL_date',
          sorter: (a, b) =>
            moment(a['GL_date']).unix() - moment(b['GL_date']).unix()
        }
      : fromSummary
      ? ({
          title: 'GL DATE',
          dataIndex: 'GL_date',
          key: 'GL_date',
          sorter: (a, b) =>
            moment(a['GL_date']).unix() - moment(b['GL_date']).unix()
        },
        {
          title: 'INVOICE DATE',
          dataIndex: 'invoice_date',
          key: 'invoice_date',
          sorter: (a, b) =>
            moment(a['invoice_date']).unix() - moment(b['invoice_date']).unix()
        })
      : {
          title: 'INVOICE DATE',
          dataIndex: 'invoice_date',
          key: 'invoice_date',
          sorter: (a, b) =>
            moment(a['invoice_date']).unix() - moment(b['invoice_date']).unix()
        },
    {
      title: 'NET PAYABLE',
      dataIndex: 'net_payable',
      key: 'net_payable',
      sorter: (a, b) => Number(a.net_payable) - Number(b.net_payable),
      ellipsis: {
        showTitle: false
      },
      render: (net_payable) => {
        return (
          <>
            <Tooltip
              title={numIntStyleFormat(net_payable)}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span style={{ fontWeight: 'bold' }}>
                {net_payable >= 0 ? (
                  <span>₹{numFormating(net_payable)}</span>
                ) : (
                  <span>-₹{numFormating(net_payable)}</span>
                )}
              </span>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'AGEING',
      dataIndex: 'ageing',
      key: 'ageing',
      sorter: (a, b) =>
        Number(a.ageing.replace(` days`, '')) -
        Number(b.ageing.replace(` days`, ''))
    }
  ];

  const rowSelection = {
    selectedRowKeys: reqAllocInvoiceKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      const unselectedRows = data?.filter((object1) => {
        return !selectedRows.some((object2) => {
          return object1.id === object2.id;
        });
      });
      setUnselectedInvoices(unselectedRows);

      setReqAllocInvoiceKeys(selectedRowKeys);
      setReqAllocInvoices(selectedRows);

      const reCalculatedTotalAmount = selectedRows?.reduce(
        (a, b) => a + parseFloat(b?.net_payable),
        0
      );
      // setReqAmount(reCalculatedTotalAmount);
      setTotalInvoicesAmount(reCalculatedTotalAmount);
    }
  };

  // console.log('reqAllocInvoices', reqAllocInvoices);
  // console.log('unselectedInvoices', unselectedInvoices);
  // console.log('data', data);

  useEffect(() => {
    const total = Math.abs(totalInvoicesAmount) + Math.abs(currentTotal);
    if (total > Math.abs(grandTotal)) {
      message.error('You are exceeding the allocated liability');
    }
  }, [totalInvoicesAmount]);

  const onOk = () => {
    if (user_role === 'Finance CoE') {
      // let debit_bank_detail = null;
      // const allRows = data?.map((item) => item?.id);
      const approvedRows = reqAllocInvoices?.map((item) => item?.id);
      //const rejectedRows = unselectedInvoices?.map((item) => item?.id);
      let payload = {
        approved_allocation_invoice_id: approvedRows, // data?.map((item) => item?.id),
        rejected_allocation_invoice_id: [], //unselectedInvoices?.map(
        //   (item) => item?.id
        // ),
        bank_detail: activeDebitBankList.find(
          (item) => item.bank_account_number === selectedBank
        ),
        requested_fund: totalInvoicesAmount,
        selected_worth: totalInvoicesAmount
        /*  bank_detail: {
          bank_name: bankName,
          bank_branch_name: bankIFSC,
          bank_account_number: bankAccount,
          bank_account_name: accountHolderName
        } */
        /*  approved_allocation_invoice_id:
          approvedRows?.length === 0 && rejectedRows?.length === 0
            ? allRows
            : approvedRows,
        rejected_allocation_invoice_id: rejectedRows,
        debit_bank_detail: activeDebitBankList.find(
          (item) => item.bank_branch_name === selectedBank
        ) */
        /*  debit_bank_detail: data[0]?.non_H2H
        ? debit_bank_detail
        : activeDebitBankList.find(
            (item) => item.bank_branch_name === selectedBank
          ), */
      };
      handlePostBuRequestFundAllocation('Finance', payload);
    }
  };

  return (
    <CustomModal
      title="Request Fund Allocation"
      visible={visible}
      width={'90%'}
      bodyStyle={{ height: '80vh', overflow: 'scroll', overflowX: 'hidden' }}
      style={{ top: 50 }}
      onCancel={toggleModal}
      cancelButtonProps={{ onClick: toggleModal }}
      okText={
        totalInvoicesAmount >= 0
          ? `Submit Fund allocation of ₹${numFormating(
              totalInvoicesAmount
            )} for vendor`
          : `Submit Fund allocation of -₹${numFormating(
              totalInvoicesAmount
            )} for vendor`
      }
      okButtonProps={{
        disabled:
          !selectedBank?.length > 0 ||
          totalInvoicesAmount >= 0 ||
          Math.abs(totalInvoicesAmount) + Math.abs(currentTotal) >
            Math.abs(grandTotal),
        //disabled: !selectedBank?.length || !reqAmount > 0,
        // disabled:
        //   !bankName?.length > 0 ||
        //   !bankIFSC?.length > 0 ||
        //   !bankAccount?.length > 0 ||
        //   !accountHolderName > 0,
        loading: postAllocationRequestLoading,
        onClick: onOk
      }}
      //openCheckbox={openCheckbox}
      //setOpenCheckbox={setOpenCheckbox}
    >
      <div>
        <Space size={'middle'} align="center">
          <Text
            className={`${styles.req_alloc_modal_vendor_details} sfprotext-medium font-13`}
          >{`${SBU_name}`}</Text>
          <Space
            size={'large'}
            className={`${styles.req_alloc_modal_requested_amount_details}`}
          >
            {/*   <Text className="font-14">{'Requested amount:'}</Text> */}
            <Text className="font-14">{requestedAmount}:</Text>
            {!editReqAmount ? (
              <Text className="sfprotext-bold font-14">
                <Tooltip
                  title={numIntStyleFormat(reqAmount)}
                  color={'white'}
                  overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                  arrowPointAtCenter="false"
                >
                  {reqAmount >= 0 ? (
                    <span>₹{numFormating(reqAmount)}</span>
                  ) : (
                    <span>-₹{numFormating(reqAmount)}</span>
                  )}
                </Tooltip>
              </Text>
            ) : (
              <InputNumber
                min={1}
                max={totalInvoicesAmount}
                value={reqAmount}
                onChange={setReqAmount}
              />
            )}
            {/* <CustomButton
            type="link"
            onClick={() => setEditReqAmount(!editReqAmount)}
          >
            {editReqAmount ? 'Save' : 'Edit amount'}
          </CustomButton> */}
          </Space>
        </Space>
      </div>
      <div>
        <Space size={'middle'} align="center" className="mt-3">
          <Text className="sfprotext-bold font-14">
            {'Selected invoices for the requested amount'}
          </Text>
          <Space
            size={'middle'}
            className={`${styles.req_alloc_modal_invoices_total_amount_details}`}
          >
            <Text className="font-14">{`${
              (fromSummary ? reqAllocInvoices : data).length
            } invoices of worth`}</Text>
            <Text className="sfprotext-bold font-14">
              <Tooltip
                title={numIntStyleFormat(totalInvoicesAmount)}
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                {totalInvoicesAmount >= 0 ? (
                  <span>₹{numFormating(totalInvoicesAmount)}</span>
                ) : (
                  <span>-₹{numFormating(totalInvoicesAmount)}</span>
                )}
              </Tooltip>
            </Text>
          </Space>
        </Space>
      </div>
      {/* <div className="mt-3" style={{ paddingBottom: '5px' }}>
        <Row gutter={25}>
          <Col span={8}>
            <Text style={{ fontSize: '12px', paddingLeft: '2px' }}>
              Bank Name:
            </Text>
            <Input
              value={bankName}
              onChange={(e) => setBankName(e.target.value)}
              placeholder="Enter Bank Name:"
            />
          </Col>
          <Col span={8}>
            <Text style={{ fontSize: '12px', paddingLeft: '2px' }}>
              IFSC Code:
            </Text>
            <Input
              value={bankIFSC}
              onChange={(e) => setBankIFSC(e.target.value)}
              placeholder="Enter IFSC Code:"
            />
          </Col>
        </Row>
      </div>
      <div style={{ paddingBottom: '15px' }}>
        <Row gutter={25}>
          <Col span={8}>
            <Text style={{ fontSize: '12px', paddingLeft: '2px' }}>
              {' '}
              A/C Number:
            </Text>
            <Input
              value={bankAccount}
              onChange={(e) => setBankAccount(e.target.value)}
              placeholder="Enter A/C Number"
            />
          </Col>
          <Col span={8}>
            <Text style={{ fontSize: '12px', paddingLeft: '2px' }}>
              {' '}
              A/C Holder Name:
            </Text>
            <Input
              value={accountHolderName}
              onChange={(e) => setAccountHolderName(e.target.value)}
              placeholder="Enter A/C Holder Name"
            />
          </Col>
        </Row>
      </div> */}
      <div className="mt-3">
        <Text className="sfprotext-medium font-14 mr-3">Select Bank:</Text>
        <Select
          size="middle"
          showSearch
          style={{ minWidth: '50%', fontSize: '12px', height: 30 }}
          placeholder="Search to Select"
          value={selectedBank}
          optionFilterProp="children"
          filterOption={(input, option) => option.children.includes(input)}
          onChange={setSelectedBank}
          defaultValue={`Name: ${activeDebitBankList[0]?.bank_name}, IFSC: ${activeDebitBankList[0]?.bank_branch_name}, A/c no: ${activeDebitBankList[0]?.bank_account_number}`}
        >
          {activeDebitBankList.map((bank, index) => {
            const { bank_account_number } = bank;
            /* const { bank_name, bank_branch_name, bank_account_number } = bank; */
            return (
              <Option key={index} value={bank.bank_account_number}>
                {' '}
                <div style={{ fontSize: '12px' }}>
                  {` A/c no: ${bank_account_number}`}
                  {/* {`Name: ${bank_name}, IFSC: ${bank_branch_name}, A/c no: ${bank_account_number}`} */}
                </div>
              </Option>
            );
          })}
        </Select>
      </div>

      {/* <div className="mt-3">
        <SearchBarDashboard
          justify="start"
          align="middle"
          className="mb-4"
          gutter={32}
          inputProps={{
            placeholder: 'Search using invoice number/project'
          }}
          //filterValues={filterValues}
          handleFiltersOnChange={handleFiltersOnChange}
          user_role={user_role}
        />
      </div> */}
      <div className="mt-3">
        <CustomTableModal
          style={{
            fontFamily: 'SFProText Regular',
            padding: '40px 4px !important',
            lineHeight: '18px',
            color: '#32324D'
          }}
          data={data}
          columns={columns}
          pagination={false}
          rowSelection={fromSummary ? rowSelection : null}
        />
      </div>
    </CustomModal>
  );
};

export default FinanceCoESummaryAllocModal;
