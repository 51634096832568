import { configureStore } from '@reduxjs/toolkit';
import loginReducer from 'features/login/redux/slice';
import counterReducer from 'features/counter/redux/slice';
import invoicesReducer from 'features/invoices/redux/slice';
import treasuryReducer from 'features/invoices/components/treasury/redux/slice';
import userReducer from 'features/user/redux/slice';

export const store = configureStore({
  reducer: {
    login: loginReducer,
    counter: counterReducer,
    invoices: invoicesReducer,
    treasury: treasuryReducer,
    user: userReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});
