import React from 'react';
import { Image, Tag, Tooltip, Row, Button, Spin } from 'antd';
import styles from './index.module.less';
import emptyState from 'assets/images/empty_state.svg';
import Header from 'common/Content/header';
import SearchBarDashboard from 'common/Content/searchBarDashboard';
import CustomTable from 'common/Content/CustomTable';
import SubHeader from 'common/Content/SubHeader';
//import CustomButton from 'common/CustomButton';
import Switcher from '../Switcher';
import {
  ageFilters,
  statusFilters,
  numFormating,
  numIntStyleFormat
} from 'utilities/helpers';
import ReqAllocModal from '../ReqAllocModal';
import moment from 'moment';
import { history } from 'app/history';
import ExportProposedInvoices from './exportProposedInvoices/index';
import ExportInitiatedInvoices from './exportInitiatedInvoices';
import ExportRatifiedInvoices from './exportRatifiedInvoices';
import ExportAllocatedInvoices from './exportAllocatedInvoices';
import ExportInvoicesJourney from './exportInvoicesJourney';
import LiabilityNotAvailableModal from './if_liability_not_available';
import ReqRejectModal from '../ReqRejectModal';

//const { Link } = Typography;

const InvoicesPage = ({
  loading,
  invoices = [],
  pagination,
  filterValues,
  page,
  setPage,
  pageSize,
  setPageSize,
  glView,
  toggleGlView,
  reqAllocInvoiceKeys,
  setReqAllocInvoiceKeys,
  reqAllocInvoices,
  setReqAllocInvoices,
  vendorList,
  openReqAllocModal,
  setOpenReqAllocModal,
  activeBankList,
  handleGetActiveBankList,
  postAllocationRequestLoading,
  handlePostAllocationRequest,
  handleFiltersOnChange,
  tab,
  handleTabOnChange,
  //allInvoicesPagination,
  //openInvoicesPagination,
  //paidInvoicesPagination,
  //pendingInvoicesPagination,
  // rejectedInvoicesPagination,
  // allocatedInvoicesPagination,
  // approvedInvoicesPagination,
  // ratifiedInvoicesPagination,
  user_role,
  projectList,
  //projectAssigned,
  // BU_assigned,
  BUList,
  SBUList,
  activeDebitBankList,
  sendBackAllocRequestHandler,
  getExportPaidInvoicesHandler,
  //vendorSearch,
  setVendorSearch,
  setProjectSearch,
  all,
  initiated,
  open,
  paid,
  rejected,
  proposed,
  ratified,
  allocated,
  openExportPropModal,
  setOpenExportPropModal,
  openExportInitiatedModal,
  setOpenExportInitiatedModal,
  setOpenExportRatifiedModal,
  openExportRatifiedModal,
  setOpenExportAllocatedModal,
  openExportAllocatedModal,
  setOpenExportJourneyModal,
  openExportJourneyModal,
  setOpenLiabilityNotAvailableModal,
  openLiabilityNotAvailableModal,
  handlePostRejectRequest,
  setOpenReqRejectModal,
  openReqRejectModal,
  postSendBackAllocRequestInvoiceWiseLoading,
  getTLReportsHandler,
  lodingTLReport
}) => {
  // console.log('invoices_Next>>>>:', invoices);
  // console.log('parseInt ()>>>>:', parseInt('134567'));
  let requestedAmount = 'RequestedAmount';

  const columnsPE = [
    {
      //title: 'PROJECT CODE',
      title: (
        <Tooltip
          title="PROJECT CODE"
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          PROJECT CODE
        </Tooltip>
      ),
      dataIndex: 'tds_project_code',
      key: 'tds_project_code',
      width: '100px',
      sorter: (a, b) => a.tds_project_code - b.tds_project_code,
      ellipsis: {
        showTitle: false
      },
      render: (tds_project_code) => {
        return (
          <>
            <Tooltip
              title={tds_project_code}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {tds_project_code}
            </Tooltip>
          </>
        );
      }
    },
    {
      // title: 'VENDOR',
      title: <span>VENDOR</span>,
      dataIndex: 'vendor_name',
      key: 'vendor_name',
      sorter: (a, b) => a.vendor_name.localeCompare(b.vendor_name),
      ellipsis: true,
      // width: '200px',
      render: (vendor_name) => {
        return (
          <>
            <Tooltip
              title={vendor_name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
              placement="top"
            >
              <span style={{ textTransform: 'uppercase' }}>{vendor_name}</span>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'INVOICE NUMBER', //style={{backgroundColor:"white !important", color:"black"}}
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      sorter: (a, b) => a.invoice_number.localeCompare(b.invoice_number),
      ellipsis: {
        showTitle: false
      },
      //  width: '200px',
      render: (invoice_number) => {
        return (
          <>
            <Tooltip
              title={<a>{invoice_number}</a>}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
              placement="top"
            >
              <a>{invoice_number}</a>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: `${glView ? 'GL' : 'INVOICE'} DATE`,
      dataIndex: `${glView ? 'GL' : 'invoice'}_date`,
      key: `${glView ? 'GL' : 'invoice'}_date`,
      ellipsis: true,
      width: '125px',
      sorter: (a, b) =>
        moment(a[`${glView ? 'GL' : 'invoice'}_date`]).unix() -
        moment(b[`${glView ? 'GL' : 'invoice'}_date`]).unix()
    },

    {
      title: 'NET PAYABLE',
      dataIndex: 'net_payable',
      key: 'net_payable',
      sorter: (a, b) => Number(a.net_payable) - Number(b.net_payable),
      ellipsis: {
        showTitle: false
      },
      width: '125px',
      render: (net_payable) => {
        return (
          <>
            <Tooltip
              title={
                <span style={{ fontWeight: 'bold' }}>
                  {numIntStyleFormat(net_payable)}
                </span>
              }
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span style={{ fontWeight: 'bold' }}>
                {net_payable >= 0 ? (
                  <span>₹{numFormating(net_payable)}</span>
                ) : (
                  <span>-₹{numFormating(net_payable)}</span>
                )}
              </span>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'AGEING',
      dataIndex: 'ageing',
      key: 'ageing',
      width: '115px',
      sorter: (a, b) =>
        Number(a.ageing.replace(` days`, '')) -
        Number(b.ageing.replace(` days`, ''))
    },
    {
      //title: 'ALLOCATION STATUS',
      title: (
        <Tooltip
          title="ALLOCATION STATUS"
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          ALLOCATION STATUS
        </Tooltip>
      ),
      dataIndex: 'status',
      key: 'status',
      width: '130px',
      ellipsis: {
        showTitle: false
      },
      render: (status) => {
        return (
          <Tag
            className={`${styles.status_tag}`}
            color={'#EAF5FF'}
            style={{
              color: '#006096',
              textTransform: 'capitalize',
              fontSize: '10.5px',
              fontWeight: 400
              // lineHeight: '16px'
            }}
          >
            <span style={{ textAlign: 'center' }}>{status}</span>
          </Tag>
        );
      }
    }
    /*  {
      title: 'ACTION',
      key: 'action',
      //ellipsis: true,
      width: '130px',
      render: (record) => (
        <Button
          className={`${styles.allocation_btn}`}
          style={{
            fontSize: '11px'
          }}
          type="link"
          disabled={
            user_role === 'Project Engineer'
              ? !reqAllocInvoices?.length ||
                record.status === 'initiated' ||
                reqAllocInvoices.find(
                  (item) => item.vendor_code !== record.vendor_code
                )
              : user_role === 'SBU Commercial'
              ? !reqAllocInvoices.length ||
                record.my_approval === 'proposed' ||
                reqAllocInvoices.find(
                  (item) => item.tds_project_code !== record.tds_project_code
                )
              : user_role === 'SBU Head'
              ? !reqAllocInvoices.length ||
                record.my_approval === 'ratified' ||
                reqAllocInvoices.find(
                  (item) => item.tds_project_code !== record.tds_project_code
                )
              : user_role === 'Treasury'
              ? !reqAllocInvoices.length ||
                record.my_approval === 'allocated' ||
                reqAllocInvoices.find(
                  (item) => item.tds_project_code !== record.tds_project_code
                )
              : !reqAllocInvoices.length ||
                reqAllocInvoices.find(
                  (item) => item.vendor_code !== record.vendor_code
                )
          }
          onClick={() => {
            setOpenReqAllocModal(!openReqAllocModal);
          }}
        >
          Request allocation
        </Button>
      )
    } */
  ];
  const columns = [
    {
      //title: 'PROJECT CODE',
      title: (
        <Tooltip
          title="PROJECT CODE"
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          PROJECT CODE
        </Tooltip>
      ),
      dataIndex: 'tds_project_code',
      key: 'tds_project_code',
      width: '100px',
      sorter: (a, b) => a.tds_project_code - b.tds_project_code,
      ellipsis: {
        showTitle: false
      },
      render: (tds_project_code) => {
        return (
          <>
            <Tooltip
              title={tds_project_code}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              {tds_project_code}
            </Tooltip>
          </>
        );
      }
    },
    {
      // title: 'VENDOR',
      title: <span>VENDOR</span>,
      dataIndex: 'vendor_name',
      key: 'vendor_name',
      sorter: (a, b) => a.vendor_name.localeCompare(b.vendor_name),
      ellipsis: true,
      // width: '200px',
      render: (vendor_name) => {
        return (
          <>
            <Tooltip
              title={vendor_name}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
              placement="top"
            >
              <span style={{ textTransform: 'uppercase' }}>{vendor_name}</span>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'INVOICE NUMBER', //style={{backgroundColor:"white !important", color:"black"}}
      dataIndex: 'invoice_number',
      key: 'invoice_number',
      sorter: (a, b) => a.invoice_number.localeCompare(b.invoice_number),
      ellipsis: {
        showTitle: false
      },
      //  width: '200px',
      render: (invoice_number) => {
        return (
          <>
            <Tooltip
              title={<a>{invoice_number}</a>}
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
              placement="top"
            >
              <a>{invoice_number}</a>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: `${glView ? 'GL' : 'INVOICE'} DATE`,
      dataIndex: `${glView ? 'GL' : 'invoice'}_date`,
      key: `${glView ? 'GL' : 'invoice'}_date`,
      ellipsis: true,
      width: '125px',
      sorter: (a, b) =>
        moment(a[`${glView ? 'GL' : 'invoice'}_date`]).unix() -
        moment(b[`${glView ? 'GL' : 'invoice'}_date`]).unix()
    },

    {
      title: 'NET PAYABLE',
      dataIndex: 'net_payable',
      key: 'net_payable',
      sorter: (a, b) => Number(a.net_payable) - Number(b.net_payable),
      ellipsis: {
        showTitle: false
      },
      width: '125px',
      render: (net_payable) => {
        return (
          <>
            <Tooltip
              title={
                <span style={{ fontWeight: 'bold' }}>
                  {numIntStyleFormat(net_payable)}
                </span>
              }
              color={'white'}
              overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
              arrowPointAtCenter="false"
            >
              <span style={{ fontWeight: 'bold' }}>
                {net_payable >= 0 ? (
                  <span>₹{numFormating(net_payable)}</span>
                ) : (
                  <span>-₹{numFormating(net_payable)}</span>
                )}
              </span>
            </Tooltip>
          </>
        );
      }
    },
    {
      title: 'AGEING',
      dataIndex: 'ageing',
      key: 'ageing',
      width: '115px',
      sorter: (a, b) =>
        Number(a.ageing.replace(` days`, '')) -
        Number(b.ageing.replace(` days`, ''))
    },
    {
      //title: 'ALLOCATION STATUS',
      title: (
        <Tooltip
          title="ALLOCATION STATUS"
          color={'white'}
          overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
          arrowPointAtCenter="false"
        >
          ALLOCATION STATUS
        </Tooltip>
      ),
      dataIndex: 'status',
      key: 'status',
      width: '130px',
      ellipsis: {
        showTitle: false
      },
      render: (status) => {
        return (
          <Tag
            className={`${styles.status_tag}`}
            color={'#EAF5FF'}
            style={{
              color: '#006096',
              textTransform: 'capitalize',
              fontSize: '10.5px',
              fontWeight: 400
              // lineHeight: '16px'
            }}
          >
            <span style={{ textAlign: 'center' }}>{status}</span>
          </Tag>
        );
      }
    },
    /*  {
      title: 'ACTION',
      key: 'action',
      //ellipsis: true,
      width: '130px',
      render: (record) => (
        <Button
          className={`${styles.allocation_btn}`}
          style={{
            fontSize: '11px'
          }}
          type="link"
          disabled={
            user_role === 'Project Engineer'
              ? !reqAllocInvoices?.length ||
                record.status === 'initiated' ||
                reqAllocInvoices.find(
                  (item) => item.vendor_code !== record.vendor_code
                )
              : user_role === 'SBU Commercial'
              ? !reqAllocInvoices.length ||
                record.my_approval === 'proposed' ||
                reqAllocInvoices.find(
                  (item) => item.tds_project_code !== record.tds_project_code
                )
              : user_role === 'SBU Head'
              ? !reqAllocInvoices.length ||
                record.my_approval === 'ratified' ||
                reqAllocInvoices.find(
                  (item) => item.tds_project_code !== record.tds_project_code
                )
              : user_role === 'Treasury'
              ? !reqAllocInvoices.length ||
                record.my_approval === 'allocated' ||
                reqAllocInvoices.find(
                  (item) => item.tds_project_code !== record.tds_project_code
                )
              : !reqAllocInvoices.length ||
                reqAllocInvoices.find(
                  (item) => item.vendor_code !== record.vendor_code
                )
          }
          onClick={() => {
            setOpenReqAllocModal(!openReqAllocModal);
          }}
        >
          Request allocation
        </Button>
      )
    } */
    {
      title: 'ACTION',
      key: 'action',
      //ellipsis: true,
      width: '130px',
      render: (record) => (
        <Button
          className={`${styles.allocation_btn}`}
          style={{
            fontSize: '11px'
          }}
          type="link"
          disabled={
            user_role === 'SBU Commercial'
              ? record.status === 'proposed' ||
                record.status === 'ratified' ||
                record.status === 'allocated' ||
                record.status === 'Disbursed' ||
                record.status === 'Ratified' ||
                record.status === 'paid'
              : user_role === 'SBU Head'
              ? record.status === 'initiated' ||
                record.status === 'ratified' ||
                record.status === 'allocated' ||
                record.status === 'Disbursed' ||
                record.status === 'Ratified' ||
                record.status === 'paid'
              : user_role === 'Treasury'
              ? record.status === 'proposed' ||
                record.status === 'allocated' ||
                record.status === 'Disbursed' ||
                record.status === 'initiated' ||
                record.status === 'paid'
              : ''
          }
          onClick={() => {
            setOpenReqRejectModal(!openReqRejectModal);
          }}
        >
          Request reject
        </Button>
      )
    }
  ];
  let locale = {
    emptyText: (
      <span>
        <Image alt="users_empty_state" src={emptyState} preview={false} />
        <div className={styles.empty_state_text}>No Invoices</div>
      </span>
    )
  };

  const rowSelection = {
    selectedRowKeys: reqAllocInvoiceKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setReqAllocInvoiceKeys(selectedRowKeys);
      setReqAllocInvoices(selectedRows);
    }
    /* getCheckboxProps: (record) => ({
      disabled:
        user_role === 'Project Engineer'
          ? record.status === 'initiated' ||
            record.status === 'proposed' ||
            record.status === 'allocated' ||
            record.status === 'paid' ||
            record.status === 'disbursed' ||
            record.status === 'ratified' ||
            reqAllocInvoices.find(
              (item) => item.vendor_code !== record.vendor_code
            )
          : user_role === 'SBU Commercial'
          ? record.status === 'proposed' ||
            record.status === 'allocated' ||
            record.status === 'paid' ||
            record.status === 'open' ||
            record.status === 'ratified' ||
            record.status === 'disbursed' ||
            reqAllocInvoices.find(
              (item) => item.tds_project_code !== record.tds_project_code
            )
          : user_role === 'SBU Head'
          ? record.status === 'ratified' ||
            record.status === 'allocated' ||
            record.status === 'rejected' ||
            record.status === 'open' ||
            record.status === 'disbursed' ||
            record.status === 'initiated' ||
            record.status === 'paid' ||
            reqAllocInvoices.find(
              (item) => item.tds_project_code !== record.tds_project_code
            )
          : user_role === 'Treasury'
          ? record.status === 'paid' ||
            record.status === 'allocated' ||
            record.status === 'rejected' ||
            record.status === 'open' ||
            record.status === 'disbursed' ||
            record.status === 'initiated' ||
            record.status === 'proposed' ||
            reqAllocInvoices.find(
              (item) => item.tds_project_code !== record.tds_project_code
            )
          : reqAllocInvoices.find(
              (item) => item.vendor_code !== record.vendor_code
            ),

      name: record.vendor_code
    }) */
  };

  return (
    <div className={styles.invoices_container}>
      <Header
        justify="space-between"
        align="middle"
        title="All Invoices"
        titleSuffix=""
        rightComponent={
          <Row>
            {user_role === 'Project Engineer' && (
              <div className={styles.custTag}>
                <a
                  onClick={() => {
                    setOpenLiabilityNotAvailableModal(true);
                  }}
                  className="sfprotext-medium font-15"
                  /* style={{
                  color: '#000000',
                  fontWeight: 500,
                  fontFamily: 'SFProText Medium !important'
                }} */
                >
                  <span className={styles.liabilityNotAvailable_text}>
                    If Liability Not Available
                  </span>
                </a>
              </div>
            )}
            <Switcher
              left={'GL Date'}
              right={'Invoice date'}
              onChange={toggleGlView}
            />
          </Row>
        }
        className="mb-4"
      />

      {user_role === 'Project Engineer' && (
        <Spin spinning={lodingTLReport}>
          <SubHeader
            justify="space-between"
            align="middle"
            tabs={[
              {
                id: 1,
                label: 'Open',
                count: open || 0 // openInvoicesPagination?.total_records || 0
              },
              {
                id: 2,
                label: 'Initiated',
                count: initiated || 0 // pendingInvoicesPagination?.total_records || 0
              },
              {
                id: 3,
                label: 'Rejected',
                count: rejected || 0 // rejectedInvoicesPagination?.total_records || 0
              },
              {
                id: 4,
                label: 'Paid',
                count: paid || 0 // paidInvoicesPagination?.total_records || 0
              },
              {
                id: 5,
                label: 'All',
                count: all || 0 // allInvoicesPagination?.total_records || 0
              }
            ]}
            onTabChange={handleTabOnChange}
            buttons={[
              {
                text: 'Export initiated',
                onClick: () => {
                  setOpenExportInitiatedModal(true);
                }
              },
              {
                text: 'Export paid',
                onClick: () => getExportPaidInvoicesHandler()
              },
              {
                text: 'Invoices Journey',
                onClick: () => setOpenExportJourneyModal(true)
              },
              {
                text: 'TL Report',
                onClick: () => getTLReportsHandler()
              },
              {
                type: 'primary',
                text: 'Summarize',
                onClick: () => history.push('/summary')
              }
            ]}
          />
        </Spin>
      )}
      {user_role === 'SBU Commercial' && (
        <Spin spinning={lodingTLReport}>
          <SubHeader
            justify="space-between"
            align="middle"
            tabs={[
              {
                id: 1,
                label: 'Initiated',
                count: initiated // pendingInvoicesPagination?.total_records || 0
              },
              {
                id: 2,
                label: 'Proposed',
                count: proposed // approvedInvoicesPagination?.total_records || 0
              },
              {
                id: 3,
                label: 'Rejected',
                count: rejected // rejectedInvoicesPagination?.total_records || 0
              },

              {
                id: 4,
                label: 'Paid',
                count: paid // paidInvoicesPagination?.total_records || 0
              },
              {
                id: 5,
                label: 'All',
                count: all // allInvoicesPagination?.total_records || 0
              }
            ]}
            onTabChange={handleTabOnChange}
            buttons={[
              {
                text: 'Transaction complete',
                onClick: () => sendBackAllocRequestHandler()
              },
              {
                text: 'Export initiated',
                onClick: () => {
                  setOpenExportInitiatedModal(true);
                }
              },
              {
                text: 'Export proposed',
                onClick: () => {
                  setOpenExportPropModal(true);
                }
              },
              /*  {
              text: 'Export paid',
              onClick: () => getExportPaidInvoicesHandler()
            }, */
              {
                text: 'Invoices Journey',
                onClick: () => setOpenExportJourneyModal(true)
              },
              {
                text: 'TL Report',
                onClick: () => getTLReportsHandler()
              },
              {
                type: 'primary',
                text: 'Summarize',
                onClick: () => history.push('/summary')
              }
            ]}
          />
        </Spin>
      )}
      {user_role === 'SBU Head' && (
        <Spin spinning={lodingTLReport}>
          <SubHeader
            justify="space-between"
            align="middle"
            tabs={[
              {
                id: 1,
                label: 'Proposed',
                count: proposed //approvedInvoicesPagination?.total_records || 0
              },
              {
                id: 2,
                label: 'Ratified',
                count: ratified // ratifiedInvoicesPagination?.total_records || 0
              },
              /* {
              id: 3,
              label: 'Rejected',
              count: rejectedInvoicesPagination?.total_records || 0
            },
 */
              {
                id: 3,
                label: 'Paid',
                count: paid //paidInvoicesPagination?.total_records || 0
              },
              {
                id: 4,
                label: 'All',
                count: all // allInvoicesPagination?.total_records || 0
              }
            ]}
            onTabChange={handleTabOnChange}
            buttons={[
              {
                text: 'Transaction complete',
                onClick: () => sendBackAllocRequestHandler()
              },
              {
                text: 'Export proposed',
                onClick: () => {
                  setOpenExportPropModal(true);
                }
              },
              /* {
              text: 'Export paid',
              onClick: () => getExportPaidInvoicesHandler()
            }, */
              {
                text: 'Invoices Journey',
                onClick: () => setOpenExportJourneyModal(true)
              },
              {
                text: 'TL Report',
                onClick: () => getTLReportsHandler()
              },
              {
                type: 'primary',
                text: 'Summarize',
                onClick: () => history.push('/summary')
              }
            ]}
          />
        </Spin>
      )}
      {user_role === 'Treasury' && (
        <Spin spinning={lodingTLReport}>
          <SubHeader
            justify="space-between"
            align="middle"
            tabs={[
              {
                id: 1,
                label: 'Ratified',
                count: ratified //ratifiedInvoicesPagination?.total_records || 0
              },
              {
                id: 2,
                label: 'Allocated',
                count: allocated // allocatedInvoicesPagination?.total_records || 0
              },
              {
                id: 3,
                label: 'Paid',
                count: paid // paidInvoicesPagination?.total_records || 0
              },
              {
                id: 4,
                label: 'All',
                count: all // allInvoicesPagination?.total_records || 0
              }
            ]}
            onTabChange={handleTabOnChange}
            buttons={[
              {
                text: 'Transaction complete',
                onClick: () => sendBackAllocRequestHandler()
              },
              {
                text: 'Export ratified',
                onClick: () => {
                  setOpenExportRatifiedModal(true);
                }
              },
              {
                text: 'Export Allocated',
                onClick: () => {
                  setOpenExportAllocatedModal(true);
                }
              },
              /*  {
              text: 'Export paid',
              onClick: () => getExportPaidInvoicesHandler()
            }, */
              {
                text: 'Invoices Journey',
                onClick: () => setOpenExportJourneyModal(true)
              },
              {
                text: 'TL Report',
                onClick: () => getTLReportsHandler()
              },
              {
                type: 'primary',
                text: 'Summarize',
                onClick: () => history.push('/summary')
              }
            ]}
          />
        </Spin>
      )}
      <div className={`${styles.invoices_table_container}`}>
        {user_role === 'Project Engineer' ? (
          <SearchBarDashboard
            justify="start"
            align="middle"
            className="mb-4"
            gutter={32}
            inputProps={{
              placeholder: 'Search using invoice number/vendor name'
            }}
            filters={[
              /*  {
                label: 'Non-H2H & Foreign',
                slug: '',
                // options: '',
                type: 'select'
              }, */
              {
                label: 'Project',
                slug: 'tds_project_code',
                options: projectList,
                type: 'select'
              },
              {
                label: 'Vendor',
                slug: 'vendor',
                options: vendorList,
                type: 'select'
              },
              {
                label: 'Age',
                slug: 'age',
                options: ageFilters
              },
              {
                label: `${glView ? 'GL' : 'Invoice'} date`,
                slug: `${glView ? 'gl' : 'invoice'}_date`,
                type: 'date'
              },
              tab === 5
                ? {
                    label: 'Status',
                    slug: 'status',
                    options: statusFilters
                  }
                : null
            ]}
            filterValues={filterValues}
            handleFiltersOnChange={handleFiltersOnChange}
            user_role={user_role}
            setVendorSearch={setVendorSearch}
            setProjectSearch={setProjectSearch}
          />
        ) : user_role === 'SBU Commercial' ? (
          <SearchBarDashboard
            justify="start"
            align="middle"
            className="mb-4"
            gutter={32}
            inputProps={{
              placeholder: 'Search using invoice number/vendor name'
            }}
            filters={[
              {
                label: 'BU',
                slug: 'BU_name',
                options: BUList,
                type: 'select'
              },
              /*  {
                label: 'BU',
                slug: 'BU_name',
                options: BUList,
                type: 'select'
              }, */
              {
                label: 'Project',
                slug: 'tds_project_code',
                options: projectList,
                type: 'select'
              },
              {
                label: 'Vendor',
                slug: 'vendor',
                options: vendorList,
                type: 'select'
              },
              {
                label: 'Age',
                slug: 'age',
                options: ageFilters
              },
              {
                label: `${glView ? 'GL' : 'Invoice'} date`,
                slug: `${glView ? 'gl' : 'invoice'}_date`,
                type: 'date'
              },

              tab === 5
                ? {
                    label: 'Status',
                    slug: 'status',
                    options: statusFilters
                  }
                : null
            ]}
            filterValues={filterValues}
            handleFiltersOnChange={handleFiltersOnChange}
            user_role={user_role}
            setVendorSearch={setVendorSearch}
            setProjectSearch={setProjectSearch}
          />
        ) : user_role === 'SBU Head' ? (
          <SearchBarDashboard
            justify="start"
            align="middle"
            className="mb-4"
            gutter={32}
            inputProps={{
              placeholder: 'Search using invoice number/vendor name'
            }}
            filters={[
              {
                label: 'BU',
                slug: 'BU_name',
                options: BUList,
                type: 'select'
              },
              {
                label: 'Project',
                slug: 'tds_project_code',
                options: projectList,
                type: 'select'
              },
              {
                label: 'Vendor',
                slug: 'vendor',
                options: vendorList,
                type: 'select'
              },
              {
                label: 'Age',
                slug: 'age',
                options: ageFilters
              },
              {
                label: `${glView ? 'GL' : 'Invoice'} date`,
                slug: `${glView ? 'gl' : 'invoice'}_date`,
                type: 'date'
              },

              tab === 5
                ? {
                    label: 'Status',
                    slug: 'status',
                    options: statusFilters
                  }
                : null
            ]}
            filterValues={filterValues}
            handleFiltersOnChange={handleFiltersOnChange}
            user_role={user_role}
            setVendorSearch={setVendorSearch}
            setProjectSearch={setProjectSearch}
          />
        ) : user_role === 'Treasury' ? (
          <SearchBarDashboard
            justify="start"
            align="middle"
            className="mb-4"
            gutter={32}
            inputProps={{
              placeholder: 'Search using invoice number/vendor name'
            }}
            filters={[
              {
                label: 'SBU',
                slug: 'SBU_name',
                options: SBUList,
                type: 'select'
              },
              {
                label: 'BU',
                slug: 'BU_name',
                options: BUList,
                type: 'select'
              },
              {
                label: 'Project',
                slug: 'tds_project_code',
                options: projectList,
                type: 'select'
              },
              {
                label: 'Vendor',
                slug: 'vendor',
                options: vendorList,
                type: 'select'
              },
              {
                label: 'Age',
                slug: 'age',
                options: ageFilters
              },
              {
                label: `${glView ? 'GL' : 'Invoice'} date`,
                slug: `${glView ? 'gl' : 'invoice'}_date`,
                type: 'date'
              },

              tab === 5
                ? {
                    label: 'Status',
                    slug: 'status',
                    options: statusFilters
                  }
                : null
            ]}
            filterValues={filterValues}
            handleFiltersOnChange={handleFiltersOnChange}
            user_role={user_role}
            setVendorSearch={setVendorSearch}
            setProjectSearch={setProjectSearch}
          />
        ) : null}

        <CustomTable
          data={invoices}
          columns={user_role === 'Project Engineer' ? columnsPE : columns}
          locale={locale}
          loading={loading}
          rowSelection={rowSelection}
          pagination={{
            current: page,
            size: 'small',
            showSizeChanger: true,
            total: pagination?.total_records || 0,
            pageSize: pageSize,
            onChange: (page, pageSize) => {
              setPage(page);
              setPageSize(pageSize);
            }
          }}
        />

        {openReqAllocModal ? (
          <ReqAllocModal
            requestedAmount={requestedAmount}
            visible={openReqAllocModal}
            toggleModal={() => {
              setOpenReqAllocModal(!openReqAllocModal);
            }}
            data={reqAllocInvoices}
            glView={glView}
            activeBankList={activeBankList}
            handleGetActiveBankList={handleGetActiveBankList}
            postAllocationRequestLoading={postAllocationRequestLoading}
            handlePostAllocationRequest={handlePostAllocationRequest}
            user_role={user_role}
            handleFiltersOnChange={handleFiltersOnChange}
            activeDebitBankList={activeDebitBankList}
          />
        ) : null}
      </div>
      {openExportPropModal ? (
        <ExportProposedInvoices
          openExportPropModal={openExportPropModal}
          setOpenExportPropModal={setOpenExportPropModal}
          // glView={glView}
        />
      ) : (
        ''
      )}
      {openExportInitiatedModal ? (
        <ExportInitiatedInvoices
          setOpenExportInitiatedModal={setOpenExportInitiatedModal}
          openExportInitiatedModal={openExportInitiatedModal}
          // glView={glView}
        />
      ) : (
        ''
      )}

      {openExportRatifiedModal ? (
        <ExportRatifiedInvoices
          setOpenExportRatifiedModal={setOpenExportRatifiedModal}
          openExportRatifiedModal={openExportRatifiedModal}
        />
      ) : (
        ''
      )}
      {openExportAllocatedModal ? (
        <ExportAllocatedInvoices
          setOpenExportAllocatedModal={setOpenExportAllocatedModal}
          openExportAllocatedModal={openExportAllocatedModal}
        />
      ) : (
        ''
      )}
      {openExportJourneyModal ? (
        <ExportInvoicesJourney
          setOpenExportJourneyModal={setOpenExportJourneyModal}
          openExportJourneyModal={openExportJourneyModal}
        />
      ) : (
        ''
      )}
      {openLiabilityNotAvailableModal ? (
        <LiabilityNotAvailableModal
          setOpenLiabilityNotAvailableModal={setOpenLiabilityNotAvailableModal}
          openLiabilityNotAvailableModal={openLiabilityNotAvailableModal}
        />
      ) : (
        ''
      )}
      {openReqRejectModal ? (
        <ReqRejectModal
          handlePostRejectRequest={handlePostRejectRequest}
          requestedAmount={requestedAmount}
          visible={openReqRejectModal}
          toggleModal={() => {
            setOpenReqRejectModal(!openReqRejectModal);
          }}
          data={reqAllocInvoices}
          glView={glView}
          activeBankList={activeBankList}
          handleGetActiveBankList={handleGetActiveBankList}
          postSendBackAllocRequestInvoiceWiseLoading={
            postSendBackAllocRequestInvoiceWiseLoading
          }
          user_role={user_role}
          handleFiltersOnChange={handleFiltersOnChange}
          activeDebitBankList={activeDebitBankList}
        />
      ) : null}
    </div>
  );
};

export default InvoicesPage;
