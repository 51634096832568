import React, { useState, useEffect } from 'react';
import { InputNumber, Space, Typography, Button, Tooltip } from 'antd';
import styles from './index.module.less';
import CustomButton from 'common/CustomButton';
import {
  liabilityToLabelBU,
  numFormating,
  numIntStyleFormat
} from 'utilities/helpers';
import CustomModal from 'common/CustomModal';
import BuHeadSummaryAllocModal from '../BuHeadSummaryAllocModal';
import { DoubleRightOutlined } from '@ant-design/icons';
import { history } from 'app/history';

const { Title, Text } = Typography;

const BuHeadSummaryInvoiceItem = ({
  data,
  reqFund,
  setReqFund,
  openReqFundAllocModal,
  setOpenReqFundAllocModal,
  handleRequestFund,
  selectedBankBranchName,
  // setSelectedBankBranchName,
  openReqAllocModal,
  setOpenReqAllocModal,
  postRequestFundLoading,
  requestFundInvoices,
  postAllocationRequestLoading,
  handlePostAllocationRequest,
  summaryInvoicesActiveBankList,
  clickSubmitInvoices,
  setClickSubmitInvoices,
  allInvoicesByAge,
  handleSubmitInvoices,
  user_role,
  handlePostBuRequestFundAllocation,
  liabilityAmount,
  setOpenCheckbox,
  openCheckbox,
  handleFiltersOnChangeSerchInModal
}) => {
  if (!data) return null;

  const {
    initiated_liability,
    tds_project_code,
    tds_project_name,
    total_liability,
    proposed_liability,
    ratified_liability,
    total_advances_up
  } = data;

  const [liabilities, setLiabilities] = useState([]);

  useEffect(() => {
    let allLiabilities = Object.keys(total_liability);
    allLiabilities.splice(6, 1);
    setLiabilities(allLiabilities);
  }, [total_liability]);

  return (
    <div className={`${styles.summary_invoice_item_container} mb-3`}>
      <div style={{ position: 'relative' }}>
        <Space>
          <Text
            className={`sfprotext-medium ${styles.summary_invoice_item_vendor_details_container}`}
          >
            {`${tds_project_name} (${tds_project_code})`}
            <Tooltip
              title="View vendors list"
              color={'#1E206A'}
              overlayInnerStyle={{ color: ' #FFFFFF', fontSize: '11px' }}
              arrowPointAtCenter="false"
            >
              <DoubleRightOutlined
                style={{ marginLeft: '8px' }}
                onClick={() => {
                  history.push(
                    `/buHeadVendorList/BU_name:${data?.BU_name},tds_project_code:${data?.tds_project_code} `
                  );
                }}
              />
            </Tooltip>
          </Text>
          <Text className="font-12">
            <Text className="mr-1" style={{ color: '#666687' }}>
              Advances(UP):
              <Tooltip
                title={numIntStyleFormat(total_advances_up)}
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                ₹{numFormating(total_advances_up)}
              </Tooltip>
            </Text>
            <Text style={{ color: '#212134' }}>{''}</Text>
          </Text>
        </Space>
        <CustomButton
          className={`${styles.summary_invoice_item_req_btn}`}
          onClick={() => {
            setOpenReqFundAllocModal({ [tds_project_code]: true });
          }}
        >
          Ratified Fund
        </CustomButton>
      </div>
      <div className={`${styles.summary_invoice_item_liabilities_container}`}>
        <Space
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginRight: '140px',
            marginLeft: '10px'
          }}
        >
          {liabilities.map((item, index) => {
            let isIndexOne = index === 0;
            let isTotalLiability = item === 'requested_amount';

            const submitPayload = {
              page: 1,
              per_page: 100000000000,
              tds_project_code,
              status: 'proposed',
              non_H2H: item === 'liability_non_H2H' ? true : false,
              BU_name: data?.BU_name
            };

            if (!isTotalLiability) {
              submitPayload['age'] =
                item === 'liability_non_H2H' || item === 'total_liability'
                  ? ' '
                  : item.replace('_days', '').replace('liability', 'days');
            }

            return (
              <Space
                key={index}
                direction="vertical" /* space beetween two libility columns */
              >
                <div
                  className="font-12"
                  style={{
                    color: '#666687',
                    padding: '2px',
                    margin: '0px'
                  }}
                >
                  {liabilityToLabelBU[item]}
                </div>

                <div
                  className="sfprotext-bold "
                  style={{
                    color: '#212134',
                    paddingLeft: '1px',
                    marginTop: '4px',
                    lineHeight: '0px',
                    fontSize: '12px' // Amount font size
                  }}
                >
                  <Tooltip
                    title={'Total'}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    <span>T: </span>
                  </Tooltip>
                  <Tooltip
                    title={numIntStyleFormat(total_liability[item])}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    {total_liability[item] === undefined ? (
                      ''
                    ) : total_liability[item] >= 0 ? (
                      total_liability[item] === undefined ? (
                        ''
                      ) : (
                        <span>₹{numFormating(total_liability[item])}</span>
                      )
                    ) : total_liability[item] === undefined ? (
                      ''
                    ) : (
                      <span>
                        -₹
                        {numFormating(total_liability[item])}
                      </span>
                    )}
                  </Tooltip>
                </div>
                <div
                  className="sfprotext-bold "
                  style={{
                    color: '#212134',
                    paddingLeft: '3px',
                    marginTop: '14px',
                    lineHeight: '0px',
                    fontSize: '12px' // Amount font size
                  }}
                >
                  {' '}
                  <Tooltip
                    title={'Initiated'}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    <span>I: </span>
                  </Tooltip>
                  <Tooltip
                    title={numIntStyleFormat(initiated_liability[item])}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    {initiated_liability[item] >= 0 ? (
                      initiated_liability[item] === undefined ? (
                        ''
                      ) : (
                        <span>₹{numFormating(initiated_liability[item])}</span>
                      )
                    ) : initiated_liability[item] === undefined ? (
                      ''
                    ) : (
                      <span>-₹{numFormating(initiated_liability[item])}</span>
                    )}
                  </Tooltip>
                </div>
                <div
                  className="sfprotext-bold "
                  style={{
                    color: '#212134',
                    paddingLeft: '2px',
                    marginTop: '14px',
                    lineHeight: '0px',
                    fontSize: '12px' // Amount font size
                  }}
                >
                  <Tooltip
                    title={'Proposed'}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    <span>P: </span>
                  </Tooltip>

                  <Tooltip
                    title={numIntStyleFormat(proposed_liability[item])}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    {proposed_liability[item] >= 0 ? (
                      proposed_liability[item] === undefined ? (
                        ''
                      ) : (
                        <span>₹{numFormating(proposed_liability[item])}</span>
                      )
                    ) : proposed_liability[item] === undefined ? (
                      ''
                    ) : (
                      <span>-₹{numFormating(proposed_liability[item])}</span>
                    )}
                  </Tooltip>
                </div>
                <div
                  className="sfprotext-bold "
                  style={{
                    color: '#212134',
                    paddingLeft: '2px',
                    marginTop: '14px',
                    lineHeight: '0px',
                    fontSize: '12px' // Amount font size
                  }}
                >
                  <Tooltip
                    title={'Ratified'}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    <span>R: </span>
                  </Tooltip>
                  <Tooltip
                    title={numIntStyleFormat(ratified_liability[item])}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    {ratified_liability[item] >= 0 ? (
                      ratified_liability[item] === undefined ? (
                        ''
                      ) : (
                        <span>₹{numFormating(ratified_liability[item])}</span>
                      )
                    ) : ratified_liability[item] === undefined ? (
                      ''
                    ) : (
                      <span>-₹{numFormating(ratified_liability[item])}</span>
                    )}
                  </Tooltip>
                </div>
                {!isIndexOne ? (
                  <Button
                    size="small"
                    style={{
                      paddingLeft: '0px',
                      paddingRight: '0px',
                      margin: '0px',
                      lineHeight: '0px'
                    }}
                    type="link"
                    onClick={() => {
                      setClickSubmitInvoices({ [tds_project_code]: true });
                      handleSubmitInvoices(submitPayload);
                    }}
                    disabled={
                      !proposed_liability[item] ||
                      proposed_liability[item] === ratified_liability[item]
                    }
                    className={`${styles.summary_invoice_item_submit_btn}`}
                  >
                    Submit invoices
                  </Button>
                ) : (
                  <span style={{ color: 'transparent' }}>.</span>
                )}
              </Space>
            );
          })}
        </Space>
      </div>
      {openReqFundAllocModal[tds_project_code] ? (
        <CustomModal
          visible={openReqFundAllocModal[tds_project_code]}
          onCancel={() => setOpenReqFundAllocModal({})}
          cancelButtonProps={{
            onClick: () => setOpenReqFundAllocModal({})
          }}
          okButtonProps={{
            loading: postRequestFundLoading,

            onClick: () =>
              handleRequestFund({
                requested_fund: reqFund[tds_project_code],
                tds_project_code,
                BU_name: data?.BU_name
              })
          }}
        >
          <Title level={5} className="font-12">
            Enter the amount you want to ratified
          </Title>
          <InputNumber
            min={1}
            max={
              proposed_liability['liability'] <= 0
                ? Math.abs(proposed_liability['liability'])
                : proposed_liability['liability']
            }
            placeholder="Enter amount"
            style={{ width: '70%' }}
            value={reqFund[tds_project_code] || null}
            onChange={(value) =>
              setReqFund({ ...reqFund, [tds_project_code]: value })
            }
          />
        </CustomModal>
      ) : null}
      {console.log(
        'openReqAllocModal[tds_project_code]',
        openReqAllocModal[tds_project_code]
      )}
      {openReqAllocModal[tds_project_code] ? (
        <BuHeadSummaryAllocModal
          requestedAmount={liabilityAmount}
          fromSummary={true}
          visible={openReqAllocModal[tds_project_code]}
          toggleModal={() => {
            setOpenReqAllocModal({});
            setClickSubmitInvoices({});
            setOpenCheckbox(false);
          }}
          data={
            clickSubmitInvoices[tds_project_code]
              ? allInvoicesByAge
              : requestFundInvoices
          }
          grandTotal={proposed_liability['liability']}
          currentTotal={ratified_liability['liability']}
          summaryInvoicesActiveBankList={summaryInvoicesActiveBankList}
          postAllocationRequestLoading={postAllocationRequestLoading}
          handlePostAllocationRequest={handlePostAllocationRequest}
          selectedBankBranchName={selectedBankBranchName}
          user_role={user_role}
          handlePostBuRequestFundAllocation={handlePostBuRequestFundAllocation}
          setOpenCheckbox={setOpenCheckbox}
          openCheckbox={openCheckbox}
          handleFiltersOnChangeSerchInModal={handleFiltersOnChangeSerchInModal}
        />
      ) : null}
    </div>
  );
};

export default BuHeadSummaryInvoiceItem;
