import React from 'react';
import {
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Input,
  Menu,
  Row,
  Select,
  Space,
  Typography
} from 'antd';
import { SearchOutlined, CaretDownOutlined } from '@ant-design/icons';
import moment from 'moment';
import { debounce } from 'lodash';

const { Text } = Typography;
const { Option } = Select;

const DisplayFilter = ({ type, options, value, onChange }) => {
  switch (type) {
    case 'select':
      return (
        <Select
          size="small"
          showSearch
          style={{
            width: 210,
            fontSize: '11px',
            padding: '8px'
          }}
          placeholder="Search to Select"
          optionFilterProp="children"
          filterOption={(input, option) => {
            return (
              option?.props?.children
                ?.toString()
                ?.toLowerCase()
                ?.indexOf(input?.toLowerCase()) >= 0 ||
              option?.props?.label
                ?.toString()
                ?.toLowerCase()
                ?.indexOf(input?.toLowerCase()) >= 0
            );
          }}
          onChange={onChange}
          defaultValue={options[0]} /////-----new added
        >
          {options.map((item, index) => {
            const { label, value } = item;

            return (
              <Option key={index} value={value} label={label}>
                <div style={{ fontSize: '11px' }}>{label}</div>
              </Option>
            );
          })}
        </Select>
      );

    case 'date':
      return (
        <DatePicker
          size="small"
          /*  style={{fontSize:"11px"}}
        dropdownStyle={{ height: 200 ,fontSize:"11px"}} */
          value={value !== 'All' ? moment(value) : null}
          format={'DD-MMM-YYYY'}
          onChange={(_, dateValue) => {
            onChange(dateValue || 'All');
          }}
        />
      );

    default:
      return (
        <Menu>
          {options.map((item, index) => {
            const { label, value } = item;

            return (
              <Menu.Item key={index} onClick={() => onChange(value)}>
                <span style={{ fontSize: '11px' }}>{label}</span>
              </Menu.Item>
            );
          })}
        </Menu>
      );
  }
};

const SearchBarVendorWise = ({
  inputProps,
  filters,
  filterValues,
  handleFiltersOnChange,
  //handleSearchVendor,
  user_role,
  ...rest
}) => {
  return (
    <Row {...rest}>
      {inputProps ? (
        <Col span={6}>
          <Input
            size="small"
            style={{ padding: '4px 10px' }}
            {...inputProps}
            prefix={<SearchOutlined />}
            onChange={debounce((e) => {
              if (user_role === 'SBU Commercial') {
                if (handleFiltersOnChange) {
                  handleFiltersOnChange('search_vendor', e.target.value);
                } else {
                  // handleSearchVendor(e.target.value);
                }
              } else if (user_role === 'SBU Head') {
                if (handleFiltersOnChange) {
                  handleFiltersOnChange('search_vendor', e.target.value);
                } else {
                  // handleSearchVendor(e.target.value);
                }
              } else if (user_role === 'Treasury') {
                if (handleFiltersOnChange) {
                  handleFiltersOnChange('search_vendor', e.target.value);
                } else {
                  // handleSearchVendor(e.target.value);
                }
              } else if (user_role === 'Project Engineer') {
                if (handleFiltersOnChange) {
                  handleFiltersOnChange('search_vendor', e.target.value);
                } else {
                  // handleSearchVendor(e.target.value);
                }
              } else if (user_role === 'Finance CoE') {
                if (handleFiltersOnChange) {
                  handleFiltersOnChange('search_vendor', e.target.value);
                } else {
                  // handleSearchVendor(e.target.value);
                }
              }
            }, 400)}
          />
        </Col>
      ) : null}
      {filters?.length ? (
        <Col span={18}>
          <Space
            style={{
              fontFamily: 'SFProText Regular',
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '12px',
              lineHeight: '18px',
              color: '#32324D'
            }}
          >
            <Text
              className="SFProText Regular"
              style={{
                fontStyle: 'normal',
                fontSize: '12px',
                lineHeight: '18px',
                fontWeight: 500,
                color: '#000000'
              }}
            >
              Filters:
            </Text>
            {filters.map((item, index) => {
              if (!item) return null;

              const { label, slug, options, type } = item;
              const selectedFilterValue = filterValues[slug] || '';
              const selectedFilterValueLabel = options?.length
                ? options?.find((o) => o.value === selectedFilterValue)
                    ?.label || 'All' //'NA'
                : selectedFilterValue;
              const showDivider = filters.length - 1 !== index;

              return (
                <Space key={index}>
                  <Text>{`${label}: `}</Text>
                  <Dropdown
                    overlay={
                      <DisplayFilter
                        type={type}
                        options={options}
                        value={selectedFilterValue}
                        onChange={(value) => handleFiltersOnChange(slug, value)}
                      />
                    }
                    trigger={['click']}
                    placement="bottom"
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <span>
                        {selectedFilterValueLabel} <CaretDownOutlined />
                      </span>
                    </a>
                  </Dropdown>
                  {showDivider ? <Divider type="vertical" /> : null}
                </Space>
              );
            })}
          </Space>
        </Col>
      ) : null}
    </Row>
  );
};

export default SearchBarVendorWise;
