import React, { useMemo } from 'react';
import {
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Input,
  Menu,
  Row,
  Select,
  Space,
  Typography
} from 'antd';
import { SearchOutlined, CaretDownOutlined } from '@ant-design/icons';
import moment from 'moment';
import { debounce } from 'lodash';

const { Text } = Typography;
const { Option } = Select;

const DisplayFilter = ({
  type,
  options,
  value,
  onChange,
  // setVendorSearch,
  // setProjectSearch,
  setSearchValue
}) => {
  const debounceFetcher = useMemo(() => {
    const loadOptions = (searchValue) => {
      setSearchValue(searchValue);
    };
    return debounce(loadOptions, 400);
  }, []);
  switch (type) {
    case 'select':
      return (
        <Select
          size="small"
          showSearch
          style={{
            width: 360,
            fontSize: '11px',
            padding: '0px'
          }}
          placeholder="Search to Select"
          optionFilterProp="children"
          filterOption={(input, option) => {
            // input?.length >= 3 ? setVendorSearch(input) : '';
            // input?.length >= 3 ? setProjectSearch(input) : '';
            // console.log('input', input);
            // onChange={onChange}
            // defaultValue={options[0]} /////-----new added
            return (
              option?.props?.children
                ?.toString()
                ?.toLowerCase()
                ?.indexOf(input?.toLowerCase()) >= 0 ||
              option?.props?.label
                ?.toString()
                ?.toLowerCase()
                ?.indexOf(input?.toLowerCase()) >= 0
            );
          }}
          onChange={onChange}
          defaultValue={options[0]?.value}
          onSearch={debounceFetcher}
          // options={options}
          // filterOption={false}
        >
          {options.map((item, index) => {
            const { label, value } = item;
            console.log('item', item);
            return (
              <Option key={index} value={value} label={label}>
                <div style={{ fontSize: '11px' }}>
                  {' '}
                  <span>{`${label} (${value})`}</span>
                </div>
              </Option>
            );
          })}
        </Select>
      );

    case 'date':
      return (
        <DatePicker
          size="small"
          style={{ fontSize: '11px' }}
          value={value !== 'All' ? moment(value) : null}
          format={'DD-MMM-YYYY'}
          onChange={(_, dateValue) => {
            onChange(dateValue || 'All');
          }}
        />
      );

    default:
      return (
        <Menu>
          {options.map((item, index) => {
            const { label, value } = item;

            return (
              <Menu.Item key={index} onClick={() => onChange(value)}>
                <span style={{ fontSize: '11px' }}>{label}</span>
              </Menu.Item>
            );
          })}
        </Menu>
      );
  }
};
const DisplayFilter1 = ({
  type,
  options,
  value,
  onChange,
  // setVendorSearch,
  // setProjectSearch,
  setSearchValue
}) => {
  const debounceFetcher = useMemo(() => {
    const loadOptions = (searchValue) => {
      setSearchValue(searchValue);
    };
    return debounce(loadOptions, 400);
  }, []);
  switch (type) {
    case 'select':
      return (
        <Select
          size="small"
          showSearch
          style={{
            width: 360,
            fontSize: '11px',
            padding: '0px'
          }}
          placeholder="Search to Select"
          optionFilterProp="children"
          filterOption={(input, option) => {
            // input?.length >= 3 ? setVendorSearch(input) : '';
            // input?.length >= 3 ? setProjectSearch(input) : '';
            // console.log('input', input);
            // onChange={onChange}
            // defaultValue={options[0]} /////-----new added
            return (
              option?.props?.children
                ?.toString()
                ?.toLowerCase()
                ?.indexOf(input?.toLowerCase()) >= 0 ||
              option?.props?.label
                ?.toString()
                ?.toLowerCase()
                ?.indexOf(input?.toLowerCase()) >= 0
            );
          }}
          onChange={onChange}
          defaultValue={options[0]?.value}
          onSearch={debounceFetcher}
          // options={options}
          // filterOption={false}
        >
          {options.map((item, index) => {
            const { label, value } = item;
            console.log('item', item);
            return (
              <Option key={index} value={value} label={label}>
                <div style={{ fontSize: '11px' }}>{label}</div>
              </Option>
            );
          })}
        </Select>
      );

    case 'date':
      return (
        <DatePicker
          size="small"
          style={{ fontSize: '11px' }}
          value={value !== 'All' ? moment(value) : null}
          format={'DD-MMM-YYYY'}
          onChange={(_, dateValue) => {
            onChange(dateValue || 'All');
          }}
        />
      );

    default:
      return (
        <Menu>
          {options.map((item, index) => {
            const { label, value } = item;

            return (
              <Menu.Item key={index} onClick={() => onChange(value)}>
                <span style={{ fontSize: '11px' }}>{label}</span>
              </Menu.Item>
            );
          })}
        </Menu>
      );
  }
};
const SearchBar = ({
  inputProps,
  filters,
  filterValues,
  handleFiltersOnChange,
  setVendorSearch,
  setProjectSearch,
  //handleSearchVendor,
  user_role,
  rightComponent,
  ...rest
}) => {
  return (
    <>
      {user_role === 'Treasury' ? (
        <Row {...rest}>
          {inputProps ? (
            <Col span={6}>
              <Input
                size="small"
                style={{ padding: '4px 10px', fontSize: '11px' }}
                {...inputProps}
                prefix={<SearchOutlined />}
                onChange={debounce((e) => {
                  if (user_role === 'SBU Commercial') {
                    if (handleFiltersOnChange) {
                      handleFiltersOnChange(
                        'search_tds_project',
                        e.target.value
                      );
                    } else {
                      // handleSearchVendor(e.target.value);
                    }
                  } else if (user_role === 'SBU Head') {
                    if (handleFiltersOnChange) {
                      handleFiltersOnChange(
                        'search_tds_project',
                        e.target.value
                      );
                    } else {
                      // handleSearchVendor(e.target.value);
                    }
                  } else if (user_role === 'Treasury') {
                    if (handleFiltersOnChange) {
                      handleFiltersOnChange('search_SBU', e.target.value);
                    } else {
                      // handleSearchVendor(e.target.value);
                    }
                  } else if (user_role === 'Project Engineer') {
                    if (handleFiltersOnChange) {
                      handleFiltersOnChange('search_vendor', e.target.value);
                    } else {
                      // handleSearchVendor(e.target.value);
                    }
                  } else if (user_role === 'Finance CoE') {
                    if (handleFiltersOnChange) {
                      handleFiltersOnChange('search_vendor', e.target.value);
                    } else {
                      // handleSearchVendor(e.target.value);
                    }
                  }
                }, 400)}
                allowClear
              />
            </Col>
          ) : null}
          {filters?.length ? (
            <Col span={11}>
              <Space
                style={{
                  fontFamily: 'SFProText Regular',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: '12px',
                  lineHeight: '18px',
                  color: '#32324D'
                }}
              >
                <Text
                  className="SFProText Regular"
                  style={{
                    fontStyle: 'normal',
                    fontSize: '12px',
                    lineHeight: '18px',
                    fontWeight: 500,
                    color: '#000000'
                  }}
                >
                  Filters:
                </Text>
                {filters.map((item, index) => {
                  if (!item) return null;

                  const { label, slug, options, type } = item;
                  const selectedFilterValue = filterValues[slug] || '';
                  const selectedFilterValueLabel = options?.length
                    ? options?.find((o) => o.value === selectedFilterValue)
                        ?.label || 'All' //'NA'
                    : selectedFilterValue;
                  const showDivider = filters.length - 1 !== index;
                  let setSearchValue =
                    slug === 'tds_project_code'
                      ? setProjectSearch
                      : setVendorSearch;
                  return (
                    <Space key={index}>
                      <Text>{`${label}: `}</Text>
                      <Dropdown
                        menu={{
                          selectable: true
                        }}
                        overlay={
                          slug === 'tds_project_code' || slug === 'vendor' ? (
                            <DisplayFilter
                              type={type}
                              options={options}
                              value={selectedFilterValue}
                              onChange={(value) => {
                                setSearchValue('');
                                handleFiltersOnChange(slug, value);
                              }}
                              setSearchValue={setSearchValue}
                              setVendorSearch={setVendorSearch}
                              setProjectSearch={setProjectSearch}
                            />
                          ) : (
                            <DisplayFilter1
                              type={type}
                              options={options}
                              value={selectedFilterValue}
                              onChange={(value) => {
                                setSearchValue('');
                                handleFiltersOnChange(slug, value);
                              }}
                              setSearchValue={setSearchValue}
                              setVendorSearch={setVendorSearch}
                              setProjectSearch={setProjectSearch}
                            />
                          )
                        }
                        trigger={['hover']}
                        placement="bottomLeft"
                      >
                        <a onClick={(e) => e.preventDefault()}>
                          <span style={{ fontSize: '11px', padding: 0 }}>
                            {selectedFilterValueLabel} <CaretDownOutlined />
                          </span>
                        </a>
                      </Dropdown>
                      {showDivider ? (
                        <Divider
                          type="vertical"
                          /*  style={{ marginLeft: '4px', marginRight: '4px' }} */
                        />
                      ) : null}
                    </Space>
                  );
                })}
              </Space>
            </Col>
          ) : null}
          <Col span={11}></Col>
          {/* useing dummy for float right side, only for Treasury role */}
          {rightComponent ? <Col span={7}>{rightComponent} </Col> : null}
        </Row>
      ) : (
        <Row {...rest}>
          {inputProps ? (
            <Col span={6}>
              <Input
                size="small"
                style={{ padding: '4px 10px', fontSize: '11px' }}
                {...inputProps}
                prefix={<SearchOutlined />}
                onChange={debounce((e) => {
                  if (user_role === 'SBU Commercial') {
                    if (handleFiltersOnChange) {
                      handleFiltersOnChange(
                        'search_tds_project',
                        e.target.value
                      );
                    } else {
                      // handleSearchVendor(e.target.value);
                    }
                  } else if (user_role === 'SBU Head') {
                    if (handleFiltersOnChange) {
                      handleFiltersOnChange(
                        'search_tds_project',
                        e.target.value
                      );
                    } else {
                      // handleSearchVendor(e.target.value);
                    }
                  } else if (user_role === 'Treasury') {
                    if (handleFiltersOnChange) {
                      handleFiltersOnChange('search_SBU', e.target.value);
                    } else {
                      // handleSearchVendor(e.target.value);
                    }
                  } else if (user_role === 'Project Engineer') {
                    if (handleFiltersOnChange) {
                      handleFiltersOnChange('search_vendor', e.target.value);
                    } else {
                      // handleSearchVendor(e.target.value);
                    }
                  } else if (user_role === 'Finance CoE') {
                    if (handleFiltersOnChange) {
                      handleFiltersOnChange('search_vendor', e.target.value);
                    } else {
                      // handleSearchVendor(e.target.value);
                    }
                  }
                }, 400)}
                allowClear
              />
            </Col>
          ) : null}
          {filters?.length ? (
            <Col span={11}>
              <Space
                style={{
                  fontFamily: 'SFProText Regular',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  fontSize: '12px',
                  lineHeight: '18px',
                  color: '#32324D'
                }}
              >
                <Text
                  className="SFProText Regular"
                  style={{
                    fontStyle: 'normal',
                    fontSize: '12px',
                    lineHeight: '18px',
                    fontWeight: 500,
                    color: '#000000'
                  }}
                >
                  Filters:
                </Text>
                {filters.map((item, index) => {
                  if (!item) return null;

                  const { label, slug, options, type } = item;
                  const selectedFilterValue = filterValues[slug] || '';
                  const selectedFilterValueLabel = options?.length
                    ? options?.find((o) => o.value === selectedFilterValue)
                        ?.label || 'All' //'NA'
                    : selectedFilterValue;
                  const showDivider = filters.length - 1 !== index;
                  let setSearchValue =
                    slug === 'tds_project_code'
                      ? setProjectSearch
                      : setVendorSearch;
                  return (
                    <Space key={index}>
                      <Text>{`${label}: `}</Text>
                      <Dropdown
                        menu={{
                          selectable: true
                        }}
                        overlay={
                          <DisplayFilter
                            type={type}
                            options={options}
                            value={selectedFilterValue}
                            onChange={(value) => {
                              //setSearchValue('');
                              handleFiltersOnChange(slug, value);
                            }}
                            setSearchValue={setSearchValue}
                            setVendorSearch={setVendorSearch}
                            setProjectSearch={setProjectSearch}
                          />
                        }
                        trigger={['hover']}
                        placement="bottomLeft"
                      >
                        <a onClick={(e) => e.preventDefault()}>
                          <span style={{ fontSize: '11px', padding: 0 }}>
                            {selectedFilterValueLabel} <CaretDownOutlined />
                          </span>
                        </a>
                      </Dropdown>
                      {showDivider ? (
                        <Divider
                          type="vertical"
                          /*  style={{ marginLeft: '4px', marginRight: '4px' }} */
                        />
                      ) : null}
                    </Space>
                  );
                })}
              </Space>
            </Col>
          ) : null}
          {rightComponent ? <Col span={7}>{rightComponent} </Col> : null}
        </Row>
      )}
    </>
  );
};

export default SearchBar;
//style={{ marginRight: '-1px important' }}
