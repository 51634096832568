import apiClient from 'utilities/apiClient';

export const sbuList = (role, payload) => {
  return apiClient.get(
    `${apiClient.Urls.apiUrl}/${role}/getSBUList`,
    payload,
    true
  );
};
/* export const inboundFailed_List = (role, payload) => {
  return apiClient.get(
    `${apiClient.Urls.apiUrl}/${role}/getInboundFailedList`,
    payload,
    true
  );
}; */
