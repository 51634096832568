import React, { useState, useEffect } from 'react';
import {
  Divider,
  Dropdown,
  InputNumber,
  Menu,
  Space,
  Typography,
  Button,
  Tooltip
} from 'antd';
import styles from './index.module.less';
import CustomButton from 'common/CustomButton';
import { CaretDownOutlined } from '@ant-design/icons';
import {
  liabilityToLabel,
  numFormating,
  numIntStyleFormat
} from 'utilities/helpers';
import CustomModal from 'common/CustomModal';
import PeSummaryAllocModal from '../PeSummaryAllocModal/index';

const { Title, Text } = Typography;

const SummaryInvoiceItem = ({
  data,
  reqFund,
  setReqFund,
  openReqFundAllocModal,
  setOpenReqFundAllocModal,
  handleRequestFund,
  selectedBankBranchName,
  setSelectedBankBranchName,
  openReqAllocModal,
  setOpenReqAllocModal,
  postRequestFundLoading,
  requestFundInvoices,
  postAllocationRequestLoading,
  handlePostAllocationRequest,
  summaryInvoicesActiveBankList,
  clickSubmitInvoices,
  setClickSubmitInvoices,
  allInvoicesByAge,
  handleSubmitInvoices,
  user_role,
  liabilityAmount,
  setOpenCheckbox,
  openCheckbox,
  handleFiltersOnChange,
  handleFiltersOnChangeSerchInModal,
  invoicesByAgeLoading
}) => {
  if (!data) return null;

  const {
    vendor_name,
    vendor_code,
    tds_project_code,
    active_bank_list,
    vendor_site_code,
    total_liability,
    //pending_liability,
    initiated_liability,
    total_advances_up
  } = data;
  //console.log("total_liability['liability']", total_liability['liability']);
  const [liabilities, setLiabilities] = useState([]);

  useEffect(() => {
    let allLiabilities = Object.keys(total_liability);
    allLiabilities.splice(6, 1);
    setLiabilities(allLiabilities);
  }, [total_liability, initiated_liability]);

  const selectedBank =
    active_bank_list?.length === 1
      ? (selectedBankBranchName[vendor_code] =
          active_bank_list[0]?.bank_account_number)
      : active_bank_list.find(
          (item) =>
            item.bank_account_number === selectedBankBranchName[vendor_code]
        );
  return (
    <div className={`${styles.summary_invoice_item_container} mb-3`}>
      <div style={{ position: 'relative' }}>
        <Space>
          <Text
            className={`sfprotext-medium ${styles.summary_invoice_item_vendor_details_container}`}
          >{`${vendor_name} (${vendor_code})`}</Text>
          <Text className="font-12">
            <Text className="mr-1" style={{ color: '#666687' }}>
              Advances(UP):
              <Tooltip
                title={numIntStyleFormat(total_advances_up)}
                color={'white'}
                overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                arrowPointAtCenter="false"
              >
                ₹{numFormating(total_advances_up)}
              </Tooltip>
            </Text>
            <Text style={{ color: '#212134' }}>{''}</Text>
          </Text>
          <Divider type="vertical" />
          {active_bank_list?.length ? (
            <>
              <Text style={{ fontSize: '11px' }}>
                <Text className="mr-1 font-12" style={{ color: '#666687' }}>
                  Bank:
                </Text>
                <Dropdown
                  overlay={
                    <Menu>
                      {active_bank_list.map((bank, index) => {
                        const {
                          bank_name,
                          bank_branch_name,
                          bank_account_number
                        } = bank;
                        return (
                          <Menu.Item
                            //defaultValue={active_bank_list[0]?.bank_branch_name}
                            key={index}
                            onClick={() => {
                              setSelectedBankBranchName({
                                ...selectedBankBranchName,
                                [vendor_code]:
                                  bank_account_number /* bank_branch_name */
                              });
                              // console.log(' SelectedBankBranchName=',selectedBankBranchName);
                            }}
                          >
                            <span
                              style={{ fontSize: '11px' }}
                            >{`Name: ${bank_name}, IFSC: ${bank_branch_name}, A/c no: ${bank_account_number}`}</span>
                          </Menu.Item>
                        );
                      })}
                    </Menu>
                  }
                  trigger={['click']}
                  // defaultValue={`Name: ${active_bank_list[0]?.bank_name}, IFSC: ${active_bank_list[0]?.bank_branch_name}, A/c no: ${active_bank_list[0]?.bank_account_number}`}
                >
                  <a>
                    {active_bank_list?.length === 1
                      ? `Name: ${active_bank_list[0]?.bank_name}, IFSC: ${active_bank_list[0]?.bank_branch_name}, A/c no: ${active_bank_list[0]?.bank_account_number}`
                      : selectedBank
                      ? `Name: ${selectedBank.bank_name}, IFSC: ${selectedBank.bank_branch_name}, A/c no: ${selectedBank.bank_account_number}`
                      : 'Select'}{' '}
                    <CaretDownOutlined />
                  </a>
                </Dropdown>
              </Text>
              <Divider type="vertical" />
            </>
          ) : null}
        </Space>
        <CustomButton
          className={`${styles.summary_invoice_item_req_btn}`}
          onClick={() => {
            setOpenReqFundAllocModal({ [vendor_code]: true });
          }}
          /*  disabled={!selectedBankBranchName[vendor_code]} */ /* /* For non-H2H vendor bank selection */
          disabled={
            data?.active_bank_list?.length === 0 ||
            data?.active_bank_list?.length > 1
              ? !selectedBankBranchName[vendor_code]
              : null
          }
        >
          Initiate Fund
        </CustomButton>
      </div>
      <div className={`${styles.summary_invoice_item_liabilities_container}`}>
        <Space
          // size={72}
          // align="start"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginRight: '135px',
            marginLeft: '5px'
          }}
        >
          {liabilities.map((item, index) => {
            let isIndexOne = index === 0;
            let isTotalLiability = item === 'requested_amount';

            const submitPayload = {
              page: 1,
              per_page: 100000000000,
              tds_project_code,
              vendor_code,
              // vendor_site_code,
              status: 'open_rejected',
              non_H2H: item === 'liability_non_H2H' ? true : false
            };

            if (!isTotalLiability) {
              submitPayload['age'] =
                item === 'liability_non_H2H' || item === 'total_liability'
                  ? ' '
                  : item.replace('_days', '').replace('liability', 'days');
            }

            return (
              <Space key={index} direction="vertical">
                <div
                  className="font-12 "
                  style={{ color: '#666687', padding: '2px', margin: '0px' }}
                >
                  {liabilityToLabel[item]}
                </div>

                <div
                  className="sfprotext-bold "
                  style={{
                    color: '#212134',
                    padding: '2px',
                    margin: '0px',
                    lineHeight: '0px',
                    fontSize: '12px'
                  }}
                >
                  <Tooltip
                    title={'Total'}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    <span>T: </span>
                  </Tooltip>
                  <Tooltip
                    title={numIntStyleFormat(total_liability[item])}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    {total_liability[item] === undefined ? (
                      ''
                    ) : total_liability[item] >= 0 ? (
                      total_liability[item] === undefined ? (
                        ''
                      ) : (
                        <span>₹{numFormating(total_liability[item])}</span>
                      )
                    ) : total_liability[item] === undefined ? (
                      ''
                    ) : (
                      <span>-₹{numFormating(total_liability[item])}</span>
                    )}
                  </Tooltip>
                </div>
                <div
                  className="sfprotext-bold "
                  style={{
                    color: '#212134',
                    paddingLeft: '4px',
                    marginTop: '14px',
                    lineHeight: '0px',
                    fontSize: '12px' // Amount font size
                  }}
                >
                  <Tooltip
                    title={'Initiated'}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    <span>I: </span>
                  </Tooltip>

                  <Tooltip
                    title={numIntStyleFormat(initiated_liability[item])}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    {initiated_liability[item] === undefined ? (
                      ''
                    ) : initiated_liability[item] >= 0 ? (
                      initiated_liability[item] === undefined ? (
                        ''
                      ) : (
                        <span>₹{numFormating(initiated_liability[item])}</span>
                      )
                    ) : initiated_liability[item] === undefined ? (
                      ''
                    ) : (
                      <span>-₹{numFormating(initiated_liability[item])}</span>
                    )}
                  </Tooltip>
                </div>

                {!isIndexOne ? (
                  <Button
                    size="small"
                    style={{
                      padding: '0px',
                      marginLeft: '0px',
                      lineHeight: '0px'
                    }}
                    type="link"
                    onClick={() => {
                      setClickSubmitInvoices({ [vendor_code]: true });
                      handleSubmitInvoices(submitPayload);
                    }}
                    disabled={
                      total_liability[item] === initiated_liability[item] ||
                      data?.active_bank_list?.length === 0 ||
                      data?.active_bank_list?.length > 1
                        ? !selectedBankBranchName[vendor_code] ||
                          !total_liability[item] ||
                          total_liability[item] === initiated_liability[item]
                        : !total_liability[item] ||
                          total_liability[item] === initiated_liability[item]
                      //|| total_liability[item] >= 0
                    }
                    className={`${styles.summary_invoice_item_submit_btn}`}
                  >
                    Submit invoices
                  </Button>
                ) : (
                  <span style={{ color: 'transparent' }}>.</span>
                )}
              </Space>
            );
          })}
        </Space>
      </div>
      {openReqFundAllocModal[vendor_code] ? (
        <CustomModal
          visible={openReqFundAllocModal[vendor_code]}
          onCancel={() => setOpenReqFundAllocModal({})}
          cancelButtonProps={{ onClick: () => setOpenReqFundAllocModal({}) }}
          okButtonProps={{
            loading: postRequestFundLoading,

            onClick: () =>
              handleRequestFund({
                requested_fund: reqFund[vendor_code],
                tds_project_code,
                vendor_code,
                vendor_site_code
              })
          }}
        >
          <Title level={5} className="font-12">
            Enter the amount you want to initiate
          </Title>

          <InputNumber
            min={1}
            max={
              total_liability['total_liability'] <= 0
                ? Math.abs(total_liability['total_liability'])
                : total_liability['total_liability']
            }
            placeholder="Enter amount"
            style={{ width: '70%' }}
            value={reqFund[vendor_code] || null}
            onChange={(value) => {
              setReqFund({ ...reqFund, [vendor_code]: value });
              console.log('fund', reqFund);
            }}
          />
        </CustomModal>
      ) : null}
      {openReqAllocModal[vendor_code] ? (
        <PeSummaryAllocModal
          requestedAmount={liabilityAmount}
          fromSummary={true}
          visible={openReqAllocModal[vendor_code]}
          toggleModal={() => {
            setOpenReqAllocModal({});
            setClickSubmitInvoices({});
            setOpenCheckbox(false);
          }}
          data={
            clickSubmitInvoices[vendor_code]
              ? allInvoicesByAge
              : requestFundInvoices
          }
          grandTotal={total_liability['liability']}
          currentTotal={initiated_liability['liability']}
          summaryInvoicesActiveBankList={
            data?.active_bank_list?.length > 0
              ? summaryInvoicesActiveBankList
              : summaryInvoicesActiveBankList
          }
          postAllocationRequestLoading={postAllocationRequestLoading}
          handlePostAllocationRequest={handlePostAllocationRequest}
          selectedBankBranchName={
            data?.active_bank_list?.length > 0
              ? selectedBankBranchName[vendor_code]
              : null
          }
          user_role={user_role}
          setOpenCheckbox={setOpenCheckbox}
          openCheckbox={openCheckbox}
          handleFiltersOnChange={handleFiltersOnChange}
          handleFiltersOnChangeSerchInModal={handleFiltersOnChangeSerchInModal}
          invoicesByAgeLoading={invoicesByAgeLoading}
        />
      ) : null}
    </div>
  );
};

export default SummaryInvoiceItem;
