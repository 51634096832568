import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  activeBankList,
  allocationRequest,
  invoices,
  requestFund,
  vendorList,
  buApproveAllocationRequest,
  buForwardAllocationRequest,
  projectList,
  buList,
  activeDebitBankList,
  vendorwise
  //exportAllocatedInvoices
} from './api';

const initialState = {
  //exportAllocatedInvoicesLoading: false,
  requestFundCheckboxLoading: false,
  invoicesByAgeLoading: false,
  getInvoicesByAgeLoading: false,
  getVendorWiseLoading: false,
  vendorList: [],
  activeBankList: [],
  allInvoicesByAge: [],
  projectList: [],
  BUList: [],
  activeDebitBankList: [],
  vendorWiseList: []
};

const actions = {
  GET_INVOICES: 'invoices/GET_INVOICES',
  GET_OPEN_INVOICES: 'invoices/GET_OPEN_INVOICES',
  GET_PAID_INVOICES: 'invoices/GET_PAID_INVOICES',
  GET_PENDING_INVOICES: 'invoices/GET_PENDING_INVOICES',
  GET_REJECTED_INVOICES: 'invoices/GET_REJECTED_INVOICES',
  GET_APPROVED_INVOICES: 'invoices/GET_APPROVED_INVOICES',
  GET_ALLOCATED_INVOICES: 'invoices/GET_ALLOCATED_INVOICES',
  GET_VENDOR_LIST: 'invoices/GET_VENDOR_LIST',
  GET_ACTIVE_BANK_LIST: 'invoices/GET_ACTIVE_BANK_LIST',
  POST_ALLOCATION_REQUEST: 'invoices/POST_ALLOCATION_REQUEST',
  POST_REQUEST_FUND: 'invoices/POST_REQUEST_FUND',
  GET_INVOICES_BY_AGE: 'invoices/GET_INVOICES_BY_AGE',
  POST_BU_APPROVE_ALLOCATION_REQUEST:
    'invoices/POST_BU_APPROVE_ALLOCATION_REQUEST',
  POST_BU_REQUEST_FUND_ALLOCATION: 'invoices/POST_BU_REQUEST_FUND_ALLOCATION',
  GET_PROJECT_LIST: 'invoices/GET_PROJECT_LIST',
  GET_BU_LIST: 'invoices/GET_BU_LIST',
  GET_ACTIVE_DEBIT_BANK_LIST: 'invoices/GET_ACTIVE_DEBIT_BANK_LIST',
  GET_VENDOR_WISE_LIST: 'invoices/GET_VENDOR_WISE_LIST',
  GET_DISBURSED_INVOICES: 'invoices/GET_DISBURSED_INVOICES'
  // GET_EXPORT_ALLOCATED_INVOICES:
  //   'getExportAllocatedInvoices/GET_EXPORT_ALLOCATED_INVOICES'
};

export const getInvoices = createAsyncThunk(
  actions.GET_INVOICES,
  async ({ role, type, payload }) => {
    const response = await invoices(role, type, payload);

    return response;
  }
);

export const getOpenInvoices = createAsyncThunk(
  actions.GET_OPEN_INVOICES,
  async ({ role, type, payload }) => {
    const response = await invoices(role, type, payload);

    return response;
  }
);

export const getPaidInvoices = createAsyncThunk(
  actions.GET_PAID_INVOICES,
  async ({ role, type, payload }) => {
    const response = await invoices(role, type, payload);

    return response;
  }
);

export const getPendingInvoices = createAsyncThunk(
  actions.GET_PENDING_INVOICES,
  async ({ role, type, payload }) => {
    const response = await invoices(role, type, payload);

    return response;
  }
);

export const getRejectedInvoices = createAsyncThunk(
  actions.GET_REJECTED_INVOICES,
  async ({ role, type, payload }) => {
    const response = await invoices(role, type, payload);

    return response;
  }
);

export const getAllocatedInvoices = createAsyncThunk(
  actions.GET_ALLOCATED_INVOICES,
  async ({ role, type, payload }) => {
    const response = await invoices(role, type, payload);

    return response;
  }
);

export const getApprovedInvoices = createAsyncThunk(
  actions.GET_APPROVED_INVOICES,
  async ({ role, type, payload }) => {
    const response = await invoices(role, type, payload);

    return response;
  }
);

export const getDisbursedInvoices = createAsyncThunk(
  actions.GET_DISBURSED_INVOICES,
  async ({ role, type, payload }) => {
    const response = await invoices(role, type, payload);

    return response;
  }
);

export const getVendorList = createAsyncThunk(
  actions.GET_VENDOR_LIST,
  async (payload) => {
    const response = await vendorList(payload);

    return response;
  }
);

export const getActiveBankList = createAsyncThunk(
  actions.GET_ACTIVE_BANK_LIST,
  async (payload) => {
    const response = await activeBankList(payload);
    return response;
  }
);

export const postAllocationRequest = createAsyncThunk(
  actions.POST_ALLOCATION_REQUEST,
  async ({ role, payload }) => {
    const response = await allocationRequest(role, payload);

    return response;
  }
);

export const postRequestFund = createAsyncThunk(
  actions.POST_REQUEST_FUND,
  async ({ role, payload }) => {
    const response = await requestFund(role, payload);

    return response;
  }
);

export const getInvoicesByAge = createAsyncThunk(
  actions.GET_INVOICES_BY_AGE,
  async ({ role, type, payload }) => {
    const response = await invoices(role, type, payload);

    return response;
  }
);

export const postBuApproveAllocationRequest = createAsyncThunk(
  actions.POST_BU_APPROVE_ALLOCATION_REQUEST,
  async ({ role, payload }) => {
    const response = await buApproveAllocationRequest(role, payload);

    return response;
  }
);

export const PostBuRequestFundAllocation = createAsyncThunk(
  actions.POST_BU_REQUEST_FUND_ALLOCATION,
  async ({ role, payload }) => {
    const response = await buForwardAllocationRequest(role, payload);

    return response;
  }
);

export const getProjectList = createAsyncThunk(
  actions.GET_PROJECT_LIST,
  async ({ role, payload }) => {
    const response = await projectList(role, payload);

    return response;
  }
);

export const getBUList = createAsyncThunk(
  actions.GET_BU_LIST,
  async ({ role, payload }) => {
    const response = await buList(role, payload);

    return response;
  }
);

export const getActiveDebitBankList = createAsyncThunk(
  actions.GET_ACTIVE_DEBIT_BANK_LIST,
  async (payload) => {
    const response = await activeDebitBankList(payload);
    return response;
  }
);

export const getVendorWise = createAsyncThunk(
  actions.GET_VENDOR_WISE_LIST,
  async ({ role, type1, type2, payload }) => {
    const response = await vendorwise(role, type1, type2, payload);
    return response;
  }
);

/* export const getExportAllocatedInvoices = createAsyncThunk(
  actions.GET_EXPORT_ALLOCATED_INVOICES,
  async ({ role, payload }) => {
    const response = await exportAllocatedInvoices(role, payload);
    return response;
  }
); */
// `?from_date=${payload?.from_date}&to_date=${payload?.to_date}&is_H2H=${payload?.is_H2H}`,

export const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getInvoices.pending, (state, action) => {
        const { arg } = action.meta;
        const { type } = arg;

        state[`${type}InvoicesLoading`] = true;
      })
      .addCase(getInvoices.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;
        const { arg } = action.meta;
        const { type } = arg;

        state[`${type}InvoicesLoading`] = false;

        if (success) {
          state[`${type}Invoices`] = data
            .filter((o) => o)
            .map((item, index) => ({
              ...item,
              key: index
            }));
          if (type === 'summary') {
            let summaryInvoicesActiveBankList = {};
            data
              .filter((o) => o)
              .forEach((item) => {
                summaryInvoicesActiveBankList[item.vendor_code] =
                  item.active_bank_list;
              });
            state[`${type}InvoicesActiveBankList`] =
              summaryInvoicesActiveBankList;
          }
          state[`${type}InvoicesPagination`] = pagination || null;
        } else {
          state[`${type}Invoices`] = [];

          // message.error(msg);
        }
      })
      .addCase(getInvoices.rejected, (state, action) => {
        const { message: msg } = action.error;
        const { arg } = action.meta;
        const { type } = arg;

        state[`${type}InvoicesLoading`] = false;

        message.error(msg);
      });

    builder
      .addCase(getOpenInvoices.pending, (state) => {
        state.openInvoicesLoading = true;
      })
      .addCase(getOpenInvoices.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;

        state.openInvoicesLoading = false;

        if (success) {
          state.openInvoices = data.map((item, index) => ({
            ...item,
            key: index
          }));
          state.openInvoicesPagination = pagination || null;
        } else {
          state.openInvoices = [];
          state.openInvoicesPagination = 0;
          // message.error(msg);
        }
      })
      .addCase(getOpenInvoices.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.openInvoicesLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getPaidInvoices.pending, (state) => {
        state.paidInvoicesLoading = true;
      })
      .addCase(getPaidInvoices.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;

        state.paidInvoicesLoading = false;

        if (success) {
          state.paidInvoices = data.map((item, index) => ({
            ...item,
            key: index
          }));
          state.paidInvoicesPagination = pagination || null;
        } else {
          state.paidInvoices = [];
          state.paidInvoicesPagination = 0;
          // message.error(msg);
        }
      })
      .addCase(getPaidInvoices.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.paidInvoicesLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getPendingInvoices.pending, (state) => {
        state.pendingInvoicesLoading = true;
      })
      .addCase(getPendingInvoices.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;

        state.pendingInvoicesLoading = false;

        if (success) {
          state.pendingInvoices = data.map((item, index) => ({
            ...item,
            key: index
          }));
          state.pendingInvoicesPagination = pagination || null;
        } else {
          state.pendingInvoices = [];
          state.pendingInvoicesPagination = 0;
          // message.error(msg);
        }
      })
      .addCase(getPendingInvoices.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.pendingInvoicesLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getRejectedInvoices.pending, (state) => {
        state.rejectedInvoicesLoading = true;
      })
      .addCase(getRejectedInvoices.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;

        state.rejectedInvoicesLoading = false;

        if (success) {
          state.rejectedInvoices = data.map((item, index) => ({
            ...item,
            key: index
          }));
          state.rejectedInvoicesPagination = pagination || null;
        } else {
          state.rejectedInvoices = [];
          state.rejectedInvoicesPagination = 0;
          // message.error(msg);
        }
      })
      .addCase(getRejectedInvoices.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.rejectedInvoicesLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllocatedInvoices.pending, (state) => {
        state.allocatedInvoicesLoading = true;
      })
      .addCase(getAllocatedInvoices.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;

        state.allocatedInvoicesLoading = false;

        if (success) {
          state.allocatedInvoices = data.map((item, index) => ({
            ...item,
            key: index
          }));
          state.allocatedInvoicesPagination = pagination || null;
        } else {
          state.allocatedInvoices = [];
          state.allocatedInvoicesPagination = 0;
          // message.error(msg);
        }
      })
      .addCase(getAllocatedInvoices.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.allocatedInvoicesLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getApprovedInvoices.pending, (state) => {
        state.approvedInvoicesLoading = true;
      })
      .addCase(getApprovedInvoices.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;

        state.approvedInvoicesLoading = false;

        if (success) {
          state.approvedInvoices = data.map((item, index) => ({
            ...item,
            key: index
          }));
          state.approvedInvoicesPagination = pagination || null;
        } else {
          state.approvedInvoices = [];
          state.approvedInvoicesPagination = 0;
          // message.error(msg);
        }
      })
      .addCase(getApprovedInvoices.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.approvedInvoicesLoading = false;

        message.error(msg);
      });
    //----------------
    builder
      .addCase(getDisbursedInvoices.pending, (state) => {
        state.disbursedInvoicesLoading = true;
      })
      .addCase(getDisbursedInvoices.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;

        state.disbursedInvoicesLoading = false;

        if (success) {
          state.disbursedInvoices = data.map((item, index) => ({
            ...item,
            key: index
          }));
          state.disbursedInvoicesPagination = pagination || null;
        } else {
          state.disbursedInvoices = [];
          state.disbursedInvoicesPagination = 0;
          // message.error(msg);
        }
      })
      .addCase(getDisbursedInvoices.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.disbursedInvoicesLoading = false;

        message.error(msg);
      });
    //------------------

    builder
      .addCase(getVendorList.pending, (state) => {
        state.getVendorListLoading = true;
      })
      .addCase(getVendorList.fulfilled, (state, action) => {
        const { success, vendor_list } = action.payload;

        state.getVendorListLoading = false;

        if (success) {
          const vendorList = vendor_list.map((item) => {
            if (item['All']) {
              return { label: 'All', value: 'All' };
            } else {
              return { label: item.vendor_name, value: item.vendor_code };
            }
          });
          state.vendorList = vendorList;
        } else {
          // message.error(msg);
        }
      })
      .addCase(getVendorList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getVendorListLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getActiveBankList.pending, (state) => {
        state.getActiveBankListLoading = true;
      })
      .addCase(getActiveBankList.fulfilled, (state, action) => {
        const { success, active_bank_list, message: msg } = action.payload;

        state.getActiveBankListLoading = false;

        if (success) {
          state.activeBankList = active_bank_list;
        } else {
          message.error(msg);
        }
      })
      .addCase(getActiveBankList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getActiveBankListLoading = false;

        message.error(msg);
      });

    builder
      .addCase(postAllocationRequest.pending, (state) => {
        state.postAllocationRequestLoading = true;
      })
      .addCase(postAllocationRequest.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;

        state.postAllocationRequestLoading = false;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(postAllocationRequest.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postAllocationRequestLoading = false;

        message.error(msg);
      });

    builder
      .addCase(postRequestFund.pending, (state) => {
        state.postRequestFundLoading = true;
        state.requestFundCheckboxLoading = false;
      })
      .addCase(postRequestFund.fulfilled, (state, action) => {
        const { success, data, message: msg } = action.payload;

        state.postRequestFundLoading = false;

        if (success) {
          state.requestFundCheckboxLoading = true;
          message.success(msg);
          state.requestFundInvoices = data
            .filter((o) => o)
            .map((item, index) => ({
              ...item,
              key: index
            }));
          console.log('state.requestFundInvoices', state.requestFundInvoices);
        } else {
          state.requestFundCheckboxLoading = false;
          message.error(msg);
        }
      })
      .addCase(postRequestFund.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postRequestFundLoading = false;
        state.requestFundCheckboxLoading = false;
        message.error(msg);
      });

    builder
      .addCase(getInvoicesByAge.pending, (state) => {
        state.getInvoicesByAgeLoading = true;
      })
      .addCase(getInvoicesByAge.fulfilled, (state, action) => {
        const { success, data, message: msg } = action.payload;

        state.getInvoicesByAgeLoading = false;

        if (success) {
          state.invoicesByAgeLoading = true;
          state.allInvoicesByAge = data
            .filter((o) => o)
            .map((item, index) => ({
              ...item,
              key: index
            }));
        } else {
          state.invoicesByAgeLoading = false;
          message.error(msg);
        }
      })
      .addCase(getInvoicesByAge.rejected, (state) => {
        // const { message: msg } = action.error;

        state.getInvoicesByAgeLoading = false;
      });

    builder
      .addCase(postBuApproveAllocationRequest.pending, (state) => {
        state.postBuApproveAllocationRequestLoading = true;
      })
      .addCase(postBuApproveAllocationRequest.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;

        state.postBuApproveAllocationRequestLoading = false;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(postBuApproveAllocationRequest.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.postBuApproveAllocationRequestLoading = false;

        message.error(msg);
      });

    builder
      .addCase(PostBuRequestFundAllocation.pending, (state) => {
        state.PostBuRequestFundAllocationLoading = true;
      })
      .addCase(PostBuRequestFundAllocation.fulfilled, (state, action) => {
        const { success, message: msg } = action.payload;

        state.PostBuRequestFundAllocationLoading = false;

        if (success) {
          message.success(msg);
        } else {
          message.error(msg);
        }
      })
      .addCase(PostBuRequestFundAllocation.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.PostBuRequestFundAllocationLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getProjectList.pending, (state) => {
        state.getProjectListLoading = true;
      })
      .addCase(getProjectList.fulfilled, (state, action) => {
        const { success, project_list, message: msg } = action.payload;

        state.getProjectListLoading = false;

        if (success) {
          const projectList = project_list.map((item) => {
            if (item['All']) {
              return { label: 'All', value: 'All' };
            } else {
              return {
                label: item.tds_project_name,
                value: item.tds_project_code
              };
            }
          });
          state.projectList = projectList;
        } else {
          message.error(msg);
        }
      })
      .addCase(getProjectList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getProjectListLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getBUList.pending, (state) => {
        state.getBUListLoading = true;
      })
      .addCase(getBUList.fulfilled, (state, action) => {
        const { success, BU_list, message: msg } = action.payload;

        state.getBUListLoading = false;

        if (success) {
          const BUList = BU_list.map((item) => {
            if (item['All']) {
              return { label: 'All', value: 'All' };
            } else {
              return {
                label: item,
                value: item
              };
            }
          });
          state.BUList = BUList;
        } else {
          message.error(msg);
        }
      })
      .addCase(getBUList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getBUListLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getActiveDebitBankList.pending, (state) => {
        state.getActiveDebitBankListLoading = true;
      })
      .addCase(getActiveDebitBankList.fulfilled, (state, action) => {
        const {
          success,
          active_debit_bank_list,
          message: msg
        } = action.payload;

        state.getActiveDebitBankListLoading = false;

        if (success) {
          state.activeDebitBankList = active_debit_bank_list;
        } else {
          message.error(msg);
        }
      })
      .addCase(getActiveDebitBankList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getActiveDebitBankListLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getVendorWise.pending, (state) => {
        state.getVendorWiseLoading = true;
      })
      .addCase(getVendorWise.fulfilled, (state, action) => {
        const { success, pagination, data, message: msg } = action.payload;
        // console.log('action.payload', action.payload);
        state.getVendorWiseLoading = false;
        console.log('data:::::', data);
        if (success) {
          state.vendorWiseList = data;
          console.log('vendorWiseList::::::', state.vendorWiseList);
          state.pagination = pagination || null;
        } else {
          message.error(msg);
        }
      })
      .addCase(getVendorWise.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getVendorWiseLoading = false;

        message.error(msg);
      });
    /*  builder
      .addCase(getVendorWise.pending, (state, action) => {
        const { arg } = action.meta;
        const { type } = arg;

        state[`${type}getVendorWiseLoading`] = true;
      })
      .addCase(getVendorWise.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;
        const { arg } = action.meta;
        const { type } = arg;

        state[`${type}getVendorWiseLoading`] = false;

        if (success) {
          state[`${type}Invoices`] = data
            .filter((o) => o)
            .map((item, index) => ({
              ...item,
              key: index
            }));
          if (type === 'summary') {
            let summaryInvoicesActiveBankList = {};
            data
              .filter((o) => o)
              .forEach((item) => {
                summaryInvoicesActiveBankList[item.vendor_code] =
                  item.active_bank_list;
              });
            state[`${type}InvoicesActiveBankList`] =
              summaryInvoicesActiveBankList;
          }
          state[`${type}InvoicesPagination`] = pagination || null;
        } else {
          state[`${type}Invoices`] = [];

          // message.error(msg);
        }
      })
      .addCase(getVendorWise.rejected, (state, action) => {
        const { message: msg } = action.error;
        const { arg } = action.meta;
        const { type } = arg;

        state[`${type}InvoicesLoading`] = false;

        message.error(msg);
      }); */

    // builder
    //   .addCase(getExportAllocatedInvoices.pending, (state) => {
    //     state.exportAllocatedInvoicesLoading = true;
    //   })
    //   .addCase(getExportAllocatedInvoices.fulfilled, (state, action) => {
    //     const { success, message: msg } = action.payload;

    //     state.exportAllocatedInvoicesLoading = false;

    //     if (success) {
    //       /* response.blob().then((blob) => {
    //         const url = window.URL.createObjectURL(new Blob([blob]));
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute(
    //           'download',
    //           `a2p_exported_allocated_invoices.csv`
    //         );

    //         document.body.appendChild(link);
    //         link.click();
    //         link.parentNode.removeChild(link);
    //       }); */
    //     } else {
    //       message.error(msg);
    //     }
    //   })
    //   .addCase(getExportAllocatedInvoices.rejected, (state, action) => {
    //     const { message: msg } = action.error;

    //     state.exportAllocatedInvoicesLoading = false;

    //     message.error(msg);
    //   });
  }
});

// export const {} = invoicesSlice.actions;

export default invoicesSlice.reducer;
