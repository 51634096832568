import React, { useState } from 'react';
import { Modal, Form, DatePicker, Col, Row, Spin } from 'antd';
import styles from './index.module.less';
import moment from 'moment';
import { getLoggedInUser, userRole } from 'utilities/helpers';
import { message } from 'antd';

function ExportProposedInvoices({
  openExportPropModal,
  setOpenExportPropModal
}) {
  const [dateRange, setDateRange] = useState([moment(), moment()]);
  const [loading, setLoading] = useState(false);

  const user_role1 = getLoggedInUser();
  const user_role = user_role1?.user_details?.role;

  console.log('dateRange', dateRange);

  const onOk = () => {
    const fromDate = moment(dateRange[0]?._d)?.format('DD-MMM-YYYY') || '';
    const toDate = moment(dateRange[1]?._d)?.format('DD-MMM-YYYY') || '';
    setLoading(true);
    if (user_role === 'SBU Commercial') {
      fetch(
        `${process.env.REACT_APP_API_URL}/${userRole(
          user_role
        )}/exportMyProposedInvoices?from_date=${fromDate}&to_date=${toDate}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/csv',
            Authorization: `Bearer ${user_role1?.accessToken}`
          }
        }
      ).then((response) => {
        if (response?.status !== 500) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `a2p_exported_proposed_invoices.csv`);

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          });
          setOpenExportPropModal(false); //close the Modal...........
          setLoading(false);
        } else {
          message.success('No any proposed invoices found');
          setOpenExportPropModal(false);
          setLoading(false);
          console.log('responceNOT');
        }
      });
    } else if (user_role === 'SBU Head') {
      fetch(
        `${process.env.REACT_APP_API_URL}/${userRole(
          user_role
        )}/exportProposedInvoices?from_date=${fromDate}&to_date=${toDate}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/csv',
            Authorization: `Bearer ${user_role1?.accessToken}`
          }
        }
      ).then((response) => {
        if (response?.status !== 500) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `a2p_exported_proposed_invoices.csv`);

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          });
          setOpenExportPropModal(false); //close the Modal...........
          setLoading(false);
        } else {
          message.success('No any proposed invoices found');
          setOpenExportPropModal(false);
          setLoading(false);
          console.log('responceNOT');
        }
      });
    }
  };

  return (
    <>
      <Modal
        title={
          <span style={{ fontWeight: 600, fontSize: '16px' }}>
            Export Proposed Invoices :{' '}
          </span>
        }
        visible={openExportPropModal}
        // className={`${styles.check_circle_icon}`}
        width={'720px'}
        onCancel={() => setOpenExportPropModal(false)}
        onOk={() => setOpenExportPropModal(false)}
        // cancelButtonProps={{ style: { display: 'none' } }}
        okText={'Export'}
        okButtonProps={{
          //loading: postAllocationRequestLoading,
          disabled: loading,
          onClick: onOk
        }}
      >
        <Form
          name="date"
          initialValues={{ remember: true }}
          //onFinish={onOk}
          autoComplete="off"
        >
          <Row>
            <Col className="gutter-row" span={24}>
              <Form.Item
                style={{ fontWeight: '600' }}
                label="Select start and end date"
                name="fromDate"
                rules={[
                  {
                    required: false,
                    message: 'Please enter date'
                  }
                ]}
              >
                <DatePicker.RangePicker
                  format={'DD-MMM-YYYY'}
                  onChange={(x) => {
                    setDateRange(x);
                  }}
                  disabledDate={(current) => {
                    return current && current.valueOf() > Date.now();
                  }}
                  value={dateRange}
                  // separator={'-'}
                  // style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {loading && (
          <Spin tip="Loading" size="small">
            <div className={`${styles.content}`} />
          </Spin>
        )}
      </Modal>
    </>
  );
}
export default ExportProposedInvoices;
