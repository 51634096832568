import React, { useState, useEffect } from 'react';
import { Space, Typography, Tooltip } from 'antd';
import styles from './index.module.less';
import {
  liabilityToLabelVendorList,
  numFormating,
  numIntStyleFormat
} from 'utilities/helpers';

const { Text } = Typography;

const BuVendorListPage = ({ data }) => {
  if (!data) return null;

  const {
    proposed_liability,
    initiated_liability,
    total_liability,
    vendor_name,
    vendor_code
  } = data;

  const [liabilities, setLiabilities] = useState([]);

  useEffect(() => {
    let allLiabilities = Object.keys(total_liability);
    allLiabilities.splice(6, 1);
    setLiabilities(allLiabilities);
  }, [total_liability]);

  return (
    <div className={`${styles.summary_invoice_item_container} mb-3`}>
      <div style={{ position: 'relative' }}>
        <Space>
          <Text
            className={`sfprotext-medium ${styles.summary_invoice_item_vendor_details_container}`}
          >
            {`${vendor_name} (${vendor_code})`}
          </Text>
        </Space>
      </div>
      <div
        className={`${styles.summary_invoice_item_liabilities_container}`}
        style={{ paddingBottom: '24px', paddingTop: '5px' }}
      >
        <Space
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginRight: '100px',
            marginLeft: '10px'
          }}
        >
          {liabilities.map((item, index) => {
            return (
              <Space key={index} direction="vertical">
                <div
                  className="font-12"
                  style={{
                    color: '#666687',
                    padding: '2px',
                    margin: '0px'
                  }}
                >
                  {liabilityToLabelVendorList[item]}
                </div>

                <div
                  className="sfprotext-bold "
                  style={{
                    color: '#212134',
                    paddingLeft: '1px',
                    marginTop: '4px',
                    lineHeight: '0px',
                    fontSize: '12px' // Amount font size
                  }}
                >
                  <Tooltip
                    title={numIntStyleFormat(total_liability[item])}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    <span>T: </span>
                    {total_liability[item] === undefined ? (
                      ''
                    ) : total_liability[item] >= 0 ? (
                      total_liability[item] === undefined ? (
                        ''
                      ) : (
                        <span>₹{numFormating(total_liability[item])}</span>
                      )
                    ) : total_liability[item] === undefined ? (
                      ''
                    ) : (
                      <span>-₹{numFormating(total_liability[item])}</span>
                    )}
                  </Tooltip>
                </div>
                <div
                  className="sfprotext-bold "
                  style={{
                    color: '#212134',
                    paddingLeft: '3px',
                    marginTop: '14px',
                    lineHeight: '0px',
                    fontSize: '12px' // Amount font size
                  }}
                >
                  <Tooltip
                    title={numIntStyleFormat(initiated_liability[item])}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    <span>I: </span>
                    {initiated_liability[item] >= 0 ? (
                      initiated_liability[item] === undefined ? (
                        ''
                      ) : (
                        <span>₹{numFormating(initiated_liability[item])}</span>
                      )
                    ) : initiated_liability[item] === undefined ? (
                      ''
                    ) : (
                      <span>-₹{numFormating(initiated_liability[item])}</span>
                    )}
                  </Tooltip>
                </div>
                <div
                  className="sfprotext-bold "
                  style={{
                    color: '#212134',
                    paddingLeft: '2px',
                    marginTop: '14px',
                    lineHeight: '0px',
                    fontSize: '12px' // Amount font size
                  }}
                >
                  <Tooltip
                    title={numIntStyleFormat(proposed_liability[item])}
                    color={'white'}
                    overlayInnerStyle={{ color: '#32324D', fontSize: '12px' }}
                    arrowPointAtCenter="false"
                  >
                    <span>P: </span>
                    {proposed_liability[item] >= 0 ? (
                      proposed_liability[item] === undefined ? (
                        ''
                      ) : (
                        <span>₹{numFormating(proposed_liability[item])}</span>
                      )
                    ) : proposed_liability[item] === undefined ? (
                      ''
                    ) : (
                      <span>-₹{numFormating(proposed_liability[item])}</span>
                    )}
                  </Tooltip>
                </div>
              </Space>
            );
          })}
        </Space>
      </div>
    </div>
  );
};

export default BuVendorListPage;
